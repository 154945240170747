import { createSlice } from '@reduxjs/toolkit';

const chatData = createSlice({
  name: 'chat',
  initialState: {
    data: [{ text: 'THE_GODS_OF_THE_WORLD_GREET_YOU' }],
    onlineList: [],
    chatHeroList: [],
    private: [],
    leave: {},
  },
  reducers: {
    sendToChat(state, action) {
      state.data = [action.payload, ...state.data];
    },

    sendToChatClear(state, action) {
      state.data = action.payload;
    },

    sendToChatOnlineList(state, action) {
      state.onlineList = action.payload;
    },

    sendToChatHeroList(state, action) {
      state.chatHeroList = action.payload;
    },

    sendToPrivateChat(state, action) {
      state.private = [action.payload, ...state.private];
    },

    setChatLeave(state, action) {
      state.leave = action.payload;
    },
  },
});

export const {
  sendToChat,
  sendToChatClear,
  sendToChatOnlineList,
  sendToChatHeroList,
  sendToPrivateChat,
  setChatLeave,
} = chatData.actions;

export { chatData };
