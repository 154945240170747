import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { useSelector } from 'react-redux';

import imgCh from '../../media/img/game/playingField/chat/ch.png';
import { Dropdown } from '../dropdown/Dropdown';
import imgMini1 from '../../media/img/game/character/miniInfo/mini1.png';

import Tooltip from '../tooltip/Tooltip';
import CharacterInfoTooltip from '../character/CharacterInfoTooltip';
import { useRequest } from '../../hooks/useRequest';

export const ChatListItem = memo(
  ({ onHandleSelectHero, dropdownItems, onlineListItem, heroName }) => {
    const ref = useRef();
    const [show, toggleShow] = useToggle(false);
    const { glory, name } = onlineListItem;
    const user = useSelector((state) => state.allUserData.data.h_info.name);
    const [openHover, setOpenHover] = useState(false);
    const [requestSend] = useRequest();
    const [lock, setLock] = useState(false);
    const lockRef = useRef(null);

    const getInfoForUser = () => {
      if (!openHover)
        requestSend.sendData('RequestHeroInfo', {
          id: onlineListItem.hero_id,
        });
      setOpenHover(true);
    };

    useClickAway(ref, () => {
      toggleShow(false);
    });

    const handleToggleShow = () => {
      if (name !== user) {
        toggleShow();
      }
    };

    const onHandleSelect = useCallback(
      (key) => {
        if (key === '/to @' && name === heroName) {
          return;
        } else if (key === '/to @' && name !== heroName) {
          onHandleSelectHero(onlineListItem, `/to @${name}`);
          toggleShow(false);
          return;
        }
        onHandleSelectHero(onlineListItem, key);
        toggleShow(false);
      },
      [toggleShow, onHandleSelectHero, onlineListItem, heroName],
    );

    const handleMouseDown = () => {
      if (name === heroName) {
        return;
      }
      onHandleSelect(`/to @${name}`);
      setLock(true);
      lockRef.current = setTimeout(() => {
        setLock(false);
      }, 3000);
    };

    useEffect(() => {
      return () => {
        if (lockRef.current) {
          clearTimeout(lockRef.current);
        }
      };
    }, []);

    return (
      <>
        {/* {name !== user ? ( */}
        <div className="chat__block2--box--item" ref={ref}>
          {!!onlineListItem.battle_id && (
            <img src={imgMini1} alt="" style={{ backgroundColor: 'red' }} />
          )}
          <img src={imgCh} alt="" onClick={handleToggleShow} />
          <div className="chat__block2--box--item--lvl">{glory}</div>
          <Tooltip
            key={Math.random()}
            zIndex={9990}
            content={<CharacterInfoTooltip />}
            onHover={() => getInfoForUser()}
            onLeave={() => setOpenHover(false)}
            disable={lock}
            onMouseDown={handleMouseDown}
          >
            <div className="chat__block2--box--item--user">{name}</div>
          </Tooltip>
          <Dropdown
            show={show}
            items={dropdownItems}
            onClick={onHandleSelect}
          />
        </div>
        {/* ) : null} */}
      </>
    );
  },
);
