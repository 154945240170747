import React from 'react';
import Images from '../../../helper/Images';
import Lang from '../../language';
import imgG from '../../../media/img/inventory/gold.png';
import imgE from '../../../media/img/inventory/etherium.png';

const InventoryHubThingsItemKey = ({ toItem }) => {
  // console.log('toItem', toItem);
  return (
    <div className="inventory__container__hub--body--things--item">
      <div className="inventory__container__hub--body--things--item--box">
        <div
          className={`inventory__container__hub--body--things--item--box--icon`}
        >
          <Images name={toItem.id} />
        </div>
        <div className="inventory__container__hub--body--things--item--box--head">
          <div className="inventory__container__hub--body--things--item--box--head--title">
            <Lang>{toItem.id}</Lang>
          </div>
        </div>
      </div>
      <div className="inventory__container__hub--body--things--item--box">
        <div className="inventory__container__hub--body--things--item--box--head--other">
          <div
            className="inventory__container__hub--body--things--item--box--head--other--char"
            style={{ flexWrap: 'wrap', maxWidth: '70%' }}
          >
            <Lang>
              {toItem.$type === 31
                ? 'KEY_DESC'
                : toItem.$type === 32
                  ? 'PICK_DESC'
                  : ''}
            </Lang>
          </div>
        </div>
      </div>
      <div className="inventory__container__hub--body--things--item--box">
        {toItem.price && toItem.price !== 0 && (
          <div className="inventory__container__hub--body--things--item--box--info">
            <div className="inventory__container__hub--body--things--item--box--info--title">
              <Lang>PRICE</Lang>
            </div>
            <div className="inventory__container__hub--body--things--item--box--info--other">
              <img src={imgG} alt="" />
              <div className={toItem.id}>{toItem.price[1]}</div>
            </div>
            <div className="inventory__container__hub--body--things--item--box--info--other">
              <img src={imgE} alt="" />
              <div className={toItem.id}>{toItem.price[2]}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InventoryHubThingsItemKey;
