import React from 'react';
import './styles.scss';
const LocationElement = ({ index, i }) => {
  return (
    <div
      key={index}
      name={i.name}
      className="locationIn__container--item"
      style={{
        top: i.img.top,
        left: i.img.left,
        width: i.img.width,
        height: i.img.height ? i.img.height : 'auto',
        zIndex: index,
      }}
      onClick={i.onClick}
    >
      {i.img.src ? (
        <img className="imgLI" src={i.img.src} alt="" />
      ) : (
        <div
          className="imgLI"
          style={{
            width: i.img.width,
            height: i.img.height ? i.img.height : 'auto',
          }}
        />
      )}
      <div
        className="locationIn__container--item--back"
        style={{
          bottom: i.img.backBottom ? i.img.backBottom : 0,
        }}
      >
        <img src={i.img.srcBack} alt="" style={i.style} />
      </div>
    </div>
  );
};

export default LocationElement;
