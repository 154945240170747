import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import imgBottom from '../../media/img/game/character/botBg.png';
import imgSlot1 from '../../media/img/game/character/slots/1.png';
import imgSlot2 from '../../media/img/game/character/slots/2.png';
import imgSlot3 from '../../media/img/game/character/slots/3.png';
import imgSlot4 from '../../media/img/game/character/slots/4.png';
import imgSlot5 from '../../media/img/game/character/slots/5.png';
import imgSlot6 from '../../media/img/game/character/slots/6.png';
import imgSlot7 from '../../media/img/game/character/slots/7.png';
import imgSlot8 from '../../media/img/game/character/slots/8.png';
import imgSlot9 from '../../media/img/game/character/slots/9.png';
import imgSlot10 from '../../media/img/game/character/slots/10.png';
import './styles.scss';

import Images from '../../helper/Images';
import { useDictionary } from '../../hooks/useDictionary';
import { useRequest } from '../../hooks/useRequest';
import imgC1 from '../../media/img/character/ch1.png';
import imgC2 from '../../media/img/character/ch2.png';
import imgC3 from '../../media/img/character/ch3.png';
import imgC4 from '../../media/img/character/ch4.png';
import imgC5 from '../../media/img/character/ch5.png';
import imgC6 from '../../media/img/character/ch6.png';
import imgC7 from '../../media/img/character/ch7.png';
import imgC8 from '../../media/img/character/ch8.png';
import imgMob1 from '../../media/img/npc/Mob1.png';
import imgMob2 from '../../media/img/npc/Mob2.png';
import imgMob3 from '../../media/img/npc/Mob3.png';
import imgMob4 from '../../media/img/npc/Mob4.png';
import imgMob5 from '../../media/img/npc/Mob5.png';
import imgMob6 from '../../media/img/npc/Mob6.png';
import imgMob7 from '../../media/img/npc/Mob7.png';
import imgMob8 from '../../media/img/npc/Mob8.png';
import imgMob9 from '../../media/img/npc/Mob9.png';
import InventoryHubThingsItem from '../Inventory/InventoryHubThings/InventoryHubThingsItem';
import Tooltip from '../tooltip/Tooltip';
import CharacterInfoTooltip from './CharacterInfoTooltip';
import InventoryHubEffect from '../Inventory/InventoryHubSkills/InverntoryHubEffect';
import BattleInitBattleProgress from '../battle/BattleInit/BattleInitBattleProgress';

const slots = [
  {
    id: 1,
    img: imgSlot1,
  },
  {
    id: 2,
    img: imgSlot2,
  },
  {
    id: 3,
    img: imgSlot3,
  },
  {
    id: 4,
    img: imgSlot4,
  },
  {
    id: 5,
    img: imgSlot5,
  },
  {
    id: 6,
    img: imgSlot6,
  },
  {
    id: 7,
    img: imgSlot7,
  },
  {
    id: 8,
    img: imgSlot8,
  },
  {
    id: 9,
    img: imgSlot9,
  },
  {
    id: 10,
    img: imgSlot10,
  },
];

const characterIDList = new Map([
  ['her.1', imgC1],
  ['her.2', imgC2],
  ['her.3', imgC3],
  ['her.4', imgC4],
  ['her.5', imgC5],
  ['her.6', imgC6],
  ['her.7', imgC7],
  ['her.8', imgC8],
]);

const botIDList = new Map([
  ['avatar1', imgMob1],
  ['avatar2', imgMob2],
  ['avatar3', imgMob3],
  ['avatar4', imgMob4],
  ['avatar5', imgMob5],
  ['avatar6', imgMob6],
  ['avatar7', imgMob7],
  ['avatar8', imgMob8],
  ['avatar9', imgMob9],
]);

const CharacterAntagonist = ({
  pairCurrent,
  effects,
  disableTooltip = false,
}) => {
  const [requestSend] = useRequest();
  const [openHover, setOpenHover] = useState(false);
  const [dict, getInfoDictionary] = useDictionary();
  const battleStatus = useSelector((state) => state.battle.status);
  const bots = dict?.bot_presets ? dict?.bot_presets : [];
  const [antoPic, setAntoPic] = useState(null);
  const [mob, setMob] = useState(null);

  const getInfoForUser = () => {
    if (!openHover)
      requestSend.sendData('RequestHeroInfo', {
        id: pairCurrent?.hero_data?.h_info?.id,
      });
    setOpenHover(true);
  };

  useEffect(() => {
    if (pairCurrent?.hero_data?.h_info?.name.includes('$') && bots.length > 0) {
      let name = pairCurrent?.hero_data?.h_info?.name.split('$');
      let pic = bots.filter(
        (el) => name[0].includes(el.name) && el.name !== '',
      );
      if (pic[0]?.id && pic[0]?.id.startsWith('mob')) {
        setAntoPic(botIDList.get(pic[0]?.avatar));
        setMob(true);
      } else {
        setAntoPic(
          characterIDList.get(pairCurrent?.hero_data?.h_info?.preset_id),
        );
      }
    } else {
      setAntoPic(
        characterIDList.get(pairCurrent?.hero_data?.h_info?.preset_id),
      );
    }
  }, [pairCurrent]);

  const toSlots = (val1, val2) => {
    return slots.slice(val1, val2).map((i, index) => {
      const user = pairCurrent?.hero_data?.body?.equipments.find(
        (item) => item.slot === i.id,
      );
      if (user) {
        const infoWeapons = getInfoDictionary('equipments', user.model.dict_id);

        if (isEmpty(infoWeapons)) return null;

        if (!infoWeapons?.is_visibility)
          return (
            <div
              key={index + 'AntoChar'}
              id={`slot_${i.id}`}
              className="character--inventory--item"
            >
              {i.img && <img src={i.img} alt="" />}
            </div>
          );

        return (
          <Tooltip
            key={index + 'AntoChar'}
            open={true}
            content={
              <InventoryHubThingsItem
                user={user.model}
                pairCurrent={pairCurrent}
              />
            }
          >
            <div
              id={`slot_${i.id}`}
              className={`character--inventory--item  rr-${infoWeapons.quality}`}
            >
              <Images name={infoWeapons.id} />
            </div>
          </Tooltip>
        );
      } else {
        return (
          <div
            key={index + 'AntoChar'}
            id={`slot_${i.id}`}
            className="character--inventory--item"
          >
            {i.img && <img src={i.img} alt="" />}
          </div>
        );
      }
    });
  };

  return (
    <div className="character">
      {/*<div className="character--pre">*/}
      {/*  {effects.effects &&*/}
      {/*    effects.hero_id === pairCurrent?.hero_data?.h_info?.id &&*/}
      {/*    effects?.effects.map((el) => {*/}
      {/*      return (*/}
      {/*        <div*/}
      {/*          key={`debufs-${el.eff_id}`}*/}
      {/*          className="character__bufs--item character__bufs--item--ant negative"*/}
      {/*        >*/}
      {/*          <Images name={el.eff_id} />*/}
      {/*        </div>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*</div>*/}
      <div className="character--inventory">{toSlots(0, 5)}</div>
      <div className="character--center">
        <Tooltip
          key={Math.random()}
          zIndex={9990}
          content={<CharacterInfoTooltip mob={mob} />}
          onHover={() => getInfoForUser()}
          onLeave={() => setOpenHover(false)}
          disable={disableTooltip ? true : battleStatus !== 2 ? false : true}
        >
          <img src={antoPic} alt="" />
        </Tooltip>
      </div>
      <div className="character--inventory">{toSlots(5, 10)}</div>
      <img className="character--bottom-bg" src={imgBottom} alt="" />
      <div
        className="character__bufs"
        style={{ left: '-2.8vw', top: '-0.8vw' }}
      >
        {pairCurrent?.hero_data?.effects?.length > 0 &&
          pairCurrent?.hero_data?.effects?.map((i) => {
            const infoWeapons = getInfoDictionary('effects', i.dict_id);
            const currentTime = Date.now();
            return (
              <>
                {/*<div className="character__bufs--item positive">*/}
                {/*    <Images name={i.dict_id}/>*/}
                {/*</div>*/}
                <Tooltip
                  key={i.id + 'effect'}
                  content={
                    <InventoryHubEffect
                      toItem={{ dict_id: infoWeapons?.id, all: i }}
                    />
                  }
                >
                  <div
                    key={i.dict_id}
                    className="character__bufs--item negative"
                  >
                    <Images name={infoWeapons?.id} />
                    {/*<div className="character__bufs--item--many">*/}
                    {/*    2*/}
                    {/*</div>*/}
                  </div>
                </Tooltip>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default CharacterAntagonist;
