import React, { memo, useEffect } from 'react';
import HeroNameLink from '../HeroNameLink/HeroNameLink';
import Tooltip from '../../tooltip/Tooltip';
import { useDictionary } from '../../../hooks/useDictionary';
import InventoryHubThingsItemOther from '../../Inventory/InventoryHubThings/InventoryHubThingsItemOther';
import InventoryHubThingsItemRun from '../../Inventory/InventoryHubThings/InventoryHubThingsItemRun';
import InventoryHubThingsItem from '../../Inventory/InventoryHubThings/InventoryHubThingsItem';
import Lang from '../../language';
import { useTranslate } from '../../language/action/line';
import { replaceWithComponent } from '../../../helper/helpers';
import parse from 'html-react-parser';

const ChatMessageInfo = ({ message, toMsg }) => {
  const [dict, getInfoDictionary] = useDictionary();
  const [langTranslate] = useTranslate(message.text);

  return (
    <div className="chat__block1--body--message--item">
      <div className="chat__block1--body--message--item--time">
        {message?.time}
      </div>
      {message?.hero?.name && (
        <div className="hero-tag-link">
          <HeroNameLink userHero={message.hero.hero_id} toMsg={toMsg}>
            {/*<span id={message.hero.hero_id} className="chat__block1--body--message--item--sender">*/}
            {message.hero.name}
            {/*</span>*/}
          </HeroNameLink>
        </div>
      )}
      <div className="chat__block1--body--message--item--text">
        {replaceWithComponent(
          [
            {
              pattern: /\/nl/g,
              component: (match) => {
                return <br />;
              },
            },
            {
              pattern: /\|/g,
              component: () => <strong> {'|'} </strong>,
            },
            {
              pattern: /<=/g,
              component: () => <strong> {'<='} </strong>,
            },
            {
              pattern: /#([^*]+)\*/,
              component: (item) => {
                const infoWeapons = getInfoDictionary('equipments', item[1]);

                if (infoWeapons) {
                  return (
                    <Tooltip
                      key={Math.random()}
                      zIndex={9990}
                      content={
                        infoWeapons.item_type <= 2 ? (
                          <InventoryHubThingsItemOther
                            toItem={infoWeapons}
                            init={infoWeapons}
                          />
                        ) : infoWeapons.item_type === 3 ? (
                          <InventoryHubThingsItemRun toItem={infoWeapons} />
                        ) : (
                          <InventoryHubThingsItem
                            user={infoWeapons}
                            slot={infoWeapons.slot}
                            init={infoWeapons}
                          />
                        )
                      }
                      onLeave={() => ''}
                    >
                      <div
                        className={`chat__block1--body--message--item--text--item rrC-${infoWeapons.quality}`}
                      >
                        <Lang>{item[1]}</Lang>
                      </div>
                    </Tooltip>
                  );
                } else return '';
              },
            },
            {
              pattern: /%([^&]+)&([^*]+)\*/,
              component: (item) => {
                return (
                  <HeroNameLink userHero={item[2]} toMsg={toMsg}>
                    {/*<span id={message.hero.hero_id} className="chat__block1--body--message--item--sender">*/}
                    {item[1]}
                    {/*</span>*/}
                  </HeroNameLink>
                );
              },
            },
          ],
          langTranslate,
        ).map((element, index) => {
          if (typeof element === 'string' && element.startsWith('<a')) {
            return parse(element);
          } else {
            return <React.Fragment key={index}>{element}</React.Fragment>;
          }
        })}
        {message.val && ': ' + message.val}
      </div>
    </div>
  );
};

export default memo(ChatMessageInfo);
