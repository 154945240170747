import React from 'react';

import './styles.scss';
import Lang from '../language';
import UiButtonOne from '../ui/buttons/UiButtonOne';
import imgClose from '../../media/img/createCharacter/close-bold-svgrepo-com.svg';

const Modal = ({
  show,
  buttonGray,
  buttonRed,
  props,
  descPlus,
  data,
  styles,
  zIndex,
  close = null,
}) => {
  return (
    <div
      className={`modal ${show ? 'modal--open' : ''} ${styles}`}
      style={{
        zIndex: zIndex ? zIndex : 999,
      }}
    >
      {show && (
        <div
          className="modal__container"
          style={{
            maxWidth: props?.maxWidth !== undefined ? props.maxWidth : 'none',
          }}
        >
          {close && (
            <img
              onClick={close}
              src={imgClose}
              alt=""
              className="modal__container--close"
            />
          )}
          <div className="modal__container--content">
            {props?.title && (
              <h1>
                <Lang>{props.title}</Lang>
              </h1>
            )}
            {props?.desc && (
              <p>
                <Lang>{props.desc}</Lang>
                {descPlus && (
                  <div className="modal__container--content--plus">
                    <Lang>{descPlus}</Lang>
                  </div>
                )}
              </p>
            )}
          </div>
          {data && data}
          <div className="modal__container--buttons">
            {props && props.buttonGray && (
              <UiButtonOne
                disabled={props.buttonGray?.disabled}
                className="modal__container--buttons--gray"
                onClick={buttonGray}
              >
                <Lang>{props.buttonGray.text}</Lang>
              </UiButtonOne>
            )}
            {props?.buttonRed && (
              <UiButtonOne
                disabled={props.buttonRed?.disabled}
                className="modal__container--buttons--red"
                onClick={buttonRed}
              >
                <Lang>{props.buttonRed.text}</Lang>
              </UiButtonOne>
            )}
            {props?.buttonDecline && (
              <UiButtonOne
                disabled={props.buttonDecline?.disabled}
                className="modal__container--buttons--decline"
                onClick={buttonRed}
              >
                <Lang>{props.buttonDecline.text}</Lang>
              </UiButtonOne>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
