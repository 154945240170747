import { createSlice } from '@reduxjs/toolkit';

const tooltip = createSlice({
  name: 'tooltip',
  initialState: {
    status: false,
    data: {},
  },
  reducers: {
    tooltipOpen(state, action) {
      state.status = action.payload;
    },
    tooltipAddInfo(state, action) {
      state.data = action.payload;
    },
  },
});

export const { tooltipOpen, tooltipAddInfo } = tooltip.actions;

export { tooltip };
