import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { useSelector } from 'react-redux';
import CharacterAntagonist from '../../../components/character/CharacterAntagonist';
import BattleAntagonistBox from '../../../components/battle/BattleAntagonistBox';
import Lang from '../../../components/language';
import UiButtonOne from '../../../components/ui/buttons/UiButtonOne';
import { redirect } from '../../../helper/helpers';
import imgClose from '../../../media/img/createCharacter/close-bold-svgrepo-com.svg';
import imgShare from '../../../media/img/createCharacter/share-svgrepo-com (1).svg';
import Modal from '../../../components/modal/Modal';
import ChooseCharacterBodyModalShare from './ChooseCharacterBodyModalShare';
import { useRequest } from '../../../hooks/useRequest';

const ChooseCharacterBody = () => {
  const userHeroes = useSelector((state) => state.allUserData.heroes);
  const userUserMain = useSelector((state) => state.allUserData.user_main);
  const [requestSend] = useRequest();
  const [listHeroes, setListHeroes] = useState([]);
  const [selectHero, setSelectHero] = useState(false);
  const [openModalOne, setOpenModalOne] = useState({ show: false, data: {} });

  const sendShareCancel = (hero) => {
    if (hero.driver.is_owner) {
      requestSend.sendData('RequestDriverCancelByOwner', {
        hero_id: hero.id,
      });
    } else requestSend.sendData('RequestDriverCancel', { hero_id: hero.id });
  };

  const sendSetHero = (hero) => {
    setSelectHero(true);
    requestSend.sendData('RequestSetHero', { hero_id: hero.id });
    // redirect('/game')
  };

  useEffect(() => {
    if (userHeroes.length > 0) {
      let initialArray = [{}, {}, {}];
      let newArray = userHeroes;

      let resultArray = [
        ...Array.from(
          {
            length: Math.max(0, initialArray.length - newArray.length),
          },
          () => ({}),
        ),
        ...newArray,
      ];
      if (resultArray.length >= 3 && !resultArray.includes({})) {
        if (
          resultArray.filter((obj) => Object.keys(obj).length === 0).length ===
          0
        ) {
          resultArray.push({});
        }
      }
      const toResultArray = resultArray
        .filter((obj) => Object.keys(obj).length !== 0)
        .concat(resultArray.filter((obj) => Object.keys(obj).length === 0));
      const xx = toResultArray.sort((a, b) => {
        const isEmptyObject = (obj) =>
          Object.keys(obj).length === 0 && obj.constructor === Object;

        const isOwnerA = a.driver && a.driver.is_owner === true;
        const isOwnerB = b.driver && b.driver.is_owner === true;

        if (isOwnerA !== isOwnerB) {
          return isOwnerB - isOwnerA;
        }

        if (a.hasOwnProperty('level') && !b.hasOwnProperty('level')) {
          return -1;
        }

        if (!a.hasOwnProperty('level') && b.hasOwnProperty('level')) {
          return 1;
        }

        // Если a - пустой объект {}, b не пустой, помещаем a в конец
        if (isEmptyObject(a) && !isEmptyObject(b)) {
          return 1;
        }

        // Если b - пустой объект {}, a не пустой, помещаем b в конец
        if (!isEmptyObject(a) && isEmptyObject(b)) {
          return -1;
        }

        return 0;
      });

      setListHeroes(xx);
    }
  }, [userHeroes]);

  useEffect(() => {
    if (selectHero && userUserMain?.u_info?.hero_id) redirect('/game');
  }, [userUserMain]);

  return (
    <>
      <Modal
        show={openModalOne.show}
        props={{
          title: 'Доступ к персонажу',
        }}
        data={<ChooseCharacterBodyModalShare hero={openModalOne.data} />}
        styles="mini"
        close={() => setOpenModalOne((prev) => ({ ...prev, show: false }))}
      />
      <div className="chooseCharacter__body__container">
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          slidesPerView={3}
        >
          {listHeroes?.length > 0
            ? listHeroes?.map((e, index) => {
                if (
                  e !== undefined &&
                  e?.level?.glory !== undefined &&
                  (e?.driver?.is_owner || e?.driver?.is_confirm)
                ) {
                  const hero_data = { hero_data: e };

                  return (
                    <SwiperSlide key={e.id}>
                      <div className="chooseCharacter__body__container__box">
                        {e.driver.is_owner ? (
                          <div
                            className="chooseCharacter__body__container__box--mini"
                            onClick={() =>
                              setOpenModalOne({
                                show: true,
                                data: e,
                              })
                            }
                          >
                            <img src={imgShare} alt="" />
                          </div>
                        ) : (
                          <div
                            className="chooseCharacter__body__container__box--mini"
                            onClick={() => sendShareCancel(e)}
                          >
                            <img src={imgClose} alt="" />
                          </div>
                        )}
                        <div className="chooseCharacter__body__container__box--iner">
                          <BattleAntagonistBox
                            pairCurrent={{
                              ...hero_data,
                              glory: hero_data.hero_data.level.glory,
                            }}
                            disableTooltip={true}
                            user={true}
                          />
                          {((e.driver.is_owner &&
                            !e.driver.is_confirm &&
                            !e.driver.driver_id) ||
                            (!e.driver.is_owner &&
                              e.driver.is_confirm &&
                              e.driver.driver_id)) && (
                            <UiButtonOne
                              onClick={() => {
                                sendSetHero(e);
                              }}
                            >
                              <Lang>TO_CONNECT</Lang>
                            </UiButtonOne>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                } else {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        className="chooseCharacter__body__container__box"
                        onClick={() => redirect('/new-character')}
                      >
                        <div className="chooseCharacter__body__container__box--iner noIn">
                          +<div>Создать персонажа</div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                }
              })
            : Array.from({ length: 3 }, () => ({})).map((i, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="chooseCharacter__body__container__box"
                    onClick={() => redirect('/new-character')}
                  >
                    <div className="chooseCharacter__body__container__box--iner noIn">
                      +<div>Создать персонажа</div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </>
  );
};

export default ChooseCharacterBody;
