import React, { useEffect } from 'react';
import Lang from '../../language';
import { useRequest } from '../../../hooks/useRequest';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../../hooks/useDictionary';
import Images from '../../../helper/Images';
import { tooltipInfo } from '../../../store/reducer/Slices/SlicesTooltipInfo';

const choiceList = new Map([
  [
    1,
    {
      title: 'FORCE',
      desc: 'FORCE_DESC',
      val: [1],
    },
  ],
  [
    4,
    {
      title: 'DEXTERITY',
      desc: 'DEXTERITY_DESC',
      val: [4],
    },
  ],
  [
    12,
    {
      title: 'INTUITION',
      desc: 'INTUITION_DESC',
      val: [12],
    },
  ],
  [
    23,
    {
      title: 'INTELLIGENCE',
      desc: 'INTELLIGENCE_DESC',
      val: [23],
    },
  ],
  [
    19,
    {
      title: 'HEALTH_TITLE',
      desc: 'HEALTH_DESC',
      val: [19],
    },
  ],
  [
    32,
    {
      title: 'DAMAGE_TITLE',
      desc: 'DAMAGE_DESC',
      val: [32],
    },
  ],
  [
    7,
    {
      title: 'DODGE_TITLE',
      desc: 'DODGE_DESC',
      val: [7],
    },
  ],
  [
    16,
    {
      title: 'CRETE_TITLE',
      desc: 'CRETE_DESC',
      val: [15],
    },
  ],
  [
    21,
    {
      title: 'MAXIMUM_HP',
      desc: 'MAXIMUM_HP_DESC',
      val: [21],
    },
  ],
  [
    45,
    {
      title: 'SPIRIT',
      desc: 'SPIRIT_DESC',
      val: [45],
    },
  ],
  [
    27,
    {
      title: 'HEAD_ARMOR',
      desc: 'HEAD_ARMOR_DESC',
      val: [27],
    },
  ],
  [
    28,
    {
      title: 'CHEST_ARMOR',
      desc: 'CHEST_ARMOR_DESC',
      val: [28],
    },
  ],
  [
    29,
    {
      title: 'WAIST_ARMOR',
      desc: 'WAIST_ARMOR_DESC',
      val: [29],
    },
  ],
  [
    30,
    {
      title: 'LEGS_ARMOR',
      desc: 'LEGS_ARMOR_DESC',
      val: [30],
    },
  ],
  [
    22,
    {
      title: 'HP',
      desc: 'HP_DESC',
      val: [22],
    },
  ],
  [
    44,
    {
      title: 'STRENGTH_OF_MIND',
      desc: 'STRENGTH_OF_MIND_DESC',
      val: [44],
    },
  ],
  [
    31,
    {
      title: 'ARMOR',
      desc: 'ARMOR_DESC',
      val: [31],
    },
  ],
  [
    111,
    {
      title: 'GLORY_LEVEL',
      desc: 'GLORY_LEVEL_DESC',
      val: [],
    },
  ],
  [
    112,
    {
      title: 'LEVEL',
      desc: 'LEVEL_DESC',
      val: [],
    },
  ],
  [
    11,
    {
      title: 'ANTI_DODGE',
      desc: 'ANTI_DODGE_DESC',
      val: [10],
    },
  ],
  [
    18,
    {
      title: 'ANTI_CRETE',
      desc: 'ANTI_CRETE_DESC',
      val: [17],
    },
  ],
  [
    115,
    {
      title: 'DINAR',
      desc: 'DINAR_DESC',
      val: [],
    },
  ],
  [
    116,
    {
      title: 'ETH',
      desc: 'ETH_DESC',
      val: [],
    },
  ],
]);

const CharacterInfoTooltip = ({ choice, hero_id = null }) => {
  const [requestSend] = useRequest();
  const TooltipInfo = useSelector((state) => state.tooltipInfo.data);
  const [dict, getInfoDictionary] = useDictionary();

  useEffect(() => {
    if (hero_id) {
      requestSend.sendData('RequestCharactersInfo', { hero_id });
    } else requestSend.sendData('RequestCharactersInfo');
  }, []);

  return (
    <div className="game--container__left--block__info--box__tooltip">
      <h2>
        <Lang>{choiceList.get(+choice)?.title}</Lang>
      </h2>
      <h5>
        <Lang>{choiceList.get(+choice)?.desc}</Lang>
      </h5>
      <div className="game--container__left--block__info--box__tooltip--list">
        {choiceList.get(+choice)?.val.map((e) =>
          TooltipInfo.map(
            (y) =>
              y.char === e &&
              y.equipments.map((u) => {
                const info = getInfoDictionary('equipments', u.dict_id);

                return (
                  <div className="game--container__left--block__info--box__tooltip--list--item">
                    + {u.value}{' '}
                    <div
                      className={`game--container__left--block__info--box__tooltip--list--item--img rr-${info.quality}`}
                    >
                      <Images name={info.id} />
                    </div>{' '}
                    <Lang>{info.id}</Lang>
                  </div>
                );
              }),
          ),
        )}
        {choiceList.get(+choice)?.val.map((e) =>
          TooltipInfo.map(
            (y) =>
              y.char === e &&
              y.effects.map((u) => {
                const info = getInfoDictionary('effects', u.dict_id);

                return (
                  <div className="game--container__left--block__info--box__tooltip--list--item">
                    + {u.value}{' '}
                    <div className="game--container__left--block__info--box__tooltip--list--item--img">
                      <Images name={info.id} />
                    </div>{' '}
                    {info.id}
                  </div>
                );
              }),
          ),
        )}
      </div>
    </div>
  );
};

export default CharacterInfoTooltip;
