import React, { useContext, useEffect, useRef, useState } from 'react';

import { useRequest } from '../../../hooks/useRequest';
import imgAbilityCombat from '../../../media/img/inventory/skills/abilityCombat.png';
import imgAbilityNonCombat from '../../../media/img/inventory/skills/abilityNonCombat.png';
import { toRomanNumber } from '../../../helper/helpers';
import DragAndDrop from '../../dragAndDrop/DragAndDrop';
import imgAbility1 from '../../../media/img/skills/ability1.png';
import imgAbility2 from '../../../media/img/skills/ability2.png';
import imgAbility3 from '../../../media/img/skills/ability3.png';
import imgAbility4 from '../../../media/img/skills/ability4.png';
import imgAbility5 from '../../../media/img/skills/ability5.png';
import { UserContext } from '../../../page/Game/Game';
import InventoryHubSkillsItem from './InventoryHubSkillsItem';
import Tooltip from '../../tooltip/Tooltip';
import InventoryUseSkill from '../InventoryUseSkill';
import Modal from '../../modal/Modal';
import InventoryItemCell from '../InventoryItemCell/InventoryItemCell';
import { useDictionary } from '../../../hooks/useDictionary';
import { useSelector } from 'react-redux';
import InventoryHubThingsItemBuffs from '../InventoryHubThings/InventoryHubThingsItemBuffs';
import InventoryMiniSlotsActive from '../InventoryMiniSlotsActive';
import Images from '../../../helper/Images';
import Lang from '../../language';

const tabsAbilities = [
  {
    id: 2,
    img: imgAbilityCombat,
  },
  {
    id: 4,
    img: imgAbilityNonCombat,
  },
];
const Img = {
  // Мок пока-что нет картинок
  1: imgAbility1,
  2: imgAbility2,
  3: imgAbility3,
  4: imgAbility4,
  5: imgAbility5,
};

const InventoryHubSkillsAbilities = ({ abilities, isBattle }) => {
  const [tabAbilities, setTabAbilities] = useState(2);
  const [requestSend] = useRequest();
  const userContext = useContext(UserContext);
  const [tooltip, setTooltip] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [nowData, setNowData] = useState(undefined);
  const [infoAbilities, setInfoAbilities] = useState([]);
  const [
    dict,
    getInfoDictionary,
    getInfoDictionaryName,
    getInfoDictionaryAllName,
  ] = useDictionary();
  const userInvent = useSelector(
    (state) => state.allUserData.data.invent.invent_equipments,
  );
  const userBody = useSelector((state) => state.allUserData.data.body);
  const [emptyEquipmentItems, setEmptyEquipmentItems] = useState(26);

  useEffect(() => {
    let sd = userInvent.filter((i) => i.$type === tabAbilities);
    setInfoAbilities(sd);
    if (sd.length <= 29) {
      setEmptyEquipmentItems(29 - sd.length);
    } else {
      setEmptyEquipmentItems(0);
    }
  }, [tabAbilities]);

  const handleMouseEnter = (info) => {
    if (!isBattle && info.required_runes?.length > 0) {
      const runeItems = document.getElementsByClassName('rune');
      for (let i = 0; i < runeItems.length; i++) {
        runeItems[i].style.opacity = 0.4;
      }
      for (let i = 0; i < info.required_runes.length; i++) {
        let els = document.getElementsByClassName(
          `rune_${info.required_runes[i]}`,
        )[0];
        if (els) els.style.opacity = 1;
      }
    }
  };

  const handleMouseLeave = () => {
    const runeItems = document.getElementsByClassName('rune');
    for (let i = 0; i < runeItems.length; i++) {
      runeItems[i].removeAttribute('style');
    }
  };

  const toDropData = (elem) => {
    requestSend.sendData('RequestActiveSlotOff', { slot: elem.slot });
  };

  const toActiveData = (slot) => {
    const slots = userBody.active_slots.find((i) => !i.model);
    if (slots) {
      requestSend.sendData('RequestActiveSlotOn', {
        slot: slots.slot,
        id: slot.id,
      });
    } else {
      requestSend.sendData('RequestActiveSlotOn', {
        slot: slots.slot,
        id: 1,
      });
    }
  };

  const checkRun = (infoSkills) => {
    let check = 'yes';
    let oper_slots = userBody.oper_slots;
    if (infoSkills.required_runes?.length > 0) {
      infoSkills.required_runes.forEach((i) => {
        // let a = userInvent.find((y) => y.dict_id === i);
        // let b = oper_slots.find((y) => y.model?.dict_id === i);
        // check = a === undefined ? (b === undefined ? 'no' : 'yes') : 'yes';
        check = oper_slots.find((y) => y.model?.dict_id === i) ? 'yes' : 'no';
      });
    }

    return check;
  };

  return (
    <div className="inventory__container__hub--body--skills--abilities">
      {nowData && (
        <Modal
          show={openModal}
          styles="mini"
          props={{
            title: nowData.props.title || '',
            desc: nowData.props.desc || '',
            buttonGray: { text: 'CANCEL' },
            buttonRed: { text: 'CONFIRM' },
          }}
          data={nowData.component || ''}
          buttonGray={() => setOpenModal(false)}
          buttonRed={() => nowData.buttonClickRed}
        />
      )}
      <div className="inventory__container__hub--body--skills--abilities__title">
        <Lang>ACTIVE_SKILLS</Lang>
      </div>
      <div className="inventory__container__hub--body--skills--abilities--active">
        <InventoryMiniSlotsActive />
      </div>

      <DragAndDrop
        dropType="abilityListActive"
        dropData={(e) => toDropData(e)}
        className="inventory__container__hub--body--skills--abilities--hub"
      >
        <div className="inventory__container__hub--body--skills--abilities--hub--filter">
          {tabsAbilities.map((i) => (
            <div
              key={i.id + 't'}
              className={`inventory__container__hub--body--skills--abilities--hub--filter--nav ${i.id === tabAbilities ? 'inventory__container__hub--body--skills--abilities--hub--filter--nav--active' : ''}`}
              onClick={() => setTabAbilities(i.id)}
            >
              <img src={i.img} alt="" />
            </div>
          ))}
        </div>
        <div className="inventory__container__hub--body--skills--abilities--hub--grid">
          {infoAbilities &&
            infoAbilities.map((i, index) => {
              const infoSkills = getInfoDictionary('equipments', i.dict_id);

              return (
                <Tooltip
                  key={infoSkills.id + 'infoSkills' + index}
                  maxWidth="420px"
                  content={
                    <InventoryHubSkillsItem
                      fromItem={i}
                      toItem={infoSkills}
                      maxWidth={'400px'}
                    />
                  }
                >
                  <DragAndDrop
                    dragData={{
                      data: i,
                      dragType: 'abilityListActive',
                    }}
                  >
                    <InventoryItemCell type="mini">
                      <div
                        onMouseEnter={() => handleMouseEnter(infoSkills)}
                        onMouseLeave={() => handleMouseLeave()}
                        onClick={() => {
                          toActiveData(i);
                        }}
                        className={`inventory__container__hub--body--skills--abilities--hub--grid--item ${checkRun(infoSkills)}`}
                      >
                        <span className="inventory__container__hub--body--skills--abilities--hub--grid--item__level">
                          {toRomanNumber(i.level)}
                        </span>

                        <Images
                          className={'skill skill_' + i.dict_id}
                          name={i.dict_id}
                        />
                      </div>
                    </InventoryItemCell>
                  </DragAndDrop>
                </Tooltip>
              );
            })}
          {Array.from(Array(emptyEquipmentItems).keys()).map((i, index) => (
            <InventoryItemCell type="mini" key={index + 'hub'}>
              <div className="inventory__container__hub--body--skills--equipments--hub--grid--item" />
            </InventoryItemCell>
          ))}
        </div>
      </DragAndDrop>
    </div>
  );
};

export default InventoryHubSkillsAbilities;
