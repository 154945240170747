import React, { useEffect } from 'react';
import { Naming } from '../../../helper/naming';
import imgG from '../../../media/img/inventory/gold.png';
import imgE from '../../../media/img/inventory/etherium.png';
import { useDictionary } from '../../../hooks/useDictionary';
import Images from '../../../helper/Images';
import { useSelector } from 'react-redux';
import Lang from '../../language';

const InventoryHubThingsItem = ({
  user,
  slot,
  init = null,
  pairCurrent = null,
}) => {
  const [dict, getInfoDictionary] = useDictionary();
  const info =
    init === null ? getInfoDictionary('equipments', user.dict_id) : init;
  const userData = useSelector((state) => state.allUserData.data);
  const fromBody = useSelector((state) => state.allUserData);
  const userBody = pairCurrent
    ? pairCurrent?.hero_data?.body
    : fromBody?.data?.body;
  const fromUserBody =
    slot && userBody.equipments.find((item) => item.slot === slot);
  const infoWeapons =
    init === null
      ? fromUserBody &&
        getInfoDictionary('equipments', fromUserBody.model.dict_id)
      : init;
  const durabTimer = user;
  const dataInfo = pairCurrent ? pairCurrent.hero_data : userData;

  const checkComparison = (oneMin, oneMax, twoMin, twoMax) => {
    let one = (oneMin + oneMax) / 2;
    let two = (twoMin + twoMax) / 2;

    return one > two ? 'greenC' : one === two ? '' : one < two ? 'redC' : '';
  };

  const checkComparisonD = (fromOne, fromTwo) => {
    let one = fromOne !== undefined ? fromOne : 0;
    let two = fromTwo !== undefined ? fromTwo : one;

    return one > two ? 'greenC' : one === two ? '' : one < two ? 'redC' : '';
  };

  const checkToP = (char) => {
    switch (char) {
      case 43:
        return '%';
      case 35:
        return '%';
    }
  };

  // console.log('info###', info);

  const qualityColor = (num) => {
    switch (num) {
      case 1:
        return 'auto';
      case 2:
        return '#3D85C6';
      case 3:
        return '#E69138';
      case 4:
        return '#674EA7';
      case 5:
        return '#CC0000';
      default:
        return 'auto';
    }
  };

  return (
    <div
      key={info.key}
      className="inventory__container__hub--body--things--item"
      style={{}}
    >
      <div className="inventory__container__hub--body--things--item--box">
        <div
          className={`inventory__container__hub--body--things--item--box--icon rr-${info.quality}`}
        >
          <Images name={info.id} />
        </div>
        <div className="inventory__container__hub--body--things--item--box--head">
          <div className="inventory__container__hub--body--things--item--box--head--title">
            {/* <Lang>{info.id}</Lang> Мок пока-что нет списка имен */}
            {info?.entity_id ? (
              <>
                <Lang>BOOK</Lang>: <Lang>{info?.entity_id}</Lang>
              </>
            ) : (
              <>
                <Lang>{info.id}</Lang>
              </>
            )}
          </div>
          <div className="inventory__container__hub--body--things--item--box--head--other">
            <div className="inventory__container__hub--body--things--item--box--head--other--char">
              <Lang>QUALITY</Lang>:{' '}
              <span style={{ color: qualityColor(info.quality) }}>
                <Lang>{Naming.quality[info.quality]}</Lang>
              </span>
              {/* <br /> */}
              {user.points && user.points > 0 ? (
                <>
                  {' ('}
                  <Lang>VICTORIES</Lang>
                </>
              ) : null}
              {user.points &&
              info.points_up &&
              user.points > 0 &&
              info.points_up > 0
                ? `: ${user.points}/${info.points_up})`
                : null}
            </div>
            {info.durability && (
              <div
                className={`inventory__container__hub--body--things--item--box--info--other${durabTimer.durab <= 5 ? '--limit' : ''}`}
              >
                <Lang>DURABILITY_TIMER</Lang>:
                <div>
                  {durabTimer.durab} / {info.durability}
                </div>
              </div>
            )}
          </div>
          {/*<div className="inventory__container__hub--body--things--item--box--head--other--desc">*/}
          {/*    <Lang>{info.id + '_DESC'}</Lang>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="inventory__container__hub--body--things--item--box">
        <div className="inventory__container__hub--body--things--item--box--info">
          <div className="inventory__container__hub--body--things--item--box--info--title">
            <Lang>CHARACTERISTICS</Lang>
          </div>
          {info.equipment_type === 1 ? (
            <>
              <div className="inventory__container__hub--body--things--item--box--info--other">
                <Lang>DAMAGE</Lang>:
                <div
                  className={
                    infoWeapons &&
                    checkComparison(
                      info.damage_min,
                      info.damage_max,
                      infoWeapons.damage_min,
                      infoWeapons.damage_max,
                    )
                  }
                >
                  {info.damage_min}-{info.damage_max}
                </div>
              </div>
              <div className="inventory__container__hub--body--things--item--box--info--other">
                <Lang>DAMAGE_TYPE</Lang>:{' '}
                <Lang>{Naming.DamageType[info.damage_type]}</Lang>
              </div>
            </>
          ) : (
            ''
          )}
          {info.equipment_type === 2 ? (
            <>
              {info.character_bonuses
                .filter((i) => [27, 28, 29, 30].includes(i.char))
                .map((i) => {
                  return (
                    <div
                      key={i.char + 'char'}
                      className="inventory__container__hub--body--things--item--box--info--other"
                    >
                      <Lang>{Naming.character[i.char]}</Lang>:
                      <div
                        className={
                          infoWeapons &&
                          infoWeapons.character_bonuses.find(
                            (y) => y.char === i.char,
                          ) &&
                          checkComparisonD(
                            i.points,
                            infoWeapons.character_bonuses.find(
                              (y) => y.char === i.char,
                            ).points,
                          )
                        }
                      >
                        +{i.points}
                        {checkToP(i.char)}
                      </div>
                    </div>
                  );
                })}
            </>
          ) : (
            ''
          )}
        </div>
        {info.character_bonuses && (
          <div className="inventory__container__hub--body--things--item--box--info">
            <div className="inventory__container__hub--body--things--item--box--info--title">
              <Lang>ADDITIONAL_PROPERTIES</Lang>
            </div>
            {info.rune_slots && (
              <div className="inventory__container__hub--body--things--item--box--info--other">
                <Lang>RUNE_SLOTS</Lang>:
                <div
                  className={
                    infoWeapons &&
                    checkComparisonD(info.rune_slots, infoWeapons.rune_slots)
                  }
                >
                  {info.rune_slots}
                </div>
              </div>
            )}
            {info.character_bonuses
              .filter((i) => ![27, 28, 29, 30].includes(i.char))
              .map((i) => {
                return (
                  <div
                    key={i.char + 'char'}
                    className="inventory__container__hub--body--things--item--box--info--other"
                  >
                    <Lang>{Naming.character[i.char]}</Lang>:
                    <div
                      className={
                        infoWeapons &&
                        infoWeapons.character_bonuses.find(
                          (y) => y.char === i.char,
                        ) &&
                        checkComparisonD(
                          i.points,
                          infoWeapons.character_bonuses.find(
                            (y) => y.char === i.char,
                          ).points,
                        )
                      }
                    >
                      +{i.points}
                      {checkToP(i.char)}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {info.requirements && info.requirements.length > 0 && (
          <div className="inventory__container__hub--body--things--item--box--info">
            <div className="inventory__container__hub--body--things--item--box--info--title">
              <Lang>REQUIREMENTS</Lang>:
            </div>
            {info.requirements.map(
              (
                i, // Мок
              ) => {
                const check = dataInfo.char.characters.find(
                  (e) => e.char === i.character,
                );
                // console.log('check', check);
                if (!check) {
                  return null;
                }
                return (
                  <div
                    key={i.$type + 'type'}
                    className={`inventory__container__hub--body--things--item--box--info--other ${check.points < i.value && 'inventory__container__hub--body--things--item--box--info--other--limit'}`}
                  >
                    <Lang>{Naming.RequirementType[i.$type]}</Lang>:
                    {/* {info.weapon_class && (
                    <div className="inventory__container__hub--body--things--item--box--info--other--name">
                      <Lang>{Naming.WeaponClassType[info.weapon_class]}</Lang>:
                    </div>
                  )} */}
                    {i.character && (
                      <div className="inventory__container__hub--body--things--item--box--info--other--name">
                        <Lang>{Naming.character[i.character]}</Lang> -
                      </div>
                    )}
                    <div className="inventory__container__hub--body--things--item--box--info--other--name">
                      {i.value} <Lang>LVL</Lang> / ({check.points}{' '}
                      <Lang>LVL</Lang>)
                    </div>
                  </div>
                );
              },
            )}
          </div>
        )}
      </div>
      <div className="inventory__container__hub--body--things--item--box">
        {user.runes && user.runes.length > 0
          ? user.runes.map(() => (
              <div
                className="inventory__container__hub--body--things--item--box--buffs"
                key={'runes'}
              >
                <img src="" />
                <div className="inventory__container__hub--body--things--item--box--buffs--info">
                  <div className="inventory__container__hub--body--things--item--box--buffs--info--title">
                    <Lang>MAGIC</Lang>:
                  </div>
                  <div className="inventory__container__hub--body--things--item--box--buffs--info--desc">
                    s
                  </div>
                </div>
              </div>
            ))
          : ''}
        {user.runes &&
          Array(info.rune_slots - user.runes.length)
            .fill('')
            .map((i, indx) => (
              <div
                className="inventory__container__hub--body--things--item--box--runP"
                key={'arr-runes' + indx}
              >
                <div className="inventory__container__hub--body--things--item--box--runP--rune" />
                <div className="inventory__container__hub--body--things--item--box--runP--info">
                  <div className="inventory__container__hub--body--things--item--box--runP--info--title">
                    <Lang>SLOT</Lang>: {indx + 1} <Lang>EMPTY</Lang>
                  </div>
                </div>
              </div>
            ))}
        {info.price && info.price !== 0 && (
          <div className="inventory__container__hub--body--things--item--box--info">
            <div className="inventory__container__hub--body--things--item--box--info--title">
              <Lang>PRICE</Lang>
            </div>
            <div className="inventory__container__hub--body--things--item--box--info--other">
              <img src={imgG} alt="" />
              <div
                className={
                  infoWeapons
                  // &&
                  // checkComparisonD(info.price[1], infoWeapons.price[1])
                }
              >
                {info.price[1]}
              </div>
            </div>
            <div className="inventory__container__hub--body--things--item--box--info--other">
              <img src={imgE} alt="" />
              <div
                className={
                  infoWeapons
                  // &&
                  // checkComparisonD(info.price[2], infoWeapons.price[2])
                }
              >
                {info.price[2]}
              </div>
            </div>
          </div>
        )}
      </div>
      {/*<div className="inventory__container__hub--body--things--item--box"> // Мок тут будут бафы*/}
      {/*    {*/}
      {/*        content.buffs.map((i) => (*/}
      {/*            <div className="inventory__container__hub--body--things--item--box--buffs">*/}
      {/*                <img src={i.img} alt={i.typeNme} />*/}
      {/*                <div className="inventory__container__hub--body--things--item--box--buffs--info">*/}
      {/*                    <div className="inventory__container__hub--body--things--item--box--buffs--info--title">*/}
      {/*                        Магия: {i.typeNme}*/}
      {/*                    </div>*/}
      {/*                    <div className="inventory__container__hub--body--things--item--box--buffs--info--desc">*/}
      {/*                        {i.desc}*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        ))*/}
      {/*    }*/}
      {/*</div>*/}
    </div>
  );
};

export default InventoryHubThingsItem;
