import React from 'react';
import '../styles.scss';
import { useDispatch } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import { setOpenApplication } from '../../../store/reducer/Slices/SlicesApplicationOpen';
import Lang from '../../language';
import imgBackItem from '../../../media/img/game/playingField/map/loc1/backItem.png';
import imgBackItem2 from '../../../media/img/game/playingField/map/loc2/backImg.png';
import imgMapBack from '../../../media/img/game/playingField/map/loc2/map.png';
import imgBack from '../../../media/img/game/playingField/map/loc2/loc2B.jpg';
import { setOpenMap } from '../../../store/reducer/Slices/SlicesMap';
import LocationElement from '../LocationElement';
import { setEnterModal } from '../../../store/reducer/Slices/SlicesDungeon';

const Location2 = () => {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();

  const locations = {
    backImg: imgBack,
    items: [
      {
        name: 'Роща клинков',
        img: {
          top: '7.2vw',
          left: '48vw',
          width: '7.2vw',
          height: '10.2vw',
          srcBack: imgBackItem2,
        },
        onClick: () => {
          dispatch(
            setEnterModal({
              open: true,
              building_id: 'bld.4',
            }),
          );
        },
      },
      {
        name: 'Железный лес',
        img: {
          top: '13.2vw',
          left: '17vw',
          width: '7.2vw',
          height: '12.2vw',
          srcBack: imgBackItem2,
        },
        onClick: () => {
          dispatch(
            setEnterModal({
              open: true,
              building_id: 'bld.5',
            }),
          );
        },
      },
      {
        name: 'Карта',
        img: {
          top: '22.2vw',
          left: '30.2vw',
          width: '9.2vw',
          src: imgMapBack,
          srcBack: imgBackItem,
        },
        onClick: () => dispatch(setOpenMap(true)),
      },
    ],
  };

  return (
    <div className="locationIn">
      <div className="locationIn__back">
        <img src={locations.backImg} alt="" />
      </div>
      <div className="locationIn__container">
        {/* <div className="locationIn__container--buttons">
          <UiButtonOne
            type="middle"
            onClick={() => {
              dispatch(setOpenApplication(1));
            }}
          >
            <Lang>LIST_OF_APPLICATIONS</Lang>
          </UiButtonOne>
          <UiButtonOne
            type="middle"
            onClick={() => {
              requestSend.sendData('RequestBattleRequestPvpBot');
            }}
          >
            <Lang>FIGHT_WITH_A_BOT</Lang>
          </UiButtonOne>
        </div> */}
        {locations.items.map((i, index = index + 1) => (
          <LocationElement index={index} i={i} key={i.name} />
        ))}
      </div>
    </div>
  );
};

export default Location2;
