import { Response } from './reqRspName';
import {Naming} from "./naming";

export const checkRegular = (name, val) => {
  if (name === 'email') {
    let reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !reg.test(val);
  }
  // if (name === 'password') {
  //   let reg = /^[0-9a-zA-Z]{5,}$/;
  //   return !reg.test(val);
  // }
  if (name === 'nick') {
    let reg =
      /^[ёЁа-яА-Яa-zA-Z0-9](?!.*--)(?!.*__)(?!.* {2})(?!.*-_)(?!.*_-)(?!.*- )(?!.* -)(?!.*_ )(?!.* _)[ёЁа-яА-Яa-zA-Z0-9\-_ ]{1,18}[ёЁа-яА-Яa-zA-Z0-9]$/;
    return !reg.test(val);
  }
};

export const redirect = (url) => {
  if (window.location.pathname !== url) window.location.replace(url);
};

export const checkPercent = (val1, val2) => {
  return (val1 / val2) * 100;
};

export const getFormatText = (other, text, val) => {
  return {
    time: new Date().toLocaleTimeString(),
    sender: other.cheat ? other.cheat : other,
    text: text ? text : 'Запрос выполнен.',
    val: val ? val : '',
  };
};

export const getFormatTextBattle = (data, battleStart, game) => {
  const chatObj = {
    time:
      window.location.pathname === '/game' && !game
        ? new Date().toLocaleTimeString()
        : checkTimeFormat(data.created),
    data: { ...data },
  };

  const sortGroupNames = (group) => {
    return group
      .sort((a, b) => b - a)
      .map((i) => i.name)
      .slice(0, 5)
      .join(', ');
  };

  switch (data.$type) {
    case Response.ResponseBattleStart:
      const allies = sortGroupNames(data.party1);
      const opponents = sortGroupNames(data.party2);

      const alliesQuantity = data.party1.length;
      const opponentsQuantity = data.party2.length;

      const enteredQuantityText =
        alliesQuantity > 1 ? 'CHAT_ENTEREDS' : 'CHAT_ENTERED';
      const enemyQuantityText =
        opponentsQuantity > 1 ? 'CHAT_OPPONENTS' : 'CHAT_ENEMY';
      const moreOneAlliesEnteredText =
        alliesQuantity > 5 ? 'CHAT_AND_FURTHER ' + (alliesQuantity - 5) : '';
      const moreOneOpponentsEnteredText =
        opponentsQuantity > 5
          ? 'CHAT_AND_FURTHER ' + (opponentsQuantity - 5)
          : '';
      const theirQuantityText =
        alliesQuantity > 1
          ? 'CHAT_THEIR'
          : data.party1[0].sex === 1
            ? 'CHAT_HIS'
            : 'CHAT_HER';
      const becameQuantityText =
        opponentsQuantity > 1
          ? 'CHAT_BECOME'
          : data.party2[0].sex === 1
            ? 'CHAT_BECAME'
            : 'CHAT_BECAMEER';

      chatObj.text = `${moreOneAlliesEnteredText} ${enteredQuantityText} CHAT_TO_BATTLE , ${theirQuantityText} ${enemyQuantityText} ${becameQuantityText} ${moreOneOpponentsEnteredText}`;
      chatObj.allies = allies;
      chatObj.opponents = opponents;
      chatObj.sender = 'START';

      return chatObj;
    case Response.ResponseBattleEnd:
      const alliesEnd = sortGroupNames(data.party1);
      const opponentsEnd = sortGroupNames(data.party2);

      chatObj.text = `CHAT_TO_END_BATTLE`;
      chatObj.allies = alliesEnd;
      chatObj.opponents = opponentsEnd;
      chatObj.sender = 'END';

      return chatObj;
    case Response.ResponseBattleHeroDead:
      const isMan = data.sex === 1;

      chatObj.name = data.name;
      chatObj.sender = 'DEATH';
      chatObj.text = `${isMan ? 'CHAT_FELL' : 'CHAT_FELLER'} CHAT_BLEEDING ${isMan ? 'CHAT_HE_IS_DEAD' : 'CHAT_SHE_IS_DEAD'}`;

      return chatObj;
    case Response.ResponseBattleTurnResultSpell:
      const tur = () => {
        const a_her = {};
        const d_her = {};

        [battleStart.party1, battleStart.party2].forEach((item) => {
          item.forEach((item) => {
            if (data.a_her_id === item.hero_id) {
              a_her.hero_id = item.hero_id;
              a_her.name = item.name;
              a_her.hp = data.a_hp;
              a_her.max_hp = item.max_hp;
              a_her.pantheon = item.pantheon;
              a_her.preset_id = item.preset_id;
              a_her.isGenderMan = item.sex === 1;
              a_her.weapon = item.weapon_id.split('.')[0];
            }

            if (data.d_her_id === item.hero_id) {
              d_her.hero_id = item.hero_id;
              d_her.name = item.name;
              d_her.hp = data.d_hp;
              d_her.max_hp = item.max_hp;
              d_her.pantheon = item.pantheon;
              d_her.preset_id = item.preset_id;
              d_her.isGenderMan = item.sex === 1;
              d_her.weapon = item.weapon_id.split('.')[0];
            }
          });
        });

        chatObj.sender = 'MOVE';
        chatObj.a_her = a_her;
        chatObj.d_her = d_her;
        chatObj.templNum = Math.floor(Math.random() * 3);

        return chatObj;
      };

      const xx = tur();

      return xx;
    case Response.ResponseBattleTurnActionResult:
      const a_her = {};
      const d_her = {};

      [battleStart.party1, battleStart.party2]?.forEach((item) => {
        item?.forEach((item) => {
          if (data.a_her_id === item.hero_id) {
            a_her.hero_id = item.hero_id;
            a_her.name = item.name;
            a_her.hp = data.a_hp;
            a_her.max_hp = item.max_hp;
            a_her.pantheon = item.pantheon;
            a_her.preset_id = item.preset_id;
            a_her.isGenderMan = item.sex === 1;
            a_her.weapon = item.weapon_id.split('.')[0];
          }

          if (data.d_her_id === item.hero_id) {
            d_her.hero_id = item.hero_id;
            d_her.name = item.name;
            d_her.hp = data.d_hp;
            d_her.max_hp = item.max_hp;
            d_her.pantheon = item.pantheon;
            d_her.preset_id = item.preset_id;
            d_her.isGenderMan = item.sex === 1;
            d_her.weapon = item.weapon_id.split('.')[0];
          }
        });
      });

      chatObj.sender = 'MOVE';
      chatObj.a_her = a_her;
      chatObj.d_her = d_her;
      chatObj.templNum = Math.floor(Math.random() * 3);

      return chatObj;
    default:
      return chatObj;
  }
};

export const getFormatTextChat = (fromData) => {
  let data = fromData;
  let inputValue = data.text;

  const textValues = data.text.split(' ');
  const heroNames = data.text.match(/[^%]+(?=\*)/g);
  const equipments = data.text.match(/[^#]+(?=\*)/g);
  const links = data.text.match(/(?:https?|file|ftp):\/\/([^\/\s]+)[^\s]*/g);
  const lineBreaks = data.text.match(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g);
  const getEquipmentTemplate = (item, currentIdx, lastIdx) => {
    const [name, id] = item.split('&');

    if (name && id) {
      const templ = `<span id=${id} data-id=${id} class="chat__block1--body--message--item--text--item">
                            ${name}
                           </span>${currentIdx !== lastIdx - 1 ? ',' : ''}`;

      // inputValue = inputValue.replace(`#${item}*`, templ);
    }
  };

  const getHeroTemplate = (item, currentIdx, lastIdx) => {
    const [name, id] = item.split('&');

    if (name && id) {
      const templ = `%${name}&${id}* ${currentIdx !== lastIdx - 1 ? ',' : ''}`;

      inputValue = inputValue.replace(`%${item}*`, templ);
    }
  };

  const getLinkTemplate = (link) => {
    const battleLink = link.includes('battle');

    const parts = link.split('/');
    const battleIdLink = parts[parts.length - 1];

    return `<a href=${link} target="_blank" class="chat__block1--body--message--item--text--${battleLink ? 'battleLink' : 'link'}">
                    ${battleLink ? `#${battleIdLink}` : link}
                </a>`;
  };

  const getEmotionTemplate = (text) => {
    return `<span class="chat__block1--body--message--item--text--emotion">
                    ${text}
               </span>`;
  };

  lineBreaks?.forEach((item) => {
    inputValue = inputValue.replace(item, '<br>');
  });

  links?.forEach((item) => {
    inputValue = inputValue.replace(item, getLinkTemplate(item));
  });

  heroNames?.forEach((item, idx, arr) => {
    getHeroTemplate(item, idx, arr.length);
  });

  // equipments?.forEach((item, idx, arr) => {
  //     getEquipmentTemplate(item, idx, arr.length);
  // });

  switch (textValues[0]) {
    case '/to':
      inputValue = inputValue.replace(textValues[0], '->');
      break;
    case '/yell':
      inputValue = inputValue.replace(
        textValues[0],
        `SHOUTED ${data.hero.sex === 1 ? '' : 'SHOUTED_HER'}: `,
      );
      break;
    case '/me':
      inputValue = getEmotionTemplate(inputValue.replace(textValues[0], ''));
      break;
    case '/attack':
      inputValue = inputValue.replace(textValues[0], 'ATTACKED');
      break;
    case '/assist':
      inputValue = inputValue.replace(textValues[0], 'ASSISTED');
      break;
    default:
      break;
  }

  const chatObj = {
    ...data,
    text: inputValue,
    time: new Date().toLocaleTimeString(),
  };

  return chatObj;
};

export const toStringUsersList = (users) => {
  return users.reduce((acc, hero) => {
    acc += `%${hero.name}&${hero.hero_id}* `;
    return acc;
  }, 'CHAT_HERE_ARE : ');
};

export const getRangeTime = (one, two) => {
  let seconds = two - Math.floor(one / 1000);

  return { seconds };
};

export const filterOnlyMy = (userGlory, i, onlyMy, tab, userInfo) => {
  let result;
  let forLvl;

  if (tab === 1) {
    forLvl = i.creator_info.glory_level === userGlory;
  }
  if (tab === 2 || tab === 3 || tab === 4) {
    forLvl =
      i.creator_info.glory_level >= userGlory - 1 &&
      i.creator_info.glory_level <= userGlory + 1;
  }
  if (i.glory_level_cup >= 0 && i.glory_level_cup !== userGlory) {
    return false;
  }

  result = (onlyMy && forLvl) || !onlyMy;

  result = result && i.creator_info.user_id !== userInfo.u_info.id;

  result = result && i.status === 1;

  return result;
};

export const timer = (oStart, oEnd) => {
  let start = new Date(oStart);
  let end = new Date(oEnd * 1000);

  let diff = Date.parse(end) - Date.parse(start);

  let hours = Math.floor((diff / (1000 * 3600)) % 24);
  let minutes = Math.floor((diff / 1000 / 60) % 60);
  let sec = Math.floor((diff / 1000) % 60);

  return { hours, minutes, sec };
};

export const checkMinMax = (e, min, max) => {
  let value = parseInt(e.target.value);
  if (!value || value < min) {
    return { val: value, status: false };
  } else if (!value || value > max) {
    return { max, status: false };
  } else {
    return { val: value, status: true };
  }
};

export const toRomanNumber = (num) => {
  if (isNaN(num)) return NaN;
  var digits = String(+num).split(''),
    key = [
      '',
      'C',
      'CC',
      'CCC',
      'CD',
      'D',
      'DC',
      'DCC',
      'DCCC',
      'CM',
      '',
      'X',
      'XX',
      'XXX',
      'XL',
      'L',
      'LX',
      'LXX',
      'LXXX',
      'XC',
      '',
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX',
    ],
    roman = '',
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
};

export const checkTime = (val) => {
  let hours = Math.floor(val / 3600);

  let minutes = Math.floor(val / 60) - Math.floor(val / 3600) * 60;

  let sec = val % 60;

  return { hours, minutes, sec };
};

export const checkTimeFormat = (val) => {
  let date = new Date(val * 1000);

  return date.toLocaleString('ru-RU', {
    dateStyle: 'short',
    timeStyle: 'short',
  });
};

export const getFormatMentions = (data) =>
  data.map(({ name, hero_id, user_id }) => ({
    display: name,
    id: hero_id,
    userId: user_id,
  }));

export const getFormatItemMentions = (data) => {
  return data.map((item) => {
    return { display: item.dict_id, id: item.id };
  });
};

export const generateRandomLettersAndNumbers = () => {
  let letters = '';
  for (let i = 0; i < 2; i++) {
    letters += String.fromCharCode(97 + Math.floor(Math.random() * 26));
  }

  let numbers = '';
  for (let j = 0; j < 2; j++) {
    numbers += Math.floor(Math.random() * 10);
  }

  return letters + numbers;
};

export const replaceWithComponent = (patterns, inputString) => {
  let result = [inputString];

  patterns.forEach(({ pattern, component }) => {
    const regex = new RegExp(pattern, 'g');
    let newResult = [];

    result.forEach((part) => {
      if (typeof part === 'string') {
        let match;
        let lastIndex = 0;

        while ((match = regex.exec(part)) !== null) {
          const index = match.index;
          const matchedComponent = component(match);

          if (index > lastIndex) {
            newResult.push(part.substring(lastIndex, index));
          }

          newResult.push(matchedComponent);

          lastIndex = index + match[0].length;
        }

        if (lastIndex < part.length) {
          newResult.push(part.substring(lastIndex));
        }
      } else {
        newResult.push(part);
      }
    });

    result = newResult;
  });

  return result;
};

export const replaceProperties = (sourceObj, targetObj) => {
  const newObject = { ...targetObj };
  for (let prop in sourceObj) {
    if (sourceObj.hasOwnProperty(prop)) {
      newObject[prop] = sourceObj[prop];
    }
  }
  return newObject;
};

export const extractNameFromAttackPath = (path) => {
  const regex = /%([^&]+)&/;

  const match = path.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return '';
};

export const timeUnixRange = (time) => {
  const finishTime = time;
  const currentTime = Math.floor(Date.now() / 1000);
  const timeRemaining = finishTime - currentTime;

  if (timeRemaining > 0) {
    const days = Math.floor(timeRemaining / (24 * 60 * 60));
    const hours = Math.floor((timeRemaining % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((timeRemaining % (60 * 60)) / 60);
    const seconds = timeRemaining % 60;

    let timeString = 'Осталось: ';

    if (days > 0) timeString += `${days} дней `;
    if (hours > 0) timeString += `${hours} часов `;
    if (minutes > 0) timeString += `${minutes} минут `;
    if (seconds > 0) timeString += `${seconds} секунд`;

    return timeString.trim();
  } else {
    return 'Уже не работает';
  }
};

export const selectCountName = (count) => {
  const countNames = {
    1: 'FIRST',
    2: 'SECOND',
    3: 'THIRD',
    4: 'FOURTH',
    5: 'FIFTH',
    6: 'SIXTH',
    7: 'SEVENTH',
    8: 'EIGHTH',
    9: 'NINTH',
    10: 'TENTH'
  };

  if (count in countNames) {
    return countNames[count];
  }

  switch (true) {
    case (count > 100):
      return 'MORE_THAN_ONE_HUNDRED';
    case (count > 50):
      return 'MORE_THAN_FIFTY';
    case (count > 30):
      return 'MORE_THAN_THIRTY';
    case (count > 20):
      return 'MORE_THAN_TWENTY';
    case (count > 10):
      return 'MORE_THAN_TEN';
    default:
      return '';
  }
};

export const timeInfoDM = (time, text = 'ZODIAC_DAY') => {
  const fromTime = new Date(time * 1000)
  const day = (fromTime.getDate()).toString()
  const month = (fromTime.getMonth() + 1).toString()

  if (!(!!time)) return text

  return {day: text + day, month: text + month}
};


export const infoZodiac = (num) => {
  const ii = Naming.zodiacInfo[num]

  return 'INFO_ZODIAC_' + ii
};
