import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../../../hooks/useDictionary';
import imgArm from '../../../../media/img/battle/btlArm.png';
import imgAttack from '../../../../media/img/battle/btlAttack.png';
import imgNav1 from '../../../../media/img/battle/nav/1.png';
import imgNav2 from '../../../../media/img/battle/nav/2.png';
import imgNav3 from '../../../../media/img/battle/nav/3.png';
import imgNav4 from '../../../../media/img/battle/nav/4.png';
import imgNav1C from '../../../../media/img/battle/nav/1-c.png';
import imgNav2C from '../../../../media/img/battle/nav/2-c.png';
import imgNav3C from '../../../../media/img/battle/nav/3-c.png';
import imgNav4C from '../../../../media/img/battle/nav/4-c.png';
import imgTest from '../../../../media/test.gif';
import imgTest2 from '../../../../media/test2.gif';

const navFight = [
  {
    id: 1,
    type: 1,
    img: imgNav1,
    imgC: imgNav1C,
    text: 'Голова',
    nameZone: 'hd_ch',
  },
  {
    id: 2,
    type: 1,
    img: imgNav2,
    imgC: imgNav2C,
    text: 'Торс',
    nameZone: 'ch_bl',
  },
  {
    id: 3,
    type: 1,
    img: imgNav3,
    imgC: imgNav3C,
    text: 'Пояс',
    nameZone: 'bl_lg',
  },
  {
    id: 4,
    type: 1,
    img: imgNav4,
    imgC: imgNav4C,
    text: 'Ноги',
    nameZone: 'bl_lg',
  },
];
const navFight2 = [
  {
    id: 1,
    type: 2,
    img: imgNav1,
    imgC: imgNav1C,
    text: 'Голова',
  },
  {
    id: 2,
    type: 2,
    img: imgNav2,
    imgC: imgNav2C,
    text: 'Торс',
  },
  {
    id: 3,
    type: 2,
    img: imgNav3,
    imgC: imgNav3C,
    text: 'Пояс',
  },
  {
    id: 4,
    type: 2,
    img: imgNav4,
    imgC: imgNav4C,
    text: 'Ноги',
  },
];

const ModulesNavFight = ({ selectBlock, selectAttack, battleAction, play }) => {
  const userInvent = useSelector((state) => state.allUserData.data.body);
  const [blockList, setBlockList] = useState([]);
  const [attackList, setAttackList] = useState([]);
  const [attackZone, setAttackZone] = useState(1);
  const [blockZone, setBlockZone] = useState([]);
  const [blockHover, setBlockHover] = useState([]);
  const [blockCombine, setBlockCombine] = useState('');
  const [dict, getInfoDictionary] = useDictionary();

  const getR = (to, id, zone) => {
    let toA = to;
    if (toA.indexOf(id) >= 0) {
      toA.splice(toA.indexOf(id), 1);
    } else {
      if (to.length < zone) toA.push(id);
    }
    return toA;
  };

  const toCheckBlock = (nameZone) => {
    if (blockZone.length) {
      const chose = blockZone.find((i) => i.id === nameZone);
      return chose.zones;
    } else return [];
  };

  const toBlock = (nameZone, id) => {
    const toList = toCheckBlock(nameZone);
    if (
      (blockList.length > 0 && toList.toString() === blockList.toString()) ||
      blockList.includes(id)
    ) {
      clearL();
    } else {
      if (toList.length) {
        setBlockList(toList);
        selectBlock({ def_id: nameZone, def_zone: toList });
        setBlockCombine(nameZone);
      } else {
        setBlockList([id]);
        selectBlock({ def_zone: [id] });
      }
    }
  };

  const toAttack = (id) => {
    if (attackList.includes(id)) {
      clearR();
    } else {
      if (attackZone > 1) {
        setAttackList([...getR(attackList, id, attackZone)]);
        selectAttack([...getR(attackList, id, attackZone)]);
      } else {
        setAttackList([id]);
        selectAttack([id]);
      }
    }
  };

  const toOnHover = (nameZone) => {
    setBlockHover(toCheckBlock(nameZone));
  };

  const clearL = () => {
    setBlockList([]);
    selectBlock({});
    setBlockCombine('');
  };

  const clearR = () => {
    selectAttack([]);
    setAttackList([]);
  };

  useEffect(() => {
    if (battleAction === 1) {
      clearL();
      clearR();
    }
  }, [battleAction]);

  useEffect(() => {
    if (!play) {
      clearL();
      clearR();
    }
  }, [play]);

  useEffect(() => {
    const variant = userInvent.equipments.find((i) => i.slot === 8);
    const variantAttack = userInvent.equipments.find(
      (i) => i.weapon_type === 2,
    );
    if (variant) {
      const leftWeapon = getInfoDictionary('equipments', variant.model.dict_id);
      if (variant && leftWeapon.equipment_type === 2) {
        setBlockZone(dict.pair_zones);
      }
    }
    if (variantAttack) {
      setAttackZone(2);
    }
  }, []);

  return (
    <div className="battle__init--start--three">
      <div className="battle__init--start--three--box">
        <div className="battle__init--start--three--box--img">
          <img
            className="battle__init--start--three--box--img"
            src={imgArm}
            alt=""
          />
          {/*<div className="battle__init--start--three--box--img--ain"/>*/}
        </div>
        {navFight.map((item, index) => (
          <div
            key={item.text + index}
            className={`battle__init--start--three--box--item ${blockHover.indexOf(item.id) !== -1 ? 'hover' : ''} ${blockList.indexOf(item.id) !== -1 ? 'active' : ''}`}
            onClick={() =>
              battleAction === 1 && play ? toBlock(item.nameZone, item.id) : ''
            }
            onMouseEnter={() =>
              battleAction === 1 ? toOnHover(item.nameZone) : ''
            }
            onMouseLeave={() => setBlockHover([])}
          >
            {/*{*/}
            {/*    blockList.indexOf(item.id) !== -1 && <div className="battle__init--start--three--box--item--ain1"/>*/}
            {/*}*/}
            {blockList.indexOf(item.id) !== -1 && (
              <img
                className="battle__init--start--three--box--item--ain1"
                src={imgTest}
                alt=""
              />
            )}
            <img
              src={blockList.indexOf(item.id) !== -1 ? item.imgC : item.img}
              alt=""
              className="battle__init--start--three--box--item--nav"
            />
            <span className="battle__init--start--three--box--item--text">
              {item.text}
            </span>
          </div>
        ))}

        {blockCombine.length !== 0 && (
          <div
            className={`battle__init--start--three--box--item--${blockCombine}${battleAction !== 1 ? '--active' : ''}`}
          />
        )}
      </div>
      <div className="battle__init--start--three--box">
        <div className="battle__init--start--three--box--img">
          <img
            className="battle__init--start--three--box--img"
            src={imgAttack}
            alt=""
          />
          {/*<div className="battle__init--start--three--box--img--ain2"/>*/}
        </div>
        {navFight2.map((item, index) => (
          <div
            key={item.text + index + 2}
            className={`battle__init--start--three--box--item ${attackList.indexOf(item.id) !== -1 ? 'active' : ''}`}
            onClick={() =>
              battleAction === 1 && play ? toAttack(item.id) : ''
            }
          >
            {/*{*/}
            {/*    attackList.indexOf(item.id) !== -1 && <div className="battle__init--start--three--box--item--ain"/>*/}
            {/*}*/}
            {attackList.indexOf(item.id) !== -1 && (
              <img
                className="battle__init--start--three--box--item--ain1 battle__init--start--three--box--item--ain1--r"
                src={imgTest2}
                alt=""
              />
            )}
            <img
              src={attackList.indexOf(item.id) !== -1 ? item.imgC : item.img}
              alt=""
              className="battle__init--start--three--box--item--nav"
            />
            <span className="battle__init--start--three--box--item--text">
              {item.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModulesNavFight;
