import React, { useEffect, useState } from 'react';
import { Naming } from '../../helper/naming';
import Lang from '../language';
import { useTranslate } from '../language/action/line';
import Images from '../../helper/Images';
import { useDictionary } from '../../hooks/useDictionary';
import Select from '../ui/select';
import { useRequest } from '../../hooks/useRequest';
import { useSelector } from 'react-redux';
import UiButtonOne from '../ui/buttons/UiButtonOne';

const InventoryUseSkill = ({ info, user, img, closeModal }) => {
  const [toName, setToName] = useState('');
  const [CHARACTER_NAME] = useTranslate('CHARACTER_NAME');
  const [dict, getInfoDictionary] = useDictionary();
  const [requestSend] = useRequest();
  const fromCheckName = useSelector((state) => state.checkName.check);
  const allItemInfo = getInfoDictionary('equipments', info.dict_id);
  const [disable, setDisable] = useState(false);

  const checkName = (name) => {
    requestSend.sendData('RequestHeroNameExists', { name });
  };

  const send = () => {
    const result = {
      item_id: info.id,
    };
    if (allItemInfo.destination !== 1) {
      result.dest_hero_name = toName;
    }
    console.log('info', info);
    requestSend.sendData('RequestInventoryUse', result);
    closeModal();
  };

  const checkVisible = () => {
    switch (allItemInfo.destination) {
      case 0:
        return ['ON_ME', 'ON_TEAMT', 'ON_ANTO'];
      case 1:
        return ['ON_ME'];
      case 2:
        return ['ON_ANTO'];
      case 3:
        return ['ON_ANTO'];
      case 4:
        return ['ON_TEAMT'];
      case 5:
        return ['ON_ANTO_ALL'];
      case 6:
        return ['ON_RANDOM_ANTO'];
      case 7:
        return ['ON_TEAMT_ALL'];
      case 8:
        return ['ON_RANDOM_TEAMT'];
    }
  };

  useEffect(() => {
    checkName(toName);
  }, [toName]);

  useEffect(() => {
    console.log('fromCheckName', fromCheckName);
  }, [fromCheckName]);

  return (
    <div className="inventory__preObject">
      <div className="inventory__preObject--in">
        <div className="inventory__preObject--in--title">
          <Lang>CAST_A_SPELL</Lang>{' '}
          <b>
            <Lang>{info.dict_id}</Lang>
          </b>
        </div>
      </div>
      <div className="inventory__preObject--in">
        <div className="inventory__preObject--img">
          <Images name={info.dict_id} />
        </div>
        <div className="inventory__preObject--info">
          <h4>
            <Lang>{info.dict_id}</Lang>
          </h4>
        </div>
      </div>
      <div className="inventory__preObject--in">
        <div className="inventory__preObject--in--text">
          <Select list={checkVisible()} disable={setDisable} />
        </div>
        {(allItemInfo.destination === 3 || allItemInfo.destination === 4) && (
          <input
            className="input"
            type="text"
            placeholder={CHARACTER_NAME}
            onChange={(e) => setToName(e.target.value)}
          />
        )}
      </div>
      <div className="inventory__preObject--in--buttons">
        <UiButtonOne onClick={() => closeModal()}>
          <Lang>CANCEL</Lang>
        </UiButtonOne>
        <UiButtonOne
          onClick={() => send()}
          disabled={
            // !fromCheckName.exists &&
            // (allItemInfo.destination === 3 || allItemInfo.destination === 4) &&
            disable
          }
        >
          <Lang>CONFIRM</Lang>
        </UiButtonOne>
      </div>
    </div>
  );
};

export default InventoryUseSkill;
