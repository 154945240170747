import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import imgPers from '../../media/img/game/character/char.png';
import imgLogo from '../../media/img/auth/logo.png';
import imgGoogle from '../../media/img/auth/google.png';
import imgVk from '../../media/img/auth/vk.png';
import { checkRegular, redirect } from '../../helper/helpers';
import Modal from '../../components/modal/Modal';
import { h1, h2, h3, h4, h5 } from './AuthorizationHub';
import TooltipInput from '../../components/tooltip/TooltipInput';
import { useAuth } from '../../hooks/useAuth';
import { cookieGet, cookieSet } from '../../helper/cookie';
import Lang from '../../components/language';
import { useTranslate } from '../../components/language/action/line';
import { useRequest } from '../../hooks/useRequest';
import { useLocation } from 'react-use';
import { useSelector } from 'react-redux';
import { RequestCommand } from '../../helper/reqRspName';

const EmailExistsResult = {
  3: h1,
  4: h2,
  5: h3,
  6: h4,
  7: h5,
};

let restoreParam;

const Authorization = (props) => {
  const location = useLocation();
  const [requestSend] = useRequest();
  let centrifuge = useSelector((state) => state.connectionApi.data);
  let navigate = useNavigate();
  const [field, setField] = useState({});
  const [pass, setPass] = useState(false);
  const [nopeEmail, setNopeEmail] = useState(false);
  const [nopePass, setNopePass] = useState(false);
  const [playDisabled, setPlayDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [nextAction, setNextAction] = useState(1);
  const [checkEmail, login, emailStatus] = useAuth(field.email);
  const [ENTER_EMAIL] = useTranslate('ENTER_EMAIL');
  const [ENTER_PASSWORD] = useTranslate('ENTER_PASSWORD');

  const buttonClickRed = (val) => {
    switch (val) {
      case 3:
        setShow(false);
        break;
      case 4:
        setShow(false);
        break;
      case 5:
        setShow(false);
        sendForgotPass();
        break;
      case 6:
        setShow(false);
        break;
    }
  };

  const handle = (e) => {
    if (e.target.name === 'email') {
      setField({ ...field, [e.target.name]: e.target.value });
      setPlayDisabled(checkRegular(e.target.name, e.target.value));
      setPass(false);
    } else {
      setField({ ...field, [e.target.name]: e.target.value });
      setPlayDisabled(checkRegular(e.target.name, e.target.value));
      setNopePass(false);
    }
  };

  const clickNope = () => {
    setNopeEmail(true);
    setTimeout(() => setNopeEmail(false), 1000);
  };

  const buttonClick = () => {
    cookieSet('email', field.email, 6400);
    if (!nopeEmail && !pass && playDisabled) clickNope(); // Не корректный email

    if (!nopeEmail && !pass && !playDisabled) {
      // Включение поля password
      checkEmail();
    }
    if (!nopeEmail && pass && !playDisabled) {
      login(field.email, field.password)
        .then((rsp) => {
          if (rsp.jwt) {
            redirect('/choose-character');
          }
          if (rsp.wid && rsp.wid === 3) {
            wrongPass();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } // Успешно введено все

    if (!nopeEmail && pass && playDisabled) {
      // wrongPass()
    } // Не корректный password
    // Для теста, когда будет запросы и ответ будет неверный пароль буду выводить это

    // console.log('nopeEmail |', 'pass |', 'playDisabled |', nopeEmail, pass, playDisabled)
  };

  const okEmail = () => {
    setPass(true);
    setPlayDisabled(true);
  };

  const wrongPass = () => {
    setNopePass(true);
  };

  const sendForgotPass = () => {
    requestSend.sendData('RequestBeginResetPassword', { email: field.email });
    setNextAction(6);
    setShow(true);
  };

  useEffect(() => {
    if (emailStatus) {
      okEmail();
    }
    if (emailStatus === false) {
      navigate('/new-character');
    }
  }, [emailStatus]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    restoreParam = queryParams.get('restore');
    const restoreParamEmail = queryParams.get('email');
    if (restoreParam) {
      console.log('Значение параметра "restore":', restoreParam);
      console.log('Значение параметра "restoreParamEmail":', restoreParamEmail);
      setField({ ...field, email: restoreParamEmail });
      setNextAction(7);
      setShow(true);
      if (centrifuge) {
        centrifuge.rpc(RequestCommand.RequestResetPassword.toString(), {
          $type: RequestCommand.RequestResetPassword,
          email: restoreParamEmail,
          confirm: restoreParam,
        });
      }
    }
  }, [centrifuge]);

  useEffect(() => {
    const email = cookieGet('email');
    if (email) {
      setField({ ...field, email });
      setPlayDisabled(false);
    }
  }, []);

  return (
    <div className="authorization">
      <Modal
        show={show}
        buttonGray={() => setShow(false)}
        buttonRed={() => buttonClickRed(nextAction)}
        props={
          EmailExistsResult[nextAction]
            ? EmailExistsResult[nextAction]
            : EmailExistsResult[3]
        }
        descPlus={field.email}
      />
      <div className="authorization__container">
        <div className="authorization__container--left">
          <img src={imgPers} alt="authorization" />
        </div>
        <div className="authorization__container--right">
          <div className="authorization__container--right__block">
            <div className="authorization__container--right__block--logo">
              <img src={imgLogo} alt="" />
            </div>
            <div className="authorization__container--right__block--soc">
              <div className="authorization__container--right__block--soc--img">
                <img src={imgGoogle} alt="В скором времени будет доступно." />
              </div>
              <div className="authorization__container--right__block--soc--img">
                <img src={imgVk} alt="В скором времени будет доступно." />
              </div>
            </div>
            <div className="authorization__container--right__block--field">
              <label>
                Email:
                <input
                  className={nopeEmail ? 'nope' : ''}
                  name="email"
                  type="text"
                  placeholder={ENTER_EMAIL}
                  value={field['email']}
                  onChange={(e) => handle(e)}
                />
              </label>
            </div>
            {pass && (
              <div className="authorization__container--right__block--field pass">
                <TooltipInput
                  open={nopePass}
                  styleInit={{ display: 'flex', flexDirection: 'column' }}
                  content={
                    <>
                      <Lang>WRONG_PASS</Lang>.
                      <div
                        className="authorization__container--right__block--field--forgot"
                        onClick={() => {
                          setNextAction(5);
                          setShow(true);
                        }}
                      >
                        <Lang>FORGOT_PASS</Lang>?
                      </div>
                    </>
                  }
                >
                  <input
                    name="password"
                    type="password"
                    placeholder={ENTER_PASSWORD}
                    value={field['password']}
                    onChange={(e) => handle(e)}
                  />
                </TooltipInput>
              </div>
            )}
            <div
              className="authorization__container--right__block--play"
              onClick={() => buttonClick()}
              disabled={playDisabled}
            >
              <Lang>PLAY</Lang>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
