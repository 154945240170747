import { createSlice } from '@reduxjs/toolkit';

const language = createSlice({
  name: 'language',
  initialState: {
    lang: ['ru', 'en'],
    langActive: 'ru',
    list: [],
  },
  reducers: {
    setActiveLang(state, action) {
      state.langActive = action.payload;
    },
    setListLang(state, action) {
      state.list = action.payload;
    },
  },
});

export const { setActiveLang, setListLang } = language.actions;

export { language };
