import React, { useState, useEffect } from 'react';
import { timer } from '../../helper/helpers';

const TimerInit = ({ next_status_time, nowTime }) => {
  const [time, setTime] = useState({ hours: 0, minutes: 0, sec: 1 });

  useEffect(() => {
    const timerTo = setInterval(() => {
      const { hours, minutes, sec } = timer(new Date(), next_status_time);
      setTime({ hours, minutes, sec });
      nowTime && nowTime(time);
    }, 1000);
    return () => clearInterval(timerTo);
  }, [time]);

  return (
    <div>
      {' ' + time.hours > 0 && time.hours + ' ч. '}
      {' ' + time.minutes > 0 && time.minutes + ' м. '}
      {' ' + time.sec > 0 && time.sec + ' с. '}
    </div>
  );
};

export default TimerInit;
