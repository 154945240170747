import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { RequestCommand } from '../helper/reqRspName';
import { useManagerGame } from '../manager/managerGame';
import { Changer } from '../manager/managerGameChanger';
import { sendToChat } from '../store/reducer/Slices/SlicesChat';

const getHeroIdsAndUser = (text, mentions) => {
  const heroIds = getHeroIds(text);
  const user = mentions?.find(({ id: heroId }) => heroId === heroIds[0]);

  if (heroIds.length === 0) {
    console.error('Heroes not found');
    return;
  }

  return { heroIds, user };
};

const getHeroIds = (text) =>
  text.match(/[^%]+(?=\*)/g)?.map((hero) => hero.split('&')[1]);

export const useRequest = () => {
  const dispatch = useDispatch();
  const [command] = useManagerGame();
  const [status, setStatus] = useState(false);
  const applications = useSelector((state) => state.applicationsList);
  const heroGroup = useSelector((state) => state.groups.heroGroup);
  const userMain = useSelector((state) => state.allUserData.user_main);

  let centrifuge = useSelector((state) => state.connectionApi.data);

  const toSendData = async (cmd, data, other) => {
    return await centrifuge?.rpc(cmd.toString(), { $type: cmd, ...data }).then(
      async (res) => {
        if (res.data.length) {
          for (let i = 0; i < res.data.length; i++) {
            // console.log("toSendData :", res.data);
            await command(res.data[i], other, applications);
            setStatus(!status);
          }
          return res.data;
        }
      },
      (err) => {
        console.log('rpc error toSendData', err);
        // if (err.code === 1) {
        //     centrifuge.on('connect', function(connectCtx){
        //         console.log('connected', connectCtx)
        //         dispatch(setConnection(centrifuge))
        //     });
        // }
      },
    );
  };

  const requestSend = {
    sendChat: async ({ text, type }, mentions) => {
      let resultText = text.split(' ', 4);
      if (resultText[0] === '/cheat') {
        switch (resultText[1]) {
          case 'add_experience':
            toSendData(
              RequestCommand.RequestCheatAddExperience,
              { exp: Number(resultText[2]) },
              {
                cheat: resultText[1],
                val: resultText[2],
              },
            );
            break;
          case 'add_item':
            toSendData(
              RequestCommand.RequestCheatAddItem,
              { item_id: resultText[2] },
              {
                cheat: resultText[1],
                val: resultText[2],
              },
            );
            break;
          case 'add_item_other':
            toSendData(
              RequestCommand.RequestCheatAddToInventory,
              { item_id: resultText[2] },
              {
                cheat: resultText[1],
                val: resultText[2],
              },
            );
            break;
          case 'add_effect':
            console.log('add_item');
            break;
          case 'add_skill':
            toSendData(
              RequestCommand.RequestCheatAddWeaponSkill,
              {
                weapon_class: Number(resultText[2]),
                count: Number(resultText[3]),
              },
              {
                cheat: resultText[1],
                val: resultText[2],
              },
            );
            break;
          case 'add_weapon_experience':
            const splitedText = text.split(' ', 5);
            console.log('splitedText', splitedText);
            toSendData(
              RequestCommand.RequestCheatAddEquipmentPoints,
              {
                points: Number(splitedText[3]),
                slot: Number(splitedText[2]),
              },
              {
                cheat: splitedText[1],
                val: splitedText[2],
              },
            );
            break;
          case 'add_money':
            toSendData(
              RequestCommand.RequestCheatAddMoney,
              { count: Number(resultText[2]), money_type: 1 },
              {
                cheat: resultText[1],
                val: resultText[2],
              },
            );
            break;
          case 'add_ether':
            toSendData(
              RequestCommand.RequestCheatAddMoney,
              { count: Number(resultText[2]), money_type: 2 },
              {
                cheat: resultText[1],
                val: resultText[2],
              },
            );
            break;
          case 'reset':
            toSendData(RequestCommand.RequestCheatReset);
            window.location.reload();
            break;
          default:
            break;
        }
      } else if (resultText[0] === '/to') {
        const heroIds = getHeroIds(text);
        toSendData(RequestCommand.RequestChatPersonalText, {
          text: text,
          ids: heroIds,
        });
      } else if (resultText[0] === '/yell') {
        toSendData(RequestCommand.RequestChatTextAllUsers, { text });
      } else if (resultText[0] === '/invite') {
        const { heroIds, user } = getHeroIdsAndUser(text, mentions);

        if (!user) {
          requestSend
            .sendData('RequestHeroInfo', { id: heroIds[0] })
            .then(async (res) => {
              const user = {
                display: res[0].hero_info.h_info.name,
                id: res[0].hero_info.h_info.id,
                userId: res[0].hero_info.h_info.user_id,
              };

              const inviteData = {
                invite_user_id: res[0].hero_info.h_info.user_id,
                invite_hero_id: heroIds[0],
              };

              if (isEmpty(heroGroup)) {
                await toSendData(RequestCommand.RequestGroupCreate, {});
                await toSendData(RequestCommand.RequestGroupInvite, inviteData);
              } else {
                await toSendData(RequestCommand.RequestGroupInvite, inviteData);
              }
              const { toChat } = Changer.toChatGroup(
                heroGroup,
                userMain,
                'GroupInvite',
                user,
              );
              dispatch(sendToChat(toChat));
            });
        } else {
          const inviteData = {
            invite_user_id: user.userId,
            invite_hero_id: heroIds[0],
          };

          if (isEmpty(heroGroup)) {
            await toSendData(RequestCommand.RequestGroupCreate, {});
            await toSendData(RequestCommand.RequestGroupInvite, inviteData);
          } else {
            await toSendData(RequestCommand.RequestGroupInvite, inviteData);
          }
          const { toChat } = Changer.toChatGroup(
            heroGroup,
            userMain,
            'GroupInvite',
            user,
          );
          dispatch(sendToChat(toChat));
        }
        const { toChat } = Changer.toChatGroup(
          heroGroup,
          userMain,
          'GroupInvite',
          user,
        );
        dispatch(sendToChat(toChat));
      } else if (resultText[0] === '/kick') {
        const { heroIds, user } = getHeroIdsAndUser(text, mentions);
        console.log('user', user);
        const inviteData = {
          hero_name: user.display,
          hero_id: heroIds[0],
        };
        await toSendData(RequestCommand.RequestGroupHeroKick, inviteData);

        const { toChat } = Changer.toChatGroup(
          heroGroup,
          userMain,
          'GroupHeroKick',
          user,
        );
        dispatch(sendToChat(toChat));
      } else {
        switch (type) {
          case 'chat':
            toSendData(RequestCommand.RequestChatText, {
              text: text,
            });
            break;
          default:
            break;
        }
      }
    },
    sendData: async (req, data) => {
      return await toSendData(RequestCommand[req], data);
    },
    sendChanel: (centrifugeInit) => {
      centrifugeInit.on('publication', function (ctx) {
        for (let i = 0; i < ctx.data.length; i++) {
          command(ctx.data[i]);
        }
      });
    },
  };

  return [requestSend, applications];
};
