import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import imgBlSmt from '../../media/img/game/playingField/npc/blacksmith.png';
import imgTvrn from '../../media/img/game/playingField/npc/tavern.jpg';
import imgShp from '../../media/img/game/playingField/npc/shop.jpg';
import imgOutpost from '../../media/img/game/playingField/npc/alatar-mnt.png';
import imgTemp from '../../media/img/game/playingField/map/mapicon.png';
import imgNpc1 from '../../media/img/npc/NPC1.png';
import imgNpc2 from '../../media/img/npc/NPC2.png';
import imgNpc3 from '../../media/img/npc/NPC3.png';
import imgNpc4 from '../../media/img/npc/NPC4.png';
import imgNpc5 from '../../media/img/npc/NPC5.png';
import NpcModal from './NpcModal';
import { useDictionary } from '../../hooks/useDictionary';
import './styles.scss';

function NpcBase() {
  const [dict, getInfoDictionary] = useDictionary();
  const [backImage, setBackImage] = useState(null);
  const [imageNpc, setImageNpc] = useState(null);
  const user = useSelector((state) => state.allUserData.data.h_location);
  const building = user.building_id;
  const build = getInfoDictionary('buildings', building);

  useEffect(() => {
    switch (building) {
      case 'bld.1':
        (() => {
          setBackImage(imgBlSmt);
          setImageNpc(imgNpc1);
        })();
        break;
      case 'bld.2':
        (() => {
          setBackImage(imgShp);
          setImageNpc(imgNpc2);
        })();
        break;
      case 'bld.3':
        (() => {
          setBackImage(imgTvrn);
          setImageNpc(imgNpc3);
        })();
        break;
      case 'bld.10':
        (() => {
          setBackImage(imgOutpost);
          setImageNpc(imgNpc4);
        })();
        break;
      case 'bld.12':
        (() => {
          setBackImage(imgOutpost);
          setImageNpc(imgNpc5);
        })();
        break;
      default:
        break;
    }
  }, [building]);

  if (!build?.npc_id) {
    return null;
  }

  return (
    <div className="npc_container">
      {backImage && (
        <>
          <div className="npc_container--npcImg">
            <img src={imageNpc} alt="npc-img" />
          </div>
          <img src={backImage} alt="back-img" />
          <NpcModal />
        </>
      )}
    </div>
  );
}

export default NpcBase;
