import React, { useEffect, useState } from 'react';
import './styles.scss';

import imgClose from '../../media/img/shop/x.png';
import imgG from '../../media/img/inventory/gold.png';
import imgE from '../../media/img/inventory/etherium.png';
import imgP from '../../media/img/shop/+.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOpenShop,
  setPurchase,
} from '../../store/reducer/Slices/SlicesShop';
import ShopHub from './ShopHub';
import { useRequest } from '../../../src/hooks/useRequest';
import { useDictionary } from '../../../src/hooks/useDictionary';
import ButtonRed from '../../../src/components/ui/buttons/UiButtonOne';
import Tooltip from '../tooltip/Tooltip';
import CharacterInfoTooltip from '../character/Info/CharacterInfoTooltip';
import Lang from '../language';

const Shop = () => {
  const dispatch = useDispatch();
  const [dict, getInfoDictionary] = useDictionary();
  const user = useSelector((state) => state.allUserData.data);
  const shopOpen = useSelector((state) => state.shopOpen.open);
  const userMoney = useSelector((state) => state.allUserData.data.money);
  const [requestSend] = useRequest();
  const [npcShop, setNpcShop] = useState(null);
  const modal = useSelector((state) => state.shopOpen.purchase);
  const eventEntity = user?.h_location?.random_event_id
    ? getInfoDictionary('random_events', user.h_location.random_event_id)
    : null;

  const closeShop = () => {
    dispatch(setOpenShop(false));
  };

  const closeModal = () => {
    dispatch(setPurchase(null));
  };

  const bld_ids = [
    'bld.1',
    'bld.2',
    'bld.3',
    'bld.10',
    'bld.12',
    'bld.15',
    'bld.16',
    'bld.19',
    'bid.21',
    'bld.24',
  ];

  useEffect(() => {
    if (user.h_location.building_id) {
      if (bld_ids.includes(user.h_location.building_id)) {
        setNpcShop(true);
        const building = getInfoDictionary(
          'buildings',
          user.h_location.building_id,
        );
        const npc = getInfoDictionary('npc', building.npc_id);
        if (npc.actions.includes(2)) {
          requestSend.sendData('RequestBuyerListItems', {
            model_type: 0,
            from: 0,
            count: 10,
          });
        }
      }
    } else if (user.h_location.random_event_id) {
      if (eventEntity.event_type === 1) {
        const npc = getInfoDictionary('npc', eventEntity.entity_id);
        if (npc && npc.actions.includes(2)) {
          setNpcShop(true);
          requestSend.sendData('RequestBuyerListItems', {
            model_type: 0,
            from: 0,
            count: 10,
          });
        } else {
          setNpcShop(true);
        }
      }
    }
  }, [user]);

  if (shopOpen && npcShop) {
    return (
      <>
        {modal && (
          <div className="modal_shop">
            <div className="modal_shop--container">
              <div className="modal_shop--container--border">
                <div className="modal_shop--container--msg">
                  <Lang>BOUGHT</Lang>: <Lang>{modal?.data?.id}</Lang>
                </div>
                <ButtonRed onClick={() => closeModal()}>
                  <Lang>FINE</Lang>
                </ButtonRed>
              </div>
            </div>
          </div>
        )}
        <div className="preShop">
          <div className="shop">
            <div className="shop__border">
              <div
                className="shop__container--close"
                onClick={() => closeShop()}
              >
                <img src={imgClose} alt="закрыть" />
              </div>
              <div className="shop__container">
                <div className="shop__container--header">
                  <Tooltip
                    key="DINAR"
                    zIndex={9990}
                    maxWidth="450px"
                    content={<CharacterInfoTooltip choice="115" />}
                  >
                    <div className="shop__container--header--item">
                      <img src={imgG} alt="" />
                      <div className="shop__container--header--item--num">
                        {userMoney['current_money'][1]}
                      </div>
                    </div>
                  </Tooltip>{' '}
                  <Tooltip
                    key="ETH"
                    zIndex={9990}
                    maxWidth="450px"
                    content={<CharacterInfoTooltip choice="116" />}
                  >
                    <div className="shop__container--header--item">
                      <img src={imgE} alt="" />
                      <div className="shop__container--header--item--num">
                        {parseFloat(userMoney['current_money'][2]).toFixed(2) %
                          1 ===
                        0
                          ? parseFloat(userMoney['current_money'][2]).toFixed(0)
                          : parseFloat(userMoney['current_money'][2]).toFixed(
                              2,
                            )}
                      </div>
                    </div>
                  </Tooltip>
                  <div className="shop__container--header--item">
                    <div className="shop__container--header--item--other">
                      <img src={imgP} alt="" />
                    </div>
                  </div>
                </div>
                <ShopHub user={user} event={eventEntity} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Shop;
