import React, { useEffect, useState } from 'react';
import ShopHubSell from './ShopHubSell';
import ShopHubBuy from './ShopHubBuy';
import LineImg from '../../media/img/shop/line.png';
import { useDictionary } from '../../../src/hooks/useDictionary';

const tabs = [
  {
    id: 1,
    name: 'Купить',
  },
  {
    id: 2,
    name: 'Продать',
  },
];

const hub = {
  1: <ShopHubBuy />,
  2: <ShopHubSell />,
};

const ShopHub = ({ user, event }) => {
  const [tab, setTab] = useState(1);
  const [dict, getInfoDictionary] = useDictionary();
  const [sellAllow, setSellAllow] = useState(null);
  const [buyAllow, setBuyAllow] = useState(null);

  const location = user.h_location.building_id || null;
  const eventId = event ? event.entity_id : null;

  useEffect(() => {
    if (location) {
      const building = getInfoDictionary('buildings', location);
      const npc = getInfoDictionary('npc', building.npc_id);
      if (npc.actions.includes(2)) {
        setSellAllow(true);
      }
      if (npc.sell_items.length > 0) {
        setBuyAllow(true);
      }
    }
    if (eventId) {
      const npc = getInfoDictionary('npc', eventId);
      if (npc.actions.includes(2)) {
        setSellAllow(true);
      }
      if (npc.sell_items.length > 0) {
        setBuyAllow(true);
      }
    }
  }, []);

  return (
    <div className="shop__container__hub">
      <div className="shop__container__hub--tab">
        {buyAllow && (
          <>
            <div
              key={tabs[0].name + 'hub'}
              className={`shop__container__hub--tab--item ${tab === tabs[0].id ? 'shop__container__hub--tab--item--active' : ''}`}
              onClick={() => setTab(tabs[0].id)}
            >
              <p style={{ margin: '2px' }}>{tabs[0].name}</p>
            </div>
          </>
        )}
        {sellAllow && buyAllow && (
          <div
            className="line"
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <img
              src={LineImg}
              style={{ height: '50%', paddingTop: '15px' }}
              alt="arrow"
            />
          </div>
        )}
        {sellAllow && (
          <>
            {/* <div className='line' style={{ display: 'flex', justifyContent: 'end' }} ><img src={LineImg} style={{ height: '50%', paddingTop: '15px' }} alt='arrow'/></div> */}
            <div
              key={tabs[1].name + 'hub'}
              className={`shop__container__hub--tab--item ${tab === tabs[1].id ? 'shop__container__hub--tab--item--active' : ''}`}
              onClick={() => setTab(tabs[1].id)}
            >
              <p style={{ margin: '2px' }}>{tabs[1].name}</p>
            </div>
          </>
        )}
      </div>
      {hub[tab]}
    </div>
  );
};

export default ShopHub;
