import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../../hooks/useDictionary';
import { useRequest } from '../../../hooks/useRequest';
import NpcModal from '../NpcModal';
import { loadersSpin1 } from '../../loaders/loaders';
import imgNpc from '../../../media/img/npc/NPC13.png';
import imgMob1 from '../../../media/img/npc/Mob1.png';
import imgMob2 from '../../../media/img/npc/Mob2.png';
import imgMob3 from '../../../media/img/npc/Mob3.png';
import imgMob4 from '../../../media/img/npc/Mob4.png';
import imgMob5 from '../../../media/img/npc/Mob5.png';
import imgMob6 from '../../../media/img/npc/Mob6.png';
import imgMob7 from '../../../media/img/npc/Mob7.png';
import imgMob8 from '../../../media/img/npc/Mob8.png';
import imgMob9 from '../../../media/img/npc/Mob9.png';
import Lang from '../../language';
import './styles.scss';

const spin = loadersSpin1;

const botIDList = new Map([
  ['avatar1', imgMob1],
  ['avatar2', imgMob2],
  ['avatar3', imgMob3],
  ['avatar4', imgMob4],
  ['avatar5', imgMob5],
  ['avatar6', imgMob6],
  ['avatar7', imgMob7],
  ['avatar8', imgMob8],
  ['avatar9', imgMob9],
]);

function EventBase() {
  const [dict, getInfoDictionary] = useDictionary();
  const [requestSend] = useRequest();
  const [open, setOpen] = useState(false);
  const [mob, setMob] = useState(null);
  const [mobPic, setMobPic] = useState(null);
  const bots = dict?.bot_presets ? dict?.bot_presets : [];
  const [npc, setNpc] = useState(null);
  const [time, setTime] = useState(10);
  const [startBattle, setStartBattle] = useState(false);
  const userLocation = useSelector(
    (state) => state.allUserData.data.h_location,
  );
  const battleStatus = useSelector((state) => state.battle.status);
  const dlg = useSelector((state) => state.NpcData.npc_dialogues);
  const eventID = userLocation?.random_event_id;
  const eventEntity = getInfoDictionary('random_events', eventID)
    ? getInfoDictionary('random_events', eventID)
    : null;
  const battleReq = useSelector((state) => state.allUserData.data.h_battle_req);

  const handleLeave = () => {
    requestSend.sendData('RequestRandomEventLeave', {}).then((res) => {
      if (res[0].$type === 15) {
        setStartBattle(true);
      } else {
        setOpen(false);
      }
    });
  };

  const handleBattle = () => {
    requestSend.sendData('RequestRandomEventBattle', {});
    setStartBattle(true);
  };

  useEffect(() => {
    if (eventEntity && eventEntity.event_type === 1) {
      if (dlg && dlg[eventEntity.entity_id]) {
        setNpc(dlg[eventEntity.entity_id]);
      }
    } else if (eventEntity && eventEntity.event_type >= 2) {
      setMob(eventEntity.entity_id);
      let pic;
      if (eventEntity.entity_id.startsWith('d.')) {
        pic = bots.filter((el) => el.id === eventEntity.entity_id.slice(2));
      } else {
        pic = bots.filter((el) => el.id === eventEntity.entity_id);
      }
      setMobPic(botIDList.get(pic[0].avatar));
    } else if (eventID === '') {
      setOpen(false);
    }
    if (userLocation.random_event_id && battleStatus !== 2) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [userLocation, battleStatus, dlg, eventEntity, eventID, bots]);

  useEffect(() => {
    let intervalId;
    if (startBattle && battleReq?.request_id) {
      intervalId = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(intervalId);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startBattle, battleReq, time]);

  return (
    <>
      {open && (
        <div className="event_container">
          {mob && (
            <div className="event_container--npc_container">
              <div className="event_container--npc_container--mobImg">
                <img src={mobPic} alt="npc-img" />
              </div>
              <div className={`eventNpc_modal_base`}>
                {!startBattle && (
                  <>
                    <p>
                      <Lang>EVENT_MOB_TITLE</Lang>
                    </p>
                    <span
                      className="eventNpc_modal_base--battle"
                      onClick={() => handleBattle()}
                    >
                      - <Lang>EVENT_MOB_BATTLE</Lang>
                    </span>
                    <span
                      className="eventNpc_modal_base--leave"
                      onClick={() => handleLeave()}
                    >
                      - <Lang>EVENT_MOB_LEAVE</Lang>
                    </span>
                  </>
                )}
                {startBattle && (
                  <div className="application__forModal--load">
                    {spin}
                    <div className="application__forModal--load--time">
                      {time}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {npc && (
            <div className="event_container--npc_container">
              <div className="event_container--npc_container--npcImg">
                <img src={imgNpc} alt="npc-img" />
              </div>
              <NpcModal
                event={npc}
                leaveEvent={handleLeave}
                className="event"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default EventBase;
