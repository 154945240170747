import React, { useEffect, useState } from 'react';
import './styles.scss';

import imgC1 from '../../media/img/character/ch1.png';
import imgC2 from '../../media/img/character/ch2.png';
import imgC3 from '../../media/img/character/ch3.png';
import imgC4 from '../../media/img/character/ch4.png';
import imgC5 from '../../media/img/character/ch5.png';
import imgC6 from '../../media/img/character/ch6.png';
import imgC7 from '../../media/img/character/ch7.png';
import imgC8 from '../../media/img/character/ch8.png';
import imgAnim from '../../media/animation/Fire Icon_orange_gif.gif';

import imgBottom from '../../media/img/game/character/botBg.png';
import imgSlot1 from '../../media/img/game/character/slots/1.png';
import imgSlot2 from '../../media/img/game/character/slots/2.png';
import imgSlot3 from '../../media/img/game/character/slots/3.png';
import imgSlot4 from '../../media/img/game/character/slots/4.png';
import imgSlot5 from '../../media/img/game/character/slots/5.png';
import imgSlot6 from '../../media/img/game/character/slots/6.png';
import imgSlot7 from '../../media/img/game/character/slots/7.png';
import imgSlot8 from '../../media/img/game/character/slots/8.png';
import imgSlot9 from '../../media/img/game/character/slots/9.png';
import imgSlot10 from '../../media/img/game/character/slots/10.png';
import imgMob1 from '../../media/img/npc/Mob1.png';
import imgMob2 from '../../media/img/npc/Mob2.png';
import imgMob3 from '../../media/img/npc/Mob3.png';
import imgMob4 from '../../media/img/npc/Mob4.png';
import imgMob5 from '../../media/img/npc/Mob5.png';
import imgMob6 from '../../media/img/npc/Mob6.png';
import imgMob7 from '../../media/img/npc/Mob7.png';
import imgMob8 from '../../media/img/npc/Mob8.png';
import imgMob9 from '../../media/img/npc/Mob9.png';

import Tooltip from '../tooltip/Tooltip';
import InventoryHubThingsItem from '../Inventory/InventoryHubThings/InventoryHubThingsItem';
import imgBuffs1 from '../../media/img/skills/ability2.png';
import imgBuffs2 from '../../media/img/skills/ability1.png';
import { useSelector } from 'react-redux';
import { useRequest } from '../../hooks/useRequest';
import DragAndDrop from '../dragAndDrop/DragAndDrop';
import { useDictionary } from '../../hooks/useDictionary';
import Images from '../../helper/Images';
import BattleInitBattleProgress from '../battle/BattleInit/BattleInitBattleProgress';
import ActiveNum from '../activeNum/ActiveNum';
import ActiveBar from '../activeBar/ActiveBar';
import ActiveBarHub from '../activeBar/ActiveBarHub';
import Lang from '../language';
import CharacterInfo from './Info/CharacterInfo';
import CharacterInfoZodiac from './Info/CharacterInfoZodiac';

const slots = [
  {
    id: 1,
    img: imgSlot1,
  },
  {
    id: 2,
    img: imgSlot2,
  },
  {
    id: 3,
    img: imgSlot3,
  },
  {
    id: 4,
    img: imgSlot4,
  },
  {
    id: 5,
    img: imgSlot5,
  },
  {
    id: 6,
    img: imgSlot6,
  },
  {
    id: 7,
    img: imgSlot7,
  },
  {
    id: 8,
    img: imgSlot8,
  },
  {
    id: 9,
    img: imgSlot9,
  },
  {
    id: 10,
    img: imgSlot10,
  },
];

const characterIDList = new Map([
  ['her.1', imgC1],
  ['her.2', imgC2],
  ['her.3', imgC3],
  ['her.4', imgC4],
  ['her.5', imgC5],
  ['her.6', imgC6],
  ['her.7', imgC7],
  ['her.8', imgC8],
]);

const botIDList = new Map([
  ['avatar1', imgMob1],
  ['avatar2', imgMob2],
  ['avatar3', imgMob3],
  ['avatar4', imgMob4],
  ['avatar5', imgMob5],
  ['avatar6', imgMob6],
  ['avatar7', imgMob7],
  ['avatar8', imgMob8],
  ['avatar9', imgMob9],
]);

const CharacterInfoTooltip = () => {
  const userFull = useSelector((state) => state.heroData.data);
  const userBody = useSelector((state) => state.heroData.data.body);
  const characterID = useSelector(
    (state) => state.heroData.data.h_info.preset_id,
  );
  // const battleStatus = useSelector((state) => state.battle.status);
  const [dict, getInfoDictionary] = useDictionary();
  const bots = dict?.bot_presets ? dict?.bot_presets : [];
  const [antoPic, setAntoPic] = useState(null);
  const [mob, setMob] = useState(null);

  useEffect(() => {
    if (
      userFull?.h_info?.name &&
      userFull?.h_info?.name.includes('$') &&
      bots.length > 0
    ) {
      let name =
        userFull?.h_info?.name.split('$') || userFull?.h_info?.preset_id;
      // let name = userFull?.h_info?.preset_id;
      let pic = bots.filter((el) => name.includes(el.name) && el?.bot_type);
      if (pic && pic.length > 0 && pic[0]?.id.startsWith('mob')) {
        setAntoPic(botIDList.get(pic[0].avatar));
        setMob(true);
      } else {
        setAntoPic(characterIDList.get(userFull?.h_info?.preset_id));
      }
    } else {
      setAntoPic(characterIDList.get(userFull?.h_info?.preset_id));
    }
  }, [userFull]);

  const toSlots = (val1, val2) => {
    return slots.slice(val1, val2).map((i, index) => {
      const user = userBody.equipments.find((item) => item.slot === i.id);
      if (user && user.model) {
        const infoWeapons = getInfoDictionary('equipments', user.model.dict_id);

        if (!infoWeapons?.is_visibility)
          return (
            <div id={`slot_${i.id}`} className="character--inventory--item">
              {i.img && <img src={i.img} alt="" />}
            </div>
          );

        return (
          <>
            <div
              id={`slot_${i.id}`}
              className={`character--inventory--item  rr-${infoWeapons?.quality}`}
            >
              <Images name={infoWeapons?.id} />
            </div>

            <img src={imgAnim} className={`character--inventory--full`} />
          </>
        );
      } else {
        return (
          <div id={`slot_${i.id}`} className="character--inventory--item">
            {i.img && <img src={i.img} alt="" />}
          </div>
        );
      }
    });
  };

  return (
    <div className="character--tooltip">
      <div className="character--tooltip__cont">
        <div className="game--container__left--block__head">
          <ActiveNum
            num={userFull.level.glory}
            classOne="game--container__left--block__head--level"
            classTwo="game--container__left--block__head--level--num"
          />
          <div className="game--container__left--block__head--info">
            <div className="game--container__left--block__head--info--name">
              {userFull.h_info.name?.split('$')[0]}
            </div>
            {userFull.battle_id && userFull.battle_id !== '' && (
              <div className="game--container__left--block__head--info--stat">
                <ActiveBarHub
                  hp={
                    userFull.char.characters.find((item) => item.char === 22)
                      ?.points
                  }
                  hpMax={
                    userFull.char.characters.find((item) => item.char === 21)
                      ?.points
                  }
                  mind={
                    userFull.char.characters.find((item) => item.char === 44)
                      ?.points
                  }
                  mindMax={
                    userFull.char.characters.find((item) => item.char === 75)
                      ?.points
                  }
                  armor={
                    userFull.char.characters.find((item) => item.char === 31)
                      ?.points
                  }
                  armorMax={
                    userFull.char.characters.find((item) => item.char === 76)
                      ?.points
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="character">
          {userBody?.equipments && userBody?.equipments ? (
            <>
              <div className="character--inventory">{toSlots(0, 5)}</div>
              <div className="character--center">
                <img src={antoPic} alt="" />
              </div>
              <div className="character--inventory">{toSlots(5, 10)}</div>
              <div className="character__bufs">
                {/*<div className="character__bufs--item positive">*/}
                {/*    <img src={imgBuffs1} alt=""/>*/}
                {/*</div>*/}
                {/*<div className="character__bufs--item negative">*/}
                {/*    <img src={imgBuffs2} alt=""/>*/}
                {/*    <div className="character__bufs--item--many">*/}
                {/*        2*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*{*/}
                {/*    battleStatus === 2 &&*/}
                {/*    <BattleInitBattleProgress/>*/}
                {/*}*/}
              </div>
            </>
          ) : (
            <Lang>LOADING</Lang>
          )}
        </div>
        {userFull.char.characters &&
          Object.keys(userFull.char.characters).length > 0 && (
            <CharacterInfo
              char={userFull.char}
              body={userBody}
              noFreeP={true}
              mob={mob}
            />
          )}
      </div>
      <CharacterInfoZodiac />
    </div>
  );
};

export default CharacterInfoTooltip;
