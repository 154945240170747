import { createSlice } from '@reduxjs/toolkit';

const baseModal = createSlice({
  name: 'baseModals',
  initialState: {
    open: false,
    data: {},
  },
  reducers: {
    setBaseModal(state, action) {
      state.open = action.payload;
    },
    setBaseModalData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setBaseModal, setBaseModalData } = baseModal.actions;

export { baseModal };
