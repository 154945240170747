import React, { useEffect } from 'react';
import imgBg from '../../media/img/battle/btlBgCircle.png';
import imgBg2 from '../../media/img/battle/btlBgCircleIn.png';
import BattleInitStart from './BattleInit/BattleInitStart';

const BattleInit = () => {
  return (
    <div className="battle__init">
      <div className="battle__init__bg">
        <img src={imgBg} className="battle__init__bg--item" alt="" />
        <img src={imgBg2} className="battle__init__bg--item2" alt="" />
      </div>
      <BattleInitStart />
    </div>
  );
};

export default BattleInit;
