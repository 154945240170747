import React from 'react';

import { useRequest } from '../../../hooks/useRequest';
import InventoryHubSkillsEquipments from './InventoryHubSkillsEquipments';
import InventoryHubSkillsAbilities from './InventoryHubSkillsAbilities';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../../hooks/useDictionary';

const InventoryHubSkills = () => {
  const [requestSend] = useRequest();
  const userSkills = useSelector((state) => state.allUserData.data.skills);
  const battleStatus = useSelector((state) => state.battle.status);
  const [dict, getInfoDictionary] = useDictionary();

  return (
    <div className="inventory__container__hub--body">
      <div className="inventory__container__hub--body--skills">
        <InventoryHubSkillsEquipments isBattle={battleStatus > 1} />
        <InventoryHubSkillsAbilities isBattle={battleStatus > 1} />
      </div>
    </div>
  );
};

export default InventoryHubSkills;
