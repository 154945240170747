import React from 'react';

const DragAndDrop = ({
  children,
  dragData,
  dropData,
  className,
  dropType,
  drag,
}) => {
  const toOnDrop = (ev) => {
    ev.preventDefault();
    const data = ev.dataTransfer.getData('text');
    if (dropData && JSON.parse(data).dragType === dropType)
      dropData(JSON.parse(data).data);
  };

  const toOnDragOver = (ev) => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = 'move';
  };

  const toOnDragStart = (ev) => {
    ev.dataTransfer.setData('text/plain', JSON.stringify(dragData));
    ev.dataTransfer.effectAllowed = 'move';
    drag && drag();
  };

  return (
    <div
      className={`${className}`}
      draggable={!!dragData}
      onDrop={(e) => toOnDrop(e)}
      onDragOver={(e) => toOnDragOver(e)}
      onDragStart={(e) => (dragData ? toOnDragStart(e) : '')}
    >
      {children}
    </div>
  );
};

export default DragAndDrop;
