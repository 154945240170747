import React from 'react';
import imgAbil1 from '../../../media/img/skills/ability1.png';
import imgAbil2 from '../../../media/img/skills/ability2.png';
import imgAbil3 from '../../../media/img/skills/ability3.png';
import imgAbil4 from '../../../media/img/skills/ability4.png';
import imgAbil5 from '../../../media/img/skills/ability5.png';
import imgRune1 from '../../../media/img/skills/rune1.png';
import imgRune2 from '../../../media/img/skills/rune2.png';
import imgRune3 from '../../../media/img/skills/rune3.png';
import { toRomanNumber } from '../../../helper/helpers';
import { useDictionary } from '../../../hooks/useDictionary';
import { Naming } from '../../../helper/naming';
import Images from '../../../helper/Images';
import Lang from '../../language';

const InventoryHubSkillsItem = ({ fromItem, toItem, maxWidth }) => {
  const [dict, getInfoDictionary] = useDictionary();
  const eff = !!toItem.add_effect
    ? getInfoDictionary('effects', toItem.add_effect)
    : '';
  // console.log('eff', eff);

  const toDest = (destination) => {
    switch (destination) {
      case 0:
        return null;
      case 1:
        return <Lang>ON_ME</Lang>;
      case 2:
        return <Lang>ON_ANTO</Lang>;
      case 3:
        return <Lang>ON_RANDOM_ANTO</Lang>;
      case 4:
        return <Lang>ON_TEAMT</Lang>;
      case 5:
        return <Lang>ON_ANTO_ALL</Lang>;
      case 6:
        return null;
      case 7:
        return <Lang>ON_TEAMT_ALL</Lang>;
      case 8:
        return <Lang>ON_RANDOM_TEAMT</Lang>;
      default:
        return null;
    }
  };

  return (
    <div className="inventory__container__hub--body--skills--item" style={{}}>
      <div className="inventory__container__hub--body--skills--item--box">
        <div
          className={`inventory__container__hub--body--skills--item--box--icon`}
        >
          <Images name={fromItem.dict_id || toItem.id} />
          <span className="inventory__container__hub--body--skills--item--box--icon--level">
            {fromItem.level && toRomanNumber(fromItem.level)}
          </span>
        </div>
        <div className="inventory__container__hub--body--skills--item--box--head">
          <div className="inventory__container__hub--body--skills--item--box--head--title">
            <Lang>{toItem.id}</Lang> {/* Мок пока-что нет списка имен */}
          </div>
          {fromItem.level && (
            <div className="inventory__container__hub--body--skills--item--box--head--other">
              <div className="inventory__container__hub--body--skills--item--box--head--other--char">
                <Lang>LEVEL</Lang> {fromItem.level}
              </div>
              <div className="inventory__container__hub--body--skills--item--box--head--other--char--level__container">
                <div
                  className="inventory__container__hub--body--skills--item--box--head--other--char--level__container--bar"
                  style={{
                    width:
                      fromItem.level /
                        (toItem.levels.find((i) => i.level === fromItem.level)
                          .next_level_points *
                          100) +
                      '%',
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="inventory__container__hub--body--skills--item--box"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {!!eff &&
          eff.character_effects.map((i, index) => (
            <div
              key={'eff' + index}
              className="inventory__container__hub--body--things--item--box--buffs"
            >
              <Images name={eff.id} />
              <div className="inventory__container__hub--body--things--item--box--buffs--info">
                <div className="inventory__container__hub--body--things--item--box--buffs--info--desc">
                  <Lang>{Naming.character[i.CharacterType]}</Lang>: {i.Value}
                </div>
              </div>
            </div>
          ))}
        <div className="inventory__container__hub--body--skills--item--box--info">
          <div
            className="inventory__container__hub--body--skills--item--box--info--other"
            style={{ maxWidth: maxWidth }}
          >
            <Lang>{Naming.AbilityType[toItem.ability_type]}</Lang>
          </div>
        </div>
        <div className="inventory__container__hub--body--skills--item--box--info">
          <div className="inventory__container__hub--body--skills--item--box--info--other">
            <Lang>CONSUMES_STAMINA_POINTS</Lang>: {toItem.stamina_cost}
          </div>
        </div>
        <div className="inventory__container__hub--body--skills--item--box--info">
          <div className="inventory__container__hub--body--skills--item--box--info--other">
            <Lang>DESTINATION</Lang>: {toDest(toItem?.destination)}
          </div>
        </div>

        {toItem.required_runes?.length > 0 && (
          <div className="inventory__container__hub--body--skills--item--box--info">
            <div className="inventory__container__hub--body--skills--item--box--info--other">
              <div>
                <Lang>MUST_BE_USED_MUST_BE_IN_COMBAT_INVENTORY</Lang>:
              </div>
              <div className="inventory__container__hub--body--skills--item--box--info--other--runes">
                {toItem.required_runes.map((i) => {
                  return (
                    <div
                      key={'abilRunea' + i}
                      className="inventory__container__hub--body--skills--item--box--info--other--runes--item"
                    >
                      <span>
                        <Lang>{i}</Lang>
                      </span>
                      <Images name={i} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {toItem.max_count && toItem.max_count > 0 && (
          <div className="inventory__container__hub--body--skills--item--box--info">
            <div
              className="inventory__container__hub--body--skills--item--box--info--other"
              style={{ maxWidth: '180px' }}
            >
              <div>
                <Lang>MAX_COUNT</Lang>: {toItem.max_count}
              </div>
            </div>
          </div>
        )}

        {toItem.requirements_learn?.length > 0 && (
          <div className="inventory__container__hub--body--skills--item--box--info">
            <div className="inventory__container__hub--body--skills--item--box--info--other">
              <div>
                <Lang>MUST</Lang>:
              </div>
              <div className="inventory__container__hub--body--skills--item--box--info--other--runes">
                {toItem.requirements_learn.map((i) => {
                  return (
                    <div
                      key={'abilRunex' + toItem.id}
                      className="inventory__container__hub--body--skills--item--box--info--other--runes--item"
                    >
                      <span>
                        <Lang>{Naming.RequirementType[i.$type]}</Lang>
                        {i.value && i.value > 0 && <>: {i.value}</>}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {toItem.requirements_use?.length > 0 && (
          <div className="inventory__container__hub--body--skills--item--box--info">
            <div className="inventory__container__hub--body--skills--item--box--info--other">
              <div>
                <Lang>MUST</Lang>:
              </div>
              <div className="inventory__container__hub--body--skills--item--box--info--other--runes">
                {toItem.requirements_use.map((i) => {
                  return (
                    <div
                      key={'abilRunexu' + toItem.id}
                      className="inventory__container__hub--body--skills--item--box--info--other--runes--item"
                    >
                      <span>
                        <Lang>{Naming.RequirementType[i.$type]}</Lang>
                        {i?.value > 0 && <>: {i.value}</>}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InventoryHubSkillsItem;
