import React, { useEffect, useState } from 'react';
import Lang from '../../language';
import imgG from '../../../media/img/inventory/gold.png';
import imgE from '../../../media/img/inventory/etherium.png';
// import {useDictionary} from "../../../hooks/useDictionary";

const InventoryHubMagazineBattleRes = ({ battleRes }) => {
  const [type, setType] = useState(null);
  const [result, setResult] = useState(null);
  const [gain, setGain] = useState(null);
  const [money, setMoney] = useState(null);
  const [date, setDate] = useState(null);
  // const [dict, getInfoDictionary] = useDictionary();

  const battleTypes = [
    '',
    'ПВП. ',
    'Групповой бой. ',
    'Хаотический бой. ',
    'Каждый сам за себя. ',
    'Свободный бой. ',
    'Бой в данжене. ',
  ];

  useEffect(() => {
    const time = new Date(battleRes.battle_created * 1000);
    setDate(
      time.toLocaleString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }),
    );
    // if (!battleRes.trophy_hero.includes('bot')){
    //     setType(battleTypes[battleRes.battle_type])
    // } else {
    // setType(<Lang>FIGHT_WITH_A_BOT</Lang> )
    // }
    setType(battleTypes[battleRes.battle_type]);
    if (battleRes?.is_win) {
      setResult(<Lang>WIN</Lang>);
      setGain(
        <span>
          <Lang>GAINED</Lang> {battleRes.experience} <Lang>EXPERIENCES</Lang>{' '}
          {battleRes.money[1] ? ', ' : ''}
          &nbsp;&nbsp;
          {/* <img src={imgG} alt="" style={{ width: '3%' }} /> &nbsp;&nbsp;
          {battleRes.money[1]} */}
        </span>,
      );
      if (battleRes.money[1] || battleRes?.money[2]) {
        setMoney(
          <span>
            <img src={imgG} alt="" style={{ width: '3%' }} /> &nbsp;&nbsp;
            {battleRes.money[1]}
            {battleRes.money[2] ? (
              <>
                {', '}
                <Lang>CHAT_AND_FURTHER</Lang>{' '}
                <img src={imgE} alt="" style={{ width: '3%' }} />{' '}
                {battleRes.money[2]}
              </>
            ) : (
              ''
            )}
          </span>,
        );
      } else {
        setMoney('');
      }
    } else if (battleRes.is_draw) {
      setResult(<Lang>DRAW</Lang>);
      setGain('');
      setMoney('');
    } else {
      setResult(<Lang>DEFEAT</Lang>);
      if (battleRes.loose_weapon) {
        setGain(
          <span>
            <Lang>LOST_WEAPON</Lang> {battleRes.loose_weapon}
          </span>,
        );
      }
    }
  }, []);

  return (
    <span
      onClick={() => window.open(`/battle/${battleRes?.battle_id}`, '_blank')}
    >
      {date}&nbsp;&nbsp;&nbsp;&nbsp;{type} {result} {gain && gain}{' '}
      {money && money}{' '}
    </span>
  );
};

export default InventoryHubMagazineBattleRes;
