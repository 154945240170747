import React, { useEffect, useLayoutEffect, useState } from 'react';
import './styles.scss';
import {
  setBaseModal,
  setBaseModalData,
} from '../../../store/reducer/Slices/SliceModal';
import { useDispatch, useSelector } from 'react-redux';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import BattleForModalEndHead from './BattleForModalEndHead';
import { checkTime, checkTimeFormat, timer } from '../../../helper/helpers';
import imgXP from '../../../media/img/inventory/xp_fullsize.png';
import imgGold from '../../../media/img/inventory/gold.png';
import imgEth from '../../../media/img/inventory/etherium.png';
import { useRequest } from '../../../hooks/useRequest';
import { useNavigate } from 'react-router-dom';
import Lang from '../../language';
import { setAllUserBattleResults } from '../../../store/reducer/Slices/SlicesUserData';

const BattleForModalEnd = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [requestSend] = useRequest();
  const BattleResults = useSelector(
    (state) => state.allUserData.data.battle_results,
  );
  const [time, setTime] = useState({ hours: 0, minutes: 0, sec: 0 });
  const [toBattleResults, setToBattleResults] = useState(null);

  const offModal = () => {
    dispatch(setBaseModal(false));
    dispatch(setBaseModalData({}));
  };

  const toSendData = async () => {
    // offModal();
    await requestSend
      .sendData('RequestBattleResultGet', {
        result_id: toBattleResults?.id,
      })
      .then((res) => {
        const result = res.filter((el) => el.$type === 80);
        const indexToUpdate = BattleResults.findIndex(
          (item) => item.id === result[0].battle_result.id,
        );
        if (indexToUpdate !== -1) {
          const updatedBattleResults = [...BattleResults];
          updatedBattleResults[indexToUpdate] = result[0].battle_result;
          dispatch(setAllUserBattleResults(updatedBattleResults));
        }
      });
  };

  const toClose = () => {
    offModal();
  };

  // useEffect(() => {
  //     setToBattleResults(BattleResults[0])
  //     if (BattleResults[0].battle_duration) {
  //         const {hours, minutes, sec} = checkTime(BattleResults[0].battle_duration)
  //         setTime({hours, minutes, sec})
  //     }
  // }, [BattleResults])

  useEffect(() => {
    const UnprocessedResult = BattleResults.find(
      (result) => !result.is_processed,
    );

    if (UnprocessedResult) {
      setToBattleResults(UnprocessedResult);
      if (UnprocessedResult.battle_duration) {
        const { hours, minutes, sec } = checkTime(
          UnprocessedResult.battle_duration,
        );
        setTime({ hours, minutes, sec });
      }
    }
  }, [BattleResults, dispatch]);

  useLayoutEffect(() => {
    if (toBattleResults) {
      toSendData();
    }
  }, [toBattleResults]);

  return (
    <div className="application__forModal">
      {toBattleResults && (
        <>
          <BattleForModalEndHead status={toBattleResults.is_win} />
          <div className="battleEnd--box">
            <div className="battleEnd--box--time">
              <div className="battleEnd--box--time--line">
                <Lang>THE_FIGHT_HAS_BEGUN</Lang>
              </div>
              {checkTimeFormat(toBattleResults.battle_created)}
            </div>
            <div className="battleEnd--box--time">
              <div className="battleEnd--box--time--line">
                <Lang>THE_FIGHT_LASTED</Lang>
              </div>
              {time.hours > 0 && time.hours + ' ч. '}
              {time.minutes > 0 && time.minutes + ' м. '}
              {time.sec > 0 && time.sec + ' с. '}
            </div>
            <div className="battleEnd--box--prize">
              <Lang>YOU_HAVE_CAUSED</Lang> {toBattleResults.damage}{' '}
              <Lang>DAMAGES</Lang>
              {toBattleResults.experience > 0 && (
                <>
                  {' '}
                  <Lang>AND_RECEIVED</Lang>:
                </>
              )}
              {toBattleResults.experience > 0 && (
                <div className="battleEnd--box--prize--item">
                  <img src={imgXP} alt="" />
                  {toBattleResults?.experience} <Lang>EXPERIENCES</Lang>
                </div>
              )}
              {toBattleResults.money &&
                // toBattleResults.money[1] &&
                toBattleResults.money[1] > 0 && (
                  <div className="battleEnd--box--prize--item">
                    <img src={imgGold} alt="" />
                    {toBattleResults?.money[1]} <Lang>DINARS</Lang>
                  </div>
                )}
            </div>
            {toBattleResults.money &&
              toBattleResults.money[2] &&
              toBattleResults.money[2] > 0 && (
                <div className="battleEnd--box--bonus">
                  <Lang>YOUR_FIGHT_PLEASED_THE_GODS</Lang>
                  <img src={imgEth} alt="" /> {toBattleResults.money[2]}{' '}
                  <b>
                    <Lang>ETHS</Lang>!
                  </b>
                  <Lang>GLORY_TO_THE_GODS</Lang>!
                </div>
              )}
          </div>
          <div className="modal__container--buttons">
            <UiButtonOne onClick={() => toClose()}>
              <Lang>FINE</Lang>!
            </UiButtonOne>
            <UiButtonOne
              onClick={() =>
                window.open(`/battle/${toBattleResults?.battle_id}`, '_blank')
              }
            >
              <Lang>FIGHT_RECORDING</Lang>
            </UiButtonOne>
          </div>
        </>
      )}
    </div>
  );
};

export default BattleForModalEnd;
