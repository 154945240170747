import React, { useEffect, useState } from 'react';
import Lang from '../../language';
import { useTranslate } from '../../language/action/line';
import Select from '../../ui/select';
import { useRequest } from '../../../hooks/useRequest';
import { useSelector } from 'react-redux';
import UiButtonOne from '../../ui/buttons/UiButtonOne';

const BattleInitStartMiniTwoModal = ({
  slot,
  info,
  closeModal,
  toSlotSetArr,
  setMod,
  mod,
  setStamina,
}) => {
  const [toName, setToName] = useState('');
  const [CHARACTER_NAME] = useTranslate('CHARACTER_NAME');
  const [requestSend] = useRequest();
  const fromCheckName = useSelector((state) => state.checkName.check);

  const checkName = (name) => {
    requestSend.sendData('RequestHeroNameExists', { name });
  };

  const send = () => {
    setStamina((prevStamina) => prevStamina - 1);
    toSlotSetArr(slot, toName);
    closeModal();
  };

  const cancel = () => {
    // toSlotSetArr(slot);
    closeModal();
    setMod();
  };

  const checkVisible = () => {
    switch (info.destination) {
      case 3:
        return ['ON_ANTO'];
      case 4:
        return ['ON_TEAMT'];
      default:
    }
  };

  useEffect(() => {
    checkName(toName);
  }, [toName]);

  return (
    <div className="inventory__preObject">
      <div className="inventory__preObject--in">
        <div className="inventory__preObject--in--title">
          <Lang>USE</Lang> <b>{info.dict_id}</b>
        </div>
      </div>
      <div className="inventory__preObject--in">
        {/* <div className="inventory__preObject--img">
          <Images name={info.dict_id} />
        </div> */}
        <div className="inventory__preObject--info">
          {/* <h4>
            <Lang>{info.dict_id}</Lang>
          </h4> */}
        </div>
      </div>
      <div className="inventory__preObject--in">
        <div className="inventory__preObject--in--text">
          <Select list={checkVisible()} />
        </div>
        {(info.destination === 3 || info.destination === 4) && (
          <input
            className="input"
            type="text"
            placeholder={CHARACTER_NAME}
            onChange={(e) => setToName(e.target.value)}
          />
        )}
      </div>
      <div className="inventory__preObject--in--buttons">
        <UiButtonOne onClick={() => cancel()}>
          <Lang>CANCEL</Lang>
        </UiButtonOne>
        <UiButtonOne
          onClick={() => send()}
          disabled={
            !fromCheckName.exists &&
            (info.destination === 3 || info.destination === 4) &&
            toName !== ''
          }
        >
          <Lang>CONFIRM</Lang>
        </UiButtonOne>
      </div>
    </div>
  );
};

export default BattleInitStartMiniTwoModal;
