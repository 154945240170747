import React, { useEffect, useState } from 'react';
import ActiveBar from './ActiveBar';
import './styles.scss';
import CharacterInfoTooltip from '../character/Info/CharacterInfoTooltip';
import Tooltip from '../tooltip/Tooltip';

const ActiveBarHub = ({
  hp,
  hpMax,
  mind,
  mindMax,
  armor,
  armorMax,
  hpShow,
}) => {
  const [data, setData] = useState({
    hp,
    hpMax,
    mind,
    mindMax,
    armor,
    armorMax,
  });

  useEffect(() => {
    setData({ hp, hpMax, mind, mindMax, armor, armorMax });
  }, [hp, hpMax, mind, mindMax, armor, armorMax]);

  return (
    <div className="active-bar">
      <Tooltip
        key="hp15"
        zIndex={9990}
        maxWidth="450px"
        content={<CharacterInfoTooltip choice="22" />}
      >
        <ActiveBar
          classOne="active-bar--one"
          classTwo="active-bar--one--active"
          one={hp <= hpMax ? hp : hpMax}
          two={hpMax}
          check={{
            plus: 'grin',
            minus: 'red',
          }}
        />
        {/* {hpShow && ( */}
        <div className="active-bar--numHp">
          {hp} / {hpMax} Hp
        </div>
        {/* )} */}
      </Tooltip>
      <Tooltip
        key="mind15"
        zIndex={9990}
        maxWidth="450px"
        content={<CharacterInfoTooltip choice="44" />}
      >
        <ActiveBar
          classOne="active-bar--line"
          classTwo="active-bar--line--active"
          one={mind}
          two={mindMax}
          check={{
            plus: 'blo',
            minus: 'red',
          }}
          showNum={'--numMind'}
        />
      </Tooltip>
      <Tooltip
        key="armor15"
        zIndex={9990}
        maxWidth="450px"
        content={<CharacterInfoTooltip choice="31" />}
      >
        <ActiveBar
          classOne="active-bar--line"
          classTwo="active-bar--line--active"
          one={armor}
          two={armorMax}
          check={{
            plus: 'gray',
            minus: 'red',
          }}
          showNum={'--numArmor'}
        />
      </Tooltip>
    </div>
  );
};

export default ActiveBarHub;
