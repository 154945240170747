import React, { useEffect, useState } from 'react';
import './styles.scss';
import md5 from 'md5-hash';

import imgProf from '../../media/img/createCharacter/profileHead.png';
import { useDispatch, useSelector } from 'react-redux';
import Lang from '../../components/language';
import {
  setBaseModal,
  setBaseModalData,
} from '../../store/reducer/Slices/SliceModal';
import BaseModal from '../../components/modal/BaseModal';
import UiInput from '../../components/ui/inputs/UiInput';
import Modal from '../../components/modal/Modal';
import { useRequest } from '../../hooks/useRequest';
import { setConnection } from '../../store/reducer/Slices';
import { redirect } from '../../helper/helpers';
import { cookieDel } from '../../helper/cookie';
import ChooseCharacterBody from './ChooseCharacterBody/ChooseCharacterBody';
import Loading from '../../components/loading/loading';
import { setLoading } from '../../store/reducer/Slices/SlicesLoading';

const ChooseCharacter = () => {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();
  const userData = useSelector((state) => state.allUserData.data);
  const userUserMain = useSelector((state) => state.allUserData.user_main);
  const inviteHeroId = useSelector((state) => state.allUserData.invite.hero_id);
  const [fields, setFields] = useState({ one: '', two: '' });
  const [openModal, setOpenModal] = useState(false);
  const [openModalDriverInvite, setOpenModalDriverInvite] = useState(false);

  const openChangePass = () => {
    setOpenModal(true);
  };

  const checkR = (val, val2) => {
    let reg = /^[0-9a-zA-Z]{8,}$/;

    if (!reg.test(val)) {
      return !reg.test(val);
    } else return val !== val2;
  };

  const onChangePass = () => {
    const hashedPassword = md5(fields.one);
    requestSend.sendData('RequestChangePassword', { psw: hashedPassword });
    cookieDel('jwt').then(() => {
      dispatch(setConnection(null));
      redirect('/welcome');
    });
    setOpenModal(false);
  };

  const accessDriverInvite = () => {
    requestSend.sendData('RequestDriverInviteAccept', {
      hero_id: inviteHeroId,
      driver_password: fields.driver_password,
    });
    setOpenModalDriverInvite(false);
  };

  const onExit = () => {
    cookieDel('jwt').then(() => {
      dispatch(setConnection(null));
      redirect('/welcome');
    });
  };

  useEffect(() => {
    if (inviteHeroId !== '') setOpenModalDriverInvite(true);
  }, [inviteHeroId]);

  useEffect(() => {
    if (userUserMain.id.trim() !== '') {
      dispatch(setLoading(false));
    }
  }, [userUserMain]);

  useEffect(() => {
    if (
      userData?.h_info?.id !== '' &&
      userData?.id !== '' &&
      userUserMain?.u_info?.battle_id === ''
    ) {
      requestSend.sendData('RequestUnsetHero', {
        hero_id: userData?.h_info?.id,
      });
    } else if (
      userData?.h_info?.id !== '' &&
      userData?.id !== '' &&
      userUserMain?.u_info?.battle_id !== ''
    ) {
      redirect('/game');
    }
  }, [userData]);

  return (
    <div className="chooseCharacter">
      <Loading />
      <div className="chooseCharacter__header">
        <div className="chooseCharacter__header--left">
          <img src={imgProf} alt="" />
          <b>ID</b> {userUserMain?.id}
        </div>
        <div className="chooseCharacter__header--right">
          <div onClick={() => openChangePass()}>
            <Lang>CHANGE_PASS</Lang>
          </div>
          <div onClick={() => onExit()}>
            <Lang>EXIT</Lang>
          </div>
        </div>
      </div>
      <Modal
        show={openModal}
        styles="mini"
        props={{
          title: 'CHANGE_PASS',
          desc: 'CHANGE_PASS_DESC',
          maxWidth: '25%',
          buttonGray: {
            text: 'CANCEL',
            disabled: false,
          },
          buttonRed: {
            text: 'CHANGE_PASS',
            disabled: checkR(fields.one, fields.two),
          },
        }}
        data={
          <>
            <div className="chooseCharacter--toModal">
              <UiInput
                name="password"
                placeholder="NEW_PASSWORD"
                type="password"
                value={fields.one}
                onChange={(e) => setFields({ ...fields, one: e })}
              />
              <UiInput
                name="passwordTwo"
                placeholder="REPEAT_PASSWORD"
                type="password"
                value={fields.two}
                onChange={(e) => setFields({ ...fields, two: e })}
              />
            </div>
          </>
        }
        buttonGray={() => setOpenModal(false)}
        buttonRed={() => onChangePass()}
      />
      <Modal
        show={openModalDriverInvite}
        styles="mini"
        props={{
          title: 'CHARACTER_ACCESS',
          desc: 'CHARACTER_ACCESS_DESC',
          maxWidth: '25%',
          buttonGray: {
            text: 'CANCEL',
            disabled: false,
          },
          buttonRed: {
            text: 'GET_ACCESS',
            disabled: false,
          },
        }}
        data={
          <>
            <div className="chooseCharacter--toModal">
              <UiInput
                name="driver_password"
                placeholder="ACCESS_CODE"
                type="text"
                value={fields.driver_password}
                onChange={(e) => setFields({ ...fields, driver_password: e })}
              />
            </div>
          </>
        }
        buttonGray={() => setOpenModalDriverInvite(false)}
        buttonRed={() => accessDriverInvite()}
      />
      <div className="chooseCharacter__body">
        <ChooseCharacterBody />
      </div>
    </div>
  );
};

export default ChooseCharacter;
