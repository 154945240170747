import React, { useEffect } from 'react';
import imgStamina from '../../../media/img/battle/stamina.png';
import imgStaminaC from '../../../media/img/battle/stamina-c.png';
import Lang from '../../language';

const BattleInitStartMiniEsta = ({
  nowNum,
  data,
  title,
  toNum,
  visibility,
}) => {
  return (
    <div
      className="battle__init--start--mini--other"
      style={{ visibility: visibility ? 'visible' : 'hidden' }}
    >
      <div className="battle__init--start--mini--title">
        <Lang>{title}</Lang>
      </div>

      <div className="battle__init--start--mini--esta">
        {data.map((el, i) => {
          if (toNum >= el) {
            return (
              <img
                src={imgStaminaC}
                className="battle__init--start--mini--esta--bin"
                key={i}
                alt="battle__init--start--mini--esta--bin"
              ></img>
            );
          } else {
            if (nowNum && nowNum >= el) {
              return (
                <img
                  src={imgStaminaC}
                  className="battle__init--start--mini--esta--bin battle__init--start--mini--esta--bin--no"
                  key={i}
                  alt="battle__init--start--mini--esta--bin"
                ></img>
              );
            } else
              return (
                <img
                  src={imgStamina}
                  className="battle__init--start--mini--esta--bin none"
                  alt="battle__init--start--mini--esta--bin"
                ></img>
              );
          }
        })}
      </div>
    </div>
  );
};

export default BattleInitStartMiniEsta;
