import React from 'react';
import { useSelector } from 'react-redux';
import TimerInit from '../../timerInit/TimerInit';

const BattleInitSearch = ({ visibility }) => {
  const battleAction = useSelector((state) => state.battle);

  return (
    <div className="battle__init--search">
      <div className="battle__init--search--box">
        <TimerInit next_status_time={battleAction.action?.data?.finish} />
      </div>
    </div>
  );
};

export default BattleInitSearch;
