import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import sortBy from 'lodash/sortBy';

import imgAxe from '../../../media/img/weapons/axe/axe.png';
import imgAxe2 from '../../../media/img/weapons/axe/axe2.png';
import imgSh from '../../../media/img/weapons/sh.png';
import Tooltip from '../../tooltip/Tooltip';
import InventoryHubThingsItem from './InventoryHubThingsItem';
import MenuTooltip from '../../menuTooltip/MenuTooltip';
import Modal from '../../modal/Modal';
import InventoryDelObject from '../InventoryDelObject';
import InventoryDemolishResult from '../InventoryDemolishResult';
import { useSelector } from 'react-redux';
import InventorySendObject from '../InventorySendObject';
import { useRequest } from '../../../hooks/useRequest';
import DragAndDrop from '../../dragAndDrop/DragAndDrop';
import InventoryItemCell from '../InventoryItemCell/InventoryItemCell';
import { useDictionary } from '../../../hooks/useDictionary';
import { ResponseLogin } from '../../../helper/reqRspName';
import Images from '../../../helper/Images';
import InventoryHubThingsItemOther from './InventoryHubThingsItemOther';
import InventoryHubThingsItemRun from './InventoryHubThingsItemRun';
import InventoryHubThingsItemKey from './InventoryHubThingsItemKey';

const InventoryHubThingsFilter = ({ tab, thingsHeight }) => {
  const dropDownRef = useRef(null);
  const refs = useRef([]);
  const [tooltip, setTooltip] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState({ info: {}, user: {} });
  const [nowData, setNowData] = useState(undefined);
  const userInvent = useSelector((state) => state.allUserData.data.invent);
  const demolishResult = useSelector((state) => state.baseModal.data);
  const [showDemolishResult, setShowDemolishResult] = useState(false);
  const userChar = useSelector((state) => state.allUserData.data);
  const battleAction = useSelector((state) => state.battle.status);
  const [requestSend] = useRequest();
  const [equipments, setEquipments] = useState([]);
  const [inventoryItems, setInventoryItems] = useState(10);
  const gridRef = useRef(null);
  const itemRef = useRef(null);
  const [dict, getInfoDictionary] = useDictionary();

  const miniModal = (now) => {
    console.log('dataModal', dataModal);
    switch (now) {
      case 'disassemble':
        setNowData({
          component: (
            <InventoryDelObject
              info={dataModal.info}
              user={dataModal.user}
              img={dataModal.info.id} // Мок убрать когда будут сделаны изображения и когда будут добавлены в словарь
            />
          ),
          props: {
            title: 'PARSING_THE_ITEM',
            desc: 'PARSING_THE_ITEM_INFO',
          },
          buttonClickRed: () => {
            requestSend
              .sendData('RequestTakeApart', {
                id: dataModal.user.id,
              })
              .then((res) => {
                setShowDemolishResult(true);
                setOpenModal(false);
                const result = res.filter((el) => el.$type === 46);
                setNowData({
                  component: (
                    <InventoryDemolishResult
                      id={result[0].id}
                      gold={result[0].gold}
                      runes={result[0]?.runes}
                    />
                  ),
                });
              });
          },
        });
        break;
      case 'del':
        setNowData({
          component: (
            <InventoryDelObject
              info={dataModal.info}
              user={dataModal.user}
              img={dataModal.info.id} // Мок убрать когда будут сделаны изображения и когда будут добавлены в словарь
            />
          ),
          props: {
            title: 'THROW_AWAY',
            // desc: 'PARSING_THE_ITEM_INFO'
          },
          buttonClickRed: () => {
            requestSend.sendData('RequestInventoryItemDelete', {
              id: dataModal.user.id,
            });
            setOpenModal(false);
          },
        });
        break;
      case 'send':
        setNowData({
          component: (
            <InventorySendObject
              info={dataModal.info}
              user={dataModal.user}
              img={dataModal.info.id} // Мок убрать когда будут сделаны изображения и когда будут добавлены в словарь
            />
          ),
          props: {
            title: 'TRANSFERRING_AN_ITEM',
            desc: 'TRANSFERRING_AN_ITEM_INFO',
          },
          buttonClickRed: setOpenModal(false),
        });
        break;
      default:
        break;
    }
  };

  const handleMouseEnter = (id, item) => {
    if (item?.equip_shield) {
      const elem = document.getElementById(`slot_${id}`);
      elem.style.scale = '1.05';
      elem.style.background = '#373737';
      const elem2 = document.getElementById(`slot_${8}`);
      elem2.style.scale = '1.05';
      elem2.style.background = '#373737';
    } else {
      const elem = document.getElementById(`slot_${id}`);
      elem.style.scale = '1.05';
      elem.style.background = '#373737';
    }
  };

  const handleMouseLeave = (id, item) => {
    if (item?.equip_shield) {
      const elem = document.getElementById(`slot_${id}`);
      elem.style.scale = '1';
      elem.style.background = '#000000c9';
      const elem2 = document.getElementById(`slot_${8}`);
      elem2.style.scale = '1';
      elem2.style.background = '#000000c9';
    } else {
      const elem = document.getElementById(`slot_${id}`);
      elem.style.scale = '1';
      elem.style.background = '#000000c9';
    }
  };

  const handleClickContextMenu = (e, id) => {
    if (tooltip === id) {
      setTooltip(null);
    } else setTooltip(id);
  };

  const toOnClick = (name, data) => {
    if (data.type.$type === 14) {
      console.log('book', data.id);
      requestSend.sendData('RequestBookUse', {
        item_id: data.id,
      });
      return;
    }
    console.log('return');
    const slotFree = userChar.body.equipments.filter((el) => el.slot === 3);
    if (name !== 'RequestEquipmentOn') {
      miniModal(name);
      setOpenModal(true);
    } else if (data.type?.equip_shield && slotFree.length > 0) {
      requestSend.sendData('RequestEquipmentOn', {
        id: data.id,
        slot: 8,
        weapon_shield: true,
      });
    } else {
      requestSend.sendData('RequestEquipmentOn', {
        id: data.id,
        slot: data.slot,
      });
    }
  };

  useEffect(() => {
    const toSort = userInvent.invent_equipments.map((i) => {
      if (i.dict_id.startsWith('key')) {
        const infoWeapons = getInfoDictionary('chest_keys', i.dict_id);
        if (infoWeapons.price) {
          return { ...i, price: infoWeapons.price[2] };
        }
      } else if (i.dict_id.startsWith('pick')) {
        const infoWeapons = getInfoDictionary('chest_pick_lock', i.dict_id);
        if (infoWeapons.price) {
          return { ...i, price: infoWeapons.price[2] };
        }
      } else {
        const infoWeapons = getInfoDictionary('equipments', i.dict_id);
        if (i.$type !== 2 && i.$type !== 4 && i.$type !== 6) {
          if (infoWeapons.price) {
            return { ...i, price: infoWeapons.price[2] };
          } else if (infoWeapons.levels.length > 0) {
            let marId = infoWeapons.levels.find(
              (item) => item.level === i.level,
            );
            if (marId.price) {
              return { ...i, price: marId.price[2] };
            } else {
              return { ...i, price: 0 };
            }
          } else return { ...i, price: 0 };
        }
      }
    });
    const result = sortBy(toSort, 'price').reverse();
    setEquipments(result.filter(Boolean));
  }, [userInvent]);

  useLayoutEffect(() => {
    if (dropDownRef.current || itemRef.current) {
      const heightBlock = itemRef.current
        ? itemRef.current.offsetHeight + 12
        : dropDownRef.current
          ? dropDownRef.current.offsetHeight + 12
          : 82;
      let tabEquip = equipments
        .map((i) => {
          return i.dict_id.startsWith('key')
            ? getInfoDictionary('chest_keys', i.dict_id)
            : i.dict_id.startsWith('pick')
              ? getInfoDictionary('chest_pick_lock', i.dict_id)
              : getInfoDictionary('equipments', i.dict_id);
        })
        .filter((i) => {
          return i.equipment_type === tab || i.$type === tab || tab === 0;
        });
      let itemsCount = 0;
      if (thingsHeight !== 0) {
        itemsCount =
          Math.floor(
            gridRef.current.getBoundingClientRect().width / heightBlock,
          ) *
            Math.floor(thingsHeight / heightBlock) -
          tabEquip.length;
      } else {
        itemsCount =
          Math.floor(
            gridRef.current.getBoundingClientRect().width / heightBlock,
          ) *
            Math.floor(415 / heightBlock) -
          tabEquip.length;
      }
      setInventoryItems(Math.max(itemsCount, 0) + 50);
    }
  }, [tab, equipments, gridRef, itemRef, dropDownRef, thingsHeight]);

  return (
    <div
      className="inventory__container__hub--body--things--grid"
      ref={gridRef}
    >
      {nowData && (
        <Modal
          show={openModal}
          styles="mini"
          props={{
            title: nowData.props?.title || '',
            desc: nowData.props?.desc || '',
            buttonGray: { text: 'CANCEL' },
            buttonRed: { text: 'CONFIRM' },
          }}
          data={nowData.component || ''}
          buttonGray={() => setOpenModal(false)}
          buttonRed={() => nowData.buttonClickRed()}
        />
      )}
      {showDemolishResult && (
        <Modal
          show={showDemolishResult}
          styles="mini"
          props={{
            buttonGray: { text: 'CONFIRM' },
          }}
          data={nowData.component}
          buttonGray={() => setShowDemolishResult(false)}
        />
      )}

      {equipments.map((i, index) => {
        const infoWeapons = i.dict_id.startsWith('key')
          ? getInfoDictionary('chest_keys', i.dict_id)
          : i.dict_id.startsWith('pick')
            ? getInfoDictionary('chest_pick_lock', i.dict_id)
            : getInfoDictionary('equipments', i.dict_id);

        if (!refs.current[index]) {
          refs.current[index] = React.createRef();
        }
        const img = infoWeapons.id;

        if (infoWeapons) {
          if (
            (infoWeapons.equipment_type
              ? infoWeapons.equipment_type === tab
              : infoWeapons.$type === tab) ||
            tab === 0
          ) {
            const checkReq = infoWeapons.requirements
              ? infoWeapons.requirements.some((i) => {
                  const character = userChar.char.characters.find(
                    (e) => e.char === i.character,
                  );
                  return character && character.points < i.value;
                })
              : false;
            const checkBattle = battleAction === 2 ? true : false;
            return (
              <Tooltip
                key={infoWeapons.id + 'infoWeapons' + index}
                open={!(tooltip === i.id)}
                content={
                  infoWeapons.item_type <= 2 ? (
                    <InventoryHubThingsItemOther toItem={i} />
                  ) : infoWeapons.item_type === 3 ? (
                    <InventoryHubThingsItemRun toItem={infoWeapons} />
                  ) : infoWeapons.$type === 31 || infoWeapons.$type === 32 ? (
                    <InventoryHubThingsItemKey toItem={infoWeapons} />
                  ) : (
                    <InventoryHubThingsItem user={i} slot={infoWeapons.slot} />
                  )
                }
              >
                <DragAndDrop
                  dragData={{
                    data: {
                      id: i.id,
                      slot: infoWeapons.slot,
                      type: infoWeapons?.equip_shield,
                    },
                    dragType: 'things',
                  }}
                  dropData={infoWeapons}
                >
                  <InventoryItemCell type="max">
                    <div
                      ref={refs.current[index]}
                      className={`inventory__container__hub--body--things--grid--item rr-${infoWeapons.quality} inventory__item--max`}
                      onContextMenu={(e) => {
                        handleClickContextMenu(e, i.id);
                        setDataModal({
                          info: infoWeapons,
                          user: i,
                        });
                      }}
                      onMouseEnter={() =>
                        infoWeapons.slot &&
                        handleMouseEnter(infoWeapons.slot, infoWeapons)
                      }
                      onMouseLeave={() =>
                        infoWeapons.slot &&
                        handleMouseLeave(infoWeapons.slot, infoWeapons)
                      }
                    >
                      <Images
                        className={
                          tooltip === i.id
                            ? 'inventory__container__hub--body--things--grid--item--in'
                            : ''
                        }
                        name={img}
                      />
                      <MenuTooltip
                        toRef={refs.current[index]}
                        open={tooltip === i.id}
                        setForcibly={() => setTooltip(null)}
                        onClick={(name) => {
                          toOnClick(name, {
                            id: i.id,
                            slot: infoWeapons.slot,
                            type: infoWeapons,
                          });
                        }}
                        disUse={checkReq || checkBattle}
                      />
                    </div>
                  </InventoryItemCell>
                </DragAndDrop>
              </Tooltip>
            );
          } else return null;
        } else return null;
      })}
      {Array.from(Array(inventoryItems).keys()).map((i, index) => (
        <InventoryItemCell type="max" key={index + 'emptyThing'}>
          <div
            ref={itemRef}
            className="inventory__container__hub--body--things--grid--item inventory__item"
          />
        </InventoryItemCell>
      ))}
    </div>
  );
};

export default InventoryHubThingsFilter;
