import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import ShopCard from './ShopCard';
import { useDictionary } from '../../../src/hooks/useDictionary';

const ShopItemBuy = ({ tab, thingsHeight }) => {
  const dropDownRef = useRef(null);
  const [equipments, setEquipments] = useState([]);
  const [inventoryItems, setInventoryItems] = useState(10);
  const user = useSelector((state) => state.allUserData.data);
  const location = user.h_location.building_id;
  const gridRef = useRef(null);
  const itemRef = useRef(null);
  const [dict, getInfoDictionary] = useDictionary();
  const shopItems = useSelector((state) => state.shopOpen.items);
  const eventEntity = user?.h_location?.random_event_id
    ? getInfoDictionary('random_events', user?.h_location?.random_event_id)
    : null;

  const type = 1;

  const items = useMemo(() => {
    if (eventEntity) {
      const npc = getInfoDictionary('npc', eventEntity.entity_id);
      return npc ? getInfoDictionary('npc', eventEntity.entity_id) : null;
    } else {
      const npc = getInfoDictionary('buildings', location);
      return npc ? getInfoDictionary('npc', npc.npc_id) : null;
    }
  }, [eventEntity]);

  const result = useMemo(() => {
    return items?.sell_items.map((el) => {
      if (el.id.startsWith('key')) {
        return getInfoDictionary('chest_keys', el.id);
      } else if (el.id.startsWith('pick')) {
        return getInfoDictionary('chest_pick_lock', el.id);
      } else return getInfoDictionary('equipments', el.id);
    });
  }, [items]);

  useEffect(() => {
    if (shopItems) {
      setEquipments(result.concat(shopItems));
    } else {
      setEquipments(result);
    }
  }, [result, shopItems]);

  useLayoutEffect(() => {
    if (dropDownRef.current || itemRef.current) {
      const heightBlock = itemRef.current
        ? itemRef.current.offsetHeight + 12
        : dropDownRef.current
          ? dropDownRef.current.offsetHeight + 12
          : 82;
      let tabEquip = equipments
        .map((i) => {
          return getInfoDictionary('equipments', i.id);
        })
        .filter((i) => {
          return i?.equipment_type === tab || i?.$type === tab || tab === 0;
        });
      if (thingsHeight !== 0) {
        setInventoryItems(
          Math.floor(
            gridRef.current.getBoundingClientRect().width / heightBlock,
          ) *
            Math.floor(thingsHeight / heightBlock) -
            tabEquip.length,
        );
      } else {
        setInventoryItems(
          Math.floor(
            gridRef.current.getBoundingClientRect().width / heightBlock,
          ) *
            Math.floor(415 / heightBlock) -
            tabEquip.length,
        );
      }
    }
  }, [tab]);

  return (
    <div className="shop-container" ref={gridRef}>
      {equipments.map((i, index) => {
        let infoWeapons = getInfoDictionary('equipments', i.id);
        if (i.$type === 32 || i.$type === 31) {
          infoWeapons = i;
        }
        if (!infoWeapons) return null;

        const isValidTab = infoWeapons.equipment_type
          ? infoWeapons.equipment_type === tab
          : infoWeapons.$type === tab;

        if (!isValidTab && tab !== 0) return null;
        return (
          <div
            key={infoWeapons.id + 'infoWeapons' + index}
            className="tooltip shop"
          >
            <div
              ref={dropDownRef}
              className={`shop__container__hub--body--things--grid--item rr-${infoWeapons?.quality} shop__item--max`}
            >
              <ShopCard props={infoWeapons} type={type} item={i} index />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ShopItemBuy;
