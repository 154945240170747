import React, { useEffect, useState } from 'react';
import CharacterAntagonist from '../character/CharacterAntagonist';
import imgFlag from '../../media/img/game/character/flagBlue.png';
import { Naming } from '../../helper/naming';
import imgMini1 from '../../media/img/game/character/mini/mini1.png';
import imgMini2 from '../../media/img/game/character/mini/mini2.png';
import imgMini3 from '../../media/img/game/character/mini/mini3.png';
import imgMini4 from '../../media/img/game/character/mini/mini4.png';
import BattleList from './BattleList';
import { useSelector } from 'react-redux';
import ActiveBar from '../activeBar/ActiveBar';
import { battle } from '../../store/reducer/Slices/SlicesBattle';
import ActiveBarHub from '../activeBar/ActiveBarHub';
import CharacterInfoTooltip from '../character/Info/CharacterInfoTooltip';
import Tooltip from '../tooltip/Tooltip';
import BattleAntagonistBox from './BattleAntagonistBox';
import BattleListRecon from './BattleListRecon';

const BattleAntagonist = () => {
  const pairCurrent = useSelector((state) => state.battleCurrent.pairCurrent);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setNewData(pairCurrent);
  }, [pairCurrent]);

  return (
    <div className="battle__antagonist--container">
      <div className="game--container__left--chain" />

      <BattleAntagonistBox pairCurrent={newData} />

      <BattleListRecon />

      <BattleList choice={2} />
    </div>
  );
};

export default BattleAntagonist;
