import { createBrowserRouter } from 'react-router-dom';
import NewCharacter from '../page/Character/NewCharacter';
import Authorization from '../page/Authorization/Authorization';
import Game from '../page/Game/Game';
import BattleLog from '../components/battleLog/BattleLog';
import ChooseCharacter from '../page/ChooseCharacter/ChooseCharacter';

export const routerAuth = createBrowserRouter([
  {
    path: '/welcome',
    element: <Authorization />,
  },
  {
    path: '/new-character',
    element: <NewCharacter />,
  },
  {
    path: '/choose-character',
    element: <ChooseCharacter />,
  },
  {
    path: '/game',
    element: <Game />,
  },
  {
    path: '/battle/:id',
    element: <BattleLog />,
  },
]);
