import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useRequest } from '../../hooks/useRequest';
import './styles.scss';

import { ChatListItem } from './ChatListItem';
import { dropdownItems } from './constants';
import { isGroupOwnerSelector } from '../../selectors/user/isGroupOwnerSelector';

const ChatList = ({ onHandleSelectHero }) => {
  const locationId = useSelector(
    (state) => state.allUserData.data.h_location.location_id,
  );
  const leaveChat = useSelector((state) => state.chatData.leave);
  const name = useSelector((state) => state.allUserData.data.h_info.name);
  const onlineList = useSelector((state) => state.chatData.onlineList);
  const [requestSend] = useRequest();
  const isGroupOwner = useSelector(isGroupOwnerSelector);
  const memoizedDropdownItems = useMemo(
    () =>
      dropdownItems.filter(
        (item) =>
          !item.showOnlyIfIsGroupOwner ||
          item.showOnlyIfIsGroupOwner === isGroupOwner,
      ),
    [isGroupOwner],
  );

  const cleanList = useMemo(() => {
    const result = onlineList?.reduce((acc, el) => {
      const x = acc.find((hero) => hero.hero_id === el.hero_id);
      if (!x) {
        acc.push(el);
      }
      return acc;
    }, []);
    return result;
  }, [onlineList]);

  useEffect(() => {
    requestSend.sendData('RequestChatOnlineList');
  }, [locationId, leaveChat]);

  return (
    <>
      <div className="chat__block2">
        <div className="chat__block2--box">
          {cleanList?.map((onlineListItem) => (
            <ChatListItem
              key={onlineListItem.lvl + onlineListItem.name}
              onlineListItem={onlineListItem}
              dropdownItems={memoizedDropdownItems}
              onHandleSelectHero={onHandleSelectHero}
              heroName={name}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ChatList;
