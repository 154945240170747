import { createSlice } from '@reduxjs/toolkit';

const tooltipInfo = createSlice({
  name: 'tooltipInfo',
  initialState: {
    data: [],
  },
  reducers: {
    setDataTooltipInfo(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setDataTooltipInfo } = tooltipInfo.actions;

export { tooltipInfo };
