import { createSlice } from '@reduxjs/toolkit';

const allUserData = createSlice({
  name: 'userData',
  initialState: {
    data: {
      body: {},
      char: {},
      driver: {},
      id: '',
      h_battle_req: {},
      battle_results: [],
      h_info: {},
      h_group: {},
      h_location: {},
      h_effects: {
        effects: [],
      },
      invent: {},
      level: {},
      location: {},
      money: {},
      skills: {},
      h_quests: {
        quests: [],
      },
    },
    user_main: {
      id: '',
      role: {},
      u_info: {},
    },
    user_names: [],
    heroes: [],
    invite: {
      hero_id: '',
    },
  },
  reducers: {
    setAllUserMain(state, action) {
      state.user_main = action.payload;
    },
    setAllUserMainHeroes(state, action) {
      state.heroes = action.payload;
    },
    setAllUserMainDriverInvite(state, action) {
      state.invite = action.payload;
    },
    setAllUserMainId(state, action) {
      state.user_main.id = action.payload;
    },
    setAllUserMainRole(state, action) {
      state.user_main.role = action.payload;
    },
    setAllUserMainUInfo(state, action) {
      state.user_main.u_info = action.payload;
    },
    setAllUserData(state, action) {
      state.data = action.payload;
    },
    setAllUserBody(state, action) {
      state.data.body = action.payload;
    },
    setAllUserChar(state, action) {
      state.data.char = action.payload;
    },
    setAllUserDriver(state, action) {
      state.data.driver = action.payload;
    },
    setAllUserId(state, action) {
      state.data.id = action.payload;
    },
    setAllUserBattleReq(state, action) {
      state.data.h_battle_req = action.payload;
    },
    setAllUserBattleResults(state, action) {
      state.data.battle_results = action.payload;
    },
    setAllUserInfo(state, action) {
      state.data.h_info = action.payload;
    },
    setAllUserGroup(state, action) {
      state.data.h_group = action.payload;
    },
    setAllUserInvent(state, action) {
      state.data.invent = action.payload;
    },
    setAllUserLevel(state, action) {
      state.data.level = action.payload;
    },
    setAllUserLocation(state, action) {
      state.data.location = action.payload;
    },
    setAllUserMoney(state, action) {
      state.data.money = action.payload;
    },
    setAllUserSkills(state, action) {
      state.data.skills = action.payload;
    },
    setAllUserHeroLocation(state, action) {
      state.data.h_location = action.payload;
    },
    setAllUserNames(state, action) {
      state.user_names = action.payload;
    },
    setHeroEffects(state, action) {
      state.data.h_effects.effects = action.payload;
    },
    setHeroUpdatedQuests(state, action) {
      state.data.h_quests.quests = action.payload;
    },
  },
});

export const {
  setAllUserMain,
  setAllUserMainHeroes,
  setAllUserMainDriverInvite,
  setAllUserMainId,
  setAllUserMainRole,
  setAllUserMainUInfo,
  setAllUserData,
  setAllUserBody,
  setAllUserChar,
  setAllUserDriver,
  setAllUserId,
  setAllUserBattleReq,
  setAllUserBattleResults,
  setAllUserInfo,
  setAllUserGroup,
  setAllUserInvent,
  setAllUserLevel,
  setAllUserLocation,
  setAllUserHeroLocation,
  setAllUserMoney,
  setAllUserSkills,
  setAllUserNames,
  setHeroEffects,
  setHeroUpdatedQuests,
} = allUserData.actions;

export { allUserData };
