import { createSlice } from '@reduxjs/toolkit';

const applicationOpen = createSlice({
  name: 'applicationOpen',
  initialState: { now: 1 },
  reducers: {
    setOpenApplication(state, action) {
      state.now = action.payload;
    },
  },
});

export const { setOpenApplication } = applicationOpen.actions;

export { applicationOpen };
