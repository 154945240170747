import React from 'react';
import { Naming } from '../../../helper/naming';
import imgG from '../../../media/img/inventory/gold.png';
import imgE from '../../../media/img/inventory/etherium.png';
import imgAxe from '../../../media/img/weapons/axe/axe.png';
import imgAxe2 from '../../../media/img/weapons/axe/axe2.png';
import { useDictionary } from '../../../hooks/useDictionary';
import { useSelector } from 'react-redux';
import Images from '../../../helper/Images';
import Lang from '../../language';

const InventoryHubThingsItemRun = ({ toItem }) => {
  const [dict, getInfoDictionary] = useDictionary();
  const userLevel = useSelector((state) => state.allUserData.data.level.level);
  const eff = !!toItem.add_effect
    ? getInfoDictionary('effects', toItem.add_effect)
    : '';
  // console.log('toItem', toItem);
  return (
    <div className="inventory__container__hub--body--things--item" style={{}}>
      <div className="inventory__container__hub--body--things--item--box">
        <div
          className={`inventory__container__hub--body--things--item--box--icon rr-${toItem.quality}`}
        >
          <Images name={toItem.id} />
        </div>
        <div className="inventory__container__hub--body--things--item--box--head">
          <div className="inventory__container__hub--body--things--item--box--head--title">
            <Lang>{toItem.id}</Lang> {/* Мок пока-что нет списка имен */}
          </div>
          <div className="inventory__container__hub--body--things--item--box--head--other">
            <div className="inventory__container__hub--body--things--item--box--head--other--char">
              <Lang>{Naming.SpellType[toItem.spell_type]}</Lang>
            </div>
          </div>
          {/*<div className="inventory__container__hub--body--things--item--box--head--other--desc">*/}
          {/*    <Lang>{toItem.id + '_DESC'}</Lang>*/}
          {/*</div>*/}
        </div>
      </div>
      <div
        className="inventory__container__hub--body--things--item--box"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        <div className="inventory__container__hub--body--things--item--box--info--title">
          <Lang>CHARACTERISTICS</Lang>
        </div>
        {toItem.levels?.length >= 1 &&
          toItem.levels
            .filter((i) => i.level === (userLevel <= 5 ? userLevel : 5))
            .map((i, index) => (
              <div
                key={'toItemlevels' + index + toItem.id}
                className="inventory__container__hub--body--things--item--box--info"
              >
                {i.characters &&
                  i.characters.map((y, yIndex) => (
                    <div
                      key={'toItemlevelscharacters' + yIndex}
                      className="inventory__container__hub--body--things--item--box--info--other"
                    >
                      <Lang>{Naming.character[y.char]}</Lang>: {y.value}
                    </div>
                  ))}
              </div>
            ))}
        {!!eff &&
          eff.character_effects.map((i, index) => (
            <div
              key={'eff' + toItem.id + index}
              className="inventory__container__hub--body--things--item--box--buffs"
            >
              <Images name={toItem.id} />
              <div className="inventory__container__hub--body--things--item--box--buffs--info">
                <div
                  className="inventory__container__hub--body--things--item--box--buffs--info--desc"
                  style={{ maxWidth: '200px' }}
                >
                  <Lang>{Naming.character[i.CharacterType]}</Lang>: {i.Value}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default InventoryHubThingsItemRun;
