import { createSlice } from '@reduxjs/toolkit';

const applicationsList = createSlice({
  name: 'applicationsListData',
  initialState: {
    list: {
      bat_req_group: [],
      bat_req_himself: [],
      bat_req_pvp: [],
      bat_req_pvp_bot: [],
      bat_req_random: [],
    },
    acceptGame: {},
    time: null,
  },
  reducers: {
    setApplications(state, action) {
      state.list = action.payload;
    },
    setApplicationsGroup(state, action) {
      state.list.bat_req_group = action.payload;
    },
    setApplicationsHimself(state, action) {
      state.list.bat_req_himself = action.payload;
    },
    setApplicationsPvp(state, action) {
      state.list.bat_req_pvp = action.payload;
    },
    setApplicationsPvpBot(state, action) {
      state.list.bat_req_pvp_bot = action.payload;
    },
    setApplicationsRandom(state, action) {
      state.list.bat_req_random = action.payload;
    },
    setApplicationsAccept(state, action) {
      state.acceptGame = action.payload;
    },
    setApplicationsTime(state, action) {
      state.time = action.payload;
    },
  },
});

export const {
  setApplications,
  setApplicationsGroup,
  setApplicationsHimself,
  setApplicationsPvp,
  setApplicationsRandom,
  setApplicationsAccept,
  setApplicationsTime,
} = applicationsList.actions;

export { applicationsList };
