import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Lang = (props) => {
  const lang = useSelector((state) => state.language.langActive);
  const langList = useSelector((state) => state.language.list);
  const [langArray, setLangArray] = useState('');

  useEffect(() => {
    const targetLanguages = { [lang]: langList };
    const id = props.children;

    if (!targetLanguages[lang]) targetLanguages[lang] = {};
    if (id === undefined && typeof id !== 'string') {

    } else {
      let str =
          targetLanguages[lang][id] ||
          targetLanguages[lang][id?.toUpperCase()] ||
          targetLanguages[lang][id?.toLowerCase()] ||
          id;

      setLangArray(str);
    }

  }, [lang, langList, props.children]);

  return <>{langArray}</>;
};

export default Lang;
