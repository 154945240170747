import React, { useEffect, useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import './styles.scss';

import imgShare from '../../media/img/game/playingField/chat/share.png';
import imgSettings from '../../media/img/game/playingField/chat/settings.png';
import imgProfile from '../../media/img/game/playingField/chat/profile.png';
import imgPeople from '../../media/img/game/playingField/chat/people.png';
import { useRequest } from '../../hooks/useRequest';
import { useDispatch, useSelector } from 'react-redux';
import ChatBody from './ChatBody';
import ChatWindow from './ChatWindow';
import {
  extractNameFromAttackPath,
  getFormatItemMentions,
  getFormatMentions,
  toStringUsersList,
} from '../../helper/helpers';
import { Changer } from '../../manager/managerGameChanger';
import { setHeroData } from '../../store/reducer/Slices/SlicesHeroData';
import { sendToChat } from '../../store/reducer/Slices/SlicesChat';

import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';
import Lang from '../language';
import { useTranslate } from '../language/action/line';
import { useDictionary } from '../../hooks/useDictionary';
import {
  setBaseModal,
  setBaseModalData,
} from '../../store/reducer/Slices/SliceModal';

const tabs = [
  {
    id: 1,
    img: imgSettings,
  },
  {
    id: 2,
    img: imgProfile,
  },
  {
    id: 3,
    img: imgPeople,
  },
];

const chat = {
  1: 'chat',
  2: 'private',
};

const Chat = ({
  battleStatus,
  selectedHeroBylist,
  showBtnOpenWindowChat = true,
  onHandleSelectHero,
}) => {
  const [tab, setTab] = useState(1);
  const [onlyMy, setOnlyMy] = useState(false);
  const [text, setText] = useState('');
  const [mentions, setMentions] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [CHAT_CLICK_HERE_TO_START_TYPING] = useTranslate(
    'CHAT_CLICK_HERE_TO_START_TYPING',
  );
  const battleInfo = useSelector((state) => state.battle);
  const dispatch = useDispatch();

  const [requestSend] = useRequest();
  const [dict, getInfoDictionary] = useDictionary();

  const findedUser = useSelector((state) => state.chatData.chatHeroList);
  const onlineList = useSelector((state) => state.chatData.onlineList);

  const findUserBattleId = onlineList.find(
    (user) => user.name === extractNameFromAttackPath(text),
  );

  const groupInfo = useSelector((state) => state.groups);
  const invent = useSelector(
    (state) => state.allUserData.data.invent.invent_equipments,
  );
  const userMain = useSelector((state) => state.allUserData.user_main);
  const userLocation = useSelector(
    (state) => state.allUserData.data.h_location,
  );

  const mentionsInputRef = useRef(null);
  const openWindowChatBtnRef = useRef(null);

  useEffect(() => {
    const data = getFormatMentions(findedUser);
    setMentions(data);
  }, [findedUser]);

  useEffect(() => {
    if (selectedHeroBylist?.length) {
      const heroWithAction = selectedHeroBylist.find((hero) => hero.action);
      setText(heroWithAction?.action || '/to @');

      const data = getFormatMentions(selectedHeroBylist);
      setMentions(data);
      mentionsInputRef.current.focus();
    }
  }, [selectedHeroBylist]);

  useEffect(() => {
    if (mentionsInputRef.current) {
      mentionsInputRef.current.focus();
    }
  }, [mentions]);

  const handelInput = (val) => {
    if (val.length > 500) {
      return;
    }

    if (val.includes('@')) {
      const namesValues = val.split('@');
      const searchHeroName = namesValues[namesValues.length - 1].split(' ')[0];

      requestSend.sendData('RequestChatFindHero', {
        name: searchHeroName,
      });
    }

    if (val.includes('#')) {
      const equipValues = val.split('#');
      const serchEquipName = equipValues[equipValues.length - 1].split(' ')[0];

      const filtredEauip = invent.filter((item) =>
        item.dict_id
          .toLocaleLowerCase()
          .includes(serchEquipName.toLocaleLowerCase()),
      );
      const itemMent = getFormatItemMentions(filtredEauip);

      setEquipments(itemMent);
    }

    if (val.includes('/invite')) {
      const invFiltData = findedUser.filter(
        (elem) =>
          !elem.name.includes('$') &&
          onlineList.some((onlineUser) => onlineUser.name === elem.name),
      );
      const data = getFormatMentions(invFiltData);
      setMentions(data);
    }

    setText(val);
  };

  const handleCheckbox = (e) => {
    setOnlyMy(e.target.checked);
    localStorage.setItem(
      'isOnlyMyIventsInBattle',
      JSON.stringify(e.target.checked),
    );
  };

  useEffect(() => {
    if (localStorage.key('isOnlyMyIventsInBattle')) {
      setOnlyMy(JSON.parse(localStorage.getItem('isOnlyMyIventsInBattle')));
    }
  }, []);

  const onKeyDown = (e) => {
    if (e.code === 'Enter') {
      if (e.ctrlKey) {
        setText(text + '\n');
      } else {
        e.preventDefault();
        handleSendMessage();
      }
    }

    const regexAt = /^\/at{0,4}$/;

    if (e.code === 'Space' && regexAt.test(text)) {
      setText('/attack ');
    }

    const regexAs = /^\/ass?i?s?t?$/;

    if (e.code === 'Space' && regexAs.test(text)) {
      setText('/assist ');
    }
  };

  const handleSendMessage = () => {
    if (!text.trim().length) {
      return;
    }

    if (text === '/list') {
      const stringList = toStringUsersList(onlineList);
      const result = Changer.toChat({ text: stringList });

      dispatch(sendToChat(result));
      setText('');
      return;
    }

    if (text === '/create_group') {
      requestSend.sendData('RequestGroupCreate');

      setText('');
      return;
    }

    if (text === '/leave') {
      requestSend.sendData('RequestGroupHeroLeave');

      setText('');
      return;
    }

    if (text === '/group_info') {
      console.log('groupInfo', groupInfo);
      const { toChat } = Changer.toChatGroup(groupInfo, userMain, 'GroupInfo');
      dispatch(sendToChat(toChat));
      setText('');
      return;
    }

    if (text === '/help') {
      const { toChat } = Changer.toChatHelp();

      dispatch(sendToChat(toChat));

      setText('');
      return;
    }

    if (text.startsWith('/attack') && extractNameFromAttackPath(text)) {
      const userLocBattle = userLocation?.location_id
        ? getInfoDictionary('locations', userLocation?.location_id)
        : null;
      const userBld = userLocation?.building_id
        ? getInfoDictionary('buildings', userLocation?.building_id)
        : null;
      if (
        userLocation?.location_id === findUserBattleId?.location &&
        userLocBattle?.can_battle &&
        ((userLocation?.building_id === '' &&
          findUserBattleId?.building === '') ||
          (userLocation?.building_id === findUserBattleId?.building &&
            userBld?.can_battle))
      ) {
        requestSend.sendData('RequestPvpFreeCreate', {
          hero_name: extractNameFromAttackPath(text),
        });
      } else {
        dispatch(
          setBaseModalData({
            zIndex: '999',
            styles: 'mini',
            title: 'PVP_FREE_NO',
            desc: 'PVP_FREE_NO_DESC',
            buttonRed: { text: 'FINE' },
          }),
        );
        dispatch(setBaseModal(true));
      }
    }

    if (text.startsWith('/assist') && extractNameFromAttackPath(text)) {
      const heroId = onlineList.find(
        (el) => el.name === extractNameFromAttackPath(text),
      );
      requestSend
        .sendData('RequestHeroInfo', {
          id: heroId.hero_id,
        })
        .then((res) => {
          requestSend.sendData('RequestPvpFreeJoin', {
            hero_name: extractNameFromAttackPath(text),
            battle_id: res[0].hero_info.battle_id,
          });
        });
    }

    const newText = {
      type: chat[tab],
      text: text,
    };

    requestSend.sendChat(newText, mentions);
    requestSend.sendData('RequestChatFindHero', {
      name: '',
    });
    setText('');
    setMentions([]);
  };

  useEffect(() => {
    if (chat[tab] === 'private') {
      setText('/to ');
      return;
    }

    setText('');
  }, [tab]);

  const handleMouseEnter = (e) => {
    if (e.target?.id) {
      const id = e.target.id;
      requestSend.sendData('RequestHeroInfo', { id });
    }
  };

  const handleMouseLeave = (e) => {
    if (e.target?.id) {
      dispatch(setHeroData(null));
    }
  };

  const handleClickToChat = (e) => {
    if (e.target.nodeName === 'SPAN' && e.target?.id) {
      const inputValues = text && text?.split(' ');

      setMentions([{ display: e.target.outerText, id: e.target.id }]);

      if (inputValues[inputValues.length - 1] !== '@') {
        setText(text + `@`);
      }

      mentionsInputRef?.current?.focus();
    }
  };

  const handleFocus = () => {
    requestSend.sendData('RequestChatFindHero', {
      name: '',
    });
  };

  return (
    <>
      <div className="chat__block1">
        {/*<HeroTagLinkDitails />*/}
        <div className="chat__block1--head">
          <div className="chat__block1--head--tabs">
            {tabs.map((i) => (
              <div
                key={i.id + 'chat'}
                className={`chat__block1--head--tabs--item ${i.id === tab ? 'chat__block1--head--tabs--item--active' : ''}`}
                onClick={() => setTab(i.id)}
              >
                <img src={i.img} alt="" />
              </div>
            ))}
            {battleStatus === 2 && (
              <div className="chat__block1--head--tabs--only">
                <label>
                  <input
                    onChange={handleCheckbox}
                    value={onlyMy}
                    checked={onlyMy}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  <Lang>ONLY_MY_EVENTS_IN_BATTLE</Lang>
                </label>
              </div>
            )}
          </div>
          {showBtnOpenWindowChat && (
            <div
              ref={openWindowChatBtnRef}
              className="chat__block1--head--share"
            >
              <img src={imgShare} alt="" />
            </div>
          )}
        </div>

        <div className="chat__block1--body">
          <div
            className="chat__block1--body--message"
            onClick={handleClickToChat}
            onMouseOver={handleMouseEnter}
            onMouseOut={handleMouseLeave}
          >
            <ChatBody
              onlyMy={onlyMy}
              activeFilter={chat[tab]}
              battleStatus={battleStatus}
              onHandleSelectHero={onHandleSelectHero}
            />
          </div>
          <div className="chat__block1--body--input">
            <MentionsInput
              inputRef={mentionsInputRef?.current ? null : mentionsInputRef}
              placeholder={CHAT_CLICK_HERE_TO_START_TYPING}
              forceSuggestionsAboveCursor={true}
              onKeyDown={onKeyDown}
              style={defaultStyle}
              value={text}
              onChange={(e) => {
                handelInput(e.target.value);
              }}
              customSuggestionsContainer={(children) => (
                <div className="chat__block1--body--input--suggestions">
                  {children}
                </div>
              )}
              onMouseOver={handleFocus}
            >
              <Mention
                trigger="#"
                markup="#__display__&__id__*"
                appendSpaceOnAdd={true}
                data={equipments}
                style={defaultMentionStyle}
              />
              <Mention
                trigger="@"
                markup="%__display__&__id__*"
                appendSpaceOnAdd={true}
                data={mentions}
                style={defaultMentionStyle}
              />
            </MentionsInput>
            {/* <textarea
                            className={`chat__block1--body--input--field ${text.length > 50 ? "chat__block1--body--input--big" : ""}`}
                            placeholder="Нажмите сюда, чтобы начать печатать в чат"
                            value={text}
                            onChange={(e) => handelInput(e.target.value)}
                            onKeyDown={onKeyDown}
                        /> */}
            <div
              className="chat__block1--body--input--btn"
              onClick={handleSendMessage}
              style={battleStatus === 2 ? { left: '37vw' } : { left: '38vw' }}
            >
              {/* <Lang>SEND</Lang> */}
            </div>
          </div>
          {battleStatus === 2 && (
            <a
              href={`/battle/${battleInfo.data.battle.id}`}
              className="chat__block1--body--logBtl"
              target="_blank"
              rel="noreferrer"
            >
              <Lang>OPEN_ONLINE_BATTLE_LOG</Lang>
            </a>
          )}
        </div>
      </div>

      <ChatWindow openWindowChatBtnRef={openWindowChatBtnRef} />
    </>
  );
};

export default Chat;
