import { createSlice } from '@reduxjs/toolkit';

const loading = createSlice({
  name: 'loadingInfo',
  initialState: {
    status: true,
  },
  reducers: {
    setLoading(state, action) {
      state.status = action.payload;
    },
  },
});

export const { setLoading } = loading.actions;

export { loading };
