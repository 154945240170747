import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '../../tooltip/Tooltip';
import { useRequest } from '../../../hooks/useRequest';
import CharacterInfoTooltip from '../../character/CharacterInfoTooltip';

export default function HeroNameLink({
  isUser,
  children,
  userHero,
  toMsg,
  disable,
}) {
  const [requestSend] = useRequest();
  const [openHover, setOpenHover] = useState(false);
  const onlineList = useSelector((state) => state.chatData.onlineList);
  const user = useSelector((state) => state.allUserData.data.h_info.id);
  const battleStatus = useSelector((state) => state.battle.status);

  const getInfoForUser = () => {
    if (!openHover) {
      if (!userHero) {
        requestSend.sendData('RequestHeroInfo', { id: user });
        setOpenHover(true);
      } else if (children.includes('$') && children.split('$').length > 1) {
        requestSend.sendData('RequestHeroInfo', { id: children.split('$')[1] });
        setOpenHover(true);
      } else {
        requestSend.sendData('RequestHeroInfo', { id: userHero });
        setOpenHover(true);
      }
    }
  };

  if (!children) {
    return null;
  }

  const heroName = children.includes('$') ? children.split('$')[0] : children;

  return (
    <Tooltip
      key={Math.random()}
      zIndex={9990}
      content={<CharacterInfoTooltip />}
      onHover={() => getInfoForUser()}
      onLeave={() => setOpenHover(false)}
      disable={battleStatus === 2 ? true : false}
      // disable={disable ? disable : true}
    >
      <div
        className={
          isUser && isUser
            ? 'chat__block1--body--message--item--text--red'
            : 'chat__block1--body--message--item--text--blue'
        }
        onClick={() => {
          requestSend
            .sendData('RequestHeroInfo', { id: userHero })
            .then((resolve) => {
              const result = onlineList.filter(
                (el) => el.hero_id === resolve[0].hero_info.h_info.id,
              );
              toMsg(result[0], '/to @');
            });
        }}
      >
        {heroName}
      </div>
    </Tooltip>
  );
}
