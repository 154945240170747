import React, { useEffect, useState, useRef } from 'react';
import Lang from '../language';

const MenuTooltip = ({
  open,
  toRef,
  setForcibly,
  data,
  onClick,
  isBattle = false,
  type = 'invent',
  disUse = false,
}) => {
  const [tooltip, setTooltip] = useState(false);
  const [position, setPosition] = useState({ top: 0 });
  const menuRef = useRef(null);

  useEffect(() => {
    if (open && toRef.current) {
      const { top, height } = toRef.current.getBoundingClientRect();
      const menuHeight = menuRef.current?.offsetHeight || 0;
      let newTop = top + height + 10;
      if (newTop + menuHeight > window.innerHeight) {
        newTop = window.innerHeight - menuHeight - 10;
      }
      setPosition({ top: newTop });
      setTooltip(true);
    } else {
      setTooltip(false);
    }
  }, [open, toRef]);

  // useEffect(() => {
  //   setTooltip(open);
  // }, [open]);

  useEffect(() => {
    function handler(event) {
      if (!toRef.current?.contains(event.target)) {
        setTooltip(false);
        return setForcibly();
      }
    }
    window.addEventListener('click', handler);

    return () => window.removeEventListener('click', handler);
  }, []);

  return (
    <>
      {tooltip && (
        <div
          className="inventory__container__hub--body--things--grid--item--menu"
          style={{ top: position.top, position: 'fixed' }}
          ref={menuRef}
        >
          <div
            // className={`inventory__container__hub--body--things--grid--item--menu--use ${isBattle ? "inventory__container__hub--body--things--grid--item--menu--use--disabled" : ""}`}
            // onClick={() => {
            //     if(!isBattle) {
            //         onClick('RequestEquipmentOn')
            //         setTooltip(false)
            //     }
            // }}
            className={`inventory__container__hub--body--things--grid--item--menu--use${disUse ? '--disabled' : ''}`}
            onClick={() => {
              if (!disUse) {
                onClick('RequestEquipmentOn');
                setTooltip(false);
              }
            }}
          >
            <Lang>USE</Lang>
          </div>
          {type === 'invent' && (
            <>
              <div
                className="inventory__container__hub--body--things--grid--item--menu--use"
                onClick={() => {
                  onClick('disassemble');
                  setTooltip(false);
                }}
              >
                <Lang>DISASSEMBLE</Lang>
              </div>
              <div
                className="inventory__container__hub--body--things--grid--item--menu--use"
                onClick={() => {
                  onClick('del');
                  setTooltip(false);
                }}
              >
                <Lang>THROW_AWAY</Lang>
              </div>
              <div
                className="inventory__container__hub--body--things--grid--item--menu--use"
                onClick={() => {
                  onClick('send');
                  setTooltip(false);
                }}
              >
                <Lang>HAND_OVER</Lang>
              </div>
            </>
          )}
          <i></i>
          <div className="inventory__container__hub--body--things--grid--item--tooltip">
            <h3>Lorem Ipsum</h3>
            <p>Dolor sit amet, consectetur adipiscing elit.</p>
            <i></i>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuTooltip;
