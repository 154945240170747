import { useSelector, useStore } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

export const useTranslate = (token) => {
  const Language = useSelector((state) => state.language.langActive);
  const langList = useSelector((state) => state.language.list);
  const languages = { [Language]: langList };
  const [toText, setToText] = useState('');

  useEffect(() => {
    let xtx = token;
    if (token) {
      let fromText = token.split(' ');
      fromText.forEach((keyword, i) => {
        const template =
          languages[Language][keyword] ||
          languages[Language][keyword.toUpperCase()] ||
          languages[Language][keyword.toLowerCase()] ||
          keyword;
        xtx = xtx.replace(keyword, template);
        // xtx = xtx.replace(/(\w+) (а)/g, '$1$2');
        xtx = xtx.replace(/([а-яА-ЯёЁ]+) (а)/g, '$1$2');
      });
    }
    setToText(xtx);
  }, [Language, languages, token]);

  return [toText];
};
