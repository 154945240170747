import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from '../../../src/hooks/useRequest';

import Images from '../../../src/helper/Images';
import InventoryHubThingsItem from '../Inventory/InventoryHubThings/InventoryHubThingsItem';
import InventoryHubThingsItemOther from '../Inventory/InventoryHubThings/InventoryHubThingsItemOther';
import InventoryHubThingsItemRun from '../Inventory/InventoryHubThings/InventoryHubThingsItemRun';
import InventoryHubSkillsItem from '../Inventory/InventoryHubSkills/InventoryHubSkillsItem';
import InventoryHubThingsItemKey from '../Inventory/InventoryHubThings/InventoryHubThingsItemKey';
import ButtonRed from '../../../src/components/ui/buttons/UiButtonOne';
import imgG from '../../media/img/inventory/gold.png';
import Lang from '../language';
import { useDictionary } from '../../../src/hooks/useDictionary';
import { Naming } from '../../../src/helper/naming';
import Tooltip from '../tooltip/Tooltip';
import { setPurchase } from '../../store/reducer/Slices/SlicesShop';

const ShopCard = ({ props, type, item, index }) => {
  const [dict, getInfoDictionary] = useDictionary();
  // const info =
  //   props.$type === 32
  //     ? props
  //     : props.$type === 31
  //       ? props
  //       : getInfoDictionary('equipments', props.id);
  const dispatch = useDispatch();
  const [quality, setQuality] = useState('');
  const userMoney = useSelector(
    (state) => state.allUserData.data.money.current_money[1],
  );
  const shopPrice = useSelector((state) => state.shopOpen.price);
  const [sellPrice, setSellPrice] = useState(
    shopPrice.find((el) => el.id === item.id)?.price[1] || 150,
  );
  // const [priceCheck, setPriceCheck] = useState(null);
  const [requestSend] = useRequest();
  const [price, setPrice] = useState(0);

  // console.log('shop card - price', price);
  // console.log('shop card - info', info);

  useEffect(() => {
    if (props.price) {
      setPrice(props.price[1]);
    } else if (props.levels[0].price) {
      setPrice(props.levels[0].price[1]);
    } else {
      setPrice(10);
    }
  }, [props]);

  // console.log('dddddd', userMoney, price, userMoney >= price);

  // const Check = () => {
  //   if (userMoney >= price) {
  //     setPriceCheck(false);
  //   } else {
  //     setPriceCheck(true);
  //   }
  // };

  const OnClickBuy = useCallback(() => {
    requestSend.sendData('RequestNpcBuyItem', {
      money_type: 1,
      id: item.id,
    });
    dispatch(
      setPurchase({
        data: item,
      }),
    );
  }, []);

  const OnClickSell = useCallback(() => {
    console.log('sell', item);
    requestSend.sendData('RequestBuyerSellItem', {
      money_type: 1,
      id: item.id,
    });
  }, []);

  useEffect(() => {
    if (type === 2) {
      // console.log('ddddsellitem', item);
      requestSend
        .sendData('RequestBuyerGetItemPrice', {
          id: item.id,
        })
        .then((res) => {
          console.log('res', res);
          // setSellPrice(res[0].data.price)
        });
    }
    if (props.$type === 32 || props.$type === 31) {
      setQuality('NEW');
      return;
    }
    let qualityPercent;
    if (item.durab) {
      qualityPercent = Math.round((item.durab / props.durability) * 100);
    } else {
      qualityPercent = 100;
    }
    if (qualityPercent === 100) {
      setQuality('NEW');
    } else if (qualityPercent > 80) {
      setQuality('ALMOST_NEW');
    } else if (qualityPercent < 80 && qualityPercent > 60) {
      setQuality('HIGH');
    } else if (qualityPercent < 60 && qualityPercent > 40) {
      setQuality('MEDIUM');
    } else {
      setQuality('LOW');
    }
  }, []);

  // useLayoutEffect(() => {
  //   Check();
  // }, [userMoney]);

  const buy = (
    <>
      <Lang>BUY_FOR</Lang>
      <img src={imgG} alt="coin-img" style={{ padding: '5px' }} />
      {price}
    </>
  );
  const sell = (
    <>
      <Lang>SELL_FOR</Lang>
      <img src={imgG} alt="coin-img" style={{ padding: '5px' }} />
      {sellPrice}
    </>
  );

  // const info = useMemo(() => {
  //   return props.$type === 32 || props.$type === 31
  //     ? props
  //     : getInfoDictionary('equipments', props.id);
  // }, [props, getInfoDictionary]);

  const tooltipItem = useMemo(() => ({ ...props, dict_id: props.id }), [props]);
  const tooltipItemOther = useMemo(
    () => ({ ...item, dict_id: item.id }),
    [item],
  );

  const tooltipContent = useMemo(() => {
    switch (true) {
      case props.item_type <= 2:
        return <InventoryHubThingsItemOther toItem={tooltipItemOther} />;
      case props.item_type === 3:
        return <InventoryHubThingsItemRun toItem={props} />;
      case props.$type === 15:
        return (
          <InventoryHubSkillsItem fromItem={props} toItem={tooltipItemOther} />
        );
      case props.$type === 31 || props.$type === 32:
        return <InventoryHubThingsItemKey toItem={tooltipItemOther} />;
      default:
        return <InventoryHubThingsItem user={tooltipItem} />;
    }
  }, [props, tooltipItemOther]);

  return (
    <div className="shop--item">
      <div className="shop--item--left">
        <div className="shop--item--left--inner">
          <Tooltip
            key={props.id + 'infoWeapons' + index}
            open={true}
            content={tooltipContent}
          >
            <div className="shop--item--left--inner--image">
              <Images name={props.id} />
            </div>
          </Tooltip>
        </div>
        <div className="shop--item--left--inner--info">
          <h5 className="item_title">
            {props.entity_id ? (
              <>
                <Lang>BOOK</Lang>: <Lang>{props.entity_id}</Lang>
              </>
            ) : (
              <>
                <Lang>{props.id}</Lang>, <Lang>{quality}</Lang>
              </>
            )}
          </h5>
          {props.$type !== 31 && props.$type !== 32 ? (
            <>
              {getInfoDictionary('equipments', props.id).damage_min && (
                <p className="item_text">
                  <Lang>DAMAGE</Lang>: <span> </span>
                  <span style={{ color: '#a1a1a1' }}>
                    {getInfoDictionary('equipments', props.id).damage_min} -
                    {getInfoDictionary('equipments', props.id).damage_max}
                  </span>
                </p>
              )}
            </>
          ) : null}
          {props.weapon_class && (
            <p key="class" className="item_text">
              <Lang>CLASS</Lang>: <span> </span>
              <span style={{ color: '#a1a1a1' }}>
                {' '}
                <Lang>{Naming.WeaponClassType[props.weapon_class]}</Lang>{' '}
              </span>
            </p>
          )}
          <p key="bonuses" className="item_text">
            {props.character_bonuses && (
              <>
                <Lang>BONUSES</Lang>:{' '}
                {props.character_bonuses.map((el, i) => {
                  return (
                    <span key={'bonuses' + i} style={{ color: '#a1a1a1' }}>
                      <Lang>{Naming.character[el.char]}</Lang>: +{el.points}
                      {i === props.character_bonuses.length - 1 ? '' : ', '}
                    </span>
                  );
                })}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="shop--item--right">
        {type === 1 ? (
          <ButtonRed
            className={'shop'}
            type={'buy'}
            disabled={userMoney < price}
            onClick={OnClickBuy}
          >
            {buy}
          </ButtonRed>
        ) : (
          <ButtonRed
            className={'shop'}
            type={'sell'}
            disabled={false}
            onClick={OnClickSell}
          >
            {sell}
          </ButtonRed>
        )}
      </div>
    </div>
  );
};
export default React.memo(ShopCard);
