import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';

import { setOpenInventory } from '../../store/reducer/Slices/SlicesInventory';

import Character from '../../components/character/Character';
import CharacterInfo from '../../components/character/Info/CharacterInfo';
import Inventory from '../../components/Inventory/Inventory';
import Shop from '../../components/shop/Shop';

import imgMiniInventory from '../../media/img/game/character/miniInventory.png';
import imgFlag from '../../media/img/game/character/flag.png';
import imgMini1 from '../../media/img/game/character/mini/mini1.png';
import imgMini2 from '../../media/img/game/character/mini/mini2.png';
import imgMini3 from '../../media/img/game/character/mini/mini3.png';
import imgMini4 from '../../media/img/game/character/mini/mini4.png';
import PlayingField from '../../components/playingField/playingField';
import { Naming } from '../../helper/naming';
import Loading from '../../components/loading/loading';
import Window from '../../components/window/Window';
import { useRequest } from '../../hooks/useRequest';
import BaseModal from '../../components/modal/BaseModal';
import { setLoading } from '../../store/reducer/Slices/SlicesLoading';
import ActiveBar from '../../components/activeBar/ActiveBar';
import BattleList from '../../components/battle/BattleList';
import ActiveBarHub from '../../components/activeBar/ActiveBarHub';
import { useDictionary } from '../../hooks/useDictionary';
import ActiveNum from '../../components/activeNum/ActiveNum';
import InventoryMiniSlots from '../../components/Inventory/InventoryMiniSlots';
import Lang from '../../components/language';
import CharacterInfoTooltip from '../../components/character/Info/CharacterInfoTooltip';
import CharacterInfoTooltip2 from '../../components/character/CharacterInfoTooltip';
import Tooltip from '../../components/tooltip/Tooltip';
import { GroupInviteModal } from '../../features/groups/modals/GroupInviteModal';
import {
  setBaseModal,
  setBaseModalData,
} from '../../store/reducer/Slices/SliceModal';
import { cookieGet } from '../../helper/cookie';
import { sendToChat } from '../../store/reducer/Slices/SlicesChat';
import { setLevelUpModal } from '../../store/reducer/Slices/SlicesShop';
import InventoryHubEffect from '../../components/Inventory/InventoryHubSkills/InverntoryHubEffect';
import Images from '../../helper/Images';
import BattleInitBattleProgress from '../../components/battle/BattleInit/BattleInitBattleProgress';

export const UserContext = createContext(null);

const Game = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.allUserData.data);
  const BattleResults = useSelector(
    (state) => state.allUserData.data.battle_results,
  );
  const userLevel = useSelector((state) => state.allUserData.data.level);
  const userChar = useSelector((state) => state.allUserData.data.char);
  const userInfo = useSelector((state) => state.allUserData.data.h_info);
  const userSkills = useSelector((state) => state.allUserData.data.skills);
  const userMain = useSelector((state) => state.allUserData.user_main);
  const battleStatus = useSelector((state) => state.battle.status);
  const [requestSend] = useRequest();
  const [, setUserSkillsData] = useState({
    combatEquips: [],
    equips: [],
    activeAbilities: [],
    abilities: [],
  });
  const loading = useSelector((state) => state.loading.status);
  const [dict, getInfoDictionary] = useDictionary();
  const [lastResult, setLastResult] = useState({});
  const [openHover, setOpenHover] = useState(false);
  const userID = useSelector((state) => state.allUserData.data.id);

  const getInfoForUser = () => {
    if (!openHover) requestSend.sendData('RequestHeroInfo', { id: userID });
    setOpenHover(true);
  };

  useEffect(() => {
    if (!loading) {
      if (userData.h_group.group_id !== '') {
        requestSend.sendData('RequestGroupGetInfo', {});
      }
    }
  }, [loading]);

  useEffect(() => {
    // console.log('userSkillsData', userSkillsData)
    setUserSkillsData({
      combatEquips: [],
      equips: userSkills.points
        ? userSkills?.points.filter(
            (i) => getInfoDictionary('skills', i.class).skill_type === 1,
          )
        : [],
      activeAbilities: [],
      abilities: userSkills.points
        ? userSkills?.points.filter(
            (i) => getInfoDictionary('skills', i.class).skill_type === 2,
          )
        : [],
    });
  }, [userSkills]);

  const openInventory = () => {
    dispatch(setOpenInventory(true));
  };

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(userLevel).length > 0 && !!dict) {
        dispatch(setLoading(false));
      } else dispatch(setLoading(true));
    }, 1500);
  }, [userData, dict]);

  useEffect(() => {
    if (userMain.u_info && userMain.u_info.battle_id && battleStatus !== 2) {
      requestSend.sendData('RequestBattleData', {
        battle_id: userMain.u_info.battle_id,
      });
    }
  }, [userMain]);

  useEffect(() => {
    const UnprocessedResult = BattleResults.find(
      (result) => !result.is_processed,
    );
    if (UnprocessedResult && lastResult.id !== UnprocessedResult.id) {
      dispatch(setBaseModal(true));
      dispatch(
        setBaseModalData({
          zIndex: 9,
          numData: 4,
          styles: 'mini',
          maxWidth: '300px',
        }),
      );
      setLastResult(UnprocessedResult);
    }
  }, [BattleResults]);

  useEffect(() => {
    const msg = cookieGet('levelUpModal');
    if (msg && !!JSON.parse(msg)) {
      dispatch(sendToChat(JSON.parse(msg)));
      dispatch(setLevelUpModal(JSON.parse(msg)));
      dispatch(setBaseModal(true));
      dispatch(
        setBaseModalData({
          zIndex: 9,
          numData: 6,
          styles: 'mini',
          maxWidth: '300px',
        }),
      );
    }
  }, []);

  return (
    <div className="game">
      <Loading />
      {Object.keys(userLevel).length > 0 && !!dict && (
        <div className="game--container">
          <div className="game--container__left">
            {battleStatus === 2 && (
              <div className="game--container__left--infoid">
                <div>Battle ID: {userMain?.u_info?.battle_id}</div>
                <div>Hero Info: {userMain?.u_info?.hero_id}</div>
              </div>
            )}
            <div className="game--container__left--chain" />
            <div className="game--container__left--block">
              <Tooltip
                key="111"
                zIndex={9990}
                onHover={() => getInfoForUser()}
                onLeave={() => setOpenHover(false)}
                content={<CharacterInfoTooltip2 />}
              >
                <div className="game--container__left--block__head">
                  <img
                    className="game--container__left--block__head--flag"
                    src={imgFlag}
                    alt=""
                  />
                  <Tooltip
                    key="111"
                    zIndex={9990}
                    maxWidth="450px"
                    className="game--container__left--block__head--level"
                    content={<CharacterInfoTooltip choice="111" />}
                  >
                    <ActiveNum
                      num={userLevel['glory']}
                      classOne="game--container__left--block__head--level"
                      classTwo="game--container__left--block__head--level--num"
                    />
                  </Tooltip>

                  <div className="game--container__left--block__head--info">
                    <Tooltip
                      key="111"
                      zIndex={9990}
                      onHover={() => getInfoForUser()}
                      onLeave={() => setOpenHover(false)}
                      content={<CharacterInfoTooltip2 />}
                    >
                      <div className="game--container__left--block__head--info--name">
                        {userInfo['name']}
                      </div>{' '}
                    </Tooltip>{' '}
                    <div className="game--container__left--block__head--info--stat">
                      {battleStatus === 0 ||
                      battleStatus === 1 ||
                      battleStatus === 3 ? (
                        <>
                          <Tooltip
                            key="112"
                            zIndex={9990}
                            maxWidth="450px"
                            content={<CharacterInfoTooltip choice="112" />}
                          >
                            <ActiveBar
                              classOne="game--container__left--block__head--info--stat--bar-bg"
                              classTwo="game--container__left--block__head--info--stat--bar-bg--active"
                              one={userLevel['exp']}
                              two={
                                dict.hero_levels.find(
                                  (i) => i.level === userLevel['level'],
                                ).exp_next_level
                              }
                            />
                            <div className="game--container__left--block__head--info--stat--bar-bg--active--level">
                              <Lang>{Naming.lvl}</Lang> {userLevel['level']}
                            </div>
                            <div className="game--container__left--block__head--info--stat--bar-bg--active--exp">
                              {userLevel['exp']} /{' '}
                              {
                                dict.hero_levels?.find(
                                  (i) => i.level === userLevel['level'],
                                ).exp_next_level
                              }
                            </div>
                          </Tooltip>
                        </>
                      ) : (
                        <ActiveBarHub
                          hp={
                            userChar?.characters?.find(
                              (item) => item.char === 22,
                            ).points
                          }
                          hpMax={
                            userChar.characters.find((item) => item.char === 21)
                              ?.points
                          }
                          mind={
                            userChar.characters.find((item) => item.char === 44)
                              ?.points
                          }
                          mindMax={
                            userChar.characters.find((item) => item.char === 75)
                              ?.points
                          }
                          armor={
                            userChar.characters.find((item) => item.char === 31)
                              ?.points
                          }
                          armorMax={
                            userChar.characters.find((item) => item.char === 76)
                              ?.points
                          }
                          hpShow={true}
                        />
                      )}
                    </div>
                  </div>
                  {/*{*/}
                  {/*    (battleStatus === 0 || battleStatus === 1 || battleStatus === 3) &&*/}
                  {/*}*/}
                </div>
              </Tooltip>
              <div style={{ display: 'flex' }}>
                <div
                  className="game--container__left--block__head__inventory"
                  onClick={() => openInventory()}
                >
                  <img src={imgMiniInventory} alt="" />
                </div>
                <div className="character__bufs">
                  {userData?.h_effects?.effects?.map((i) => {
                    const infoWeapons = getInfoDictionary('effects', i.dict_id);

                    return (
                      <>
                        {/*<div className="character__bufs--item positive">*/}
                        {/*    <Images name={i.dict_id}/>*/}
                        {/*</div>*/}
                        <Tooltip
                          key={i.id + 'effect'}
                          content={
                            <InventoryHubEffect
                              toItem={{ dict_id: infoWeapons?.id, all: i }}
                            />
                          }
                        >
                          <div
                            key={i.dict_id}
                            className="character__bufs--item negative"
                          >
                            <Images name={infoWeapons?.id} />
                            {/*<div className="character__bufs--item--many">*/}
                            {/*    2*/}
                            {/*</div>*/}
                          </div>
                        </Tooltip>
                      </>
                    );
                  })}
                  {battleStatus === 2 && <BattleInitBattleProgress />}
                </div>
              </div>
              <Character />

              <div className="game--container__left--block__characteristics">
                <div className="game--container__left--block__characteristics--mini">
                  <div className="game--container__left--block__characteristics--mini--box">
                    <Tooltip
                      key="characters27"
                      zIndex={9990}
                      maxWidth="450px"
                      content={<CharacterInfoTooltip choice="27" />}
                      className={{ marginLeft: 12 }}
                    >
                      <div className="game--container__left--block__characteristics--mini--box--item">
                        <img src={imgMini1} alt="" />
                        {userChar.characters.find((item) => item.char === 27)
                          .points
                          ? userChar.characters.find((item) => item.char === 27)
                              .points
                          : null}
                      </div>
                    </Tooltip>
                    <Tooltip
                      key="characters28"
                      zIndex={9990}
                      maxWidth="450px"
                      content={<CharacterInfoTooltip choice="28" />}
                      className={{ marginLeft: 12 }}
                    >
                      <div className="game--container__left--block__characteristics--mini--box--item">
                        <img src={imgMini2} alt="" />
                        {userChar.characters.find((item) => item.char === 28)
                          .points
                          ? userChar.characters.find((item) => item.char === 28)
                              .points
                          : null}
                      </div>
                    </Tooltip>
                    <Tooltip
                      key="characters29"
                      zIndex={9990}
                      maxWidth="450px"
                      content={<CharacterInfoTooltip choice="29" />}
                      className={{ marginLeft: 12 }}
                    >
                      <div className="game--container__left--block__characteristics--mini--box--item">
                        <img src={imgMini3} alt="" />
                        {userChar.characters.find((item) => item.char === 29)
                          .points
                          ? userChar.characters.find((item) => item.char === 29)
                              .points
                          : null}
                      </div>
                    </Tooltip>
                    <Tooltip
                      key="characters30"
                      zIndex={9990}
                      maxWidth="450px"
                      content={<CharacterInfoTooltip choice="30" />}
                      className={{ marginLeft: 12 }}
                    >
                      <div className="game--container__left--block__characteristics--mini--box--item">
                        <img src={imgMini4} alt="" />
                        {userChar.characters.find((item) => item.char === 30)
                          .points
                          ? userChar.characters.find((item) => item.char === 30)
                              .points
                          : null}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {(battleStatus === 0 ||
                  battleStatus === 1 ||
                  battleStatus === 3) && (
                  <div className="game--container__left--block__characteristics--mini-slots">
                    <InventoryMiniSlots mini={true} />
                  </div>
                )}
              </div>
              {battleStatus === 0 ||
              battleStatus === 1 ||
              battleStatus === 3 ? (
                <CharacterInfo char={userChar} />
              ) : (
                <BattleList choice={1} />
              )}
            </div>
          </div>
          <div className="game--container__right">
            {/* Тут сделать один контейнер всех данжей для смены локации(данжей) и чилдрен прокидывать */}
            <Inventory />
            {userData.h_location.building_id && <Shop />}
            {userData.h_location.random_event_id && <Shop />}
            <PlayingField />
            <GroupInviteModal />
            {(battleStatus === 0 ||
              battleStatus === 1 ||
              battleStatus === 3) && (
              <>
                <Window />
                <BaseModal />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Game;
