import React from 'react';
import '../styles.scss';
import { useDispatch } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import { setOpenApplication } from '../../../store/reducer/Slices/SlicesApplicationOpen';
import Lang from '../../language';
import imgKuznya from '../../../media/img/game/playingField/map/loc1/kuznya.png';
import imgBackItem from '../../../media/img/game/playingField/map/loc1/backItem.png';
import imgMapBack from '../../../media/img/game/playingField/map/loc1/mapBack.png';
import imgBack from '../../../media/img/game/playingField/map/loc1/loc1B.png';
import imgLavka from '../../../media/img/game/playingField/map/loc1/lavka.png';
import imgTaverna from '../../../media/img/game/playingField/map/loc1/taverna.png';
import { setOpenMap } from '../../../store/reducer/Slices/SlicesMap';
import LocationElement from '../LocationElement';
import { setNpcOpen } from '../../../store/reducer/Slices/SliceNpc';
import ModalResp from '../../modal/ModalRespawn/ModalResp';

const Location1 = () => {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();

  const locations = {
    backImg: imgBack,
    items: [
      {
        name: 'Таверна',
        img: {
          top: '2.2vw',
          left: '27vw',
          width: '16.2vw',
          src: imgTaverna,
          srcBack: imgBackItem,
        },
        onClick: () => {
          requestSend.sendData('RequestBuildingMove', {
            build_id: 'bld.3',
          });
          dispatch(setNpcOpen(true));
        },
      },
      {
        name: 'Кузня',
        img: {
          top: '17.2vw',
          left: '17vw',
          width: '10.2vw',
          src: imgKuznya,
          srcBack: imgBackItem,
        },
        onClick: () => {
          requestSend.sendData('RequestBuildingMove', {
            build_id: 'bld.1',
          });
          dispatch(setNpcOpen(true));
        },
      },
      {
        name: 'Лавка',
        img: {
          top: '13.2vw',
          left: '42.2vw',
          width: '15.2vw',
          src: imgLavka,
          srcBack: imgBackItem,
        },
        onClick: () => {
          requestSend.sendData('RequestBuildingMove', {
            build_id: 'bld.2',
          });
          dispatch(setNpcOpen(true));
        },
      },
      {
        name: 'Карта',
        img: {
          top: '30.2vw',
          left: '43.2vw',
          width: '8.2vw',
          src: imgMapBack,
          srcBack: imgBackItem,
        },
        onClick: () => dispatch(setOpenMap(true)),
      },
    ],
  };

  return (
    <div className="locationIn">
      <ModalResp />
      <div className="locationIn__back">
        <img src={locations.backImg} alt="" />
      </div>
      <div className="locationIn__container">
        {/* <div className="locationIn__container--buttons">
          <UiButtonOne
            type="middle"
            onClick={() => {
              dispatch(setOpenApplication(1));
            }}
          >
            <Lang>LIST_OF_APPLICATIONS</Lang>
          </UiButtonOne>
          <UiButtonOne
            type="middle"
            onClick={() => {
              requestSend.sendData('RequestBattleRequestPvpBot');
            }}
          >
            <Lang>FIGHT_WITH_A_BOT</Lang>
          </UiButtonOne>
        </div> */}
        {locations.items.map((i, index = index + 1) => (
          <LocationElement index={index} i={i} key={i.name} />
        ))}
      </div>
    </div>
  );
};

export default Location1;
