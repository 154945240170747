import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import './styles.scss';
import 'swiper/css';
import 'swiper/css/navigation';

import img1 from '../../media/img/createCharacter/light_notactive.png';
import img1A from '../../media/img/createCharacter/light_activeglow.png';
import img2 from '../../media/img/createCharacter/dark_notactive.png';
import img2A from '../../media/img/createCharacter/dark_active_glow.png';
import img3 from '../../media/img/createCharacter/chaos_notactive.png';
import img3A from '../../media/img/createCharacter/chaos_active_glow.png';

import imgC1 from '../../media/img/character/ch1.png';
import imgC2 from '../../media/img/character/ch2.png';
import imgC3 from '../../media/img/character/ch3.png';
import imgC4 from '../../media/img/character/ch4.png';
import imgC5 from '../../media/img/character/ch5.png';
import imgC6 from '../../media/img/character/ch6.png';
import imgC7 from '../../media/img/character/ch7.png';
import imgC8 from '../../media/img/character/ch8.png';

import imgR from '../../media/img/createCharacter/ref.png';

import imgBGAR from '../../media/img/createCharacter/ribbon_nonactive.png';
import imgBGARA from '../../media/img/createCharacter/ribbon_active.png';

import { checkRegular, redirect } from '../../helper/helpers';
import TooltipInput from '../../components/tooltip/TooltipInput';

import { h1, h2 } from './NewCharacterHub';
import { useAuth } from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { ResponseWarning } from '../../helper/reqRspName';
import UiButtonOne from '../../components/ui/buttons/UiButtonOne';
import UiInput from '../../components/ui/inputs/UiInput';
import { useRequest } from '../../hooks/useRequest';
import Lang from '../../components/language';

const cultList = [
  {
    id: 1,
    name: 'LIGHT',
    img: img1,
    imgActive: img1A,
  },
  {
    id: 2,
    name: 'DARK',
    img: img2,
    imgActive: img2A,
  },
  {
    id: 3,
    name: 'CHAOS',
    img: img3,
    imgActive: img3A,
  },
];

const forTooltip = {
  1: h1,
  2: h2,
};

const NewCharacter = () => {
  const [requestSend] = useRequest();
  const [disabledPlay, playDisabledPlay] = useState(false);
  const [cult, setCult] = useState(Math.floor(Math.random() * 3) + 1);
  const [activeCharacter, setActiveCharacter] = useState('her.1');
  const [nick, setNick] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [actionTooltip, setActionTooltip] = useState(1);
  const [checkEmail, login, emailStatus, registration] = useAuth();
  let centrifuge = useSelector((state) => state.connectionApi.data);
  let userData = useSelector((state) => state.allUserData);
  let userNames = useSelector((state) => state.allUserData.user_names);
  let nameCheck = useSelector((state) => state.checkName.check.exists);

  const handle = (e) => {
    const replacedString = e.replace(/[^ёЁа-яА-Яa-zA-Z0-9\-_ ]/, '');
    setNick(replacedString);
    // let check = checkRegular(e, replacedString) || replacedString !== e;
    let check = checkRegular('nick', replacedString);
    requestSend.sendData('RequestHeroNameExists', {
      name: replacedString,
    });
    playDisabledPlay(check);
    setOpenTooltip(check);
    setActionTooltip(check ? 2 : 1);
  };

  const clickRefresh = () => {
    requestSend.sendData('RequestGenerateName');
  };

  useEffect(() => {
    if (userNames && userNames.length > 0) {
      setNick(userNames[0]);
      playDisabledPlay(false);
      setOpenTooltip(false);
    }
  }, [userNames]);

  const changeToActiveCharacter = (val) => {
    switch (val) {
      case 0:
        setActiveCharacter('her.1');
        break;
      case 1:
        setActiveCharacter('her.2');
        break;
      case 2:
        setActiveCharacter('her.3');
        break;
      case 3:
        setActiveCharacter('her.4');
        break;
      case 4:
        setActiveCharacter('her.5');
        break;
      case 5:
        setActiveCharacter('her.6');
        break;
      case 6:
        setActiveCharacter('her.7');
        break;
      case 7:
        setActiveCharacter('her.8');
        break;
    }
  };

  const buttonClick = () => {
    if (nameCheck) {
      playDisabledPlay(nameCheck);
      setOpenTooltip(nameCheck);
      setActionTooltip(nameCheck ? 1 : 2);
    } else if (userData?.user_main?.u_login_email) {
      requestSend
        .sendData('RequestCreateHero', {
          name: nick,
          hid: activeCharacter,
          pid: cult,
        })
        .then((res) => {
          if (Array.isArray(res) && res[0].$type === 13) {
            redirect('/choose-character');
          } else {
            playDisabledPlay(true);
            setOpenTooltip(true);
            setActionTooltip(1);
          }
        });
    } else {
      registration(nick, cult, activeCharacter).then((rsp) => {
        if (rsp.wid === ResponseWarning.HeroNameExists) {
          // clickRefresh();
          setActionTooltip(1);
          setOpenTooltip(true);
        }
      });
    }
  };

  useEffect(() => {
    clickRefresh();
    // playDisabledPlay(checkRegular('nick', nick))
    if (centrifuge && !Object.keys(centrifuge).length) {
      redirect('/welcome');
    }
  }, [centrifuge]);

  return (
    <div className="newCharacter">
      <div className="newCharacter__container">
        <div className="newCharacter__container--box">
          {cultList.map((i, index) => (
            <div
              key={index + 'newChar'}
              className={`newCharacter__container--box--item ${cult === i.id ? 'newCharacter__container--box--item--active' : ''}`}
              onClick={() => setCult(i.id)}
            >
              {cult === i.id ? (
                <>
                  <div className="newCharacter__container--box--item--anim" />
                  <img
                    src={imgBGARA}
                    className="newCharacter__container--box--item--bgar"
                  />
                </>
              ) : (
                <img
                  src={imgBGAR}
                  className="newCharacter__container--box--item--bgar"
                />
              )}
              <img
                className="newCharacter__container--box--item--img"
                src={cult === i.id ? i.imgActive : i.img}
                alt={i.name}
              />
              <h4>
                <Lang>{i.name}</Lang>
              </h4>
            </div>
          ))}
        </div>
        <div className="newCharacter__container--box">
          <div className="newCharacter__container--box__character">
            <Swiper
              initialSlide={Math.floor(Math.random() * 8) + 1}
              onActiveIndexChange={(e) =>
                changeToActiveCharacter(e.activeIndex)
              }
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="newCharacter__container--box__character--img">
                  <img src={imgC1} alt="character" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="newCharacter__container--box__character--img">
                  <img src={imgC2} alt="character" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="newCharacter__container--box__character--img">
                  <img src={imgC3} alt="character" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="newCharacter__container--box__character--img">
                  <img src={imgC4} alt="character" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="newCharacter__container--box__character--img">
                  <img src={imgC5} alt="character" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="newCharacter__container--box__character--img">
                  <img src={imgC6} alt="character" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="newCharacter__container--box__character--img">
                  <img src={imgC7} alt="character" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="newCharacter__container--box__character--img">
                  <img src={imgC8} alt="character" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="newCharacter__container--box">
          <div className="newCharacter__container--box--form">
            <div className="newCharacter__container--box--form--name">
              <Lang>NAME</Lang>:
            </div>
            <TooltipInput
              style={{ width: '180%' }}
              open={openTooltip}
              theme="black"
              content={
                <>
                  {forTooltip[actionTooltip]}
                  {userNames.length > 0 ? (
                    <>
                      <div>
                        <Lang>PLAY_INFO_6</Lang>:
                      </div>
                      <div className="newCharacter__container--box--form--name--list">
                        {userNames.map((i) => (
                          <>
                            <div
                              className="newCharacter__container--box--form--name--list--name"
                              onClick={() => {
                                setNick(i);
                                setOpenTooltip(false);
                                playDisabledPlay(false);
                              }}
                            >
                              {i}
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              }
              // contentPlus={actionTooltip === 1 && <div className="newCharacter__container--box--form--name--other">Василий</div>}
            >
              <div className="newCharacter__container--box--form--input">
                <UiInput
                  name="nick"
                  type="text"
                  value={nick}
                  onChange={(e) => handle(e)}
                  spellCheck={false}
                />
                <div
                  className="newCharacter__container--box--form--input--ref"
                  onClick={() => clickRefresh()}
                >
                  <img src={imgR} alt="" />
                </div>
              </div>
            </TooltipInput>
            <UiButtonOne disabled={disabledPlay} onClick={() => buttonClick()}>
              <Lang>PLAY</Lang>
            </UiButtonOne>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCharacter;
