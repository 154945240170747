import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  tooltipAddInfo,
  tooltipOpen,
} from '../../store/reducer/Slices/SlicesTooltip';

const Tooltip = ({
  open,
  children,
  content,
  maxWidth = 'auto',
  zIndex = 30,
  onClick,
  onMouseDown,
  fix = false,
  automatic = true,
  onHover,
  className,
  onLeave,
  disable,
}) => {
  // Переделать тултипы
  const dispatch = useDispatch();
  const toRef = useRef(null);
  // const [fromHover, setFromHover] = useState(false);
  // const refSetTimeout = useRef();
  const status = useSelector((state) => state.tooltip.status);

  // const store = useStore();

  // useEffect(() => { // убрал по запросу заказчика, возможно придется позже вернуть
  //   let timer;
  //   if (status && automatic) {
  //     timer = setTimeout(() => {
  //       dispatch(tooltipOpen(false));
  //       dispatch(tooltipAddInfo({}));
  //       onLeave?.();
  //     }, 10000);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [status, automatic, dispatch, onLeave]);

  const setHover = () => {
    let box = toRef.current.getBoundingClientRect();
    Promise.resolve(
      dispatch(
        tooltipAddInfo({
          info: {
            box,
            current: toRef.current,
            className,
          },
          content,
        }),
      ),
    ).then(() => {
      setTimeout(() => {
        dispatch(tooltipOpen(true));
      }, 300);
    });

    onHover?.();
  };

  const delHover = () => {
    dispatch(tooltipOpen(false));
    dispatch(tooltipAddInfo({}));

    onLeave?.();
  };

  // useEffect(() => {
  //     if (fromHover) {
  //         refSetTimeout.current = setTimeout(() => {
  //             setHover()
  //         }, 600);
  //     } else {
  //         clearTimeout(refSetTimeout.current)
  //         delHover()
  //     }
  // }, [fromHover])

  // useEffect(() => {                                       // закомментил для фикса драг-н-дроп
  //   // const div = document.getElementById( 'initTooltip');

  //   // document.addEventListener('click', e => {
  //   //     const withinBoundaries = e.composedPath().includes(div);
  //   //     if ( !withinBoundaries ) {
  //   //         delHover()
  //   //     }
  //   // })

  //   window.addEventListener('dragstart', (e) => {
  //     // console.log('dragstart '+store.getState().tooltip.status);
  //     console.log('dragstart ' + status);
  //     if (status) {
  //       // if (store.getState().tooltip.status) {
  //       delHover();
  //     }
  //   });
  //   window.addEventListener('dragenter', (e) => {
  //     // console.log('dragenter '+store.getState().tooltip.status);
  //     console.log('dragenter ' + status);
  //     if (status) {
  //       // if (store.getState().tooltip.status) {
  //       delHover();
  //     }
  //   });
  // }, []);

  return (
    <div
      className={`tooltip ${className}`}
      ref={toRef}
      onMouseEnter={() => {
        // console.log('enter');
        if (automatic) {
          // setFromHover(true)
          if (!disable) {
            setHover();
          }
        }
      }}
      onMouseDown={() => {
        if (automatic) {
          delHover();
          onMouseDown && onMouseDown();
        }
      }}
      onMouseLeave={() => {
        // console.log('leave');
        if (automatic) {
          // setFromHover(false)
          delHover();
        }
      }}
      onContextMenu={() => {
        if (automatic) {
          // setFromHover(false)
          delHover();
        } else {
          // setFromHover(true)
          if (!disable) {
            setHover();
          }
        }
      }}
      onClick={() => {
        onClick && onClick();
        // automatic && setFromHover(false)
        automatic && delHover();
      }}
      draggable={false}
    >
      {children}
    </div>
  );
};

export default Tooltip;
