import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from '../../../src/hooks/useRequest';
import {
  setChestOpen,
  setDungeonOpen,
} from '../../store/reducer/Slices/SlicesDungeon';
import img1 from '../../media/img/game/playingField/map/loc1/mapBack.png';
import chestNoLock from '../../media/img/game/playingField/dungeons/chest_noLock.png';
import chestNoLockMet from '../../media/img/game/playingField/dungeons/chest_noLock_metal.png';
import chestLock from '../../media/img/game/playingField/dungeons/chest_lock.png';
import chestLockMet from '../../media/img/game/playingField/dungeons/chest_lock_metal.png';
import chestOpenLock from '../../media/img/game/playingField/dungeons/chest_openLock.png';
import chestOpenLockMet from '../../media/img/game/playingField/dungeons/chest_openLock_metal.png';
import mobImg from '../../media/img/npc/Mob2.png';
import imgMob1 from '../../media/img/npc/Mob1.png';
import imgMob2 from '../../media/img/npc/Mob2.png';
import imgMob3 from '../../media/img/npc/Mob3.png';
import imgMob4 from '../../media/img/npc/Mob4.png';
import imgMob5 from '../../media/img/npc/Mob5.png';
import imgMob6 from '../../media/img/npc/Mob6.png';
import imgMob7 from '../../media/img/npc/Mob7.png';
import imgMob8 from '../../media/img/npc/Mob8.png';
import imgMob9 from '../../media/img/npc/Mob9.png';
import imgDin from '../../media/img/inventory/gold.png';
import { loadersSpin1 } from '../loaders/loaders';
import { useDictionary } from '../../hooks/useDictionary';
import Tooltip from '../tooltip/Tooltip';
import InventoryHubThingsItemOther from '../Inventory/InventoryHubThings/InventoryHubThingsItemOther';
import InventoryHubThingsItemRun from '../Inventory/InventoryHubThings/InventoryHubThingsItemRun';
import InventoryHubThingsItemKey from '../Inventory/InventoryHubThings/InventoryHubThingsItemKey';
import InventoryHubThingsItem from '../Inventory/InventoryHubThings/InventoryHubThingsItem';
import Images from '../../helper/Images';
import ButtonRed from '../ui/buttons/UiButtonOne';
// import Lang from '../language';
import _ from 'lodash';

const spin = loadersSpin1;

const botIDList = new Map([
  ['d.mob.1', imgMob5],
  ['d.mob.2', imgMob5],
  ['d.mob.3', imgMob6],
  ['d.mob.4', imgMob3],
  ['d.mob.5', imgMob2],
  ['d.mob.6', imgMob2],
  ['d.mob.7', imgMob4],
  ['d.mob.8', imgMob4],
  ['d.mob.9', imgMob5],
  ['d.mob.10', imgMob1],
  ['d.mob.11', imgMob5],
  ['d.mob.12', imgMob3],
]);

function DungeonModal({ activeRoom }) {
  const dispatch = useDispatch();
  const [dict, getInfoDictionary] = useDictionary();
  const [message, setMessage] = useState(null);
  const [responce, setResponce] = useState(null);
  const [responceAdd, setResponceAdd] = useState(null);
  const [image, setImage] = useState(img1);
  const [requestSend] = useRequest();
  const [actionType, setActionType] = useState(null);
  const [actionTypeAdd, setActionTypeAdd] = useState(null);
  const userInvent = useSelector(
    (state) => state.allUserData.data.invent.invent_equipments,
  );
  const [infoLoot, setInfoLoot] = useState(null);
  const [infoCoins, setInfoCoins] = useState(null);
  const [lootId, setLootId] = useState(null);
  const chestOpen = useSelector(
    (state) => state.DungeonsData.chestOpen || null,
  );
  const [time, setTime] = useState(10);
  const user = useSelector((state) => state.allUserData.data.h_location);
  const keys = userInvent ? userInvent.filter((el) => el.$type === 13) : [];
  const picks = userInvent ? userInvent.filter((el) => el.$type === 14) : [];
  const [chestType, setChestType] = useState(1);
  const [level, setLevel] = useState(activeRoom.level);

  console.log('activeRoom', activeRoom);

  useEffect(() => {
    setImage(img1);
    setMessage('');
    setResponce('');
    setActionType(0);
    setResponceAdd('');
    setActionTypeAdd(0);
    if (lootId === activeRoom?.id && infoCoins) {
      setMessage('Вы забрали: ');
      setResponce('Продолжить');
      setActionType(6);
      if (
        activeRoom?.game_model &&
        activeRoom?.game_model?.dict_id.startsWith('d.chst')
      ) {
        setImage(chestType > 1 ? chestOpenLockMet : chestOpenLock);
      } else if (
        activeRoom?.game_model &&
        activeRoom?.game_model?.dict_id.startsWith('d.rmn')
      ) {
        setImage(img1);
      }
    } else if (lootId === activeRoom?.id && infoLoot) {
      setMessage('Вы забрали: ');
      setResponce('Продолжить');
      setActionType(6);
      if (activeRoom.id.startsWith('d.chst')) {
        setImage(chestType > 1 ? chestOpenLockMet : chestOpenLock);
      } else if (activeRoom.id.startsWith('d.rmn')) {
        setImage(img1);
      }
    } else if (!activeRoom.game_model || activeRoom.game_model === '') {
      // пусто
      setMessage('Тут нет ничего интересного.');
      setActionType(1);
      setResponce(null);
      setImage(img1);
      setInfoLoot(null);
      //
    } else if (activeRoom.game_model.dict_id.startsWith('d.chst')) {
      // сундук
      const [x, y, num] = activeRoom.game_model.dict_id.split('.');
      setChestType(num > 2 ? 2 : 1);
      if (!activeRoom.game_model.is_open) {
        const chest = getInfoDictionary(
          'dungeon_chests',
          activeRoom.game_model.dict_id,
        );
        setResponceAdd('');
        setActionTypeAdd(0);
        if (chest.is_key && chest.is_pick_lock) {
          setMessage('Вы видите здесь сундук. Нужен ключ или отмычка.');
          setImage(num > 2 ? chestLockMet : chestLock);
          if (keys.length > 0 || picks.length > 0) {
            if (keys.length > 0) {
              setResponce('попробовать открыть ключом');
              setActionType(7);
            } else {
              setResponce('');
              setActionType(0);
            }
            if (picks.length > 0) {
              setResponceAdd('попробовать открыть отмычкой');
              setActionTypeAdd(8);
            } else {
              setResponceAdd('');
              setActionTypeAdd(0);
            }
          } else {
            setResponce('');
            setActionType(0);
            setResponceAdd('');
            setActionTypeAdd(0);
          }
        } else if (chest.is_key && !chest.is_pick_lock) {
          setMessage('Вы видите здесь сундук. Потребуется ключ.');
          setImage(num > 2 ? chestLockMet : chestLock);
          if (keys.length > 0) {
            setResponce('попробовать открыть ключом');
            setActionType(7);
          } else {
            setResponce('');
            setActionType(0);
          }
        } else {
          setMessage('Вы видите здесь сундук. Желаете открыть его?');
          setResponce('попробовать открыть');
          setActionType(2);
          setImage(num > 2 ? chestNoLockMet : chestNoLock);
        }
      } else if (activeRoom.game_model.is_open && !infoLoot && !infoCoins) {
        setMessage('Тут только пустой сундук...');
        setResponce(null);
        setResponceAdd(null);
        setInfoLoot(null);
        setImage(num > 2 ? chestOpenLockMet : chestOpenLock);
      }
      //
    } else if (
      activeRoom.game_model.dict_id.startsWith('floor') &&
      activeRoom.level === level
    ) {
      const floor = activeRoom.game_model.dict_id.split('.');
      if (floor.includes('up') && floor.includes('dn')) {
        // дверь этаж вверх
        setMessage('Похоже на переход на другой этаж...');
        setResponce('перейти на этаж выше');
        setResponceAdd('перейти на этаж ниже');
        setActionType(3.1);
        setActionTypeAdd(3.2);
        //
      } else if (floor.includes('up')) {
        setMessage('Похоже на переход на другой этаж...');
        setResponce('перейти');
        setActionType(3.2);
      } else if (floor.includes('dn')) {
        setMessage('Похоже на переход на другой этаж...');
        setResponce('перейти');
        setActionType(3.1);
      }
    } else if (
      activeRoom.game_model.dict_id.startsWith('enter') || // дверь выход
      activeRoom.game_model.dict_id.startsWith('exit')
    ) {
      setMessage('Выход из подземелья');
      setResponce('выйти');
      setActionType(4);
      //
    } else if (activeRoom.game_model.dict_id.startsWith('d.mob')) {
      // мобы
      setMessage('Тут кто-то есть... На вас напали!');
      setImage(
        botIDList.get(activeRoom?.game_model.dict_id)
          ? botIDList.get(activeRoom?.game_model.dict_id)
          : mobImg,
      );
      setResponce(null);
      setActionType(5);
      //
    } else if (
      !activeRoom?.game_model?.dict_id ||
      (activeRoom?.game_model?.dict_id &&
        !activeRoom?.game_model?.dict_id.startsWith('d.chst'))
    ) {
      setActionTypeAdd(0);
      setResponceAdd('');
    }
  }, [activeRoom, keys, picks]);

  function updateImage(newSrc) {
    const imageElement = document.querySelector('.cornerImg img');
    imageElement.classList.remove('fadeIn');
    imageElement.src = newSrc;
    void imageElement.offsetWidth;
    imageElement.classList.add('fadeIn');
  }

  useEffect(() => {
    updateImage(image);
  }, [image]);

  useEffect(() => {
    // открыть сундук
    if (chestOpen && chestOpen?.dungeon_room?.game_model?.is_open === false) {
      setMessage('Не удается открыть сундук!');
      dispatch(setChestOpen(null));
      if (picks.length === 1) {
        setActionTypeAdd(0);
        setResponceAdd('');
        setResponce('');
        setActionType(0);
      }
    }

    if (activeRoom?.loot?.length > 0) {
      // лут
      const loot = activeRoom.loot.map((item, index) => {
        const infoWeapons = item.dict_id.startsWith('pick')
          ? getInfoDictionary('chest_pick_lock', item.dict_id)
          : item.$type === 31
            ? getInfoDictionary('chest_keys', item.dict_id)
            : getInfoDictionary('equipments', item.dict_id);

        return (
          <p key={item.dict_id + index} className="loot--item">
            <Tooltip
              key={infoWeapons.id + 'infoWeapons' + index}
              content={
                infoWeapons.item_type <= 2 ? (
                  <InventoryHubThingsItemOther toItem={item} />
                ) : infoWeapons.item_type === 3 ? (
                  <InventoryHubThingsItemRun toItem={infoWeapons} />
                ) : infoWeapons.$type === 31 || infoWeapons.$type === 32 ? (
                  <InventoryHubThingsItemKey toItem={infoWeapons} />
                ) : (
                  <InventoryHubThingsItem user={item} slot={infoWeapons.slot} />
                )
              }
            >
              <Images name={infoWeapons.id} className={'loot--item--img'} />
            </Tooltip>
          </p>
        );
      });
      if (activeRoom !== null && activeRoom.loot.length > 0) {
        setInfoLoot(loot);
        setInfoCoins(activeRoom?.coins);
        setLootId(activeRoom.id);
        requestSend.sendData('RequestDungeonGetLoot', {
          coins: activeRoom?.coins ? activeRoom?.coins : 0,
          all: true,
        });
      }

      if (picks.length === 0) {
        setActionTypeAdd(0);
        setResponceAdd('');
      }
    } else if (
      activeRoom?.coins &&
      activeRoom?.coins > 0 &&
      activeRoom.loot.length === 0
    ) {
      // если есть нет лута, но есть коины
      requestSend.sendData('RequestDungeonGetLoot', {
        coins: activeRoom?.coins ? activeRoom?.coins : 0,
        all: true,
      });
      setInfoLoot(null);
      setInfoCoins(activeRoom?.coins);
      setLootId(activeRoom.id);
    }
  }, [chestOpen, activeRoom]);

  const actionEvent = (type) => {
    switch (type) {
      case 1:
        (() => {
          // ход по комнатам
          // requestSend.sendData('RequestDungeonMove', {
          //   move: direction
          // })
        })();
        break;
      case 2:
        (() => {
          // открыть сундук без ключа или отмычки
          requestSend
            .sendData('RequestDungeonChestOpen', {})
            .then((response) => {
              if (response && response[0].$type === 4) {
                setMessage('Не удалось открыть сундук...');
                setResponce(null);
              }
            });
        })();
        break;
      case 3.1:
        (() => {
          // открыть дверь и войти/выйти
          setLevel(activeRoom.level + 1);
          setMessage('Вы переходите на новый этаж...');
          setResponce('');
          setTimeout(() => {
            if (!activeRoom.game_model.is_open) {
              requestSend.sendData('RequestDungeonDoorOpen', {});
            }
            requestSend.sendData('RequestDungeonDoorUse', {
              move: 2,
            });
            setInfoLoot('');
            setInfoCoins('');
          }, 1000);
        })();
        break;
      case 3.2:
        (() => {
          // открыть дверь и войти/выйти
          setLevel(activeRoom.level - 1);
          setMessage('Вы переходите на новый этаж...');
          setResponce('');
          setTimeout(() => {
            if (!activeRoom.game_model.is_open) {
              requestSend.sendData('RequestDungeonDoorOpen', {});
            }
            requestSend.sendData('RequestDungeonDoorUse', {
              move: 1,
            });
            setInfoLoot('');
            setInfoCoins('');
          }, 1000);
        })();
        break;
      case 4:
        (() => {
          // выход из данжа
          if (!activeRoom.game_model.is_open) {
            requestSend.sendData('RequestDungeonDoorOpen', {});
          }
          requestSend.sendData('RequestDungeonDoorUse', {}).then((response) => {
            if (response && response[0].$type === 4) {
              setMessage('Так выйти нельзя');
            } else {
              const blds = dict.buildings;
              const bld = blds.filter(
                (el) => el.dungeon_id === user.location_id,
              );
              requestSend.sendData('RequestBuildingMove', {
                build_id: bld[0].id,
              });
              setActionType(0);
              dispatch(setDungeonOpen(false));
            }
          });
        })();
        break;
      case 5:
        (() => {
          // бой с мобами
        })();
        break;
      case 6:
        (() => {
          // лут взят
          setInfoLoot(null);
          setInfoCoins(null);
          setLootId(null);
          setActionType(0);
          setResponce(null);
        })();
        break;
      case 7:
        (() => {
          // открыть сундук ключем
          if (keys) {
            requestSend
              .sendData('RequestDungeonChestOpen', { key_id: keys[0].id })
              .then((response) => {
                if (response && response[0].$type === 4) {
                  setMessage('Не удалось открыть сундук...');
                  setResponce(null);
                }
              });
          }
        })();
        break;
      case 8:
        (() => {
          // открыть сундук отмычкой
          if (picks) {
            requestSend
              .sendData('RequestDungeonChestOpen', { key_id: picks[0].id })
              .then((response) => {
                if (response && response[0].$type === 4) {
                  setMessage('Не удалось открыть сундук...');
                  setResponceAdd(null);
                }
              });
          }
        })();
        break;
      default:
        break;
    }
  };

  const debouncedactionEvent = _.debounce(actionEvent, 800);

  useEffect(() => {
    let intervalId;
    if (activeRoom.game_model) {
      if (activeRoom.game_model.dict_id.startsWith('d.mob')) {
        setTime(10);
        intervalId = setInterval(() => {
          setTime((prevTime) => {
            if (prevTime > 1) {
              return prevTime - 1;
            } else {
              clearInterval(intervalId);
              setMessage('Тут никого не оказалось');
              setActionType(0);
              return 0;
            }
          });
        }, 1000);
      }

      return () => clearInterval(intervalId);
    }
  }, [activeRoom]);

  return (
    <>
      {image && activeRoom?.game_model?.$type !== 11 && (
        <div className={`cornerImg`}>
          <img src={image} alt="corner_img" style={{ height: '14vh' }} />
        </div>
      )}
      {mobImg && activeRoom?.game_model?.$type === 11 && (
        <div className={`cornerImg`} style={{ top: '0vw' }}>
          <img src={mobImg} alt="corner_img" style={{ height: '35vh' }} />
        </div>
      )}
      <div className="dungeon_modal_base">
        <p>{message}</p>
        {infoLoot && lootId === activeRoom.id && (
          <div className="loot">{infoLoot}</div>
        )}
        {infoCoins && lootId === activeRoom.id && (
          <div className="loot--money">
            <img src={imgDin} alt="" />
            {'  '}
            {infoCoins}
          </div>
        )}
        {(infoLoot || infoCoins) && lootId === activeRoom.id && (
          <span
            style={{ margin: '15px', color: '#bbb38a', cursor: 'pointer' }}
            onClick={() => debouncedactionEvent(actionType)}
          >
            <ButtonRed
              className={'loot-btn'}
              type={'buy'}
              onClick={() => debouncedactionEvent(actionType)}
            >
              {' '}
              {responce}{' '}
            </ButtonRed>
          </span>
        )}
        {responce && lootId !== activeRoom.id && (
          <span
            style={{ margin: '15px', color: '#bbb38a', cursor: 'pointer' }}
            onClick={() => debouncedactionEvent(actionType)}
          >
            {' '}
            - {responce}
          </span>
        )}
        {responceAdd && (
          <span
            style={{ margin: '15px', color: '#bbb38a', cursor: 'pointer' }}
            onClick={() => debouncedactionEvent(actionTypeAdd)}
          >
            {' '}
            - {responceAdd}
          </span>
        )}
        {actionType === 5 && (
          <div className="application__forModal--load">
            {spin}
            <div className="application__forModal--load--time">{time}</div>
          </div>
        )}
      </div>
    </>
  );
}

export default DungeonModal;
