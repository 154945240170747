import React, { useEffect, useRef, useState } from 'react';

import imgAll from '../../../media/img/shop/all.png';
import imgWeapons from '../../../media/img/shop/weapon.png';
import imgArmor from '../../../media/img/shop/armor.png';
import imgExpendable from '../../../media/img/shop/scroll.png';
import imgBooks from '../../../media/img/shop/book.png';
import imgAllActive from '../../../media/img/shop/l_all.png';
import imgWeaponsActive from '../../../media/img/shop/l_weapon.png';
import imgArmorActive from '../../../media/img/shop/l_armor.png';
import imgExpendableActive from '../../../media/img/shop/l_scroll.png';
import imgBooksActive from '../../../media/img/shop/l_book.png';
import InventoryHubThingsFilter from './InventoryHubThingsFilter';
import DragAndDrop from '../../dragAndDrop/DragAndDrop';
import { useRequest } from '../../../hooks/useRequest';

const tabs = [
  {
    id: 0,
    img: imgAll,
    img_active: imgAllActive,
  },
  {
    id: 1,
    img: imgWeapons,
    img_active: imgWeaponsActive,
  },
  {
    id: 2,
    img: imgArmor,
    img_active: imgArmorActive,
  },
  {
    id: 15,
    img: imgExpendable,
    img_active: imgExpendableActive,
  },
  {
    id: 14,
    img: imgBooks,
    img_active: imgBooksActive,
  },
];

const InventoryHubThings = () => {
  const [tab, setTab] = useState(0);
  const [requestSend] = useRequest();
  const filterRef = useRef(null);
  const [filterHeight, setFilterHeight] = useState(0);

  const toDropData = (slot) => {
    requestSend.sendData('RequestEquipmentOff', { slot });
  };

  useEffect(() => {
    setFilterHeight(filterRef.current?.getBoundingClientRect().height);
  }, [filterRef]);

  return (
    <DragAndDrop
      dropType="things"
      dropData={(e) => toDropData(e)}
      className="inventory__container__hub--body"
    >
      <div className="inventory__container__hub--body--things" ref={filterRef}>
        <div className="inventory__container__hub--body--things--filter">
          {tabs.map((i) => (
            <div
              key={i.id + 't'}
              className={`inventory__container__hub--body--things--filter--nav ${i.id === tab ? 'inventory__container__hub--body--things--filter--nav--active' : ''}`}
              onClick={() => setTab(i.id)}
            >
              <img src={i.id === tab ? i.img_active : i.img} alt="" />
            </div>
          ))}
        </div>

        <InventoryHubThingsFilter tab={tab} thingsHeight={filterHeight} />
      </div>
    </DragAndDrop>
  );
};

export default InventoryHubThings;
