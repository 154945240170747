import React, { useEffect, useState } from 'react';
import {
  setBaseModal,
  setBaseModalData,
} from '../../../store/reducer/Slices/SliceModal';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import { getRangeTime } from '../../../helper/helpers';
import { setApplicationsTime } from '../../../store/reducer/Slices/SlicesApplication';
import { loadersSpin1 } from '../../loaders/loaders';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import Lang from '../../language';

const spin = loadersSpin1;

const ApplicationForModalAccept = ({ data }) => {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();
  const applicationsTime = useSelector((state) => state.applicationsList.time);
  const [time, setTime] = useState(120);
  const [checkTime, setCheckTime] = useState(false);

  const offModal = () => {
    dispatch(setBaseModal(false));
    dispatch(setBaseModalData({}));
  };

  const toSendConfirm = () => {
    requestSend.sendData('RequestBattleRequestConfirmPvp');
  };

  const toSendCancel = () => {
    requestSend.sendData('RequestBattleRequestCancelPvp');
  };

  useEffect(() => {
    return () => {
      setCheckTime(false);
      dispatch(setApplicationsTime(null));
    };
  }, []);

  useEffect(() => {
    if (applicationsTime && !checkTime) {
      const { seconds } = getRangeTime(new Date(), applicationsTime);
      setTime(seconds);
      setCheckTime(true);
    }
  }, [applicationsTime]);

  useEffect(() => {
    const interval = setInterval(() => setTime(time - 1), 1000);

    if (time <= 1) {
      offModal();
    }

    return () => clearInterval(interval);
  }, [time]);

  return (
    <div className="application__forModal">
      <div>
        <Lang>YOUR_CHALLENGE_ACCEPTED</Lang> <b>{data.hero_name}</b> (
        <Lang>GLORY_LEVEL</Lang> {data.glory_level})<Lang>CONFIRM_THAT</Lang>
      </div>
      <div className="application__forModal--load">
        {spin}
        <div className="application__forModal--load--time">{time}</div>
      </div>
      <div className="modal__container--buttons">
        <UiButtonOne
          onClick={() => {
            offModal();
            toSendCancel();
          }}
        >
          <Lang>REFUSE</Lang>
        </UiButtonOne>
        <UiButtonOne
          onClick={() => {
            offModal();
            toSendConfirm();
          }}
        >
          <Lang>TO_BATTLE</Lang>
        </UiButtonOne>
      </div>
    </div>
  );
};

export default ApplicationForModalAccept;
