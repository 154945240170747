import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import sortBy from 'lodash/sortBy';

import ShopCard from './ShopCard';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../../src/hooks/useDictionary';

const ShopItemSell = ({ tab, thingsHeight }) => {
  const dropDownRef = useRef(null);
  const userInvent = useSelector((state) => state.allUserData.data.invent);
  const [equipments, setEquipments] = useState([]);
  // const [inventoryItems, setInventoryItems] = useState(10);
  const gridRef = useRef(null);
  // const itemRef = useRef(null);
  const [dict, getInfoDictionary] = useDictionary();

  const type = 2;

  useEffect(() => {
    const toSort = userInvent.invent_equipments.map((i) => {
      const infoWeapons = getInfoDictionary('equipments', i.dict_id);
      if (infoWeapons && i.$type !== 2 && i.$type !== 4 && i.$type !== 6) {
        if (infoWeapons.price) {
          return { ...i, price: infoWeapons.price[2] };
        } else if (infoWeapons.levels.length > 0) {
          let marId = infoWeapons.levels.find((item) => item.level === i.level);
          if (marId.price) {
            return { ...i, price: marId.price[2] };
          } else {
            return { ...i, price: 0 };
          }
        } else return { ...i, price: 0 };
      }
    });
    const result = sortBy(toSort, 'price').reverse();
    setEquipments(result.filter(Boolean));
  }, [userInvent]);

  // useLayoutEffect(() => {
  //   if (dropDownRef.current || itemRef.current) {
  //     const heightBlock = itemRef.current
  //       ? itemRef.current.offsetHeight + 12
  //       : dropDownRef.current
  //         ? dropDownRef.current.offsetHeight + 12
  //         : 82;
  //     let tabEquip = equipments
  //       .map((i) => {
  //         return getInfoDictionary('equipments', i.dict_id);
  //       })
  //       .filter((i) => {
  //         return i.equipment_type === tab || i.$type === tab || tab === 0;
  //       });
  // if (thingsHeight !== 0) {
  //   setInventoryItems(
  //     Math.floor(
  //       gridRef.current.getBoundingClientRect().width / heightBlock,
  //     ) *
  //       Math.floor(thingsHeight / heightBlock) -
  //       tabEquip.length,
  //   );
  // } else {
  //   setInventoryItems(
  //     Math.floor(
  //       gridRef.current.getBoundingClientRect().width / heightBlock,
  //     ) *
  //       Math.floor(415 / heightBlock) -
  //       tabEquip.length,
  //   );
  // }
  //   }
  // }, [tab, equipments, gridRef, itemRef, dropDownRef, thingsHeight]);

  return (
    <div className="shop-container" ref={gridRef}>
      {equipments.map((i, index) => {
        const infoWeapons = getInfoDictionary('equipments', i.dict_id);

        if (infoWeapons) {
          if (
            (infoWeapons.equipment_type
              ? infoWeapons.equipment_type === tab
              : infoWeapons.$type === tab) ||
            tab === 0
          ) {
            return (
              <div
                key={infoWeapons.id + 'infoWeapons' + index}
                className="tooltip shop"
              >
                <div
                  ref={dropDownRef}
                  className={`shop__container__hub--body--things--grid--item rr-${infoWeapons.quality} shop__item--max`}
                >
                  <ShopCard props={infoWeapons} type={type} item={i} index />
                </div>
              </div>
            );
          } else return null;
        } else return null;
      })}
    </div>
  );
};

export default ShopItemSell;
