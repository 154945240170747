import HeroNameLink from '../HeroNameLink/HeroNameLink';
import imgMini1 from '../../../media/img/game/character/miniInfo/mini1.png';
import imgShare from '../../../media/img/game/playingField/chat/share.png';
import { checkTimeFormat } from '../../../helper/helpers';
import { useTranslate } from '../../language/action/line';
import { useEffect } from 'react';

const ChatMessageBattleDeath = ({ message, userHero }) => {
  const isUser = userHero === message.data.dest_hero_id;
  const [langTranslate] = useTranslate(message.text);

  return (
    <div
      key={`${message.data.battle_id}-${message.data.name}`}
      className="chat__block1--body--message--item"
    >
      <div className="chat__block1--body--message--item--time">
        {message.time}
      </div>
      {/*<div className="chat__block1--body--message--item--battle">*/}
      {/*  <img src={imgMini1} alt="" />*/}
      {/*</div>*/}
      <div className="chat__block1--body--message--item--text">
        <HeroNameLink userHero={message.data.hero_id} isUser={isUser}>
          {message.name}
        </HeroNameLink>{' '}
        {langTranslate}.
      </div>
    </div>
  );
};

export default ChatMessageBattleDeath;
