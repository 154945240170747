import HeroNameLink from '../HeroNameLink/HeroNameLink';
import imgMini1 from '../../../media/img/game/character/miniInfo/mini1.png';
// import imgShare from '../../../media/img/game/playingField/chat/share.png';
// import { checkTimeFormat } from '../../../helper/helpers';
import { useTranslate } from '../../language/action/line';

const ChatMessageBattleStart = ({ message, userHero }) => {
  const isUser = userHero === message.data.dest_hero_id;
  const [langTranslate] = useTranslate(message.text);

  return (
    <div key={message.time} className="chat__block1--body--message--item">
      <div className="chat__block1--body--message--item--time">
        {message.time}
      </div>
      <div className="chat__block1--body--message--item--text">
        <HeroNameLink
          userHero={message.data.party1[0].hero_id}
          isUser={!isUser}
        >
          {message.allies}
        </HeroNameLink>
        {langTranslate}
        <HeroNameLink userHero={message.data.party2[0].hero_id} isUser={isUser}>
          {message.opponents}
        </HeroNameLink>
        {/* <a href="" className="chat__block1--body--message--item--text__share">
          <img src={imgShare} alt="" />
        </a> */}
      </div>
    </div>
  );
};

export default ChatMessageBattleStart;
