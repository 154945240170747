import React, { useState, useEffect, useCallback } from 'react';

const useListenerKey = (key) => {
  // подумать об оптимизации
  const [downKey, setDownKey] = useState(false);

  const onKeypress = (event) => {
    if (key === event.key) {
      setDownKey(!downKey);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeypress);
    return () => {
      window.removeEventListener('keydown', onKeypress);
    };
  }, [onKeypress]);

  return [downKey];
};

export default useListenerKey;
