import { useSelector } from 'react-redux';

const skills = [
  {
    skill_type: 1,
    skill_category: 2,
    id: 1,
    level: 1,
    unbattled: false,
  },
  {
    skill_type: 1,
    skill_category: 2,
    id: 2,
    level: 2,
    unbattled: false,
  },
  {
    skill_type: 1,
    skill_category: 2,
    id: 3,
    level: 3,
    unbattled: false,
  },
  {
    skill_type: 2,
    skill_category: 1,
    id: 4,
    level: 4,
    needRunes: [1, 3],
    description: 'Снижает восприимчивость к лечению на -2.',
    stamina: 5,
    curLvl: 150,
    nextLvl: 500,
    canUnbattled: false,
  },
  {
    skill_type: 2,
    skill_category: 1,
    id: 5,
    level: 1,
    needRunes: [1],
    description: 'Снижает восприимчивость к лечению на -4.',
    stamina: 2,
    curLvl: 50,
    nextLvl: 500,
    canUnbattled: false,
  },
  {
    skill_type: 2,
    skill_category: 2,
    id: 6,
    level: 3,
    needRunes: [2],
    description: 'Снижает восприимчивость к лечению на -1.',
    stamina: 3,
    curLvl: 250,
    nextLvl: 500,
    canUnbattled: false,
  },
];

export const useDictionary = () => {
  const dict = useSelector((state) => state.dictionary.data);

  const getInfoDictionary = (direct, id) => {
    return { ...dict, skills: skills }[direct]?.find((el) => el.id === id);
  };

  const getInfoDictionaryName = (direct, name, id) => {
    return { ...dict, skills: skills }[direct]?.find((el) => el[name] === id);
  };

  const getInfoDictionaryAllName = (direct, name, id) => {
    return { ...dict, skills: skills }[direct]?.filter((el) => el[name] === id);
  };

  return [
    dict,
    getInfoDictionary,
    getInfoDictionaryName,
    getInfoDictionaryAllName,
  ];
};
