import { createSlice } from '@reduxjs/toolkit';

const NpcData = createSlice({
  name: 'NpcData',
  initialState: {
    open: false,
    dialogue: false,
    npc_dialogues: {},
  },
  reducers: {
    setNpcOpen(state, action) {
      state.open = action.payload;
    },
    setDialogue(state, action) {
      state.dialogue = action.payload;
    },
    setNpcDialogues(state, action) {
      state.npc_dialogues = action.payload;
    },
  },
});

export const { setNpcOpen, setDialogue, setNpcDialogues } = NpcData.actions;

export { NpcData };
