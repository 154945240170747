import React from 'react';
import { routConnectionInit } from '../api/routeConnection';

const Images = ({ name, className }) => {
  const connectionInit = routConnectionInit(process.env.REACT_APP_RUN);
  return (
    <img
      className={className && className}
      src={`${connectionInit}/images/weapons/${name}.png`}
      // src={ImagesAll.get(name) ? ImagesAll.get(name) : "https://upload.wikimedia.org/wikipedia/ru/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"}
      draggable="false"
      alt=""
    />
  );
};

export default Images;
