export const cookieSet = async (name, value, hours) => {
  const date = await new Date();
  date.setHours(date.getHours() + hours);
  document.cookie = await (name +
    '=' +
    (value.toString() || '') +
    ';' +
    ' expires=' +
    date +
    ' path=/');
};

export const cookieGet = (name) => {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const cookieDel = (name) => {
  return cookieSet(name, '', -10000);
};
