import React, { useEffect, useState } from 'react';
import { checkPercent } from '../../helper/helpers';

const ActiveBar = ({ one, two, classOne, classTwo, check, showNum }) => {
  const [activeBar, setActiveBar] = useState(0);
  const [anim, setAnim] = useState(false);

  const toActiveBar = () => {
    setActiveBar(one > 0 ? checkPercent(one, two) : 0);
  };

  useEffect(() => {
    // if (one && two) {
    toActiveBar();
    setAnim(true);
    setTimeout(() => {
      setAnim(false);
    }, 1000);
    // }
  }, [one, two]);

  return (
    <div className={classOne}>
      <div
        className={`${classTwo} ${anim ? 'anim' : ''} ${activeBar >= 30 ? check?.plus : check?.minus}`}
        style={{ width: `${activeBar}%` }}
      />
      {showNum && (
        <div className={classOne + showNum}>
          {one} / {two}
        </div>
      )}
    </div>
  );
};

export default ActiveBar;
