import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../styles.scss';
import img1 from '../../../media/img/character/marker/Внешние-буквы1.png';
import img2 from '../../../media/img/character/marker/Внешний-круг.png';
import img3 from '../../../media/img/character/marker/Средний-круг.png';
import img4 from '../../../media/img/character/marker/Символы-в-середине.png';
import img5 from '../../../media/img/character/marker/Внутренний-круг.png';
import img6 from '../../../media/img/character/marker/inner_circle.png';
import imgPento from '../../../media/img/character/marker/pento.png';
import imgPentoCircl from '../../../media/img/character/marker/pentoCircl.png';

import Lang from '../../language';
import { useDictionary } from '../../../hooks/useDictionary';
import {
  infoZodiac,
  selectCountName,
  timeInfoDM,
} from '../../../helper/helpers';
import { Naming } from '../../../helper/naming';
import img1A from '../../../media/img/createCharacter/light_activeglow.png';
import img2A from '../../../media/img/createCharacter/dark_active_glow.png';
import img3A from '../../../media/img/createCharacter/chaos_active_glow.png';

import imgZ1 from '../../../media/img/character/znak/zod.sign.1.png';
import imgZ2 from '../../../media/img/character/znak/zod.sign.2.png';
import imgZ3 from '../../../media/img/character/znak/zod.sign.3.png';
import imgZ4 from '../../../media/img/character/znak/zod.sign.4.png';
import imgZ5 from '../../../media/img/character/znak/zod.sign.5.png';
import imgZ6 from '../../../media/img/character/znak/zod.sign.6.png';
import imgZ7 from '../../../media/img/character/znak/zod.sign.7.png';
import imgZ8 from '../../../media/img/character/znak/zod.sign.8.png';
import imgZ9 from '../../../media/img/character/znak/zod.sign.9.png';

import imgS1 from '../../../media/img/character/stars/zod.star.1.png';
import imgS2 from '../../../media/img/character/stars/zod.star.2.png';
import imgS3 from '../../../media/img/character/stars/zod.star.3.png';
import imgS4 from '../../../media/img/character/stars/zod.star.4.png';
import imgS5 from '../../../media/img/character/stars/zod.star.5.png';
import imgS6 from '../../../media/img/character/stars/zod.star.6.png';
import imgS7 from '../../../media/img/character/stars/zod.star.7.png';

import imgH1 from '../../../media/img/character/house/zod.house.1.png';
import imgH2 from '../../../media/img/character/house/zod.house.2.png';
import imgH3 from '../../../media/img/character/house/zod.house.3.png';
import imgH4 from '../../../media/img/character/house/zod.house.4.png';
import imgH5 from '../../../media/img/character/house/zod.house.5.png';
import imgH6 from '../../../media/img/character/house/zod.house.6.png';
import imgH7 from '../../../media/img/character/house/zod.house.7.png';

const listZnak = new Map([
  ['zod.sign.1', imgZ1],
  ['zod.sign.2', imgZ2],
  ['zod.sign.3', imgZ3],
  ['zod.sign.4', imgZ4],
  ['zod.sign.5', imgZ5],
  ['zod.sign.6', imgZ6],
  ['zod.sign.7', imgZ7],
  ['zod.sign.8', imgZ8],
  ['zod.sign.9', imgZ9],
]);

const listStars = new Map([
  ['zod.star.1', imgS1],
  ['zod.star.2', imgS2],
  ['zod.star.3', imgS3],
  ['zod.star.4', imgS4],
  ['zod.star.5', imgS5],
  ['zod.star.6', imgS6],
  ['zod.star.7', imgS7],
]);

const listHouse = new Map([
  ['zod.house.1', imgH1],
  ['zod.house.2', imgH2],
  ['zod.house.3', imgH3],
  ['zod.house.4', imgH4],
  ['zod.house.5', imgH5],
  ['zod.house.6', imgH6],
  ['zod.house.7', imgH7],
]);

const cultList = [
  {
    id: 1,
    name: 'LIGHT',
    img: img1,
    imgActive: img1A,
  },
  {
    id: 2,
    name: 'DARK',
    img: img2,
    imgActive: img2A,
  },
  {
    id: 3,
    name: 'CHAOS',
    img: img3,
    imgActive: img3A,
  },
];

const CharacterInfoZodiac = () => {
  const userFull = useSelector((state) => state.heroData.data);

  const [dict, getInfoDictionary] = useDictionary();
  const zodiac_star = getInfoDictionary(
    'zodiac_stars',
    userFull?.h_info?.zodiac_star_key,
  );
  const zodiac_sign = getInfoDictionary(
    'zodiac_signs',
    userFull?.h_info?.zodiac_sign_key,
  );
  const zodiac_house = getInfoDictionary(
    'zodiac_houses',
    userFull?.h_info?.zodiac_house_key,
  );

  useEffect(() => {
    console.log('userFull', userFull);
    console.log('dict', dict);
    console.log('zodiac_star', zodiac_star);
    console.log('zodiac_sign', zodiac_sign);
    console.log('zodiac_house', zodiac_house);
  }, [userFull]);

  return (
    <div className="character-info-zodiak">
      <div className="character-info-zodiak--one">
        <div className="character-info-zodiak--one--circles">
          <img src={img1} alt="" />
          <img src={img2} alt="" />
          <img src={img3} alt="" />
          <img src={img4} alt="" />
          <img src={img5} alt="" />
          <img src={img6} alt="" />
        </div>
        <div className="character-info-zodiak--one--pento">
          <img src={imgPento} alt="" />
        </div>
        <div className="character-info-zodiak--one--pento--circl">
          <img src={imgPentoCircl} alt="" />
        </div>
        <div className="character-info-zodiak--one--zodiak">
          <img src={listZnak.get(zodiac_sign?.id)} alt="" />
        </div>
        <div className="character-info-zodiak--one--stars">
          <img src={listStars.get(zodiac_star?.id)} alt="" />
        </div>
        <div className="character-info-zodiak--one--house">
          <img src={listHouse.get(zodiac_house?.id)} alt="" />
        </div>
      </div>
      <div className="character-info-zodiak--two">
        <div className="character-info-zodiak--two--left"></div>
        <div className="character-info-zodiak--two--right">
          <div className="character-info-zodiak--two--right--one">
            <Lang>DATE_OF_BIRTH</Lang>
          </div>
          <div className="character-info-zodiak--two--right--two">
            <Lang>
              {timeInfoDM(userFull?.h_info?.zodiac_time, 'ZODIAC_DAY')?.day}
            </Lang>
            ,{' '}
            <Lang>
              {timeInfoDM(userFull?.h_info?.zodiac_time, 'ZODIAC_MONTH')?.month}
            </Lang>
          </div>
          <div className="character-info-zodiak--two--right--three">
            {userFull?.level?.rebirth_count > 0 ? (
              <>
                {selectCountName(userFull?.level?.rebirth_count)}{' '}
                <Lang>REINCARNATION</Lang>
              </>
            ) : (
              <Lang>NO_REINCARNATIONS</Lang>
            )}
          </div>
        </div>
      </div>
      <div className="character-info-zodiak--two">
        <div className="character-info-zodiak--two--left">
          <img src={listZnak.get(zodiac_sign?.id)} alt="" />
        </div>
        <div className="character-info-zodiak--two--right">
          <div className="character-info-zodiak--two--right--one">
            <Lang>ZODIAC_SIGN</Lang> – <Lang>{zodiac_sign?.id}</Lang>
          </div>
          <div className="character-info-zodiak--two--right--two">
            <Lang>{infoZodiac(zodiac_sign?.action_type)}</Lang>{' '}
            {!!zodiac_sign?.add_effect && (
              <Lang>
                {getInfoDictionary('effects', zodiac_sign?.add_effect).id}
              </Lang>
            )}{' '}
            {!!zodiac_sign?.remove_negative_eEffects &&
              zodiac_sign?.remove_negative_eEffects.map((e, index) => (
                <>
                  <Lang>{getInfoDictionary('effects', e).id}</Lang>
                  {index === zodiac_sign?.remove_negative_eEffects.length - 1
                    ? ''
                    : ', '}
                </>
              ))}
          </div>
          <div className="character-info-zodiak--two--right--two">
            {zodiac_sign?.weapon_class > 0 && (
              <>
                <Lang>PENCHANT_FOR_WEAPONS</Lang>: {zodiac_sign?.weapon_class}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="character-info-zodiak--two">
        <div className="character-info-zodiak--two--left">
          <img src={listStars.get(zodiac_star?.id)} alt="" />
        </div>
        <div className="character-info-zodiak--two--right">
          <div className="character-info-zodiak--two--right--one">
            <Lang>STAR</Lang> – <Lang>{zodiac_star?.id}</Lang>
          </div>
          <div className="character-info-zodiak--two--right--two">
            <Lang>GIVES</Lang>:{' '}
            {zodiac_star?.character_bonuses.map((e) => (
              <Lang>{Naming.character[e.char]}</Lang>
            ))}{' '}
            {zodiac_star?.character_bonuses.length > 1 ? ', ' : ''}{' '}
            {!!zodiac_star?.add_effect && (
              <Lang>
                {getInfoDictionary('effects', zodiac_star?.add_effect).id}
              </Lang>
            )}{' '}
            {!!zodiac_star?.remove_negative_eEffects &&
              zodiac_star?.remove_negative_eEffects}
          </div>
          <div className="character-info-zodiak--two--right--two">
            {zodiac_star?.weapon_class > 0 && (
              <>
                <Lang>PENCHANT_FOR_WEAPONS</Lang>:{' '}
                <Lang>{Naming.WeaponClassType[zodiac_star?.weapon_class]}</Lang>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="character-info-zodiak--two">
        <div className="character-info-zodiak--two--left">
          <img
            src={
              cultList.find((e) => e.id === userFull?.h_info?.pantheon)
                ?.imgActive
            }
            alt=""
          />
        </div>
        <div className="character-info-zodiak--two--right">
          <div className="character-info-zodiak--two--right--one">
            <Lang>
              {cultList.find((e) => e.id === userFull?.h_info?.pantheon)?.name}
            </Lang>
          </div>
          <div className="character-info-zodiak--two--right--two">
            <Lang>{'SOME_INFO_PANTHEON_' + userFull?.h_info?.pantheon}</Lang>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharacterInfoZodiac;
