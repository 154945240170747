import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../../hooks/useDictionary';
import Tooltip from '../../tooltip/Tooltip';
import Images from '../../../helper/Images';
import InventoryHubSkillsItem from '../../Inventory/InventoryHubSkills/InventoryHubSkillsItem';
import { toRomanNumber } from '../../../helper/helpers';
import Lang from '../../language';
import Modal from '../../modal/Modal';
import BattleInitStartMiniTwoModal from './BattleInitStartMiniTwoModal';
import ButtonRed from '../../ui/buttons/UiButtonOne';

const BattleInitStartMiniTwo = ({
  data,
  title,
  toHover,
  top,
  visibility,
  zIndex,
  toSetSlot,
  nowNum,
  toNum,
  play,
  setModCL,
  setModOP,
  setMini,
}) => {
  const [dict, getInfoDictionary] = useDictionary();
  const [equipments, setEquipments] = useState([]);
  const userBody = useSelector((state) => state.allUserData.data.body);
  const userChar = useSelector((state) => state.allUserData.data.char);
  const mod = useSelector((state) => state.battle.modalState);
  const battle_type = useSelector(
    (state) => state.battle.data.battle.battle_type,
  );
  const [miniSlot, setMiniSlot] = useState(0);
  const [miniSlotArr, setMiniSlotArr] = useState(setMini);
  const [nowData, setNowData] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [stamina, setStamina] = useState(0);
  const [noStam, setNoStam] = useState(false);
  const operSlots = userBody.oper_slots.filter((i) => i.model);

  const toSetSlotArr = (i, name) => {
    if (name) {
      toSetSlot({ data: i, type: 'abil', dest_hero_name: name });
    } else {
      toSetSlot({ data: i, type: 'abil' });
    }
  };
  const toSetSlotOne = (i) => {
    toSetSlot({ data: i, type: 'spell' });
  };

  const toSlotSetArr = (slot, name) => {
    let tox;
    let tor = miniSlotArr;
    if (tor.includes(slot)) {
      tox = tor.filter((i) => i !== slot);
    } else {
      tox = [...tor, slot];
    }
    if (name) {
      setMiniSlot(0);
      setMiniSlotArr(tox);
      toSetSlotArr(tox, name);
    } else {
      if (nowNum >= toNum) {
        setMiniSlot(0);
        setMiniSlotArr(tox);
        toSetSlotArr(tox);
      }
    }
  };

  const toSlotSet = (slot) => {
    let tox = miniSlot === slot ? 0 : slot;
    if (nowNum >= toNum) {
      setMiniSlotArr([]);
      setMiniSlot(tox);
      toSetSlotOne(tox);
    }
  };

  const handleAbilChoice = (slot) => {
    const abil = userBody.active_slots.find((el) => el.slot === slot);
    const abilNow = getInfoDictionary('equipments', abil.model.dict_id);

    if (!miniSlotArr.includes(slot) && stamina === 0) {
      setNoStam(true);
      return;
    }

    if (
      battle_type !== 2 &&
      battle_type !== 3 &&
      (abilNow.destination === 3 || abilNow.destination === 4)
    ) {
      console.log('group battle only');
      return;
    }

    if (
      (battle_type === 2 || battle_type === 3) &&
      (abilNow.destination === 3 || abilNow.destination === 4)
    ) {
      setModOP();
      setNowData({
        component: (
          <BattleInitStartMiniTwoModal
            slot={slot}
            info={abilNow}
            closeModal={() => setOpenModal(false)}
            toSlotSetArr={toSlotSetArr}
            setMod={setModCL}
            mod={mod}
            // setStamina={() => setStamina()}
          />
        ),
        props: {
          title: 'PARSING_THE_ITEM',
          desc: 'PARSING_THE_ITEM_INFO',
        },
      });
      setOpenModal(true);
      return;
    }

    if (miniSlotArr.includes(slot)) {
      setStamina((prevStamina) => prevStamina + 1);
    } else {
      setStamina((prevStamina) => prevStamina - 1);
    }

    toSlotSetArr(slot);
  };

  useEffect(() => {
    setMiniSlotArr(setMini);
    setMiniSlot(0);
  }, [setMini]);

  useEffect(() => {
    if (!play) {
      setMiniSlot(0);
      setMiniSlotArr([]);
      toSetSlotArr(0);
    }
  }, [play]);

  useEffect(() => {
    const toF = userBody.active_slots.filter((i) => i.model);
    setEquipments(toF);
  }, []);

  useEffect(() => {
    if (!mod) {
      setOpenModal(false);
    }
  }, [mod]);

  useEffect(() => {
    const initialStamina =
      userChar.characters.find((item) => item.char === 26)?.points || 0;
    setStamina(initialStamina);
  }, [userChar]);

  return (
    <div
      key={title}
      className={`battle__init--start--mini ${top ? 'battle__init--start--mini--top' : ''}`}
      style={{
        visibility: visibility ? 'visible' : 'hidden',
        zIndex: zIndex,
      }}
    >
      <div className="battle__init--start--mini--title">
        <Lang>{title}</Lang>
      </div>
      {noStam && (
        <div className="modal_stamina">
          <div className="modal_stamina--container">
            <div className="modal_stamina--container--border">
              <div className="modal_stamina--container--msg">
                <Lang>NO_STAMINA</Lang>
              </div>
              <ButtonRed onClick={() => setNoStam(false)}>
                <Lang>FINE</Lang>
              </ButtonRed>
            </div>
          </div>
        </div>
      )}
      {nowData && mod && (
        <Modal show={openModal} styles="mini" data={nowData.component || ''} />
      )}
      <div
        className={`battle__init--start--mini--list ${top ? 'battle__init--start--mini--list--top' : ''}`}
      >
        {equipments.map((i, index) => {
          const infoSkills = getInfoDictionary('equipments', i.model.dict_id);
          const run_req = infoSkills?.required_runes
            ? infoSkills?.required_runes.every((req) =>
                operSlots.some((slot) => slot.model.dict_id === req),
              ) && nowNum < infoSkills.stamina_cost
            : null;

          return (
            <Tooltip
              maxWidth="450px"
              key={'miniTwoAS' + i.model.dict_id}
              open={true}
              content={
                <InventoryHubSkillsItem
                  fromItem={i.model}
                  toItem={infoSkills}
                />
              }
            >
              {infoSkills.$type === 10 ? (
                <div
                  className={`battle__init--start--mini--list--item 
                                                ${miniSlotArr.includes(i.slot) ? 'battle__init--start--mini--list--item--active' : ''}
                                                ${nowNum < infoSkills.stamina_cost ? 'noEsta' : ''}
                                            `}
                  onMouseEnter={() => {
                    toHover && toHover(infoSkills.stamina_cost);
                  }}
                  onMouseLeave={() => {
                    toHover && toHover(0);
                  }}
                  onClick={() => {
                    handleAbilChoice(i.slot);
                  }}
                >
                  <span className="inventory__container__hub--body--skills--abilities--hub--grid--item__level">
                    {toRomanNumber(i.model.level)}
                  </span>
                  {/*<div className="battle__init--start--mini--list--item--anim"/>*/}
                  <Images name={i.model.dict_id} />
                </div>
              ) : (
                <div
                  key={'miniTwoA' + i.model.dict_id}
                  className={`battle__init--start--mini--list--item 
                                                ${miniSlot === i.slot ? 'battle__init--start--mini--list--item--active' : ''}
                                                ${
                                                  run_req &&
                                                  nowNum <
                                                    infoSkills.stamina_cost
                                                    ? 'noEsta'
                                                    : ''
                                                }
                                            `}
                  onMouseEnter={() => {
                    toHover && toHover(infoSkills.stamina_cost);
                  }}
                  onMouseLeave={() => {
                    toHover && toHover(0);
                  }}
                  onClick={() => {
                    toSlotSet(i.slot);
                  }}
                >
                  <span className="inventory__container__hub--body--skills--abilities--hub--grid--item__level">
                    {toRomanNumber(i.model.level)}
                  </span>
                  {/*<div className="battle__init--start--mini--list--item--anim"/>*/}
                  <Images name={i.model.dict_id} />
                </div>
              )}
            </Tooltip>
          );
        })}
        {Array.from(
          Array(userBody.oper_slots.length - equipments.length).keys(),
        ).map(() => (
          <div className="battle__init--start--mini--list--item">
            {/*<div className="battle__init--start--mini--list--item--anim2"/>*/}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BattleInitStartMiniTwo;
