import React from 'react';
import './styles.scss';

const UiButtonOne = ({ onClick, children, disabled, className, type }) => {
  return (
    <div
      className={`${className} ui-button-one ${type}`}
      onClick={() => {
        !disabled && onClick();
      }}
      disabled={disabled}
    >
      {children}
    </div>
  );
};

export default UiButtonOne;
