import HeroNameLink from '../../HeroNameLink/HeroNameLink';
import Lang from '../../../language';

export default function Templ1({
  isUser,
  message,
  msgDamageSize,
  msgWeapon,
  msgAttackZone,
  dmg,
}) {
  const isGenderMan = message.a_her.isGenderMan;
  return (
    <div className="toChat">
      <HeroNameLink userHero={message?.a_her?.hero_id} isUser={isUser}>
        {message?.a_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_DID</Lang>{' '}
      {isGenderMan ? <Lang>CHAT_IS</Lang> : <Lang>CHAT_ER</Lang>}{' '}
      <Lang>CHAT_LUNGE_BUT</Lang>{' '}
      <HeroNameLink userHero={message?.d_her?.hero_id} isUser={!isUser}>
        {message?.d_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_SKILLFULLY_PARRIED</Lang>
      {isGenderMan ? <Lang>CHAT_IS</Lang> : <Lang>CHAT_ER</Lang>}{' '}
      <Lang>CHAT_ATTACK</Lang>.
    </div>
  );
}
