import React, { useState } from 'react';
import './styles.scss';

import imgClose from '../../media/img/inventory/close.png';
import imgG from '../../media/img/inventory/gold.png';
import imgE from '../../media/img/inventory/etherium.png';
import imgP from '../../media/img/inventory/+.png';
import imgR from '../../media/img/inventory/→.png';
import InventoryHub from './InventoryHub';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenInventory } from '../../store/reducer/Slices/SlicesInventory';
import Modal from '../modal/Modal';
import InventorySendMoney from './InventorySendMoney';
import Tooltip from '../tooltip/Tooltip';
import CharacterInfoTooltip from '../character/Info/CharacterInfoTooltip';

const miniModal = {
  sendMoney: <InventorySendMoney />,
};

const Inventory = () => {
  const dispatch = useDispatch();
  const inventoryOpen = useSelector((state) => state.inventoryOpen.auth);
  const userMoney = useSelector((state) => state.allUserData.data.money);
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState('sendMoney');

  const openInventory = () => {
    dispatch(setOpenInventory(false));
  };

  const toOpenModal = (name) => {
    setDataModal(name);
    setOpenModal(true);
  };

  // const handleAnswerChange = event => {
  //     console.log('event', event)  onKeyDown={handleAnswerChange} tabIndex={-1}
  // }
  if (inventoryOpen) {
    return (
      <div className="preInventory">
        <div className="inventory">
          <div className="inventory__container">
            <Modal
              show={openModal}
              props={{
                title: 'TRANSFER_OF_RESOURCES',
                buttonGray: {
                  text: 'CANCEL',
                },
                buttonRed: {
                  text: 'HAND_OVER',
                },
              }}
              buttonGray={() => setOpenModal(false)}
              buttonRed={() => setOpenModal(false)}
              data={miniModal[dataModal]}
              styles="mini"
            />
            <div
              className="inventory__container--close"
              onClick={() => openInventory()}
            >
              <img src={imgClose} alt="закрыть" />
            </div>
            <div className="inventory__container--header">
              <Tooltip
                key="DINAR"
                zIndex={9990}
                maxWidth="450px"
                content={<CharacterInfoTooltip choice="115" />}
              >
                <div className="inventory__container--header--item">
                  <img src={imgG} alt="" />
                  <div className="inventory__container--header--item--num">
                    {userMoney['current_money'][1]}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                key="ETH"
                zIndex={9990}
                maxWidth="450px"
                content={<CharacterInfoTooltip choice="116" />}
              >
                <div className="inventory__container--header--item">
                  <img src={imgE} alt="" />
                  <div className="inventory__container--header--item--num">
                    {parseFloat(userMoney['current_money'][2]).toFixed(2) %
                      1 ===
                    0
                      ? parseFloat(userMoney['current_money'][2]).toFixed(0)
                      : parseFloat(userMoney['current_money'][2]).toFixed(2)}
                  </div>
                </div>{' '}
              </Tooltip>
              <div className="inventory__container--header--item">
                <div className="inventory__container--header--item--other">
                  <img src={imgP} alt="" />
                </div>
                <div
                  className="inventory__container--header--item--other"
                  onClick={() => toOpenModal('sendMoney')}
                >
                  <img src={imgR} alt="" />
                </div>
              </div>
            </div>
            <InventoryHub />
          </div>
        </div>
      </div>
    );
  }
};

export default Inventory;
