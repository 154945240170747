import React, { useContext, useLayoutEffect, useRef, useState } from 'react';

import { useRequest } from '../../../hooks/useRequest';
import DragAndDrop from '../../dragAndDrop/DragAndDrop';
import imgEquipExpended from '../../../media/img/inventory/skills/equipExpended.png';
import imgEquipRunes from '../../../media/img/inventory/skills/equipRunes.png';
import { useSelector } from 'react-redux';
import { UserContext } from '../../../page/Game/Game';
import MenuTooltip from '../../menuTooltip/MenuTooltip';
import Modal from '../../modal/Modal';
import InventoryItemCell from '../InventoryItemCell/InventoryItemCell';
import { useDictionary } from '../../../hooks/useDictionary';
import InventoryMiniSlots from '../InventoryMiniSlots';
import Tooltip from '../../tooltip/Tooltip';
import InventoryHubThingsItemRun from '../InventoryHubThings/InventoryHubThingsItemRun';
import Images from '../../../helper/Images';
import InventoryHubThingsItemOther from '../InventoryHubThings/InventoryHubThingsItemOther';
import Lang from '../../language';
import InventoryUseSkill from '../InventoryUseSkill';

const tabsEquipment = [
  {
    id: 12,
    img: imgEquipExpended,
  },
  {
    id: 13,
    img: imgEquipRunes,
  },
];

const InventoryHubSkillsEquipments = ({ equips, isBattle }) => {
  const dropDownRef = useRef([]);
  const [tabEquipments, setTabEquipments] = useState(12);
  const [openModal, setOpenModal] = useState(false);
  const [nowData, setNowData] = useState(undefined);
  const [requestSend] = useRequest();
  const userContext = useContext(UserContext);
  const [tooltip, setTooltip] = useState(null);
  const [emptyEquipmentItems, setEmptyEquipmentItems] = useState(0);
  const equipRef = useRef(null);
  const [dict, getInfoDictionary] = useDictionary();
  const userInvent = useSelector(
    (state) => state.allUserData.data.invent.invent_equipments,
  );
  const userBody = useSelector((state) => state.allUserData.data.body);

  const toDropData = (elem) => {
    requestSend.sendData('RequestOperationalSlotOff', { slot: elem.slot });
  };

  const toActiveData = (slot) => {
    const slots = userBody.oper_slots.find((i) => !i.model);
    if (slots) {
      requestSend.sendData('RequestOperationalSlotOn', {
        slot: slots.slot,
        id: slot.id,
      });
    } else {
      requestSend.sendData('RequestOperationalSlotOn', {
        slot: slots.slot,
        id: 1,
      });
    }
  };

  useLayoutEffect(() => {
    const tabEquip = userInvent
      .map((i) => {
        return getInfoDictionary('equipments', i.dict_id);
      })
      .filter((i) => i?.$type === tabEquipments);
    setEmptyEquipmentItems(
      Math.floor((equipRef.current?.getBoundingClientRect().width - 14) / 49) *
        2,
      // -
      // tabEquip.length +
      // 20,
    );
  }, [equipRef, userInvent, tabEquipments]);

  const miniModal = (now, data) => {
    const dataModal = data;
    const image =
      'https://upload.wikimedia.org/wikipedia/ru/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png';

    switch (now) {
      case 'use':
        setNowData({
          component: (
            <InventoryUseSkill
              info={dataModal}
              user={dataModal.user}
              img={dataModal.info && image} // Мок убрать когда будут сделаны изображения и когда будут добавлены в словарь
              closeModal={() => setOpenModal(false)}
            />
          ),
          props: {
            title: 'PARSING_THE_ITEM',
            desc: 'PARSING_THE_ITEM_INFO',
          },
        });
        break;
      default:
        break;
    }
  };

  const handleClickContextMenu = (e, id, index) => {
    if (tooltip === id + index) {
      setTooltip(null);
    } else setTooltip(id + index);
  };

  const toOnClick = (name, data, initData) => {
    console.log('data', data);
    console.log('initData', initData);
    // if (name !== 'RequestEquipmentOn') {
    miniModal('use', data);
    setOpenModal(true);
    // } else {
    //     toActiveData(data);
    // }
  };

  const handleMouseEnter = (info) => {
    if (!isBattle) {
      const runeItems = document.getElementsByClassName('skill');
      for (let i = 0; i < runeItems.length; i++) {
        runeItems[i].style.opacity = 0.4;
      }
      let els = document.getElementsByClassName(`skill_${info.spell_id}`)[0];
      if (els) els.style.opacity = 1;
    }
  };

  const handleMouseLeave = () => {
    const runeItems = document.getElementsByClassName('skill');
    for (let i = 0; i < runeItems.length; i++) {
      runeItems[i].removeAttribute('style');
    }
  };

  return (
    <div className="inventory__container__hub--body--skills--equipments">
      {nowData && (
        <Modal show={openModal} styles="mini" data={nowData.component || ''} />
      )}
      <div className="inventory__container__hub--body--skills--equipments__title">
        <Lang>COMBAT_EQUIPMENT</Lang>
      </div>

      <div className="inventory__container__hub--body--skills--equipments--combat">
        <InventoryMiniSlots mini={false} />
      </div>
      <DragAndDrop
        dropType="skillOneList"
        dropData={(e) => toDropData(e)}
        className="inventory__container__hub--body--skills--equipments--hub"
      >
        <div className="inventory__container__hub--body--skills--equipments--hub--filter">
          {tabsEquipment.map((i) => (
            <div
              key={i.id + 't'}
              className={`inventory__container__hub--body--skills--equipments--hub--filter--nav ${i.id === tabEquipments ? 'inventory__container__hub--body--skills--equipments--hub--filter--nav--active' : ''}`}
              onClick={() => setTabEquipments(i.id)}
            >
              <img src={i.img} alt="" />
            </div>
          ))}
        </div>
        <div
          className="inventory__container__hub--body--skills--equipments--hub--grid"
          ref={equipRef}
        >
          {userInvent.map((i, index) => {
            const infoSkills = getInfoDictionary('equipments', i.dict_id);
            if (!dropDownRef.current[index]) {
              dropDownRef.current[index] = React.createRef();
            }

            if (infoSkills) {
              if (infoSkills.$type === tabEquipments) {
                return (
                  <Tooltip
                    zIndex={18}
                    maxWidth="4px"
                    key={i.id + 'infoWeaponsBasd'}
                    open={true}
                    content={
                      infoSkills.item_type <= 2 ? (
                        <InventoryHubThingsItemOther toItem={i} />
                      ) : (
                        <InventoryHubThingsItemRun toItem={infoSkills} />
                      )
                    }
                  >
                    <DragAndDrop
                      key={infoSkills.id + 'infoSkillsEquip'}
                      dragData={{
                        data: i,
                        dragType: 'skillOneList',
                      }}
                    >
                      <InventoryItemCell type="mini">
                        <div
                          className="inventory__container__hub--body--skills--equipments--hub--grid--item"
                          key={infoSkills.id}
                          onMouseEnter={() => handleMouseEnter(infoSkills, i)}
                          onMouseLeave={() => handleMouseLeave()}
                          onContextMenu={(e) => {
                            handleClickContextMenu(e, infoSkills.id, index);
                          }}
                          ref={dropDownRef.current[index]}
                        >
                          {i?.charge &&
                            i?.charge > 1 &&
                            infoSkills.item_type < 3 && (
                              <span className="inventory__container__hub--body--skills--abilities--hub--grid--item__level">
                                {i.charge}
                              </span>
                            )}
                          <Images
                            className={'rune rune_' + i.dict_id}
                            type="run"
                            name={i.dict_id}
                          />

                          {infoSkills.item_type <= 2 &&
                            infoSkills.inventory_use && (
                              <MenuTooltip
                                toRef={dropDownRef.current[index]}
                                open={tooltip === infoSkills.id + index}
                                setForcibly={() => setTooltip(null)}
                                onClick={(name) => {
                                  toOnClick(name, i, infoSkills);
                                }}
                                isBattle={isBattle}
                                type="skill"
                              />
                            )}
                        </div>
                      </InventoryItemCell>
                    </DragAndDrop>
                  </Tooltip>
                );
              }
            }
          })}
          {Array.from(Array(emptyEquipmentItems).keys()).map((i, index) => (
            <InventoryItemCell type="mini" key={index + 'hub'}>
              <div className="inventory__container__hub--body--skills--equipments--hub--grid--item" />
            </InventoryItemCell>
          ))}
        </div>
      </DragAndDrop>
    </div>
  );
};

export default InventoryHubSkillsEquipments;
