import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNpcOpen } from '../../store/reducer/Slices/SliceNpc';
import { setOpenShop } from '../../store/reducer/Slices/SlicesShop';
import { useDictionary } from '../../hooks/useDictionary';
import { useRequest } from '../../hooks/useRequest';

function NpcDialogue({ dlg, leaveEvent }) {
  const dispatch = useDispatch();
  const [parentId, setParentId] = useState('NULL');
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [dict, getInfoDictionary] = useDictionary();
  const [requestSend] = useRequest();
  const user = useSelector((state) => state.allUserData.data);
  const userQuests = user.h_quests?.quests;
  const completedQuests = user.h_quests?.completed_ids;
  const [npc, setNpc] = useState(null);

  useEffect(() => {
    const buldings = dict.buildings;
    const bld = buldings.filter((el) => el.id === user.h_location.building_id);
    const npc = bld[0]?.npc_id;
    if (npc) {
      setNpc(npc);
      if (user.h_quests.visited_npc.includes(npc)) {
        setIsFirstVisit(false);
      } else {
        requestSend.sendData('RequestQuestNpcVisit', {
          npc_id: npc,
        });
      }
    }
  }, []);

  // console.log('dict', dict);
  // console.log('dlg', dlg);

  const handleResponse = (dialog) => {
    const { action, id, condition } = dialog;
    if (action === 'dialogue') {
      // перейти на ветку диалога
      setParentId(id);
    } else if (action === 'exit') {
      // выйти из здания/нпц
      if (leaveEvent) {
        leaveEvent();
      } else {
        requestSend.sendData('RequestBuildingMove', {
          build_id: user.h_location.building_id,
        });
      }
      dispatch(setNpcOpen(false));
    } else if (action.startsWith('exit_') || action.startsWith('exit ')) {
      // вернуться к предыдущей ветке диалога
      if (action.startsWith('exit_')) {
        const [, parentId] = action.split('_');
        setParentId(parentId);
      } else if (action.startsWith('exit ')) {
        const [, parentId] = action.split(' ');
        setParentId(parentId);
      }
    } else if (action.startsWith('open')) {
      // открыть магазин
      dispatch(setOpenShop(true));
    } else if (action.startsWith('qst')) {
      // взять квест
      requestSend.sendData('RequestQuestAccept', {
        quest_dict_id: action,
      });
      // .then((resolve) => {
      //   console.log('resolve', resolve);
      // });
      setParentId('NULL');
    } else if (action.startsWith('complete.qst')) {
      // выучить умение (получить награду)
      const [, qst, num] = action.split('.');
      let reward = condition.startsWith('complete') ? '' : condition;
      const chQst = getInfoDictionary('quests', qst + '.' + num);

      // ид квеста игрока нужна
      const currentQuest = userQuests.filter((el) => {
        if (el.dict_id === qst + '.' + num) {
          return el;
        } else {
          return null;
        }
      });
      if (currentQuest.length > 0 && currentQuest[0].completed) {
        requestSend.sendData('RequestQuestComplete', {
          quest_id: currentQuest[0].id,
          reward_id: reward,
        });
        setParentId('NULL');
        if (chQst && chQst.next_quest_id) {
          requestSend.sendData('RequestQuestAccept', {
            quest_dict_id: chQst.next_quest_id,
          });
        }
      } else {
        setParentId('NULL');
      }
    }
  };

  const renderDialogs = () => {
    const texts = [];
    const responses = [];

    if (dlg) {
      const childDialogs = Object.values(dlg).filter((dialog) => {
        if (parentId === null || parentId === 'NULL') {
          return dialog.parent_id === parentId;
        } else {
          return dialog.parent_id === parentId;
        }
      });

      const renderText = (dialog) => <p key={dialog.id}>{dialog.text}</p>;

      const renderResponse = (dialog) => (
        <span
          key={dialog.id}
          className="npc_modal_base--responses--elem"
          onClick={() => handleResponse(dialog)}
        >
          - {dialog.text}
        </span>
      );

      childDialogs.forEach((dialog) => {
        switch (true) {
          case dialog.condition.startsWith('qst'): // взят ли этот квест
            const qst = userQuests.some(
              (qst) => qst.dict_id === dialog.condition,
            );
            if (qst === true) {
              texts.push(renderText(dialog));
            } else {
              return null;
            }
            break;
          case dialog.condition.startsWith('done.'): // условие для квеста, для которого необходимо завершения других
            let qstDone;
            if (dialog.condition.includes(',')) {
              const conditionQsts = dialog.condition.split(',');
              qstDone = conditionQsts.every((el) => {
                const [, qstNum, num] = el.split('.');
                return completedQuests.some(
                  (qst) => qst === `${qstNum}.${num}`,
                );
              });
            } else {
              const [, qstNum, num] = dialog.condition.split('.');
              qstDone = completedQuests.some(
                (qst) => qst === qstNum + '.' + num,
              );
              if (qstDone) {
                return null;
              } else {
                responses.push(renderResponse(dialog));
              }
            }
            break;
          case dialog.condition === 'done_quest': // есть ли выполненные квесты
            const doneQ = user.h_quests.quests.filter((el) =>
              el.completed ? el : null,
            );
            const npcQst = getInfoDictionary('npc', npc);
            let npcQ = new Set(npcQst?.quest_ids);
            let hasMatch = doneQ.some((item) => npcQ.has(item.dict_id));
            if (doneQ?.length > 0 && hasMatch) {
              responses.push(renderResponse(dialog));
            } else {
              return null;
            }
            break;
          case dialog.condition === 'have_quest': // есть взятые квесты
            const npcQst2 = getInfoDictionary('npc', npc);
            let npcQ2 = new Set(npcQst2?.quest_ids);
            let hasMatch2 = user.h_quests.quests.some((item) =>
              npcQ2.has(item.dict_id),
            );
            if (user.h_quests.quests?.length > 0 && hasMatch2) {
              responses.push(renderResponse(dialog));
            } else {
              return null;
            }
            break;
          case dialog.condition.startsWith('lvl'):
            const [, levelNum] = dialog.condition.split('.');
            if (
              toString(user.level.level) === levelNum ||
              (user.level.level >= 10 && levelNum === '10')
            ) {
              responses.push(renderResponse(dialog));
            }
            break;
          case dialog.condition.startsWith('rumor'):
            const [, rumorNum] = dialog.condition.split('.');
            if (
              toString(user.level.level) === rumorNum ||
              (user.level.level >= 10 && rumorNum === '10')
            ) {
              texts.push(renderText(dialog));
            }
            break;
          case dialog.action !== '' &&
            !completedQuests.includes(dialog.action) &&
            !dialog.action.startsWith('complete') &&
            !dialog.condition.startsWith('complete') &&
            !dialog.condition.startsWith('lvl') &&
            !dialog.condition.startsWith('rum'): //
            if (dialog.condition === 'noAbils') {
              return null;
            } else responses.push(renderResponse(dialog));
            break;
          case dialog.action.startsWith('qst'): // взять квест
            responses.push(renderResponse(dialog));
            break;
          case dialog.action.startsWith('complete'): // получить умение в награду (фильтр)
            if (dialog.condition && dialog.condition.startsWith('abil')) {
              const abils = user.invent.invent_equipments.filter((el) =>
                el.dict_id.startsWith('abil'),
              );
              if (abils.includes(dialog.condition)) {
                return null;
              } else {
                responses.push(renderResponse(dialog));
              }
            } else if (
              dialog.condition &&
              dialog.condition.startsWith('complete')
            ) {
              const [, qst, num] = dialog.condition.split('.');
              const quest = userQuests.filter((el) => {
                if (el.dict_id === qst + '.' + num) {
                  return el;
                } else {
                  return null;
                }
              });
              if (quest.length > 0 && quest[0].completed) {
                responses.push(renderResponse(dialog));
              } else {
                return null;
              }
            } else {
              const [, qst, num] = dialog.action.split('.');
              const currentQuest = userQuests.filter((el) => {
                if (el.dict_id === qst + '.' + num) {
                  return el;
                } else {
                  return null;
                }
              });
              if (currentQuest.length > 0 && currentQuest[0].completed) {
                responses.push(renderResponse(dialog));
              } else {
                return null;
              }
            }
            break;
          case dialog.condition.startsWith('complete') && dialog.action === '': // проверка на выполненный квест
            const [, qstN, numN] = dialog.condition.split('.');
            const quest = userQuests.filter((el) => {
              if (el.dict_id === qstN + '.' + numN) {
                return el;
              } else {
                return null;
              }
            });
            if (quest.length > 0 && quest[0].completed) {
              texts.push(renderText(dialog));
            } else {
              return null;
            }
            break;
          case (dialog.action === '' &&
            dialog.condition !== 'first_visit' &&
            dialog.condition !== 'repeat_visit') ||
            (dialog.condition === 'first_visit' && isFirstVisit) ||
            (dialog.condition === 'repeat_visit' && !isFirstVisit):
            texts.push(renderText(dialog));
            break;
          default:
            return null;
        }
      });
    }
    return (
      <>
        <div className="npc_modal_base--texts">{texts}</div>
        <div className="npc_modal_base--responses">{responses}</div>
      </>
    );
  };

  return <>{renderDialogs()}</>;
}

export default NpcDialogue;
