import { createSlice } from '@reduxjs/toolkit';

const DungeonsData = createSlice({
  name: 'DungeonsData',
  initialState: {
    open: false,
    level: null,
    rooms: [],
    currentRoom: {},
    loot: null,
    chestOpen: null,
    enterModal: {
      open: false,
      building_id: null,
    },
  },
  reducers: {
    setDungeonOpen(state, action) {
      state.open = action.payload;
    },
    setDungeonLevel(state, action) {
      state.level = action.payload;
    },
    setDungeonRooms(state, action) {
      state.rooms = action.payload;
    },
    setCurrentRoom(state, action) {
      state.currentRoom = action.payload;
    },
    setLoot(state, action) {
      state.loot = action.payload;
    },
    setChestOpen(state, action) {
      state.chestOpen = action.payload;
    },
    setRoomUpdate(state, action) {
      const updatedRoom = action.payload;
      state.rooms = state.rooms.map((room) => {
        if (room.id === updatedRoom.id) {
          return updatedRoom;
        }
        return room;
      });
    },
    setEnterModal(state, action) {
      state.enterModal.open = action.payload.open;
      state.enterModal.building_id = action.payload.building_id;
    },
  },
});

export const {
  setDungeonOpen,
  setDungeonLevel,
  setDungeonRooms,
  setCurrentRoom,
  setLoot,
  setChestOpen,
  setRoomUpdate,
  setEnterModal,
} = DungeonsData.actions;

export { DungeonsData };
