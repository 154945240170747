import React, { useState, useEffect } from 'react';
import { timer } from '../../helper/helpers';

const TimerInitNum = ({ timer, end, nowTime }) => {
  const [time, setTime] = useState(timer);

  useEffect(() => {
    const timerTo = setInterval(() => {
      const tt = time - 1;
      setTime(tt);
    }, 1000);
    if (time === 1) {
      clearInterval(timerTo);
      end && end();
    }
    if (time > 0 && nowTime) nowTime(time);
    return () => clearInterval(timerTo);
  }, [time]);

  return time;
};

export default TimerInitNum;
