import axios from 'axios';
import { setDictionary } from '../store/reducer/Slices/SlicesDictionary';
import { setNpcDialogues } from '../store/reducer/Slices/SliceNpc';
import { routConnectionApi, routConnectionDlg } from './routeConnection';
const connectionUrl = routConnectionApi(process.env.REACT_APP_RUN);
const connectionDlg = routConnectionDlg(process.env.REACT_APP_RUN);

export const getInfo = (dispatch) => {
  return axios.get(connectionUrl).then(function (response) {
    dispatch(setDictionary(response.data));
  });
};

export const getNpcDialogues = (dispatch) => {
  return axios
    .get(connectionDlg, { headers: { 'Cache-Control': 'no-cache' } })
    .then(function (response) {
      dispatch(setNpcDialogues(response.data));
    });
};
