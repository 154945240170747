import React, { useEffect, useState } from 'react';

const ActiveNum = ({ num, classOne, classTwo }) => {
  const [anim, setAnim] = useState(false);

  useEffect(() => {
    setAnim(true);
    setTimeout(() => {
      setAnim(false);
    }, 1000);
  }, [num]);

  return (
    <div className={classOne}>
      <div className={`${classTwo} ${anim ? 'animNum' : ''}`}>{num}</div>
    </div>
  );
};

export default ActiveNum;
