export const routConnection = (nav) => {
  switch (nav) {
    case 'local':
      return process.env.REACT_APP_HOST_LOCAL;
    case 'dev':
      return process.env.REACT_APP_HOST_DEV;
    case 'rc':
      return process.env.REACT_APP_HOST_RC;
    case 'prod':
      return process.env.REACT_APP_HOST_PROD;
    case 'beta':
      return process.env.REACT_APP_HOST_BETA;
    default:
      return process.env.REACT_APP_HOST_LOCAL;
  }
};

export const routConnectionApi = (nav) => {
  switch (nav) {
    case 'local':
      return process.env.REACT_APP_HOST_LOCAL_API;
    case 'dev':
      return process.env.REACT_APP_HOST_DEV_API;
    case 'rc':
      return process.env.REACT_APP_HOST_RC_API;
    case 'prod':
      return process.env.REACT_APP_HOST_PROD_API;
    case 'beta':
      return process.env.REACT_APP_HOST_BETA_API;
    default:
      return process.env.REACT_APP_HOST_DEV_API;
  }
};

export const routConnectionDlg = (nav) => {
  switch (nav) {
    case 'local':
      return process.env.REACT_APP_HOST_LOCAL_DLG;
    case 'dev':
      return process.env.REACT_APP_HOST_DEV_DLG;
    case 'rc':
      return process.env.REACT_APP_HOST_RC_DLG;
    case 'prod':
      return process.env.REACT_APP_HOST_PROD_DLG;
    case 'beta':
      return process.env.REACT_APP_HOST_BETA_DLG;
    default:
      return process.env.REACT_APP_HOST_DEV_DLG;
  }
};

export const routConnectionInit = (nav) => {
  switch (nav) {
    case 'local':
      return process.env.REACT_APP_HOST_LOCAL_INIT;
    case 'dev':
      return process.env.REACT_APP_HOST_DEV_INIT;
    case 'rc':
      return process.env.REACT_APP_HOST_RC_INIT;
    case 'prod':
      return process.env.REACT_APP_HOST_PROD_INIT;
    case 'beta':
      return process.env.REACT_APP_HOST_BETA_INIT;
    default:
      return process.env.REACT_APP_HOST_DEV_INIT;
  }
};
