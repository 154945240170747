import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducer/UserSlice';

export const setupStore = () => {
  return configureStore({
    reducer: userReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};
