import React from 'react';
import '../styles.scss';
import { useDispatch } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import { setOpenApplication } from '../../../store/reducer/Slices/SlicesApplicationOpen';
import Lang from '../../language';
import imgBackItem from '../../../media/img/game/playingField/map/loc1/backItem.png';
import imgMapBack from '../../../media/img/game/playingField/map/loc3/map.png';
import imgRuin from '../../../media/img/game/playingField/map/loc3/ruin.png';
import imgBash from '../../../media/img/game/playingField/map/loc3/bash.png';
import imgBack from '../../../media/img/game/playingField/map/loc3/lock3B.jpg';
import { setOpenMap } from '../../../store/reducer/Slices/SlicesMap';
import LocationElement from '../LocationElement';
import { setEnterModal } from '../../../store/reducer/Slices/SlicesDungeon';

const Location3 = () => {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();

  const locations = {
    backImg: imgBack,
    items: [
      {
        name: 'Башня',
        img: {
          top: '1.8vw',
          left: '20.1vw',
          width: '29.2vw',
          src: imgBash,
          srcBack: imgBackItem,
          backBottom: '7vw',
        },
        style: {
          position: 'relative',
          bottom: '4vh',
          left: '2vw',
          width: '29vw',
          height: '24vh',
        },
        onClick: () => {
          dispatch(
            setEnterModal({
              open: true,
              building_id: 'bld.25',
            }),
          );
        },
      },
      {
        name: 'Руины',
        img: {
          top: '17.6vw',
          left: '31vw',
          width: '21.2vw',
          src: imgRuin,
          srcBack: imgBackItem,
        },
        style: {
          position: 'relative',
          bottom: '4vh',
          left: '2vw',
          width: '18vw',
          height: '21vh',
        },
        onClick: () => {
          dispatch(
            setEnterModal({
              open: true,
              building_id: 'bld.6',
            }),
          );
        },
      },
      {
        name: 'Карта',
        img: {
          top: '27.2vw',
          left: '43.2vw',
          width: '9.2vw',
          src: imgMapBack,
          srcBack: imgBackItem,
        },
        style: { width: '8vw', height: '22vh' },
        onClick: () => dispatch(setOpenMap(true)),
      },
    ],
  };

  return (
    <div className="locationIn">
      <div className="locationIn__back">
        <img src={locations.backImg} alt="" />
      </div>
      <div className="locationIn__container">
        {/* <div className="locationIn__container--buttons">
          <UiButtonOne
            type="middle"
            onClick={() => {
              dispatch(setOpenApplication(1));
            }}
          >
            <Lang>LIST_OF_APPLICATIONS</Lang>
          </UiButtonOne>
          <UiButtonOne
            type="middle"
            onClick={() => {
              requestSend.sendData('RequestBattleRequestPvpBot');
            }}
          >
            <Lang>FIGHT_WITH_A_BOT</Lang>
          </UiButtonOne>
        </div> */}
        {locations.items.map((i, index = index + 1) => (
          <LocationElement index={index} i={i} key={i.name} />
        ))}
      </div>
    </div>
  );
};

export default Location3;
