import React, { useEffect, useState } from 'react';
import TimerInitNum from '../../timerInit/TimerInitNum';
import BattleInitStartMini from './BattleInitStartMini';
import BattleInitStartMiniEsta from './BattleInitStartMiniEsta';
import { useDispatch, useSelector } from 'react-redux';
import ModulesNavFight from './modules/ModulesNavFight';
import TimerInit from '../../timerInit/TimerInit';
import { useRequest } from '../../../hooks/useRequest';
import BattleInitSearch from './BattleInitSearch';
import BattleInitWait from './BattleInitWait';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import BattleInitStartMiniTwo from './BattleInitStartMiniTwo';
import {
  setHeroReconCounter,
  setHeroReconData,
  setModalState,
} from '../../../store/reducer/Slices/SlicesBattle';
import Lang from '../../language';

const BattleInitStart = () => {
  const [requestSend] = useRequest();
  const dispatch = useDispatch();
  const userChar = useSelector((state) => state.allUserData.data.char);
  const battleStart = useSelector((state) => state.battle.data);
  const pairCurrent = useSelector((state) => state.battleCurrent.pairCurrent);
  const battleAction = useSelector((state) => state.battle.action);
  const reconCounter = useSelector((state) => state.battle.heroReconTimer);
  const reconData = useSelector((state) => state.battle.heroReconData);
  // const mod = useSelector((state) => state.battle.modalState);
  const [battleListZonesDef, setBattleListZonesDef] = useState({ def: {} });
  const [battleListZonesAttack, setBattleListZonesAttack] = useState({
    attack: [],
  });
  const [toEndTime, setToEndTime] = useState(true);
  const [toNum, setToNum] = useState(0);
  const [miniSlotOne, setMiniSlotOne] = useState(0);
  const [miniSlotTwo, setMiniSlotTwo] = useState({ data: 0, type: 'spell' });
  const [miniActSlot, setMiniActSlot] = useState([]);
  const [elixUseDis, setElixUseDis] = useState(false);

  const sendDate = () => {
    let toData = {};
    toData.battle_id = battleStart.battle.id;
    setMiniActSlot(miniSlotTwo.data);

    if (miniSlotOne > 0) {
      toData.item_slot_id = miniSlotOne;
      toData.pair_id = pairCurrent.pair_id;
    } else {
      if (miniSlotTwo.type === 'spell' && miniSlotTwo.data > 0) {
        toData.spell_slot_id = miniSlotTwo.data;
        toData.pair_id = pairCurrent.pair_id;
        toData.dest_hero_ids = [pairCurrent.pair_id];
      } else if (miniSlotTwo.type === 'abil' && miniSlotTwo.data.length > 0) {
        toData.ability_slot_ids = miniSlotTwo.data;
        if (miniSlotTwo.dest_hero_name) {
          toData.dest_hero_name = miniSlotTwo.dest_hero_name;
        }
        if (battleListZonesDef.def.def_id) {
          toData.def_id = battleListZonesDef.def.def_id;
        } else toData.def_zone = battleListZonesDef.def.def_zone[0];
        toData.pair_id = pairCurrent.pair_id;
        toData.att_zones = battleListZonesAttack.attack;
      } else {
        if (battleListZonesDef.def.def_id) {
          toData.def_id = battleListZonesDef.def.def_id;
        } else toData.def_zone = battleListZonesDef.def.def_zone[0];
        toData.pair_id = pairCurrent.pair_id;
        toData.att_zones = battleListZonesAttack.attack;
      }
    }
    requestSend.sendData('RequestBattleTurn', toData);
    setElixUseDis(false);
    if (reconCounter > 0) {
      dispatch(setHeroReconCounter(reconCounter - 1));
    } else if (reconData && reconCounter <= 0) {
      dispatch(setHeroReconData({}));
    }
    setMiniActSlot([]);
    setMiniSlotTwo({ data: 0, type: 'spell' });
  };

  const endTime = () => {
    setMiniActSlot(miniSlotTwo.data);
    setElixUseDis(false);
    requestSend.sendData('RequestBattleAutoTurn', {
      battle_id: battleStart.battle.id,
    });
    if (reconCounter > 0) {
      dispatch(setHeroReconCounter(reconCounter - 1));
    } else if (reconData && reconCounter <= 0) {
      dispatch(setHeroReconData({}));
    }
    setMiniActSlot([]);
    setMiniSlotTwo({ data: 0, type: 'spell' });
  };

  useEffect(() => {
    if (
      battleListZonesAttack.attack?.length === 0 &&
      Object.keys(battleListZonesDef.def).length === 0 &&
      miniSlotOne === 0
    ) {
      setToEndTime(true);
    } else setToEndTime(false);

    if (miniSlotTwo.data > 0 && miniSlotTwo.type === 'spell') {
      setToEndTime(false);
    }
  }, [battleListZonesAttack, battleListZonesDef, miniSlotOne, miniSlotTwo]);

  return (
    <div className="battle__init--start">
      <div className="battle__init--start--bg" />
      <div className="battle__init--start--anim1" />
      <div className="battle__init--start--anim2" />
      <div className="battle__init--start--head">
        {battleAction.num === 1 && (
          <>
            <Lang>ITS_YOUR_TURN_ALL_THAT</Lang>{' '}
            <div>
              <TimerInit next_status_time={pairCurrent.finish} />
            </div>
          </>
        )}
        {battleAction.num === 2 && (
          <>
            <Lang>WAITING_FOR_A_FREE_OPPONENT</Lang>...
          </>
        )}
        {battleAction.num === 3 && (
          <>
            <Lang>WAITING_FOR_YOUR_OPPONENTS_MOVE</Lang>...{' '}
            <div>
              <TimerInit next_status_time={pairCurrent.finish} />
            </div>
          </>
        )}
        {battleAction.num === 4 && (
          <>
            <Lang>THERES_A_FIGHT_GOING_ON</Lang>...
          </>
        )}
      </div>
      <BattleInitSearch visibility={battleAction.num === 2} />
      <BattleInitStartMini
        play={miniSlotTwo.data === 0 || miniSlotTwo.data.length === 0}
        zIndex={3}
        visibility={battleAction.num === 1}
        title="ITEMS"
        type={1}
        top={true}
        toSetSlot={(e) => setMiniSlotOne(e)}
        elixUse={elixUseDis}
        elixAct={setElixUseDis}
      />
      {(battleAction.num === 1 ||
        battleAction.num === 3 ||
        battleAction.num === 4) && (
        <ModulesNavFight
          play={
            miniSlotOne === 0 &&
            ((miniSlotTwo.data === 0 && miniSlotTwo.type === 'spell') ||
              miniSlotTwo.type === 'abil')
          }
          battleAction={battleAction.num}
          selectBlock={(e) => setBattleListZonesDef({ def: e })}
          selectAttack={(e) => setBattleListZonesAttack({ attack: e })}
        />
      )}
      <BattleInitStartMiniEsta
        nowNum={userChar.characters.find((item) => item.char === 26)?.points}
        visibility={battleAction.num === 1}
        data={[
          1, 2, 3, 5, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ]}
        title="STAMINA"
        toNum={toNum}
      />
      <BattleInitStartMiniTwo
        play={miniSlotOne === 0}
        nowNum={userChar.characters.find((item) => item.char === 26)?.points}
        toNum={toNum}
        visibility={battleAction.num === 1}
        title="SKILLS"
        toHover={(i) => setToNum(i)}
        toSetSlot={(e) => {
          setMiniSlotTwo(e);
        }}
        setModCL={() => dispatch(setModalState(false))}
        setModOP={() => dispatch(setModalState(true))}
        setMini={miniActSlot}
      />
      {battleAction.num === 3 && <BattleInitWait />}
      {battleAction.num === 1 && (
        <div className="battle__init--start--buttons">
          <UiButtonOne
            className="battle__init--start--buttons--one"
            // className={`battle__init--start--buttons--one ${toEndTime ? 'battle__init--start--buttons--one--anim' : ''}`} // если нужно включить подсветку снова
            onClick={() => endTime()}
          >
            {toEndTime ? (
              <>
                <Lang>MOTOR_VEHICLE_THROUGH</Lang> (
                <TimerInitNum timer={30} end={() => endTime()} />)
              </>
            ) : (
              <Lang>AUTOMOBILE_TRANSPORT_CANCELED</Lang>
            )}
          </UiButtonOne>
          <UiButtonOne
            className="battle__init--start--buttons--one"
            disabled={
              !(
                toEndTime === false &&
                (miniSlotOne !== 0 ||
                  miniSlotTwo.data !== 0 ||
                  (battleListZonesAttack.attack?.length !== 0 &&
                    Object.keys(battleListZonesDef.def).length !== 0))
              )
            }
            onClick={() => (!toEndTime ? sendDate() : '')}
          >
            <Lang>FORWARD</Lang>!
          </UiButtonOne>
        </div>
      )}
    </div>
  );
};

export default BattleInitStart;
