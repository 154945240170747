import React, { useEffect, useRef, useState } from 'react';
import imgAll from '../../../src/media/img/shop/all.png';
import imgWeapons from '../../../src/media/img/shop/weapon.png';
import imgArmor from '../../../src/media/img/shop/armor.png';
import imgExpendable from '../../../src/media/img/shop/scroll.png';
import imgBooks from '../../../src/media/img/shop/book.png';
import imgAllActive from '../../../src/media/img/shop/l_all.png';
import imgWeaponsActive from '../../../src/media/img/shop/l_weapon.png';
import imgArmorActive from '../../../src/media/img/shop/l_armor.png';
import imgExpendableActive from '../../../src/media/img/shop/l_scroll.png';
import imgBooksActive from '../../../src/media/img/shop/l_book.png';
import ShopItemBuy from './ShopItemsBuy';

const tabs = [
  {
    id: 0,
    img: imgAll,
    img_active: imgAllActive,
  },
  {
    id: 1,
    img: imgWeapons,
    img_active: imgWeaponsActive,
  },
  {
    id: 2,
    img: imgArmor,
    img_active: imgArmorActive,
  },
  {
    id: 12,
    img: imgExpendable,
    img_active: imgExpendableActive,
  },
  {
    id: 14,
    img: imgBooks,
    img_active: imgBooksActive,
  },
];

const ShopHubBuy = () => {
  const [tab, setTab] = useState(0);
  const filterRef = useRef(null);
  const [filterHeight, setFilterHeight] = useState(0);

  useEffect(() => {
    setFilterHeight(filterRef.current?.getBoundingClientRect().height);
  }, [filterRef]);

  return (
    <div className="shop__container__hub--body--things" ref={filterRef}>
      <div className="shop__container__hub--body--things--filter">
        {tabs.map((i) => (
          <div
            key={i.id + 't'}
            className={`shop__container__hub--body--things--filter--nav ${i.id === tab ? 'shop__container__hub--body--things--filter--nav--active' : ''}`}
            onClick={() => setTab(i.id)}
          >
            <img src={i.id === tab ? i.img_active : i.img} alt="" />
          </div>
        ))}
      </div>

      <ShopItemBuy tab={tab} thingsHeight={filterHeight} />
    </div>
  );
};

export default ShopHubBuy;
