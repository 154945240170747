import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../hooks/useDictionary';
import Tooltip from '../tooltip/Tooltip';
import Images from '../../helper/Images';
import InventoryHubSkillsItem from '../Inventory/InventoryHubSkills/InventoryHubSkillsItem';
import { toRomanNumber } from '../../helper/helpers';
import { useRequest } from '../../hooks/useRequest';

function BattleListRecon() {
  const [requestSend] = useRequest();
  const reconData = useSelector((state) => state.battle.heroReconData);
  const battle = useSelector((state) => state.battle.data.battle.id);
  const chat = useSelector((state) => state.chatData.data);
  const [dict, getInfoDictionary] = useDictionary();
  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    if (reconData.active_slots?.length > 0) {
      const active = reconData.active_slots.filter((el) => el.model);
      setEquipments(active);
    } else {
      setEquipments([]);
    }
  }, [reconData]);

  useEffect(() => {
    if (battle && chat && chat.length <= 1) {
      requestSend
        .sendData('RequestGetBattleHistoryLength', {
          battle_id: battle,
        })
        .then((res) => {
          if (res[0].history_count > 2) {
            requestSend.sendData('RequestGetBattleLogs', {
              battle_id: battle,
              from: 1,
              count: res[0].history_count,
            });
          }
        });
    }
  }, []);

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        {equipments?.length > 0 &&
          equipments.map((i) => {
            const infoSkills = getInfoDictionary('equipments', i.model.dict_id);

            return (
              <Tooltip
                maxWidth="450px"
                key={'miniTwoAS' + i.model.dict_id}
                open={true}
                content={
                  <InventoryHubSkillsItem
                    fromItem={i.model}
                    toItem={infoSkills}
                  />
                }
              >
                <div className={`battle__init--start--mini--list--item`}>
                  <span className="inventory__container__hub--body--skills--abilities--hub--grid--item__level">
                    {toRomanNumber(i.model.level)}
                  </span>
                  <Images name={i.model.dict_id} />
                </div>
              </Tooltip>
            );
          })}
      </div>
    </>
  );
}

export default BattleListRecon;
