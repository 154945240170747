import React, { useEffect, useState } from 'react';
import './styles.scss';
import imgApp1 from '../../media/img/app/item.png';
import ApplicationButton from './ApplicationButton';
import ApplicationList from './ApplicationList';
import { useSelector } from 'react-redux';
import { useRequest } from '../../hooks/useRequest';
import Modal from '../modal/Modal';
import Lang from '../language';

const tabs = [
  {
    id: 1,
    img: imgApp1,
    title: 'ONE_ON_ONE_FIGHTS',
    name: 'bat_req_pvp',
  },
  {
    id: 2,
    img: imgApp1,
    title: 'SQUAD_TO_SQUAD',
    name: 'bat_req_group',
  },
  {
    id: 3,
    img: imgApp1,
    title: 'CHAOTIC_BATTLES',
    name: 'bat_req_random',
  },
  {
    id: 4,
    img: imgApp1,
    title: 'EVERY_MAN_FOR_HIMSELF',
    name: 'bat_req_himself',
  },
];

const Application = () => {
  const [onlyMy, setOnlyMy] = useState(false);
  const [tab, setTab] = useState('bat_req_pvp');
  const applications = useSelector((state) => state.applicationsList.list);
  const [requestSend] = useRequest();

  useEffect(() => {
    requestSend.sendData('RequestBattleRequestList');
  }, []);

  return (
    <>
      <div className="application">
        <div className="application__top">
          <label>
            <input
              onChange={(e) => setOnlyMy(e.target.checked)}
              value={onlyMy}
              type="checkbox"
            />
            <Lang>ONLY_MY_LEVEL_OF_FAME</Lang>
          </label>
        </div>
        <div className="application__one">
          <div className="application--menu">
            {tabs.map((i, index) => (
              <div
                key={index + i.title}
                className={`application--menu--item ${tab === i.name ? 'application--menu--item--active' : ''}`}
                onClick={() => setTab(i.name)}
              >
                <img src={i.img} alt="" />
                <div className="application--menu--item--box">
                  <div className="application--menu--item--box--name">
                    <Lang>{i.title}</Lang>
                  </div>
                  <div className="application--menu--item--box--desc">
                    {applications[i.name] && applications[i.name].length} боев
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="application--body">
            <ApplicationList
              key={2 * 2}
              tab={tab}
              data={applications}
              onlyMy={onlyMy}
            />
          </div>
        </div>
        <div className="application__two">
          <ApplicationButton tab={tab} tabInfo={tabs} />
        </div>
      </div>
    </>
  );
};

export default Application;
