import React from 'react';
import './styles.scss';
import PlayingFieldMain from './playingFieldMain/playingFieldMain';
import PlayingFieldFooter from './playingFieldFooter/playingFieldFooter';
import { useSelector } from 'react-redux';
import Battle from '../../components/battle/Battle';

const PlayingField = () => {
  const battleStatus = useSelector((state) => state.battle.status);

  return (
    <div className="playingField">
      {(battleStatus === 0 || battleStatus === 1 || battleStatus === 3) && (
        <>
          <PlayingFieldMain />
          <PlayingFieldFooter />
        </>
      )}
      {battleStatus === 2 && <Battle />}
    </div>
  );
};

export default PlayingField;
