import React, { useEffect } from 'react';
import ChatMessageBattleTurnType from './ChatMessageBattleTurnType';
import HeroNameLink from '../HeroNameLink/HeroNameLink';
import imgMini1 from '../../../media/img/game/character/miniInfo/Icons-Fight-log.png';
import imgMini2 from '../../../media/img/game/character/miniInfo/Icons-Fight-log-krit.png';
import imgMini3 from '../../../media/img/game/character/miniInfo/Icons-Fight-log-v.png';
import imgMini4 from '../../../media/img/game/character/miniInfo/Icons-Fight-log-z.png';
import imgMini5 from '../../../media/img/game/character/miniInfo/Icons-Fight-log-w.png';
import imgMini6 from '../../../media/img/game/character/miniInfo/magicim.png';
import imgIncomingDamage from '../../../media/img/game/playingField/chat/IncomingDamage.png';
import imgOutgoingDamage from '../../../media/img/game/playingField/chat/OutgoingDamage.png';
import { checkTimeFormat } from '../../../helper/helpers';
import Lang from '../../language';

const ChatMessageBattleTurn = ({ message, userHero }) => {
  const choice = (data, userHero) => {
    if (data.$type === 35) return null;

    return (
      <img
        className={
          data.a_her_id !== userHero &&
          'chat__block1--body--message--item--battle--rever'
        }
        src={
          data.a_crit
            ? imgMini2 // крит
            : data.d_dodge
              ? imgMini1 // додж
              : data.d_parry
                ? imgMini3 // парирование
                : data.a_contr_att || data.power
                  ? imgMini5 // контр-атака
                  : imgMini4
        }
        alt=""
      />
    );
  };

  return (
    <div key={message.data.id} className="chat__block1--body--message--item">
      <div className="chat__block1--body--message--item--time">
        {message.time}
      </div>
      <div className="chat__block1--body--message--item--battle">
        {/*{message.data.a_her_id === userHero && (*/}
        {/*  <img src={imgOutgoingDamage} alt="" />*/}
        {/*)}*/}
        {/*{message.data.a_her_id !== userHero && (*/}
        {/*  <img src={imgIncomingDamage} alt="" />*/}
        {/*)}*/}
        {/* заклинание */}
        {message.data.$type === 35 && (
          <span>
            <img src={imgMini6} alt="" />
          </span>
        )}{' '}
        {choice(message.data, userHero)}{' '}
      </div>
      {message?.data?.d_zones && (
        <div className="chat__block1--body--message--item--attack">
          <span
            className={`chat__block1--body--message--item--attack--container ${
              userHero === message.data.a_her_id
                ? 'chat__block1--body--message--item--attack--container__allies'
                : 'chat__block1--body--message--item--attack--container__opponents'
            }`}
          >
            {Array.from(Array(4).keys()).map((target, index) => (
              <span
                key={target}
                className={`chat__block1--body--message--item--attack--container--target ${
                  message.data.d_zones.find((i) => i === target + 1) ||
                  message.data.d_s_zone === target + 1 ||
                  message.data.d_dodge ||
                  message.data.d_parry
                    ? 'chat__block1--body--message--item--attack--container--target__defence'
                    : ''
                }`}
              >
                {message.data.a_zone === target + 1 && (
                  <span
                    className={`chat__block1--body--message--item--attack--container--target--attack`}
                  />
                )}
              </span>
            ))}
          </span>
        </div>
      )}
      <ChatMessageBattleTurnType message={message} userHero={userHero} />
        {
            !!message?.data?.AttackerZodiacKey && <>
            {' '}<Lang>ZODIAC_SIGN</Lang> <Lang>{message?.data?.AttackerZodiacKey}</Lang> <Lang>PROVED_HIMSELF_IN_ATTACK</Lang>{' '}
            </>
        }
        {
            !!message?.data?.DefenderZodiacKey && <>
                {' '}<Lang>ZODIAC_SIGN</Lang> <Lang>{message?.data?.DefenderZodiacKey}</Lang> <Lang>PROVED_HIMSELF_IN_DEFENSE</Lang>{' '}
            </>
        }
    </div>
  );
};

export default React.memo(ChatMessageBattleTurn);
