export const Naming = {
  quality: {
    1: 'ORDINARY',
    2: 'RARE',
    3: 'UNIQUE',
    4: 'LEGENDARY',
    5: 'EPIC',
  },
  points: 'VICTORIES',
  lvl: 'LVL',
  character: {
    1: 'BASE_STRENGTH',
    2: 'STRENGTH_BONUS',
    3: 'FORCE',
    4: 'BASIC_AGILITY',
    5: 'AGILITY_BONUS',
    6: 'DEXTERITY',
    7: 'DODGE_BONUS',
    8: 'PARRY_CHANCE',
    9: 'MF_DODGE',
    10: 'ANTI_DODGE_BONUS',
    11: 'MF_ANTI_DODGE',
    12: 'BASE_INTUITION',
    13: 'INTUITION_BONUS',
    14: 'INTUITION',
    15: 'CRIT_CHANCE_BONUS',
    16: 'MF_CRIT',
    17: 'ANTI_CRIT_BONUS',
    18: 'MF_ANTI_CRIT',
    19: 'BASE_HEALTH',
    20: 'HP_BONUS',
    21: 'MAX_HP',
    22: 'HP',
    23: 'BASE_INTELLIGENCE',
    24: 'INTELLIGENCE_BONUS',
    25: 'INTELLIGENCE',
    26: 'STAMINA',
    27: 'HEAD_ARMOR',
    28: 'CHEST_ARMOR',
    29: 'WAIST_ARMOR',
    30: 'LEGS_ARMOR',
    31: 'ARMOR_DURABILITY',
    32: 'DESTRUCTION_OF_ARMOR',
    33: 'DAMAGE_BONUS',
    34: 'BASE_DAMAGE',
    35: 'ARMOR_PENETRATION_CHANCE',
    36: 'REQUIRED_LEVEL_OF_PROFICIENCY',
    37: 'BASE_WEAPON_DAMAGE',
    38: 'POWER_ATTACK_BONUS_PERCENT',
    39: 'POWER_ATTACK_CHANCE',
    40: 'CRIT_BONUS',
    41: 'RESISTANCE_TO_CURRENT_DAMAGE_TYPE',
    42: 'DAMAGE_RESISTANCE_PERCENTAGE',
    43: 'DAMAGE_BONUS_AS_A_PERCENTAGE',
    44: 'STRENGTH_OF_MIND',
    45: 'SHIELD_BLOCK_CHANCE',
    46: 'SUSCEPTIBILITY_TO_TREATMENT',
    47: 'CHANCE_STUNNING',
    48: 'CHANCE_KNOCKED',
    49: 'CHANCE_INFLICT_POISON',
    50: 'CHANCE_CAUSE_BLEEDING',
    51: 'CHANCE_PARALYSIS',
    52: 'STUN_RESISTANCE',
    53: 'RESISTANCE_KNOCKED',
    54: 'BLEED_RESISTANCE',
    55: 'RESISTANT_TO_POISONING',
    56: 'RESIST_TO_PARALYSIS',
    57: 'PROTECTION_CUTTING',
    58: 'PROTECTION_PIERCING',
    59: 'BLUNT_PROTECTION',
    60: 'PROTECTION_SLASHING',
    61: 'PROTECTION_CUTTING_UN',
    62: 'PROTECTION_PIERCING_UN',
    63: 'PROTECTION_CRUSHING_UN',
    64: 'PROTECTION_SLASHING_UN',
    65: 'CUTTING_DAMAGE_UN',
    66: 'PIERCING_DAMAGE_UN',
    67: 'CRUSHING_DAMAGE_UN',
    68: 'SLASHING_DAMAGE_UN',
    69: 'REACTION',
    70: 'SKILL',
    71: 'DURABILITYO',
    72: 'LUCK',
    73: 'LUCK_STAMINA_BEGIN_BATTLE',
    74: 'POWER',
    75: 'StrengthOfMindMax',
    76: 'ArmorDurabilityMax',
    77: 'ADDING_FORTITUDE',
    78: 'CHANCE_COUNTERATTACK',
    79: 'MaxDamage',
    80: 'BaseMinDamage',
    81: 'BaseMaxDamage',
    82: 'MinDamageLeftWeapon',
    83: 'MaxDamageLeftWeapon',
    84: 'MagicResistPercent',
    85: 'HPBonusPercent',
    86: 'SilenceResist',
  },
  DamageType: {
    1: 'CUTTING',
    2: 'CRUSHING',
    3: 'CHOPPING',
    4: 'PRICKING',
  },
  WeaponClassType: {
    1: 'SWORD',
    2: 'HAMMER',
    3: 'AXE',
    4: 'DAGGER',
    5: 'SPEAR',
    6: 'STAFF',
  },
  RequirementType: {
    1: 'AVAILABILITY_CHARACTER_LVL',
    2: 'WEAPON_PROFICIENCY_LVL',
    3: 'GLORY_LEVEL',
    4: 'SHIELD_MUST_WORN',
    5: 'WEAPON_CLASS_EQUIPPED',
    6: 'WEAPON_TYPE',
    7: 'WEAPONS_MUST_EQUIPPED',
  },
  SpellType: {
    1: 'APPLIES_AN_EFFECT',
    2: 'HEALS',
    3: 'REMOVES_NEGATIVE_EFFECTS',
    4: 'DEALS_DAMAGE',
    5: 'CALL',
    6: 'ARCANA',
    7: 'GATE',
  },
  AbilityType: {
    1: 'HIT_SPELL_LESS_DAM',
    2: 'HIT_SPELL_MORE_DAM1',
    3: 'DAMAGE_DIRECTLY_TO_HP',
    4: 'WAY_OF_THE_SHIELD',
    5: 'SWIPE',
    6: 'DAMAGE_DIRECTLY',
    7: 'STAGGERS_ENEMY',
    8: 'DODGE_HIT',
    9: 'DODGE_COUNTER_STRIKE',
    10: 'ENEMY_BLOW_APPLIED',
    11: 'YOUR_HIT_CRITICAL',
    12: 'NEXT_CRITICAL_HIT',
    13: 'BLOW_NOT_DODGED',
    14: 'TARGET_NEXT_TURN',
    15: 'CANCELS_TECHNIQUES',
    16: 'HITS_IGNORE',
    17: 'RESTORE_HP',
    18: 'NEXT_BLOW__PROTECTED',
    19: 'EXCHANGING_BLOWS_STEAL',
    20: 'RECONNAISSANCE_IN_FORCE',
    21: 'PUNCTURE_WOUNDS',
    22: 'BLOODLUST',
    23: 'DEEP_CUTS',
    24: 'SHOCKING_BLOW',
    25: 'FURY',
    26: 'FORTITUDE',
    27: 'KNOCK_OFF',
    28: 'STUN',
    29: 'LUCKY_STRIKE',
  },
  zodiacInfo: {
    1: 'StaminaAllyDeath',
    2: 'Phoenix',
    3: 'HpRestoreOnDamage',
    4: 'StaminaPowerAttack',
    5: 'DodgeRemoveNegativeEffect',
    6: 'DecreaseDamage',
    7: 'RuneSkipDecreaseCharge',
    8: 'Characters',
    9: 'Effect'
  },
};
