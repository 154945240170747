import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Lang from '../../language';
import InventoryHubMagazineBattleRes from './InventoryHubMagazineBattleRes';

// мок для теста, пока нет заданий
const battlePass = [
  {
    id: 1,
    title: 'Вход в игру',
    status: 'done',
  },
  {
    id: 2,
    title: 'Выиграть 3 боя используя топор',
    status: 'active',
  },
  {
    id: 3,
    title: 'Убить 5 демонов',
    status: 'active',
  },
];

const InventoryHubMagazine = () => {
  const user = useSelector((state) => state.allUserData.data);
  const battles = user ? user?.battle_results : null;
  const sortedBattlesByDate = battles
    ? battles.toSorted((a, b) => b.battle_created - a.battle_created)
    : [];
  const activeQuests = useSelector(
    (state) => state.allUserData.data.h_quests.quests,
  );
  const [visibleItems, setVisibleItems] = useState(5);
  const showMoreItems = () => {
    setVisibleItems((prev) => prev + 5);
  };
  const qst = useSelector((state) => state.allUserData.data.h_quests);

  useEffect(() => {
    console.log('activeQuests', activeQuests);
    console.log('qst', qst);
  }, [activeQuests]);

  return (
    <div className="inventory__container__hub--body--magazine">
      <div className="inventory__container__hub--body--magazine--container">
        <div className="inventory__container__hub--body--magazine--container--section">
          <h4 className="inventory__container__hub--body--magazine--container--section--title">
            <Lang>QUESTS</Lang>
          </h4>
          {activeQuests.length > 0 ? (
            <table className="inventory__container__hub--body--magazine--container--section--table">
              <tbody>
                {activeQuests.map((el, index) => (
                  <tr key={index}>
                    <td
                      className={`status-icon${
                        el.completed === true ? '--done' : ''
                      }`}
                    >
                      {el.completed === true ? '✓' : '•'}
                    </td>
                    <td>
                      <div
                        className={`inventory__container__hub--body--magazine--container--section--tableItem--quest`}
                      >
                        <Lang>{el.dict_id}</Lang>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              className={`inventory__container__hub--body--magazine--container--section--tableItem--quest--empty`}
            >
              - <Lang>NOTHING</Lang>
            </div>
          )}
        </div>
        <div className="inventory__container__hub--body--magazine--container--section">
          <h4 className="inventory__container__hub--body--magazine--container--section--title">
            <Lang>BATTLE_PASS</Lang>
          </h4>
          {battlePass ? (
            <table className="inventory__container__hub--body--magazine--container--section--table">
              <tbody>
                {battlePass.map((el, index) => (
                  <tr key={index}>
                    <td
                      className={`status-icon${
                        el.status === 'done' ? '--done' : ''
                      }`}
                    >
                      {el.status === 'done' ? '✓' : '•'}
                    </td>
                    <td>
                      <div
                        className={`inventory__container__hub--body--magazine--container--section--tableItem${
                          el.status === 'done' ? '--done' : ''
                        }`}
                      >
                        {el.title}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
        <div className="inventory__container__hub--body--magazine--container--section">
          <h4 className="inventory__container__hub--body--magazine--container--section--title">
            <Lang>LAST_BATTLES</Lang>
          </h4>
          {sortedBattlesByDate.length > 0 ? (
            <table className="inventory__container__hub--body--magazine--container--section--table">
              <tbody>
                {sortedBattlesByDate.slice(0, visibleItems).map((el, index) => (
                  <tr key={index}>
                    <td>
                      <div
                        className={`inventory__container__hub--body--magazine--container--section--tableItem`}
                      >
                        <InventoryHubMagazineBattleRes battleRes={el} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

          {visibleItems < sortedBattlesByDate.length && (
            <div>
              <button
                className="inventory__container__hub--body--magazine--container--section--table--btn"
                onClick={showMoreItems}
              >
                <Lang>SHOW</Lang>...
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryHubMagazine;
