function clearCookies(except = []) {
  const cookies = document.cookie.split('; ');
  for (let c of cookies) {
    const [name, _] = c.split('=');
    if (!except.includes(name)) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }
}

function setAppVersionCookie(version) {
  document.cookie = `appVersion=${version}; path=/;`;
}

function getAppVersionFromCookie() {
  const cookies = document.cookie.split('; ');
  for (let c of cookies) {
    const [name, value] = c.split('=');
    if (name === 'appVersion') {
      return value;
    }
  }
  return null;
}

function handleAppVersionChange(newVersion) {
  const currentVersion = getAppVersionFromCookie();
  if (currentVersion !== newVersion) {
    clearCookies(['jwt', 'email', 'psw']);
    setAppVersionCookie(newVersion);
    console.log('Версия приложения была изменена');
  }
}

export function checkAppVersionOnStart() {
  const newVersion = process.env.REACT_APP_VERSION;
  handleAppVersionChange(newVersion);
}
