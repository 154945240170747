import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDictionary } from '../../../hooks/useDictionary';
import InventoryHubThingsItem from '../../Inventory/InventoryHubThings/InventoryHubThingsItem';
import Tooltip from '../../tooltip/Tooltip';
import InventoryHubThingsItemBuffs from '../../Inventory/InventoryHubThings/InventoryHubThingsItemBuffs';
import InventoryHubThingsItemRun from '../../Inventory/InventoryHubThings/InventoryHubThingsItemRun';
import InventoryHubThingsItemOther from '../../Inventory/InventoryHubThings/InventoryHubThingsItemOther';
import BattleInitElixUse from './BattleInitElixUse';
import Images from '../../../helper/Images';
import imgCube from '../../../media/testcube.gif';
import Lang from '../../language';
import { useRequest } from '../../../hooks/useRequest';

const BattleInitStartMini = ({
  data,
  title,
  toHover,
  top,
  visibility,
  zIndex,
  toSetSlot,
  play,
  elixUse,
  elixAct,
}) => {
  const dropDownRef = useRef([]);
  const [dict, getInfoDictionary] = useDictionary();
  const [equipments, setEquipments] = useState([]);
  const userBody = useSelector((state) => state.allUserData.data.body);
  const battle_id = useSelector((state) => state.battle.data.battle.id);
  const [miniSlot, setMiniSlot] = useState(0);
  const [tooltip, setTooltip] = useState(null);
  const [requestSend] = useRequest();

  const toSlotSet = (slot) => {
    let tor = miniSlot;
    // let result
    // if (miniSlot.includes(slot)) {
    //     result = tor.filter((i) => i !== slot)
    // } else {
    //     result = [...tor, slot]
    // }
    let tox = miniSlot === slot ? 0 : slot;
    setMiniSlot(tox);
    toSetSlot(tox);
  };

  const handleClickContextMenu = (e, id, index) => {
    if (tooltip === id + index) {
      setTooltip(null);
    } else setTooltip(id + index);
  };

  const toOnClick = (data) => {
    requestSend.sendData('RequestBattleElixirUse', {
      battle_id: battle_id,
      item_slot_id: data.slot,
    });
    elixAct(true);
  };

  useEffect(() => {
    if (!play) {
      setMiniSlot(0);
      toSetSlot(0);
    }
  }, [play]);

  useEffect(() => {
    const toF = userBody.oper_slots.filter((i) => i.model);
    setEquipments(toF);
    setMiniSlot(0);
    toSetSlot(0);
  }, [userBody]);

  return (
    <div
      key={title}
      className={`battle__init--start--mini ${top ? 'battle__init--start--mini--top' : ''}`}
      style={{
        visibility: visibility ? 'visible' : 'hidden',
        zIndex: zIndex,
      }}
    >
      <div className="battle__init--start--mini--title">
        <Lang>{title}</Lang>
      </div>

      <div
        className={`battle__init--start--mini--list ${top ? 'battle__init--start--mini--list--top' : ''}`}
      >
        {equipments.map((i, index) => {
          const infoSkills = getInfoDictionary('equipments', i.model.dict_id);
          if (!dropDownRef.current[index]) {
            dropDownRef.current[index] = React.createRef();
          }

          return (
            <Tooltip
              maxWidth="350px"
              key={i.id + 'infoWeaponsBattle' + index}
              open={true}
              content={
                infoSkills.item_type <= 2 ? (
                  <InventoryHubThingsItemOther toItem={i.model} />
                ) : (
                  <InventoryHubThingsItemRun toItem={infoSkills} />
                )
              }
            >
              <div
                key={'minimm' + i.model.dict_id}
                className={`battle__init--start--mini--list--item ${miniSlot === i.slot ? 'battle__init--start--mini--list--item--active' : ''}`}
                onMouseEnter={() => toHover && toHover(i)}
                onMouseLeave={() => toHover && toHover(0)}
                onClick={() => {
                  if (
                    i.model.dict_type !== 13 &&
                    !i.model.dict_id.startsWith('elix')
                  ) {
                    toSlotSet(i.slot);
                  }
                }}
                onContextMenu={(e) => {
                  if (i.model.dict_id.startsWith('elix')) {
                    handleClickContextMenu(i, infoSkills.id, index);
                  }
                }}
                ref={dropDownRef.current[index]}
              >
                {/*<div className="battle__init--start--mini--list--item--anim"/>*/}
                <img
                  className="battle__init--start--mini--list--item--anim mm"
                  src={imgCube}
                  alt=""
                />
                <Images name={i.model.dict_id} />
                {/* {infoSkills.item_type <= 2 && ( */}
                <BattleInitElixUse
                  toRef={dropDownRef.current[index]}
                  open={tooltip === infoSkills.id + index}
                  setForcibly={() => setTooltip(null)}
                  onClick={() => {
                    toOnClick(i);
                  }}
                  disUse={elixUse}
                  type="skill"
                />
                {/* )} */}
              </div>
            </Tooltip>
          );
        })}
        {Array.from(
          Array(userBody.oper_slots.length - equipments.length).keys(),
        ).map((value, index) => (
          <div
            key={'opo' + index}
            className="battle__init--start--mini--list--item"
          >
            {/*<div className="battle__init--start--mini--list--item--anim"/>*/}
            <img
              className="battle__init--start--mini--list--item--anim mm"
              src={imgCube}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BattleInitStartMini;
