/* eslint-disable */
import './App.scss';
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { routerAuth } from './router/router';
import { useDispatch, useSelector } from 'react-redux';
import { useManagerGame } from './manager/managerGame';
import { cookieGet, cookieSet, cookieDel } from './helper/cookie';
import { setConnection } from './store/reducer/Slices';
import { redirect } from './helper/helpers';
import { useRequest } from './hooks/useRequest';
import TooltipInit from './components/tooltip/TooltipInit';
import { getInfo, getNpcDialogues } from './api/checking';
import { setActiveLang, setListLang } from './store/reducer/Slices/SlicesLang';
import { getLang } from './components/language/groups';
import { CentrifugeSingleton } from './helper/centrifugeSingleton';
import AdmPanel from './components/npc/Admpanel/AdmPanel';

function App() {
  const dispatch = useDispatch();
  const jwt = cookieGet('jwt');
  const [command] = useManagerGame();
  const [requestSend, applications] = useRequest();
  const langActive = useSelector((state) => state.language.langActive);
  const languages = useSelector((state) => state.language.lang);
  const centrifugeInit = CentrifugeSingleton.getInstance(jwt);

  // if (window.location.pathname === '/game' || window.location.pathname === '/welcome' || window.location.pathname === '/new-character' || window.location.pathname === '/choose-character') {
  // }

  useEffect(() => {
    // if (window.location.pathname === '/game' || window.location.pathname === '/welcome' || window.location.pathname === '/new-character' || window.location.pathname === '/choose-character' || window.location.pathname === '/') {
    if (jwt) {
      if (
        window.location.pathname === '/welcome' ||
        window.location.pathname === '/'
      ) {
        redirect('/choose-character');
      }
      centrifugeInit
        .on('connecting', function (ctx) {
          // if (ctx.reason === 'connect called') {
          //     cookieDel('jwt')
          //     redirect('/welcome')
          // }
          if (ctx.reason === 'connect called') {
            requestSend.sendChanel(centrifugeInit);
          }
          // centrifugeInit.on('publication', function (ctx) {
          //     console.log('from main chanel', ctx)
          // })
        })
        .on('connected', function (ctx) {
          // console.log('ctx', ctx)
          command(ctx.data[0]);
          if (
            ctx.data[0]?.user_main?.u_info?.hero_id === '' &&
            window.location.pathname !== '/new-character'
          ) {
            redirect('/choose-character');
            dispatch(setConnection(centrifugeInit));
          } else if (ctx.data[0]?.eid === 1) {
            cookieDel('jwt');
            redirect('/welcome');
          } else {
            dispatch(setConnection(centrifugeInit));
          }
        })
        .on('disconnected', function (ctx) {
          // cookieDel('jwt')
          // redirect('/welcome')
          console.log('disconnected ctx', ctx);
        })
        .connect();
    } else {
      if (
        window.location.pathname === '/game' ||
        window.location.pathname === '/welcome' ||
        window.location.pathname === '/choose-character' ||
        window.location.pathname === '/'
      ) {
        redirect('/welcome');
      }
    }
    // }
  }, [jwt]);

  useEffect(() => {
    cookieSet('lang', langActive, 99999);
    getLang(langActive).then((e) => dispatch(setListLang(e)));
  }, [langActive]);

  useEffect(() => {
    getInfo(dispatch);
    getNpcDialogues(dispatch);
    const fromLang = cookieGet('lang');
    if (fromLang) {
      dispatch(setActiveLang(fromLang));
      getLang(fromLang).then((e) => dispatch(setListLang(e)));
    } else {
      cookieSet('lang', langActive, 99999);
      getLang(langActive).then((e) => dispatch(setListLang(e)));
    }
  }, []);

  return (
    <>
      <RouterProvider router={routerAuth} />
      <div className="ver">
        v. {process.env.REACT_APP_VERSION}
        {/*<AdmPanel />*/}
      </div>
      {/*<select*/}
      {/*  name="lang"*/}
      {/*  className="lang"*/}
      {/*  value={langActive}*/}
      {/*  onChange={(e) => dispatch(setActiveLang(e.target.value))}*/}
      {/*>*/}
      {/*  {languages.map((language) => (*/}
      {/*    <option key={language} value={language}>*/}
      {/*      {language}*/}
      {/*    </option>*/}
      {/*  ))}*/}
      {/*</select>*/}
      <TooltipInit />
    </>
  );
}

export default App;
