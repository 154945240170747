import React from 'react';
import { redirect } from '../helper/helpers';
import { cookieDel } from '../helper/cookie';
import { setConnection } from '../store/reducer/Slices';
import { useDispatch } from 'react-redux';

const useManagerGameError = () => {
  const dispatch = useDispatch();

  const emailExists = (e) => {
    const errors = new Map([
      [0, () => console.log('')],
      [
        1,
        () => {
          cookieDel('jwt').then(() => {
            dispatch(setConnection(null));
            redirect('/welcome');
          });
        },
      ],
      [2, () => console.log('')],
      [3, () => console.log('')],
      [4, () => console.log('')],
    ]);
    const errorHandler = errors.get(e.exists);
    if (errorHandler) errorHandler();
  };

  const typeErrors = new Map([[1, emailExists]]);

  const setErrorHandler = (error) => {
    const errorHandler = typeErrors.get(error.$type);
    if (errorHandler) errorHandler(error);
  };

  return { setErrorHandler };
};

export default useManagerGameError;
