import { createSlice } from '@reduxjs/toolkit';

const groups = createSlice({
  name: 'groups',
  initialState: {
    invites: {},
    heroGroup: {
      group_heroes: [],
    },
  },
  reducers: {
    addGroup(state, { payload }) {
      state.heroGroup = payload;
    },
    resetGroup(state) {
      state.heroGroup = {};
    },
    updateHeroes(state, { payload }) {
      state.heroGroup.group_heroes = payload;
    },
    addInvite(state, { payload }) {
      state.invites[payload.group_id] = payload;
    },
    removeInvite(state, { payload }) {
      delete state.invites[payload.group_id];
    },
    resetInvites(state) {
      state.invites = {};
    },
  },
});

export const {
  addGroup,
  updateHeroes,
  resetGroup,
  resetInvites,
  addInvite,
  removeInvite,
} = groups.actions;

export { groups };
