import React from 'react';
import '../styles.scss';
import { useDispatch } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import { setOpenApplication } from '../../../store/reducer/Slices/SlicesApplicationOpen';
import Lang from '../../language';
import imgBackItem from '../../../media/img/game/playingField/map/loc1/backItem.png';
import imgMapBack from '../../../media/img/game/playingField/map/loc4/map.png';
import imgLager from '../../../media/img/game/playingField/map/loc4/lager.png';
import imgHram from '../../../media/img/game/playingField/map/loc4/hram.png';
import imgKamen from '../../../media/img/game/playingField/map/loc4/kamen.png';
import imgBack from '../../../media/img/game/playingField/map/loc4/lock4B.jpg';
import { setOpenMap } from '../../../store/reducer/Slices/SlicesMap';
import LocationElement from '../LocationElement';
import { setNpcOpen } from '../../../store/reducer/Slices/SliceNpc';
import { setEnterModal } from '../../../store/reducer/Slices/SlicesDungeon';

const Location4 = () => {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();

  const locations = {
    backImg: imgBack,
    items: [
      {
        name: 'Камень',
        img: {
          top: '5.8vw',
          left: '21.5vw',
          width: '10.2vw',
          src: imgKamen,
          srcBack: imgBackItem,
          backBottom: '1vw',
        },
        onClick: () => {
          dispatch(setOpenApplication(1));
        },
      },
      {
        name: 'Храм',
        img: {
          top: '14.6vw',
          left: '38vw',
          width: '11.4vw',
          src: imgHram,
          srcBack: imgBackItem,
        },
        style: {
          width: '13vw',
          height: '18vh',
        },
        onClick: () => {
          dispatch(
            setEnterModal({
              open: true,
              building_id: 'bld.9',
            }),
          );
        },
      },
      {
        name: 'Лагерь',
        img: {
          top: '23vw',
          left: '23vw',
          width: '20.2vw',
          src: imgLager,
          srcBack: imgBackItem,
        },
        style: {
          position: 'relative',
          bottom: '4.5vh',
          left: '1.5vw',
          width: '17vw',
          height: '18vh',
        },
        onClick: () => {
          requestSend.sendData('RequestBuildingMove', {
            build_id: 'bld.10',
          });
          dispatch(setNpcOpen(true));
        },
      },
      {
        name: 'Карта',
        img: {
          top: '25.2vw',
          left: '48vw',
          width: '7.2vw',
          src: imgMapBack,
          srcBack: imgBackItem,
        },
        onClick: () => dispatch(setOpenMap(true)),
      },
    ],
  };

  return (
    <div className="locationIn">
      <div className="locationIn__back">
        <img src={locations.backImg} alt="" />
      </div>
      <div className="locationIn__container">
        {/* <div className="locationIn__container--buttons">
          <UiButtonOne
            type="middle"
            onClick={() => {
              dispatch(setOpenApplication(1));
            }}
          >
            <Lang>LIST_OF_APPLICATIONS</Lang>
          </UiButtonOne>
          <UiButtonOne
            type="middle"
            onClick={() => {
              requestSend.sendData('RequestBattleRequestPvpBot');
            }}
          >
            <Lang>FIGHT_WITH_A_BOT</Lang>
          </UiButtonOne>
        </div> */}
        {locations.items.map((i, index = index + 1) => (
          <LocationElement index={index} i={i} key={i.name} />
        ))}
      </div>
    </div>
  );
};

export default Location4;
