import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5-hash';
import { useNavigate } from 'react-router-dom';
import { setConnection } from '../store/reducer/Slices';
import { useState } from 'react';
import { Centrifuge } from 'centrifuge';
import { cookieGet, cookieSet } from '../helper/cookie';
import { RequestCommand, ResponseLogin } from '../helper/reqRspName';
import { redirect } from '../helper/helpers';
import { routConnection } from '../api/routeConnection';
const connectionUrl = routConnection(process.env.REACT_APP_RUN);

export const useAuth = (email) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  // const [centrifuge, setCentrifuge] = useState(null)
  const [emailStatus, setEmailStatus] = useState(null);

  let centrifuge = useSelector((state) => state.connectionApi.data);

  const centrifugeInit = new Centrifuge(connectionUrl, {
    data: {
      $type: RequestCommand.RequestEmailExists,
      email: email && email,
    },
  });

  const statusCheckEmail = (exists) => {
    switch (exists) {
      case ResponseLogin.NotExists:
        history('/new-character');
        return false;
      case ResponseLogin.Exists:
        return true;
      default:
        break;
    }
  };

  const checkEmail = () => {
    centrifugeInit
      .on('connected', function (ctx) {
        dispatch(setConnection(centrifugeInit));
        setEmailStatus(statusCheckEmail(ctx.data[0].exists));
      })
      .connect();
  };

  async function login(email, psw) {
    let vv;
    const toPsw = md5(psw);
    await centrifuge
      .rpc(RequestCommand.RequestAuthByEmail.toString(), {
        $type: RequestCommand.RequestAuthByEmail,
        email,
        psw: toPsw,
      })
      .then(
        async function (res) {
          if (res.data[0].jwt) {
            cookieSet('jwt', res.data[0].jwt, 8760);
          }
          vv = await res.data[0];
        },
        function (err) {
          return err;
        },
      );
    return vv;
  }

  const registration = async (name, pid, hid) => {
    const email = cookieGet('email');
    let vv;

    if (email) {
      await centrifuge
        .rpc(RequestCommand.RequestRegisterByEmail.toString(), {
          $type: RequestCommand.RequestRegisterByEmail,
          email,
          name,
          pid,
          hid,
          locale: 'ru',
        })
        .then(
          async function (res) {
            if (res.data[0].jwt) {
              cookieSet('jwt', res.data[0].jwt, 8760);
              cookieSet('psw', res.data[0].psw, 8760); // Мок убрать когда будет настроен SMTP
              redirect('/choose-character');
            }
            vv = await res.data[0];
          },
          function (err) {
            redirect('/welcome');
          },
        );
    } else {
    }
    return vv;
  };

  return [checkEmail, login, emailStatus, registration];
};
