import HeroNameLink from '../../HeroNameLink/HeroNameLink';
import Lang from '../../../language';
import { useDictionary } from '../../../../hooks/useDictionary';
import Tooltip from '../../../tooltip/Tooltip';
import React from 'react';
import InventoryHubSkillsItem from '../../../Inventory/InventoryHubSkills/InventoryHubSkillsItem';
import Abil from '../Abil';

export default function Templ1({ isUser, message, msgDamageSize, dmg }) {
  const [dict, getInfoDictionary] = useDictionary();
  const infoWeapons =
    message.data?.$type === 35
      ? getInfoDictionary('equipments', message.data?.spell_id)
      : '';

  return (
    <div className="toChat">
      <HeroNameLink userHero={message?.a_her?.hero_id} isUser={isUser}>
        {message?.a_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_TRIED_TO_ATTACK_HOWEVER</Lang>{' '}
      <HeroNameLink userHero={message?.d_her?.hero_id} isUser={!isUser}>
        {message?.d_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_MANAGED_TO_PLACE_A_BLOCK</Lang>{' '}
      {message.data?.$type === 35 && (
        <>
          <Lang>USE_I</Lang>
          <Tooltip
            key={Math.random()}
            zIndex={9990}
            content={
              <InventoryHubSkillsItem
                fromItem={infoWeapons}
                toItem={infoWeapons}
              />
            }
          >
            <div
              className={`chat__block1--body--message--item--text--item rrC-${infoWeapons?.quality}`}
            >
              <Lang>{message.data?.spell_id}</Lang>
            </div>
          </Tooltip>
          {message?.data?.hp > 0 && (
            <>
              <Lang>AND_CURED</Lang>{' '}
              <div className="chat__block1--body--message--item--text--hill">
                +{message?.data?.hp} HP
              </div>
            </>
          )}
          {message?.data?.a_damag > 0 && (
            <>
              <Lang>AND_APPLIED</Lang>{' '}
              <div className="chat__block1--body--message--item--text--hp">
                -{message?.data?.a_damag} HP
              </div>
            </>
          )}
          <Abil message={message} />
        </>
      )}
    </div>
  );
}
