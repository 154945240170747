import { createSlice } from '@reduxjs/toolkit';

const checkName = createSlice({
  name: 'checkName',
  initialState: {
    check: {
      name: '',
      exists: false,
    },
  },
  reducers: {
    setCheckName(state, action) {
      state.check = action.payload;
    },
  },
});

export const { setCheckName } = checkName.actions;

export { checkName };
