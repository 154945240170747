import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import ActiveBar from '../activeBar/ActiveBar';
import { useRequest } from '../../hooks/useRequest';
import Tooltip from '../tooltip/Tooltip';
import { setBattleData } from '../../store/reducer/Slices/SlicesBattle';

const BattleList = ({ choice }) => {
  const [requestSend] = useRequest();
  const dispatch = useDispatch();
  const battleStart = useSelector((state) => state.battle.data);
  const userMain = useSelector((state) => state.allUserData.user_main);
  const pairCurrent = useSelector((state) => state.battleCurrent.pairCurrent);
  const pairList = useSelector((state) => state.battleCurrent.pairList);
  const [stat, setStat] = useState({ now: 0, all: 0 });
  const chat = useSelector((state) => state.chatData.data);

  const [list, setList] = useState([]);
  const [listMeKick, setListMeKick] = useState([]);

  useEffect(() => {
    if (
      chat.length > 0 &&
      chat[0]?.data?.$type === 35 &&
      chat[0]?.data?.heal_hp > 0
    ) {
      const { d_her_id, hp } = chat[0]?.data;
      if (d_her_id && hp !== undefined && choice === 1) {
        const updatedBattleStart = { ...battleStart };
        updatedBattleStart.party1 = updatedBattleStart.party1.map((el) =>
          el.hero_id === d_her_id ? { ...el, hp: hp } : el,
        );
        dispatch(setBattleData(updatedBattleStart));
      }
    }
  }, [chat]);

  const filter = (val) => {
    let toListSort = [];
    // if (listMeKick.length > 0) {
    //     toListSort = val.sort((x,y) => x.hero_id === pairCurrent.hero_id ? -1 : y.hero_id === pairCurrent.hero_id ? 1 : 0);
    // } else {
    toListSort = val.sort((i, y) => (i.power > y.power ? -1 : 1));
    // }
    const tiListFilter = toListSort.filter((i) => i.is_dead !== true);
    setStat({ now: tiListFilter.length, all: val.length });
    setList(tiListFilter);
  };

  useEffect(() => {
    // console.log('pairList', pairList)
    if (Object.keys(pairList) > 0) {
      let hero = list.find(
        (e) => e.hero_id === pairList[pairList.length - 1].hero_id,
      );
      // console.log('listMeKick', hero)
      setListMeKick((prev) => [...prev, hero]);
    }
  }, [pairList]);

  useEffect(() => {
    console.log('battleStart', battleStart);
    const myBattle =
      battleStart.party1.find((i) => i.user_id === userMain.u_info.id)
        ?.party_id ||
      battleStart.party2.find((i) => i.user_id === userMain.u_info.id)
        ?.party_id;

    if (myBattle === choice && battleStart) {
      filter([...battleStart.party1]);
    } else filter([...battleStart.party2]);
  }, [battleStart]);

  const toSend = (val) => {
    // console.log('val', val)
    requestSend.sendData('RequestBattleChangePair', {
      battle_id: val.battle_id,
      pair_id: pairCurrent.hero_id,
      new_pair_id: val.hero_id,
    });
  };

  return (
    <div className="battle--list">
      <div className="battle--list--title">
        {choice === 1 ? 'Союзники' : 'Противники'}{' '}
        <div>
          {stat.now}/{stat.all}
        </div>
      </div>
      <div className="battle--list--box">
        {/*{*/}
        {/*    listMeKick.length > 0 && listMeKick.map((i) => (*/}
        {/*        <Tooltip*/}
        {/*            key={'contMe' + i.name}*/}
        {/*            zIndex={9990}*/}
        {/*            maxWidth="450px"*/}
        {/*            automatic={false}*/}
        {/*            content={<>asds</>}*/}
        {/*            // onClick={() => toSend(i)}*/}
        {/*        >*/}
        {/*            <div*/}
        {/*                className="battle--list--box--item--checkPair"*/}
        {/*            >*/}
        {/*                <div className="battle--list--box--item--num">*/}
        {/*                    {i.glory}*/}
        {/*                </div>*/}
        {/*                <div className="battle--list--box--item--info">*/}
        {/*                    <div className="battle--list--box--item--info--name">*/}
        {/*                        {i.name}*/}
        {/*                    </div>*/}
        {/*                    <ActiveBar*/}
        {/*                        classOne="battle--list--box--item--info--line"*/}
        {/*                        classTwo="battle--list--box--item--info--line--active"*/}
        {/*                        one={i.hp}*/}
        {/*                        two={i.max_hp}*/}
        {/*                        check={{plus: 'grin', minus: 'red'}}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </Tooltip>*/}
        {/*    ))*/}
        {/*}*/}
        {list.map((i) => (
          <Tooltip
            key={'cont' + i.name}
            zIndex={9990}
            maxWidth="450px"
            automatic={false}
            content={<>asds</>}
            // onClick={() => toSend(i)}
          >
            <div className="battle--list--box--item">
              <div className="battle--list--box--item--num">{i.glory}</div>
              <div className="battle--list--box--item--info">
                <div className="battle--list--box--item--info--name">
                  {i.name.split('$')[0]}
                </div>
                <ActiveBar
                  classOne="battle--list--box--item--info--line"
                  classTwo="battle--list--box--item--info--line--active"
                  one={i.hp}
                  two={i.max_hp}
                  check={{ plus: 'grin', minus: 'red' }}
                />
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default BattleList;
