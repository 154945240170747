import React, { useEffect, useState } from 'react';
import { useRequest } from '../../hooks/useRequest';
import { useDispatch, useSelector } from 'react-redux';
import imgClose from '../../media/img/app/close.png';
import {
  setBaseModal,
  setBaseModalData,
} from '../../store/reducer/Slices/SliceModal';
import TimerInit from '../timerInit/TimerInit';
import { setOpenApplication } from '../../store/reducer/Slices/SlicesApplicationOpen';
import { loadersSpin1 } from '../loaders/loaders';
import { RequestBattleTypeInvert } from '../../helper/reqRspName';
import Lang from '../language';

const spin = loadersSpin1;

const ApplicationButton = ({ tab, tabInfo }) => {
  const dispatch = useDispatch();
  const userBattle = useSelector(
    (state) => state.allUserData.data.h_battle_req,
  );
  const applications = useSelector((state) => state.applicationsList.list);
  const [now, setNow] = useState(1);
  const [toTab, setToTab] = useState('bat_req_pvp');
  const [requestSend] = useRequest();
  const [toApplications, setToApplications] = useState([]);
  const userMain = useSelector((state) => state.allUserData.user_main.u_info);
  const heroGroup = useSelector((state) => state.groups.heroGroup);
  const [onlyMy, setOnlyMy] = useState(false);
  const userGloryLevel = useSelector(
    (state) => state.allUserData.data.level.glory,
  );

  useEffect(() => {
    if (now === 1) setToTab(tab);
  }, [tab, now]);

  useEffect(() => {
    if (userBattle.request_id.length > 0) {
      setNow(2);
    } else setNow(1);
  }, [userBattle]);

  useEffect(() => {
    if (applications[toTab] && applications[toTab].length !== 0) {
      let a = applications[toTab].find((i) => i.id === userBattle.request_id);
      if (a) setToApplications(a);
    }
  }, [applications, userBattle]);

  const createBattle = (name, type) => {
    const result = {
      zIndex: 9,
      styles: 'mini',
      title: `APP_CREATION ${name} APP_BATTLE`,
      numData: 2,
      type,
    };

    dispatch(setBaseModalData(result));
    dispatch(setBaseModal(true));
  };

  useEffect(() => {
    if (toApplications.status === 3) {
      dispatch(setOpenApplication(0));
      const result = {
        zIndex: 9,
        styles: 'nota',
        title: `APP_THE_FIGHT_BEGINS !`,
        numData: 3,
        toApplications,
      };

      dispatch(setBaseModalData(result));
      dispatch(setBaseModal(true));
    }
  }, [toApplications.status]);

  switch (toTab) {
    case 'bat_req_pvp':
      return (
        <>
          {userBattle.request_id.length > 0 && toApplications.status > 0 ? (
            <div className="application__two--box">
              <div className="application__two--box--load">{spin}</div>
              <div className="application__two--box--text">
                <div className="application__two--box--text--title">
                  <Lang>WE_ARE_WAITING_FOR_THE_ENEMY</Lang>...
                </div>
                <div className="application__two--box--text--desc">
                  <Lang>{tabInfo[0].title}</Lang>
                </div>
              </div>
              {toApplications.status !== 3 && (
                <div
                  className="application--button"
                  onClick={() => {
                    requestSend.sendData('RequestBattleRequestsCancel', {
                      $type: 20,
                      id: userBattle.request_id,
                      request_type: RequestBattleTypeInvert[tab],
                    });
                    setOnlyMy(false);
                  }}
                >
                  <Lang>CANCEL</Lang>
                </div>
              )}
            </div>
          ) : (
            <>
              <div
                className="application__two--button"
                onClick={() => {
                  if (!onlyMy) {
                    requestSend.sendData('RequestBattleRequestCreate', {
                      $type: 17,
                      request_type: 1,
                    });
                  } else {
                    requestSend.sendData('RequestBattleRequestCreate', {
                      $type: 17,
                      request_type: 1,
                      glory_level_cup: userGloryLevel,
                    });
                  }
                }}
              >
                <Lang>TO_CHALLENGE</Lang>
              </div>
              <div className="application__two--button--check">
                <label>
                  <input
                    onChange={(e) => setOnlyMy(e.target.checked)}
                    value={onlyMy}
                    type="checkbox"
                  />
                  <Lang>MY_GLORY_LVL_REQUEST</Lang>
                </label>
              </div>
            </>
          )}
        </>
      );
    case 'bat_req_group':
      return (
        <>
          {userBattle.request_id.length > 0 && toApplications.status > 0 ? (
            <div className="application__two--box">
              <div className="application__two--box--load">{spin}</div>
              <div className="application__two--box--text">
                <div className="application__two--box--text--title">
                  <Lang>WE_ARE_WAITING_FOR_THE_ENEMY</Lang>...
                </div>
                <div className="application__two--box--text--desc">
                  <Lang>{tabInfo[1].title}</Lang>
                </div>
              </div>
              {toApplications.status !== 3 && (
                <div
                  className="application--button"
                  onClick={() => {
                    requestSend.sendData('RequestBattleRequestsCancel', {
                      $type: 20,
                      id: userBattle.request_id,
                      request_type: RequestBattleTypeInvert[tab],
                    });
                  }}
                >
                  <Lang>CANCEL</Lang>
                </div>
              )}
            </div>
          ) : (
            <>
              {userMain.hero_id === heroGroup.creator_id ? (
                <div
                  className="application__two--button"
                  onClick={() => {
                    // createBattle('SQUAD_TO_SQUAD', 2);
                    requestSend.sendData('RequestBattleRequestCreate', {
                      $type: 17,
                      request_type: 2,
                    });
                  }}
                >
                  <Lang>CREATE_A_FIGHT</Lang>
                </div>
              ) : (
                <div className="application__two--box">
                  <div className="application__two--box--load">
                    <img src={imgClose} alt="" />
                  </div>
                  <div className="application__two--box--text">
                    <div className="application__two--box--text--title">
                      <Lang>ATTENTION</Lang>!
                    </div>
                    <div className="application__two--box--text--desc">
                      <Lang>ONLY_THE_SQUAD_LEADER_CAN_ISSUE</Lang>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      );
    case 'bat_req_random':
      return (
        <>
          {userBattle.request_id.length > 0 && toApplications.status > 0 ? (
            <div className="application__two--box">
              <div className="application__two--box--load">{spin}</div>
              <div className="application__two--box--text">
                <div className="application__two--box--text--title">
                  <Lang>PARTICIPANTS</Lang> {toApplications.members_count} /{' '}
                  {toApplications.max_members}
                </div>
                <div className="application__two--box--text--desc">
                  <TimerInit
                    next_status_time={toApplications.next_status_time}
                  />
                  <Lang>{tabInfo[2].title}</Lang>
                </div>
              </div>
              {toApplications.status !== 3 && (
                <div
                  className="application--button"
                  onClick={() => {
                    requestSend.sendData('RequestBattleRequestsCancel', {
                      $type: 20,
                      id: userBattle.request_id,
                      request_type: RequestBattleTypeInvert[tab],
                    });
                  }}
                >
                  <Lang>CANCEL</Lang>
                </div>
              )}
            </div>
          ) : (
            <div
              className="application__two--button"
              onClick={() => {
                createBattle('APP_CHAOTIC', 3);
              }}
            >
              <Lang>CREATE_A_FIGHT</Lang>
            </div>
          )}
        </>
      );
    case 'bat_req_himself':
      return (
        <>
          {userBattle.request_id.length > 0 && toApplications.status > 0 ? (
            <div className="application__two--box">
              <div className="application__two--box--load">{spin}</div>
              <div className="application__two--box--text">
                <div className="application__two--box--text--title">
                  <Lang>PARTICIPANTS</Lang> {toApplications.members_count} /{' '}
                  {toApplications.max_members}
                </div>
                <div className="application__two--box--text--desc">
                  <TimerInit
                    next_status_time={toApplications.next_status_time}
                  />
                  <Lang>{tabInfo[2].title}</Lang>
                </div>
              </div>
              {toApplications.status !== 3 && (
                <div
                  className="application--button"
                  onClick={() => {
                    requestSend.sendData('RequestBattleRequestsCancel', {
                      $type: 20,
                      id: userBattle.request_id,
                      request_type: RequestBattleTypeInvert[tab],
                    });
                  }}
                >
                  <Lang>CANCEL</Lang>
                </div>
              )}
            </div>
          ) : (
            <div
              className="application__two--button"
              onClick={() => {
                createBattle('APP_EVERY_MAN_FOR_HIMSELF', 4);
              }}
            >
              <Lang>CREATE_A_FIGHT</Lang>
            </div>
          )}
        </>
      );
    default:
      break;
  }
};

export default ApplicationButton;
