import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../components/modal/Modal';
import { GroupInviteProcessType } from '../../../helper/reqRspName';
import { useRequest } from '../../../hooks/useRequest';
import { removeInvite } from '../../../store/reducer/Slices/SliceGroups';
import Lang from '../../../components/language';

const modalProps = {
  title: 'INVITE_TO_GROUP',
};

export const GroupInviteModal = () => {
  const [showInvitesModal, setShowInvitesModal] = useState(true);
  const invites = useSelector((state) => state.groups.invites);
  const dispatch = useDispatch();
  const [requestSend] = useRequest();
  const [creatorName, setCreatorName] = useState(null);

  useEffect(() => {
    if (invites && Object.keys(invites).length > 0) {
      let firstKey = invites ? Object.keys(invites)[0] : null;
      let { creator_id: creatorId, group_heroes: groupHeroes } =
        invites[firstKey];
      let creator = groupHeroes.find((hero) => hero.hero_id === creatorId);
      setCreatorName(creator.name);
    }
  }, [invites]);

  useEffect(() => {
    setShowInvitesModal(!isEmpty(invites));
  }, [invites]);

  const handleAccept = async () => {
    const firstInvite = Object.values(invites)[0];

    await requestSend.sendData('RequestGroupInviteProcess', {
      answer: GroupInviteProcessType.Accept,
      group_id: firstInvite.group_id,
    });

    dispatch(removeInvite(firstInvite));
  };

  const handleClose = async () => {
    const firstInvite = Object.values(invites)[0];

    await requestSend.sendData('RequestGroupInviteProcess', {
      answer: GroupInviteProcessType.Decline,
      group_id: firstInvite.group_id,
    });

    dispatch(removeInvite(firstInvite));
  };

  const handleBan = async () => {
    const firstInvite = Object.values(invites)[0];

    await requestSend.sendData('RequestGroupInviteProcess', {
      answer: GroupInviteProcessType.Ban,
      group_id: firstInvite.group_id,
    });

    dispatch(removeInvite(firstInvite));
  };

  return (
    <Modal
      show={showInvitesModal}
      styles="modal_invite"
      props={{
        ...modalProps,
        buttonGray: { text: 'AGREE' },
        buttonRed: { text: 'NO' },
        buttonDecline: { text: 'IGNORE' },
      }}
      data={
        <>
          <div
            style={{
              color: '#d5d5d5',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Lang>FROM</Lang> {creatorName}
          </div>
        </>
      }
      buttonDecline={handleBan}
      buttonGray={handleAccept}
      buttonRed={handleClose}
    />
  );
};
