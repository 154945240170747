import HeroNameLink from '../HeroNameLink/HeroNameLink';
import imgMini1 from '../../../media/img/game/character/miniInfo/mini1.png';
import { useTranslate } from '../../language/action/line';
import Lang from '../../language';

const ChatMessageBattleEnd = ({ message, userHero }) => {
  const isUser = userHero === message.data.dest_hero_id;
  const [langTranslate] = useTranslate(message.text);

  const renderHeroLinks = (names, party, isUser) => {
    const namesInt = names.split(',');
    return namesInt.map((name, index) => {
      const hero = party.find((hero) => hero.name === name.trim());
      return (
        <>
          <HeroNameLink
            key={hero.hero_id}
            userHero={hero.hero_id}
            isUser={isUser}
          >
            {name.trim()}
          </HeroNameLink>{' '}
          {index < namesInt.length - 1 ? ', ' : ''}
        </>
      );
    });
  };

  return (
    <div key={message.time} className="chat__block1--body--message--item">
      <div className="chat__block1--body--message--item--time">
        {message.time}
      </div>
      <div className="chat__block1--body--message--item--text">
        <span style={{ whiteSpace: 'nowrap' }}>{langTranslate} - </span>
        {renderHeroLinks(message.allies, message.data.party1, !isUser)}
        <Lang>AND</Lang>{' '}
        {renderHeroLinks(message.opponents, message.data.party2, isUser)}
        {/* <HeroNameLink
          userHero={message.data.party1[0].hero_id}
          isUser={!isUser}
        >
          {message.allies}
        </HeroNameLink>
        <Lang>AND</Lang>
        <HeroNameLink userHero={message.data.party2[0].hero_id} isUser={isUser}>
          {message.opponents}
        </HeroNameLink> */}
      </div>
    </div>
  );
};

export default ChatMessageBattleEnd;
