import Templ1 from '../templates/Block/Templ1';
import Templ1_Parry from '../templates/Parry/Templ1';
import Templ1_Dodge from '../templates/Dodge/Templ1';
import TemplElix from '../templates/Block/TemplElix';

export default function ChatMessageBlock(props) {
  let Tmpl = Templ1;

  if (props.message.data.d_dodge) {
    Tmpl = Templ1_Dodge;
  }

  if (props.message.data.d_parry) {
    Tmpl = Templ1_Parry;
  }

  if (props.msgWeapon === 'SPELL') {
    Tmpl = TemplElix;
  }

  return <Tmpl {...props} />;
}
