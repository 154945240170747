import {
  getFormatText,
  getFormatTextBattle,
  getFormatTextChat,
  replaceProperties,
} from '../helper/helpers';
import { RequestBattleType } from '../helper/reqRspName';

const dataReplace = (obj, replacers = {}, storeMap = new Map()) => {
  if (storeMap.has(obj)) {
    return storeMap.get(obj);
  }

  const type = typeof obj;

  if (obj === null || (type !== 'object' && type !== 'function')) {
    return obj;
  }

  let result;
  if (type === 'function') {
    result = function () {
      return obj.apply(this, arguments);
    };
    try {
      Object.defineProperties(result, {
        name: Object.getOwnPropertyDescriptor(obj, 'name'),
        length: Object.getOwnPropertyDescriptor(obj, 'length'),
      });
    } catch {}
  } else if (Array.isArray(obj)) {
    result = obj.map((value) => dataReplace(value, replacers, storeMap));
    storeMap.set(obj, result);
    return result;
  } else {
    result = Object.setPrototypeOf({}, Object.getPrototypeOf(obj));
  }

  storeMap.set(obj, result);

  for (const key of Object.getOwnPropertyNames(obj).concat(
    Object.getOwnPropertySymbols(obj),
  )) {
    if (typeof replacers[key] === 'function') {
      result[key] = replacers[key](obj[key]);
    } else {
      result[key] = dataReplace(obj[key], replacers, storeMap);
    }
  }

  return result;
};

export const Changer = {
  lvlExp: (data, userData, other) => {
    let level = {
      ...userData.level,
      glory: data.level.glory,
      exp: data.level.exp,
      level: data.level.level,
      free_points: data.level.free_points,
    };

    const toChat = other
      ? getFormatText(
          other,
          other.cheat === 'add_experience' ? 'PLUS_EXPERIENCE' : 'PLUS_LEVEL',
          other.val,
        )
      : '';

    return other
      ? { result: level, toChat: { ...toChat, type: data.$type } }
      : { result: level };
  },
  toMoney: (data, userData, other) => {
    let current_money = {
      ...userData['money']['current_money'],
      1: data['money']['current_money'][1],
      2: data['money']['current_money'][2],
    };

    const toChat = other
      ? getFormatText(
          other,
          other.cheat === 'add_money' ? 'PLUS_DINARS' : 'PLUS_ETHEREUM',
          other.val,
        )
      : '';

    return other
      ? {
          result: { ...userData['money'], current_money },
          toChat: { ...toChat, type: data.$type },
        }
      : { result: { ...userData['money'], current_money } };
  },
  toCharacters: (data, userData) => {
    const newList = userData['char']['characters'].map((o) => {
      let toR = o;
      data.characters.forEach((i) => {
        if (o.char === i.char) {
          toR = i;
        }
      });
      return toR;
    });

    let from = {
      $type: 11,
      characters: newList,
      id: userData['char']['id'],
    };

    // const newData = dataReplace(userData["char"], {
    //     characters: () => newList,
    // });

    return { result: from };
  },
  toInventoryUpdate: (data, userData) => {
    let re = userData['invent']['invent_equipments'].filter(
      (elem, idx) => !data.equip_rmv.includes(elem.id),
    );
    let invent_equipments = re ? re : [];
    data.equip_upd.forEach((i) => {
      let ItemIndex = invent_equipments.findIndex((el) => el.id === i.id);
      if (ItemIndex === -1) {
        invent_equipments.push(i);
      } else {
        let existItem = invent_equipments[ItemIndex];
        if (i.charge !== undefined && existItem.charge !== i.charge) {
          invent_equipments[ItemIndex] = i;
        } else if (i.charge === undefined) {
          invent_equipments[ItemIndex] = { ...existItem, ...i };
        }
      }
    });

    return { result: { invent_equipments } };
  },
  toBodyUpdate: (data, userData) => {
    let newList;
    let equipments;
    let toResult = { ...userData['body'] };

    if (data.equip_upd.length > 0 || data.equip_rmv.length > 0) {
      newList = userData['body']['equipments'].map((o) => {
        let toR = o;
        if (data.equip_rmv.length === 0) {
          data.equip_upd.forEach((i) => {
            if (o.slot === i.slot) {
              toR = i;
            }
          });
        } else {
          data.equip_rmv.forEach((i) => {
            if (o.slot === i) {
              toR = '';
            }
          });
        }
        return toR;
      });
      equipments = newList ? newList : [];

      let toEquipments = [...data.equip_upd, ...equipments].filter(
        (o) => o !== '',
      );

      toResult = { ...toResult, equipments: toEquipments };
    } else if (data.oper_slots.length > 0) {
      newList = userData['body']['oper_slots'].map((o) => {
        let toR = o;
        data.oper_slots.forEach((i) => {
          if (o.slot === i.slot) {
            if (i.model) {
              toR = i;
            } else {
              toR = { slot: o.slot };
            }
          }
        });
        return toR;
      });
      equipments = newList ? newList : [];

      toResult = { ...toResult, oper_slots: equipments };
    }
    if (data.active_slots.length > 0) {
      newList = userData['body']['active_slots'].map((o) => {
        let toR = o;
        data.active_slots.forEach((i) => {
          if (o.slot === i.slot) {
            if (i.model) {
              toR = i;
            } else {
              toR = { slot: o.slot };
            }
          }
        });
        return toR;
      });
      equipments = newList ? newList : [];

      toResult = { ...toResult, active_slots: equipments };
    }

    return { result: toResult };
  },
  toSkillUpdate: (data, userData, other) => {
    const newList = userData['skills']['points'].map((o) => {
      let toR = o;
      data.skills.forEach((i) => {
        if (o.class === i.class) {
          toR = i;
        }
      });
      return toR;
    });

    const newData = dataReplace(userData['skills'], {
      points: () => newList,
    });

    const toChat = other ? getFormatText(other, 'ADDED', other.val) : '';

    return other
      ? { result: newData, toChat: { ...toChat, type: data.$type } }
      : { result: newData };
  },
  toBattleListUpdateAdd: (data, applications) => {
    let nameKey = Object.keys(data)[1];
    let newList = {
      ...applications,
      [nameKey]: [...applications[nameKey], data[nameKey]],
    };

    return { result: newList };
  },
  toBattleListUpdate: (data, applications) => {
    let nameKey = Object.keys(data)[1];

    let toList = applications[nameKey].map((i) => {
      if (i.id === data[nameKey].id) {
        return data[nameKey];
      } else return i;
    });

    let newList = { ...applications, [nameKey]: toList };

    return { result: newList };
  },
  toBattleListRemove: (data, applications) => {
    let toList = applications[RequestBattleType[data.request_type]].filter(
      (i) => i.id !== data.id,
    );
    let newList = {
      ...applications,
      [RequestBattleType[data.request_type]]: toList,
    };

    return { result: newList };
  },
  toBattleModalApplication: (infoModal, data) => {
    const toModal = {
      zIndex: infoModal.zIndex,
      styles: 'mini',
      title: infoModal.title,
      desc: infoModal.desc ? infoModal.desc : undefined,
      buttonRed: infoModal.buttonRed ? infoModal.buttonRed : undefined,
      numData: infoModal.numData,
      info: data,
    };

    return { result: toModal };
  },
  toBattleListHp: (data, old) => {
    const nameParty = data.party_id === 1 ? 'party1' : 'party2';
    let toOldParty = old[nameParty].map((i) => {
      if (i.hero_id === data.hero_id) {
        return { ...i, hp: data.hp };
      } else return i;
    });

    return { result: toOldParty };
  },
  toBattleChat: (data, battleStart, game) => {
    const toChat = getFormatTextBattle(data, battleStart, game);
    return { toChat: { ...toChat, type: data.$type } };
  },
  toChat: (data) => {
    const toChat = getFormatTextChat(data);
    return toChat;
  },
  toChatHelp: () => {
    let toChat = '';

    toChat = {
      text:
        'Базовые команды чата: /nl ' +
        '/nl ' +
        '/to, /private @имя персонажа[, @имя персонажа 2, … @имя персонажа Х] (текст) – команда отправки приватного сообщения списку персонажей (максимум 10 человек). /nl ' +
        '/nl ' +
        '/me (текст эмоции или действия) - отображается в чате текущей локации другим цветом (серым) /nl ' +
        '/nl ' +
        '/yell (текст) - сообщение всем пользователям, не только тем кто в одной локации. /nl ' +
        '/attack @имя_персонажа - немедленно атаковать персонажа или моба, независимо от того, находитесь ли вы в одном отряде. /nl ' +
        '/nl ' +
        '/assist @имя_персонажа - немедленно вступить в бой на стороне данного персонажа, если тот находится в бою и текущий персонаж может вступить в этот бой. /nl ' +
        '/nl ' +
        '/list - список персонажей в текущей локации в виде системного сообщения с именами (гиперссылками) через запятую. /nl ' +
        '/nl ' +
        '/help - системное сообщение со списком команд чата, доступных конкретному игроку в соответствии с его статусом (уровнем доступа аккаунта) и их описанием в 1 строку. /nl ',
    };

    return { toChat };
  },
  toChatGroup: (data, userMain, type, other) => {
    let toChat = '';
    let fromType = data.$type || 0;
    if (type === 'GroupAdd') {
      if (data?.group) {
        if (data?.group?.creator_id === userMain.u_info.hero_id) {
          toChat = {
            text:
              'У вас есть группа. Так же вы можете использовать команды для управления группой: /nl ' +
              '/group_info – Информация о группе /nl ' +
              '/invite @имя_игрока – Пригласить игрока /nl ' +
              '/kick @имя_игрока – Выгнать игрока из группы /nl ' +
              '/list Список игроков группы, которые онлайн /nl ' +
              '/leave – Покинуть группу (Если вы покинете группу, то группа будет распущена)',
          };
        } else {
          toChat = {
            text:
              'Вы состоите в группе. Так же вы можете использовать команды для группы: /nl ' +
              '/group_info – Информация о группе /nl ' +
              '/list Список игроков группы, которые онлайн /nl ' +
              '/leave – Покинуть группу',
          };
        }
      }
    }
    if (type === 'GroupUpdate') {
      if (!!userMain.h_group.group_id) {
        if (data?.h_group?.group_id !== userMain.h_group.group_id) {
          toChat = {
            text: `Группа изменена.`,
            data: replaceProperties(data?.h_group, userMain.h_group),
          };
        }
      } else {
        toChat = {
          text: `Поздравляем, вы успешно состоите в группе.`,
          data: replaceProperties(data?.h_group, userMain.h_group),
        };
      }
    }
    if (type === 'GroupInvite') {
      if (other.id !== userMain.u_info.hero_id) {
        toChat = {
          text: `Ваше приглашение игроку %${other.display}&${other.id}* в группу – отправлено.`,
        };
      }
    }
    if (type === 'GroupHeroKick') {
      if (other.id !== userMain.u_info.hero_id) {
        toChat = { text: `Вы выгнали игрока %${other.display}&${other.id}* .` };
      }
    }
    if (type === 'GroupHeroUpdate') {
      // if (other.id !== userMain.u_info.hero_id) {
      //   toChat = {text: `Вы выгнали игрока %${other.display}&${other.id}* .`}
      // }
    }
    if (type === 'GroupHeroLeave') {
      toChat = { text: 'Вы покинули группу.' };
    }
    if (type === 'GroupInfo') {
      const listGroup = data.heroGroup.group_heroes;
      if (listGroup.length > 0) {
        const le =
          'Список игроков в группе: ' +
          listGroup
            .map((i) => {
              return `%${i.name}&${i.hero_id}* -- Игрок в локации ${i.location_id} и он ${!!i.battle_id ? '(в бою)' : '(не в бою)'}`;
            })
            .join('| ');
        toChat = { text: le };
      }
    }

    return { type: fromType, toChat };
  },
  toBattleResultsRemove: (data, BattleResults) => {
    let toList = BattleResults.filter((i) => i.id !== data.result_id);

    return { result: toList };
  },
  toBattleResultsList: async (data, processData) => {
    // console.log('processData', processData)
    // console.log('data', data)
    let bb = await processData;
    let bc = await data;
    let fromP = [...bb, bc];
    // console.log('fromP', fromP)
    return { fromData: fromP };
  },
  toChangePairCurrent: (data, pairCurrent) => {
    // console.log("data", data);
    // console.log("pairCurrent from update****", pairCurrent);
    // let toList = (pairCurrent).filter((i) => i.id !== data.result_id)

    // return {result: toList}
    return '';
  },
  toHeroDriverUpdate: (userHeroes, newData) => {
    console.log('userHeroes', userHeroes);
    console.log('newData', newData);
    let checkInListUser = userHeroes;
    if (!checkInListUser.some((e) => e?.driver?.id === newData.driver.id)) {
      checkInListUser.push(newData);
    }
    return checkInListUser.map((item) => {
      // Проверяем, соответствует ли driver_id текущего элемента искомому
      console.log('item', item);
      console.log('newData.driver.driver_id', newData.driver.id);
      if (item.driver.id === newData.driver.id) {
        // Заменяем данные текущего элемента новыми данными
        return {
          ...item,
          driver: {
            ...newData.driver,
            // Добавьте новые данные, которые вы хотите установить
            // Например: newData: 'новые данные'
          },
        };
      } else {
        // Если driver_id не соответствует, возвращаем текущий элемент без изменений
        return item;
      }
    });
  },
  toHeroDriverDel: (userHeroes, newData) => {
    return userHeroes.filter((e) => e.driver.id !== newData.hero_id);
  },
};
