import React from 'react';
import imgLogo from '../../media/img/auth/logo.png';
import { useSelector } from 'react-redux';
import { loading } from '../../store/reducer/Slices/SlicesLoading';

const Loading = () => {
  const loading = useSelector((state) => state.loading.status);

  return (
    <div className={`load ${loading ? '' : 'load--hide'}`}>
      {loading && (
        <>
          <img src={imgLogo} alt="" />
          <div className={loading ? 'lds-ellipsis' : ''}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Loading;
