import React, { useEffect, useMemo } from 'react';
import './styles.scss';
import PlayingFieldFooter from '../playingField/playingFieldFooter/playingFieldFooter';
import BattleInit from './BattleInit';
import BattleAntagonist from './BattleAntagonist';
import { setLoading } from '../../store/reducer/Slices/SlicesLoading';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from '../../hooks/useRequest';

const Battle = () => {
  const [requestSend] = useRequest();
  const dispatch = useDispatch();
  const battleStart = useSelector((state) => state.battle.data);
  const pairCurrent = useSelector((state) => state.battleCurrent.pairCurrent);
  const userMain = useSelector((state) => state.allUserData.user_main);

  useMemo(() => {
    if (battleStart) {
      dispatch(setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(pairCurrent).length) {
      requestSend.sendData('RequestBattleData', {
        battle_id: userMain.u_info.battle_id,
      });
    }
  }, [pairCurrent]);

  return (
    <div className="battle">
      <div className="battle__center">
        <div className="battle__center__box">
          <BattleInit />
        </div>
        <PlayingFieldFooter />
      </div>
      <div className="battle__antagonist">
        <BattleAntagonist />
      </div>
    </div>
  );
};

export default Battle;
