import React from 'react';
import './styles.scss';
import { useTranslate } from '../../language/action/line';

const UiInput = ({
  onChange,
  name,
  type,
  value,
  disabled,
  placeholder,
  ClassName,
  maxValue,
  spellCheck,
}) => {
  const [ENTER] = useTranslate(placeholder ? placeholder : '');

  const handleInputChange = (e) => {
    let value = e.target.value;

    if (maxValue) {
      if (value > maxValue) {
        value = maxValue;
      }
    }

    onChange(value);
  };
  return (
    <input
      className={`iu-input ${ClassName}`}
      name={name}
      type={type}
      value={value}
      onChange={(e) => handleInputChange(e)}
      disabled={disabled}
      placeholder={ENTER}
      spellCheck={spellCheck}
    />
  );
};

export default UiInput;
