import React, { useState } from 'react';
import imgG from '../../media/img/inventory/gold.png';
import imgE from '../../media/img/inventory/etherium.png';
import { useSelector } from 'react-redux';
import Lang from '../language';
import { useTranslate } from '../language/action/line';

const InventorySendMoney = () => {
  const [check, setCheck] = useState(1);
  const userMoney = useSelector((state) => state.allUserData.data.money);
  const [CHARACTER_NAME] = useTranslate('CHARACTER_NAME');
  const [ENTER_AMOUNT] = useTranslate('ENTER_AMOUNT');

  return (
    <div className="inventory__preSendMoney">
      <p>
        <Lang>SEND_DESC</Lang>
      </p>
      <div className="inventory__sendMoney">
        <div className="inventory__sendMoney--one">
          <Lang>TO_WHOM</Lang>:
        </div>
        <div className="inventory__sendMoney--two">
          <input className="input" type="text" placeholder={CHARACTER_NAME} />
          <div
            className="inventory__sendMoney--two--info"
            onClick={() => setCheck(1)}
          >
            <div
              className={`inventory__sendMoney--two--info--check ${check === 1 ? 'inventory__sendMoney--two--info--check--active' : ''}`}
            />
            <div className="inventory__sendMoney--two--info--text">
              <Lang>SEND_DIN</Lang> <img src={imgG} alt="" />{' '}
              {userMoney['current_money'][1]}
            </div>
          </div>
          <div
            className="inventory__sendMoney--two--info"
            onClick={() => setCheck(2)}
          >
            <div
              className={`inventory__sendMoney--two--info--check ${check === 2 ? 'inventory__sendMoney--two--info--check--active' : ''}`}
            />
            <div className="inventory__sendMoney--two--info--text">
              <Lang>SEND_ETH</Lang> <img src={imgE} alt="" />{' '}
              {parseFloat(userMoney['current_money'][2]).toFixed(2) % 1 === 0
                ? parseFloat(userMoney['current_money'][2]).toFixed(0)
                : parseFloat(userMoney['current_money'][2]).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div className="inventory__sendMoney">
        <div className="inventory__sendMoney--one">
          <Lang>SEND_SUM</Lang>:
        </div>
        <div className="inventory__sendMoney--two">
          <input className="input" type="text" placeholder={ENTER_AMOUNT} />
        </div>
      </div>
    </div>
  );
};

export default InventorySendMoney;
