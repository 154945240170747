import { Centrifuge } from 'centrifuge';
import { routConnection } from '../api/routeConnection';
import { RequestCommand } from './reqRspName';

const connectionUrl = routConnection(process.env.REACT_APP_RUN);

export class CentrifugeSingleton {
  static instance;

  static getInstance(jwt) {
    if (!CentrifugeSingleton.instance) {
      CentrifugeSingleton.instance = new Centrifuge(connectionUrl, {
        data: {
          $type: RequestCommand.RequestAuthByJwt,
          jwt: jwt,
        },
      });
    }
    return CentrifugeSingleton.instance;
  }
}
