import { createSlice } from '@reduxjs/toolkit';

const battle = createSlice({
  name: 'battleInfo',
  initialState: {
    status: 0, // 0 не начался, 1 начинается, 2 бой идет, 3 закончен
    data: {
      battle: {},
      dest_hero_id: '',
      party1: [],
      party2: [],
    },
    action: {
      num: 0, // 0 не начался, 1 мой ход, 2 поиск противника, 3 ожидание хода противника, 4 результат боя с текущим противником
      data: {},
    },
    battle_result: {},
    processData: [],
    heroReconData: {},
    heroReconTimer: 0,
    modalState: true,
    effects: [],
    debug_msg: [],
    myTotal: {},
  },
  reducers: {
    setBattleStatus(state, action) {
      state.status = action.payload;
    },
    setBattleData(state, action) {
      state.data = action.payload;
    },
    setBattleResult(state, action) {
      state.battle_result = action.payload;
    },
    setBattleParty1(state, action) {
      state.data.party1 = action.payload;
    },
    setBattleParty2(state, action) {
      state.data.party2 = action.payload;
    },
    setAction(state, action) {
      state.action = action.payload;
    },
    setActionNum(state, action) {
      state.action.num = action.payload;
    },
    setActionProcess(state, action) {
      state.processData = action.payload;
    },
    setHeroReconData(state, action) {
      state.heroReconData = action.payload;
    },
    setHeroReconCounter(state, action) {
      state.heroReconTimer = action.payload;
    },
    setModalState(state, action) {
      state.modalState = action.payload;
    },
    setEffects(state, action) {
      state.effects = action.payload;
    },
    setDebugMsg(state, action) {
      state.debug_msg = action.payload;
    },
    setMyTotal(state, action) {
      state.myTotal = action.payload;
    },
  },
});

export const {
  setBattleStatus,
  setBattleData,
  setBattleResult,
  setBattleParty1,
  setBattleParty2,
  setAction,
  setActionNum,
  setActionProcess,
  setHeroReconData,
  setHeroReconCounter,
  setModalState,
  setEffects,
  setDebugMsg,
  setMyTotal,
} = battle.actions;

export { battle };
