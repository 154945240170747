import React, { useEffect, useState } from 'react';
import './styles.scss';

import imgC1 from '../../media/img/character/ch1.png';
import imgC2 from '../../media/img/character/ch2.png';
import imgC3 from '../../media/img/character/ch3.png';
import imgC4 from '../../media/img/character/ch4.png';
import imgC5 from '../../media/img/character/ch5.png';
import imgC6 from '../../media/img/character/ch6.png';
import imgC7 from '../../media/img/character/ch7.png';
import imgC8 from '../../media/img/character/ch8.png';
import imgAnim from '../../media/animation/Fire Icon_orange_gif.gif';

import imgBottom from '../../media/img/game/character/botBg.png';
import imgSlot1 from '../../media/img/game/character/slots/1.png';
import imgSlot2 from '../../media/img/game/character/slots/2.png';
import imgSlot3 from '../../media/img/game/character/slots/3.png';
import imgSlot4 from '../../media/img/game/character/slots/4.png';
import imgSlot5 from '../../media/img/game/character/slots/5.png';
import imgSlot6 from '../../media/img/game/character/slots/6.png';
import imgSlot7 from '../../media/img/game/character/slots/7.png';
import imgSlot8 from '../../media/img/game/character/slots/8.png';
import imgSlot9 from '../../media/img/game/character/slots/9.png';
import imgSlot10 from '../../media/img/game/character/slots/10.png';

import Tooltip from '../tooltip/Tooltip';
import InventoryHubThingsItem from '../Inventory/InventoryHubThings/InventoryHubThingsItem';
import { useSelector } from 'react-redux';
import { useRequest } from '../../hooks/useRequest';
import DragAndDrop from '../dragAndDrop/DragAndDrop';
import { useDictionary } from '../../hooks/useDictionary';
import Images from '../../helper/Images';
import BattleInitBattleProgress from '../battle/BattleInit/BattleInitBattleProgress';
import CharacterInfoTooltip from './CharacterInfoTooltip';
import InventoryHubEffect from '../Inventory/InventoryHubSkills/InverntoryHubEffect';
import { timeUnixRange } from '../../helper/helpers';

const slots = [
  {
    id: 1,
    img: imgSlot1,
  },
  {
    id: 2,
    img: imgSlot2,
  },
  {
    id: 3,
    img: imgSlot3,
  },
  {
    id: 4,
    img: imgSlot4,
  },
  {
    id: 5,
    img: imgSlot5,
  },
  {
    id: 6,
    img: imgSlot6,
  },
  {
    id: 7,
    img: imgSlot7,
  },
  {
    id: 8,
    img: imgSlot8,
  },
  {
    id: 9,
    img: imgSlot9,
  },
  {
    id: 10,
    img: imgSlot10,
  },
];

const characterIDList = new Map([
  ['her.1', imgC1],
  ['her.2', imgC2],
  ['her.3', imgC3],
  ['her.4', imgC4],
  ['her.5', imgC5],
  ['her.6', imgC6],
  ['her.7', imgC7],
  ['her.8', imgC8],
]);

const Character = () => {
  const [requestSend] = useRequest();
  const [openHover, setOpenHover] = useState(false);
  const userDataOLD = useSelector((state) => state.allUserData.data);
  const userData = useSelector((state) => state.heroData.data);
  const userID = useSelector((state) => state.allUserData.data.id);
  const userBody = useSelector((state) => state.allUserData.data.body);
  const characterID = useSelector(
    (state) => state.allUserData.data.h_info.preset_id,
  );
  const battleStatus = useSelector((state) => state.battle.status);
  const [dict, getInfoDictionary] = useDictionary();
  const [fullArm, setFullArm] = useState(false);

  const getInfoForUser = () => {
    if (!openHover) requestSend.sendData('RequestHeroInfo', { id: userID });
    setOpenHover(true);
  };
  const toOnClick = (id, slot) => {
    requestSend.sendData('RequestEquipmentOff', { slot });
  };

  const toDropData = (data, slot, i) => {
    if (data?.type && i.id === 8) {
      requestSend.sendData('RequestEquipmentOn', {
        id: data.id,
        slot: 8,
        weapon_shield: true,
      });
    } else if (i && data.slot === i.id) {
      requestSend.sendData('RequestEquipmentOn', {
        id: data.id,
        slot: data.slot,
      });
    } else if (data.slot === slot) {
      requestSend.sendData('RequestEquipmentOn', {
        id: data.id,
        slot: data.slot,
      });
    }
  };

  const checkFull = () => {
    let stat = 0;
    const filterSetEquip = dict.equipments.find(
      (e) => e.id === userBody.equipments[0]?.model.dict_id,
    );
    if (filterSetEquip && filterSetEquip.set_key !== '') {
      const dictFilter = dict.equipments.filter(
        (e) => e.set_key === filterSetEquip?.set_key,
      );
      for (let i = 0; i <= 10; i++) {
        let bodyEquip = userBody.equipments.find((e) => e.slot === i);
        let find = userBody.equipments.find((e) => e.slot === i);
        let findSetEquip = dictFilter.find(
          (e) => e.id === bodyEquip?.model.dict_id,
        );
        if (find && findSetEquip) {
          if (find.model) {
            stat += 1;
          }
        }
      }
    }
    setFullArm(stat >= 10);
  };

  useEffect(() => {
    checkFull();
  }, [userBody, slots]);

  const toSlots = (val1, val2) => {
    return slots.slice(val1, val2).map((i, index) => {
      const user = userBody.equipments.find((item) => item.slot === i.id);
      if (user && user.model) {
        const infoWeapons = getInfoDictionary('equipments', user.model.dict_id);
        let actSlot = null;
        if (user.slot !== infoWeapons.slot) {
          actSlot = user.slot;
        } else {
          actSlot = infoWeapons.slot;
        }
        return (
          <Tooltip
            key={index + 'char'}
            open={true}
            content={<InventoryHubThingsItem user={user.model} />}
          >
            <DragAndDrop
              dropType="things"
              dragData={{
                data: actSlot,
                dragType: 'things',
              }}
              dropData={(e) => toDropData(e, actSlot, i)}
            >
              <div
                id={`slot_${i.id}`}
                className={`character--inventory--item  rr-${infoWeapons.quality}`}
                onContextMenu={() => {
                  toOnClick(user.model.id, i.id);
                  checkFull();
                }}
              >
                <Images name={infoWeapons.id} />
              </div>

              <img
                src={imgAnim}
                className={`character--inventory--full ${fullArm ? 'character--inventory--full--yes' : ''}`}
              />
            </DragAndDrop>
          </Tooltip>
        );
      } else {
        return (
          <DragAndDrop
            key={index}
            dropType="things"
            dropData={(e) => toDropData(e, i.id, i)}
          >
            <div id={`slot_${i.id}`} className="character--inventory--item">
              {i.img && <img src={i.img} alt="" />}
            </div>
          </DragAndDrop>
        );
      }
    });
  };

  return (
    <div className="character">
      <div className="character--inventory">{toSlots(0, 5)}</div>
      <div className="character--center">
        <Tooltip
          key={Math.random()}
          zIndex={9990}
          content={<CharacterInfoTooltip />}
          onHover={() => getInfoForUser()}
          onLeave={() => setOpenHover(false)}
          disable={battleStatus !== 2 ? false : true}
        >
          <img src={characterIDList.get(characterID)} alt="" />
        </Tooltip>
      </div>
      <div className="character--inventory">{toSlots(5, 10)}</div>
      <img className="character--bottom-bg" src={imgBottom} alt="" />
    </div>
  );
};

export default Character;
