import Lang from '../../components/language';

export const h1 = <Lang>PLAY_INFO_5</Lang>;
export const h2 = (
  <>
    <p>
      • <Lang>PLAY_INFO_1</Lang>
    </p>
    <p>
      • <Lang>PLAY_INFO_2</Lang>
    </p>
    <p>
      • <Lang>PLAY_INFO_3</Lang>
    </p>
    <p>
      • <Lang>PLAY_INFO_4</Lang>
    </p>
  </>
);
