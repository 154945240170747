import { createSlice } from '@reduxjs/toolkit';

const dictionary = createSlice({
  name: 'dictionaryInfo',
  initialState: {
    data: null,
  },
  reducers: {
    setDictionary(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setDictionary } = dictionary.actions;

export { dictionary };
