import { useDispatch, useStore } from 'react-redux';
import { RequestBattleType } from '../../helper/reqRspName';
import {
  setApplications,
  setApplicationsGroup,
  setApplicationsHimself,
  setApplicationsPvp,
  setApplicationsRandom,
} from '../../store/reducer/Slices/SlicesApplication';
import { setOpenApplication } from '../../store/reducer/Slices/SlicesApplicationOpen';
import {
  setBaseModal,
  setBaseModalData,
} from '../../store/reducer/Slices/SliceModal';

export const useList = () => {
  const dispatch = useDispatch();
  const store = useStore();

  const toBattleListUpdate = (data) => {
    let applications = store.getState().applicationsList.list;
    let nameKey = Object.keys(data)[1];

    let toList = applications[nameKey].map((i) => {
      if (i.id === data[nameKey].id) {
        return data[nameKey];
      } else return i;
    });

    let newList = { ...applications, [nameKey]: toList };

    dispatch(setApplications(newList));
  };

  const toBattleListUpdateAdd = (data) => {
    if (data.bat_req_pvp_bot && data.bat_req_pvp_bot.status === 3) {
      // Бой с ботом
      dispatch(setOpenApplication(0));
      const result = {
        zIndex: 9,
        styles: 'nota',
        title: `Начинается бой c ${data.bat_req_pvp_bot.creator_info.hero_name} (NPC)!`,
        numData: 3,
        toApplications: data.bat_req_pvp_bot,
      };

      dispatch(setBaseModalData(result));
      dispatch(setBaseModal(true));
    } else {
      let applications = store.getState().applicationsList.list;
      let nameKey = Object.keys(data)[1];
      let newList = {
        ...applications,
        [nameKey]: Array.isArray(applications[nameKey])
          ? [...applications[nameKey], data[nameKey]]
          : [data[nameKey]],
      };

      dispatch(setApplications(newList));
    }
  };

  const toBattleListRemove = (data) => {
    let applications = store.getState().applicationsList.list;

    if (applications[RequestBattleType[data.request_type]]) {
      let toList = applications[RequestBattleType[data.request_type]].filter(
        (i) => i.id !== data.id,
      );

      let newList = {
        ...applications,
        [RequestBattleType[data.request_type]]: toList,
      };

      dispatch(setApplications(newList));
    }
  };

  return [toBattleListUpdateAdd, toBattleListUpdate, toBattleListRemove];
};
