import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Response } from '../../helper/reqRspName';
import ChatMessageInfo from './MessageComponents/ChatMessageInfo';
import ChatMessageBattleStart from './MessageComponents/ChatMessageBattleStart';
import ChatMessageBattleTurn from './MessageComponents/ChatMessageBattleTurn';
import ChatMessageBattleDeath from './MessageComponents/ChatMessageBattleDeath';
import ChatMessageBattleEnd from './MessageComponents/ChatMessageBattleEnd';

const ChatBody = ({
  onlyMy,
  battleStatus,
  activeFilter,
  log = {},
  onHandleSelectHero,
}) => {
  const dataChat = useSelector((state) => state.chatData.data);
  const privateChat = useSelector((state) => state.chatData.private);
  const fromUserData = useSelector((state) => state.allUserData.data);
  const userData = fromUserData;

  const filtredData = useMemo(() => {
    if (Object.keys(log).length > 0) {
      // Логи боя
      return log.dataChat;
    } else {
      if (onlyMy && battleStatus === 2) {
        return dataChat.filter((data) => {
          if (
            data.data?.a_her_id === userData.id ||
            data.data?.d_her_id === userData.id ||
            data.data?.dest_hero_id === userData.id
          ) {
            return true;
          }
          return false;
        });
      } else {
        switch (activeFilter) {
          case 'private':
            return privateChat;
          default:
            return dataChat;
        }
      }
    }
  }, [dataChat, onlyMy, activeFilter]);

  return (
    <div className="chat__block1--body--message">
      {filtredData.length > 0 &&
        filtredData.map((i, index) => {
          {
            /* console.log('i______________', i); */
          }
          switch (i.type) {
            case Response.ResponseBattleStart:
              return (
                <ChatMessageBattleStart
                  key={i.type + 'ChatMessageBattleStart' + index}
                  message={i}
                  userHero={userData.id}
                />
              );
            case Response.ResponseBattleHeroDead:
              return (
                <ChatMessageBattleDeath
                  key={i.type + 'ChatMessageBattleDeath' + index}
                  message={i}
                  userHero={userData.id}
                />
              );
            case Response.ResponseBattleEnd:
              return (
                <ChatMessageBattleEnd
                  key={i.type + 'ChatMessageBattleEnd' + index}
                  message={i}
                  userHero={userData.id}
                />
              );
            case Response.ResponseBattleTurnActionResult:
            case Response.ResponseBattleTurnResultSpell:
              return (
                <ChatMessageBattleTurn
                  key={i.type + 'ChatMessageBattleTurn' + index}
                  message={i}
                  userHero={userData.id}
                />
              );
            default:
              return (
                i.text && (
                  <ChatMessageInfo
                    key={i.type + 'ChatMessageInfo' + index}
                    message={i}
                    toMsg={onHandleSelectHero}
                  />
                )
              );
          }
        })}
    </div>
  );
};

export default React.memo(ChatBody);
