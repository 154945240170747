import React, { useEffect, useState } from 'react';
import {
  setBaseModal,
  setBaseModalData,
} from '../../../store/reducer/Slices/SliceModal';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import { getRangeTime } from '../../../helper/helpers';
import { setApplicationsTime } from '../../../store/reducer/Slices/SlicesApplication';
import { loadersSpin2 } from '../../loaders/loaders';
import { RequestBattleTypeInvert } from '../../../helper/reqRspName';
import Lang from '../../language';

const ApplicationForModalStart = ({ data }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.allUserData.user_main);
  const userBattle = useSelector(
    (state) => state.allUserData.data.h_battle_req,
  );
  const [time, setTime] = useState(5);
  const [checkTime, setCheckTime] = useState(false);

  const offModal = () => {
    dispatch(setBaseModal(false));
    dispatch(setBaseModalData({}));
  };

  useEffect(() => {
    return () => {
      setCheckTime(false);
      dispatch(setApplicationsTime(null));
    };
  }, []);

  useEffect(() => {
    if (data.toApplications.next_status_time && !checkTime) {
      const { seconds } = getRangeTime(
        new Date(),
        data.toApplications.next_status_time,
      );
      setTime(seconds);
      setCheckTime(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) setTime(time - 1);
    }, 1000);

    if (time <= 1) {
      // offModal()
    }

    return () => clearInterval(interval);
  }, [time]);

  return (
    <div className="application__forModal">
      {RequestBattleTypeInvert.bat_req_pvp ===
      data.toApplications.request_type ? (
        userBattle.owner ? (
          <div>
            <Lang>YOU_HAVE_DRAWN_YOUR_WEAPON_TO_ATTACK</Lang>
            <b>
              {
                data.toApplications?.members?.find(
                  (i) => i.id !== userInfo.u_info.hero_id,
                ).hero_name
              }
            </b>
            <Lang>NOTICED_YOUR_MOVEMENT_AND_IMMEDIATELY</Lang>
          </div>
        ) : (
          <div>
            <Lang>YOU_SEE_HOW</Lang>
            <b>
              {
                data.toApplications?.members?.find(
                  (i) => i.id !== userInfo.u_info.hero_id,
                ).hero_name
              }
            </b>
            <Lang>DREW_HIS_WEAPON_AND_MOVED</Lang>
          </div>
        )
      ) : (
        <Lang>YOU_ARE_STARTING_A_BATTLE</Lang>
      )}
      <div className="application__forModal--load">
        {loadersSpin2}
        <div className="application__forModal--load--time">{time}</div>
      </div>
    </div>
  );
};

export default ApplicationForModalStart;
