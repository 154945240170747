import { combineReducers } from '@reduxjs/toolkit';
import { connectionApi } from './Slices';
import { inventoryOpen } from './Slices/SlicesInventory';
import { chatData } from './Slices/SlicesChat';
import { allUserData } from './Slices/SlicesUserData';
import { applicationsList } from './Slices/SlicesApplication';
import { baseModal } from './Slices/SliceModal';
import { battle } from './Slices/SlicesBattle';
import { loading } from './Slices/SlicesLoading';
import { dictionary } from './Slices/SlicesDictionary';
import { battleCurrent } from './Slices/SlicesBattleCarrent';
import { applicationOpen } from './Slices/SlicesApplicationOpen';
import { heroData } from './Slices/SlicesHeroData';
import { tooltip } from './Slices/SlicesTooltip';
import { language } from './Slices/SlicesLang';
import { tooltipInfo } from './Slices/SlicesTooltipInfo';
import { checkName } from './Slices/SlicesCheckName';
import { mapOpen } from './Slices/SlicesMap';
import { shopOpen } from './Slices/SlicesShop';
import { groups } from './Slices/SliceGroups';
import { DungeonsData } from './Slices/SlicesDungeon';
import { NpcData } from './Slices/SliceNpc';

const reducer = combineReducers({
  allUserData: allUserData.reducer,
  inventoryOpen: inventoryOpen.reducer,
  mapOpen: mapOpen.reducer,
  connectionApi: connectionApi.reducer,
  chatData: chatData.reducer,
  applicationOpen: applicationOpen.reducer,
  applicationsList: applicationsList.reducer,
  baseModal: baseModal.reducer,
  battle: battle.reducer,
  battleCurrent: battleCurrent.reducer,
  loading: loading.reducer,
  dictionary: dictionary.reducer,
  heroData: heroData.reducer,
  tooltip: tooltip.reducer,
  language: language.reducer,
  tooltipInfo: tooltipInfo.reducer,
  checkName: checkName.reducer,
  shopOpen: shopOpen.reducer,
  groups: groups.reducer,
  DungeonsData: DungeonsData.reducer,
  NpcData: NpcData.reducer,
});

export default reducer;
