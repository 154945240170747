import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
// import ButtonRed from '../../ui/buttons/UiButtonOne';
import Lang from '../../language';
import imgWinTop from '../../../media/img/ui/ribbon.png';
import imgWinLeft from '../../../media/img/ui/winLose/ui-win-left.png';
import imgWinRight from '../../../media/img/ui/winLose/ui-win-right.png';
import imgAnim from '../../../media/img/ui/winLose/vfx_WinLoseScreen_b&w.gif';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import {
  setBaseModal,
  setBaseModalData,
} from '../../../store/reducer/Slices/SliceModal';
import { cookieSet } from '../../../helper/cookie';

const ModalLevelUp = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.allUserData.data);
  const lvl = user.level.level;
  const chatLvl = useSelector((state) => state.shopOpen.levelUpModal);
  const [modalOpen, setModalOpen] = useState(false);
  const baseModal = useSelector((state) => state.baseModal.open);

  const onClick = () => {
    cookieSet('levelUpModal', '', 99999).then((r) => {
      setModalOpen(false);
      dispatch(setBaseModal(false));
      dispatch(setBaseModalData({}));
    });
  };
  useEffect(() => {
    if (chatLvl.text && baseModal) {
      setModalOpen(true);
    }
  }, [chatLvl, baseModal]);

  return (
    <>
      {modalOpen && (
        <div className="application__forModal">
          <div className="modal-end-head">
            <div className={`modal-end-head--back winwin`}>
              <img src={imgAnim} alt="lvl-anim" />
            </div>
            <img
              src={imgWinTop}
              className="modal-end-head--top"
              alt="lvl-top-i"
            />
            <p
              className="modal-end-head--text"
              style={{
                position: 'absolute',
                bottom: '40px',
                left: '25%',
                zIndex: '3',
                fontSize: '20px',
                fontWeight: 'bold',
                textShadow: '0 4px 3px #0000006e',
                color: 'white',
              }}
            >
              <Lang>LEVEL</Lang> {lvl}
            </p>

            {/* <img
              src={imgWinTop}
              className="modal-end-head--top"
              alt="lvl-top-i"
            />
            <p
              style={{
                zIndex: '3',
                fontSize: '20px',
                fontWeight: 'bold',
                textShadow: '0 4px 3px #0000006e',
              }}
            >
              <Lang>УРОВЕНЬ</Lang> {lvl}
            </p> */}
            <img
              src={imgWinLeft}
              className="modal-end-head--left"
              alt="lvl-left-i"
            />
            <img
              src={imgWinRight}
              className="modal-end-head--right"
              alt="lvl-right-i"
            />
          </div>
          <div
            className="battleEnd--box"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div className="battleEnd--box--time">
              <div className="battleEnd--box--time--line"></div>
            </div>

            <div className="battleEnd--box--bonus">
              <Lang>CONGRAT_LVL</Lang>!
            </div>
          </div>
          <div className="modal__container--buttons">
            <UiButtonOne onClick={() => onClick()}>
              <Lang>FINE</Lang>!
            </UiButtonOne>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalLevelUp;
