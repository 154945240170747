export const h1 = {
  title: 'Приватная вечеринка',
  desc:
    'Доступ возможен только по специальному приглашению.\n' +
    'Спасибо за понимание!',
  buttonRed: {
    text: 'OK',
  },
};

export const h2 = {
  title: 'Технические работы',
  desc: 'В связи с техническими работами, сервер временно недоступен. Попробуйте зайти чуть позже. Спасибо за понимание, мы спешим как только можем!',
  buttonRed: {
    text: 'OK',
  },
};

export const h3 = {
  title: 'Смена пароля',
  desc: 'Вы действительно хотите сбросить пароль для аккаунта?',
  buttonGray: {
    text: 'Отмена',
  },
  buttonRed: {
    text: 'Сбросить пароль',
  },
};

export const h4 = {
  title: 'Смена пароля',
  desc: 'Письмо с инструкциями отправлено на этот email:',
  buttonRed: {
    text: 'OK',
  },
};

export const h5 = {
  title: 'Смена пароля',
  desc: 'Новый пароль для вашего аккаунта сгенерирован и отправлен на:',
  buttonGray: {
    text: 'OK',
  },
};
