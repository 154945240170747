import React from 'react';
import Images from '../../helper/Images';
import Lang from '../language';

const InventoryDemolishResult = ({ id, gold, runes }) => {
  return (
    <div className="inventory__preObject">
      <div className="inventory__preObject--in">
        <div className="inventory__preObject--info">
          <h4 style={{ padding: '5px' }}>
            <Lang>ITEM</Lang> <Lang>{id}</Lang> <Lang>DEMOLISHED</Lang>,{' '}
            <Lang>RECEIVED_TOTAL</Lang>:{' '}
            {parseFloat(gold).toFixed(2) % 1 === 0
              ? parseFloat(gold).toFixed(0)
              : parseFloat(gold).toFixed(2)}{' '}
            <Lang>ETHS</Lang>
          </h4>
          {runes?.length > 0 && (
            <>
              <h4 style={{ padding: '5px' }}>
                <Lang>RUNES</Lang>:
              </h4>
              <div
                style={{
                  display: 'flex',
                  padding: '5px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {runes &&
                  runes.map((el) => (
                    <div
                      key={`rune-${el.dict_id}`}
                      className={`inventory__preObject--img rr-${el.dict_id}`}
                    >
                      <Images name={el.dict_id} />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryDemolishResult;
