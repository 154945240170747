export default {
  width: '100%',
  height: '100%',
  borderRadius: 12,

  '&multiLine': {
    control: {
      // width: "100%",
      // height: "auto",
    },
    highlighter: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      padding: '10px 20px',
      border: '1px solid #b8afa2',
      outline: 'none',
      borderRadius: 12,
      transition: '0.3s',
    },
    input: {
      padding: '10px 20px',
      border: '1px solid #b8afa2',
      outline: 'none',
      borderRadius: 12,
      transition: '0.3s',
    },
  },
  control: {
    // backgroundColor: "#fff",
    // fontSize: 14,
    // fontWeight: "normal",
    // scrollBehavior: "auto",
    // overflowY: "scroll",
  },

  // "&multiLine": {
  //     control: {
  //         // fontFamily: "monospace",
  //         // width: 280,
  //         // minHeight: 63,
  //     },
  //     highlighter: {
  //         padding: 9,
  //         border: "1px solid transparent",
  //     },
  //     input: {
  //         padding: 9,
  //         border: "1px solid silver",
  //         borderRadius: 12,
  //     },
  // },

  // "&singleLine": {
  //     display: "inline-block",
  //     width: 180,

  //     highlighter: {
  //         padding: 1,
  //         border: "2px inset transparent",
  //     },
  //     input: {
  //         padding: 1,
  //         border: "2px inset",
  //         height: 163,
  //     },
  // },

  suggestions: {
    backgroundColor: '#b7b0a4',
    item: {
      padding: '5px 5px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
