import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';

const TooltipInit = () => {
  const refActive = useRef(null);
  const [positionY, setPositionY] = useState(-1000);
  const [positionX, setPositionX] = useState(0);
  const [positionYNav, setPositionYNav] = useState(-1000);
  const [positionXNav, setPositionXNav] = useState(-1000);
  const [statusOpenIn, setStatusOpenIn] = useState(false);
  const [statusNav, setStatusNav] = useState('left');
  let statusOpen = useSelector((state) => state.tooltip.status);
  let tooltipInfo = useSelector((state) => state.tooltip.data);

  const [cursorX, setCursorX] = useState(0);
  // const [cursorY, setCursorY] = useState(0);
  const [isMouseInside, setIsMouseInside] = useState(false);

  useEffect(() => {
    const checkPosition = (e) => {
      setCursorX(e.clientX);
      // setCursorY(e.clientY);

      if (tooltipInfo.info?.current) {
        const box = tooltipInfo.info.current.getBoundingClientRect();
        const isInside =
          e.clientX >= box.left &&
          e.clientX <= box.right &&
          e.clientY >= box.top &&
          e.clientY <= box.bottom;
        setIsMouseInside(isInside);
      }
    };

    window.addEventListener('mousemove', checkPosition);

    return () => {
      window.removeEventListener('mousemove', checkPosition);
    };
  }, [tooltipInfo]);

  useLayoutEffect(() => {
    let middle = window.innerWidth / 2;
    let middleY = window.innerHeight / 2;

    let toPositionX =
      tooltipInfo.info?.box?.left +
      (tooltipInfo.info?.current?.offsetWidth > 0
        ? tooltipInfo.info?.current?.offsetWidth
        : cursorX * 2 - tooltipInfo.info?.box?.left);
    let toPositionXCurrent = tooltipInfo.info?.current?.getBoundingClientRect();
    let fromRefActive = refActive.current?.getBoundingClientRect();

    setStatusNav('left');

    let navY =
      tooltipInfo.info?.box?.y +
      tooltipInfo.info?.current?.offsetHeight / 2 -
      11;

    setPositionYNav(navY);
    setPositionXNav(toPositionX - 19);

    let toPositionY =
      tooltipInfo.info?.box?.top +
      tooltipInfo.info?.current?.offsetHeight / 2.1;

    if (tooltipInfo.info?.box?.x >= middle) {
      setPositionXNav(tooltipInfo.info?.box?.left - 5);
      toPositionX = toPositionXCurrent.left - fromRefActive.width;
      setStatusNav('right');
    }

    if (tooltipInfo.info?.box?.y + fromRefActive.height >= window.innerHeight) {
      let xx =
        window.innerHeight -
        fromRefActive.height / 2.1 -
        tooltipInfo.info?.current?.offsetHeight / 2.1;
      toPositionY = xx;
    }

    if (tooltipInfo.info?.box?.y - fromRefActive.height / 2.1 <= 0) {
      // let xc = ((window.innerHeight) - (fromRefActive.height)) - (tooltipInfo.info?.current?.offsetHeight)
      let xc = tooltipInfo.info?.box?.y + fromRefActive.height / 2.1;
      toPositionY = xc - tooltipInfo.info?.box?.y / 1.5;
    }

    setPositionX(toPositionX);
    setPositionY(toPositionY);

    setStatusOpenIn(statusOpen);

    return () => {
      setPositionX(-1000);
      setPositionY(0);
    };
  }, [statusOpen, tooltipInfo, positionX]);

  return (
    <>
      <div
        id="initTooltip"
        ref={refActive}
        onMouseEnter={() => {
          setPositionX(-1000);
          setPositionY(0);
        }}
        className={`tooltip--active ${statusOpen && positionX > 0 && isMouseInside ? 'openT' : ''}`}
        style={{
          position: 'fixed',
          zIndex: 999,
          left: isNaN(positionX) ? '100vh' : positionX,
          top: isNaN(positionY) ? '100vw' : positionY,
          overflow: 'hidden',
          overflowY: 'auto',
          marginLeft: tooltipInfo?.info?.className?.marginLeft,
        }}
        draggable={false}
      >
        {tooltipInfo.content}
      </div>
      <i
        className={`${statusNav === 'left' ? 'oI' : 'oIR'} ${statusOpen && positionX > 0 && isMouseInside ? 'openT' : ''}`}
        style={{
          top: isNaN(positionYNav) ? '100vh' : positionYNav,
          left: isNaN(positionXNav) ? '50vw' : positionXNav,
          position: 'absolute',
          display:
            isNaN(positionYNav) && isNaN(positionXNav) && !statusOpen
              ? 'none'
              : '',
          marginLeft: tooltipInfo?.info?.className?.marginLeft,
        }}
      />
    </>
  );
};

export default TooltipInit;
