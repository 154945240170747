import React, { useState, useCallback } from 'react';
import './styles.scss';
import Chat from '../../chat/Chat';
import ChatList from '../../chat/ChatList';
import { useSelector } from 'react-redux';

const PlayingFieldFooter = () => {
  const [hero, setHero] = useState([]);
  const battleStatus = useSelector((state) => state.battle.status);

  const handleSelectHero = useCallback((hero, action) => {
    setHero([{ ...hero, action }]);
  }, []);

  return (
    <div className="playingFieldFooter">
      {(battleStatus === 0 || battleStatus === 1 || battleStatus === 3) && (
        <>
          <Chat
            battleStatus={battleStatus}
            selectedHeroBylist={hero}
            onHandleSelectHero={handleSelectHero}
          />
          <ChatList onHandleSelectHero={handleSelectHero} />
        </>
      )}
      {battleStatus === 2 && <Chat battleStatus={battleStatus} />}
    </div>
  );
};

export default PlayingFieldFooter;
