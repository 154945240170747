import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';

import cls from './Dropdown.module.scss';

import { UiButtonSlim } from '../ui/buttons/UiButtonSlim';
import Lang from '../language';

export const Dropdown = memo(({ items, onClick, show, itemComponent }) => {
  if (isEmpty(items)) {
    throw new Error('Dropdown items is empty');
  }

  const ItemComponent =
    itemComponent && typeof itemComponent === 'function'
      ? itemComponent
      : UiButtonSlim;

  return (
    <>
      {show ? (
        <div className={`${cls.root} ${show ? cls.show : ''}`}>
          {items.map((item) => (
            <ItemComponent
              key={item.key}
              className="modal__container--buttons--gray"
              onClick={() => onClick(item.key)}
            >
              <Lang>{item.value}</Lang>
            </ItemComponent>
          ))}
        </div>
      ) : null}
    </>
  );
});
