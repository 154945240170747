import React, { useEffect, useState, useRef } from 'react';
import Lang from '../../language';

const BattleInitElixUse = ({
  open,
  toRef,
  setForcibly,
  onClick,
  disUse = false,
}) => {
  const [tooltip, setTooltip] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const menuRef = useRef(null);

  useEffect(() => {
    if (open && toRef.current) {
      const { top, left, height } = toRef.current.getBoundingClientRect();
      const menuHeight = menuRef.current?.offsetHeight || 0;
      const menuWidth = menuRef.current?.offsetWidth || 0;
      let newTop = top - height - 20;
      let newLeft = left - 50;
      if (newTop + menuHeight > window.innerHeight) {
        newTop = window.innerHeight - menuHeight - 10;
      }
      if (newLeft + menuWidth > window.innerWidth) {
        newLeft = window.innerWidth - menuWidth - 10;
      }

      setPosition({ top: newTop, left: newLeft });
      setTooltip(true);
    } else {
      setTooltip(false);
    }
  }, [open, toRef]);

  useEffect(() => {
    function handler(event) {
      if (!toRef.current?.contains(event.target)) {
        setTooltip(false);
        setForcibly();
      }
    }
    window.addEventListener('click', handler);

    return () => window.removeEventListener('click', handler);
  }, [toRef, setForcibly]);

  return (
    <>
      {tooltip && (
        <div
          className="battle__init--start--mini--list--item--menu"
          style={{ top: position.top, left: position.left, position: 'fixed' }}
          ref={menuRef}
        >
          <div
            className={`battle__init--start--mini--list--item--menu--use${disUse ? '--disabled' : ''}`}
            onClick={() => {
              if (!disUse) {
                onClick();
                setTooltip(false);
              }
            }}
          >
            <Lang>USE</Lang>
          </div>
          <i></i>
        </div>
      )}
    </>
  );
};

export default BattleInitElixUse;
