import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import ButtonRed from '../../ui/buttons/UiButtonOne';
import ImgLight from '../../../media/img/createCharacter/light_notactive.png';
import ImgDark from '../../../media/img/createCharacter/dark_notactive.png';
import ImgChaos from '../../../media/img/createCharacter/chaos_notactive.png';
import ImgNPCdef from '../../../media/img/npc/NPC3.png';
import ImgNPCspirit from '../../../media/img/npc/NPCspiritChaos.png';
import Lang from '../../language';
import { setRespawnModal } from '../../../store/reducer/Slices/SlicesShop';

const ModalResp = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.allUserData.data);
  const respawnModal = useSelector((state) => state.shopOpen.respawnModal);
  const pantheon = user.h_info.pantheon;
  const userLocation = useSelector(
    (state) => state.allUserData.data.h_location,
  );
  const [cult, setCult] = useState(null);
  const [cultImg, setCultImg] = useState(null);
  const [npc, setNpc] = useState(null);
  const [npcStyles, setNpcStyles] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [welcome, setWelcome] = useState('');
  const [rebirth, setRebirth] = useState(null);

  const onClick = () => {
    setModalOpen(false);
    dispatch(setRespawnModal({}));
  };

  useEffect(() => {
    if (
      (respawnModal &&
        respawnModal === user.h_info.id &&
        userLocation.location_id.startsWith('dng.death')) ||
      rebirth
    ) {
      setModalOpen(true);
    }
    if (userLocation.location_id.startsWith('dng.death')) {
      setWelcome('CHAOS_WORLD_WELCOME');
      setNpc(ImgNPCspirit);
      setNpcStyles({ width: '21%' });
    } else {
      setWelcome('RESPAWN_WELCOME');
      setNpc(ImgNPCdef);
      setNpcStyles({ width: '23%' });
    }
    if (pantheon === 1) {
      setCult('Light');
      setCultImg(ImgLight);
    } else if (pantheon === 2) {
      setCult('Dark');
      setCultImg(ImgDark);
    } else {
      setCult('Chaos');
      setCultImg(ImgChaos);
    }
  }, []);

  return (
    <>
      {modalOpen && (
        <>
          <div className="modal_resp_background" />
          <img
            src={npc}
            alt="npc_img"
            className="modal_resp--img"
            style={npcStyles}
          />
          <img
            src={cultImg}
            alt="cult_img"
            className="modal_resp_background--cult"
          />
          <div className="modal_resp">
            <div className="modal_resp--border">
              <div className="modal_resp--msg">
                <p>
                  <Lang>{welcome}</Lang>{' '}
                </p>
              </div>
              <ButtonRed onClick={() => onClick()}>
                <Lang>PROCEED</Lang>...
              </ButtonRed>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ModalResp;
