import { useEffect, useState } from 'react';
import ChatMessageAttack from './ChatMessageAttack';
import ChatMessageBlock from './ChatMessageBlock';

const msgsWeapons = {
  swr: 'CHAT_CUT',
  axe: 'CHAT_SLASHED',
  ham: 'CHAT_HIT',
  spell: 'SPELL',
};

const msgsAttackZones = {
  1: {
    swr: 'CHAT_SWORD_TO_THE_NECK',
    ham: 'CHAT_HAMMER_TO_THE_TEMPLE',
    axe: 'CHAT_AN_AX_TO_THE_FOREHEAD',
  },
  2: {
    swr: 'CHAT_SWORD_TO_THE_CHEST',
    ham: 'CHAT_HAMMER_TO_THE_RIBS',
    axe: 'CHAT_WITH_AN_AX_TO_THE_CHEST',
  },
  3: {
    swr: 'CHAT_SWORD_ACROSS_THE_BELT',
    ham: 'CHAT_HAMMER_TO_THE_STOMACH',
    axe: 'CHAT_WITH_AN_AX_TO_THE_STOMACH',
  },
  4: {
    swr: 'CHAT_SWORD_ON_THE_LEG',
    ham: 'CHAT_HAMMER_ON_THE_KNEE',
    axe: 'CHAT_AN_AX_TO_THE_LEG',
  },
};

const msgDamageSizes = {
  xs: 'CHAT_BARELY',
  s: 'CHAT_EASILY',
  m: 'CHAT_PALPABLY',
  l: 'CHAT_PROPERLY',
  xl: 'CHAT_SKILLFULLY',
  xxl: 'CHAT_FROM_THE_HEART',
  xxxl: 'CHAT_STRONGLY',
  '4xl': 'CHAT_VERY_STRONGLY',
  '5xl': 'CHAT_TERRIBLE',
};

export default function ChatMessageBattleTurnType({ message, userHero }) {
  const [currentDmgSize, setCurrentDmgSize] = useState('');
  let dmg;
  let isUser;
  let msgWeapon;
  let msgAttackZone;
  let msgDamageSize;

  if (message.data.$type === 35) {
    // spell
    dmg = message.data.a_damag;
    isUser = userHero === message.data.a_her_id;
    msgWeapon = msgsWeapons['spell'];
    msgAttackZone = 'SPELL_ATTACK_ALL_BODY';
    msgDamageSize = msgDamageSizes[currentDmgSize];
  } else {
    dmg = message.data.a_damag;
    isUser = userHero === message.data.a_her_id;
    msgWeapon = msgsWeapons[message.a_her.weapon];
    msgAttackZone =
      message.data.a_zone &&
      msgsAttackZones[message.data.a_zone][message.a_her.weapon];
    msgDamageSize = msgDamageSizes[currentDmgSize];
  }

  useEffect(() => {
    if (dmg <= 10) {
      setCurrentDmgSize('xs');
    }

    if (dmg > 10 && dmg <= 20) {
      setCurrentDmgSize('s');
    }

    if (dmg > 20 && dmg <= 30) {
      setCurrentDmgSize('m');
    }

    if (dmg > 30 && dmg <= 50) {
      setCurrentDmgSize('l');
    }

    if (dmg > 50 && dmg <= 70) {
      setCurrentDmgSize('xl');
    }

    if (dmg > 70 && dmg <= 100) {
      setCurrentDmgSize('xxl');
    }

    if (dmg > 100 && dmg <= 150) {
      setCurrentDmgSize('xxxl');
    }

    if (dmg > 150 && dmg <= 200) {
      setCurrentDmgSize('4xl');
    }

    if (dmg > 200) {
      setCurrentDmgSize('5xl');
    }
  }, [message, dmg]);

  if (!dmg) {
    // если атака по какойто причине не прошла
    return (
      <ChatMessageBlock
        message={message}
        isUser={isUser}
        msgDamageSize={msgDamageSize}
        msgWeapon={msgWeapon}
        msgAttackZone={msgAttackZone}
        dmg={dmg}
      />
    );
  }

  return (
    <ChatMessageAttack
      message={message}
      isUser={isUser}
      msgDamageSize={msgDamageSize}
      msgWeapon={msgWeapon}
      msgAttackZone={msgAttackZone}
      dmg={dmg}
    />
  );
}
