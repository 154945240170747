import { createSlice } from '@reduxjs/toolkit';

const inventoryOpen = createSlice({
  name: 'inventoryOpen',
  initialState: { open: false },
  reducers: {
    setOpenInventory(state, action) {
      state.auth = action.payload;
    },
  },
});

export const { setOpenInventory } = inventoryOpen.actions;

export { inventoryOpen };
