import React, { useEffect, useState } from 'react';
import Lang from '../../../components/language';
import UiInput from '../../../components/ui/inputs/UiInput';
import imgG from '../../../media/img/inventory/gold.png';
import imgE from '../../../media/img/inventory/etherium.png';
import imgShare from '../../../media/img/createCharacter/share-svgrepo-com (1).svg';
import UiButtonOne from '../../../components/ui/buttons/UiButtonOne';
import TimerInit from '../../../components/timerInit/TimerInit';
import { checkTimeFormat } from '../../../helper/helpers';
import { useRequest } from '../../../hooks/useRequest';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../../components/language/action/line';

const ChooseCharacterBodyModalShare = ({ hero }) => {
  const [requestSend] = useRequest();
  const userHeroes = useSelector((state) => state.allUserData.heroes);
  const [MODAL_INFO_SHARE] = useTranslate('MODAL_INFO_SHARE');
  const [fields, setFields] = useState({
    money: '',
    ethereum: '',
    nameShare: '',
  });
  const [openingShare, setOpeningShare] = useState(1);
  const [heroInfo, setHeroInfo] = useState({});

  const sendShare = () => {
    requestSend.sendData('RequestDriverInvite', {
      email: fields.emailShare,
      hero_id: hero.id,
      money_limits: { 1: +fields.money, 2: +fields.ethereum },
    });
  };
  const sendShareCancel = () => {
    requestSend.sendData('RequestDriverCancel', { hero_id: hero.id });
  };

  useEffect(() => {
    if (userHeroes.length > 0) {
      const fromHero = userHeroes.find((e) => e.id === hero.id);
      console.log('fromHero', fromHero);
      setHeroInfo(fromHero);
      if (fromHero.driver.password_expire_time) {
        setOpeningShare(2);
      }
      if (fromHero.driver.driver_email && fromHero.driver.is_confirm) {
        setOpeningShare(3);
      }
    }
    console.log('MODAL_INFO_SHARE', MODAL_INFO_SHARE);
  }, [userHeroes]);

  return (
    <div className="chooseCharacter__body__container__box__modal">
      <div className="chooseCharacter__body__container__box__modal--info">
        {MODAL_INFO_SHARE.replace(/\/\//g, '\n\n')}
      </div>
      <div className="chooseCharacter__body__container__box__modal__points">
        <div className="chooseCharacter__body__container__box__modal__points--box">
          <img src={imgG} alt="" />
          <UiInput
            name="money"
            placeholder="Динары"
            type="number"
            value={fields.money}
            onChange={(money) => setFields({ ...fields, money })}
            maxValue={heroInfo?.money?.current_money[1]}
          />
        </div>
        <div className="chooseCharacter__body__container__box__modal__points--box">
          <img src={imgE} alt="" />
          <UiInput
            name="etherium"
            placeholder="Эфир"
            type="number"
            value={fields.ethereum}
            onChange={(ethereum) => setFields({ ...fields, ethereum })}
            maxValue={heroInfo?.money?.current_money[2]}
          />
        </div>
        {/*<UiButtonOne*/}
        {/*    onClick={() => ''}*/}
        {/*    disabled={(!fields.money || !fields.ethereum)}*/}
        {/*>*/}
        {/*    Сохранить*/}
        {/*</UiButtonOne>*/}
      </div>
      {openingShare === 1 && (
        <>
          <h4>Разрешить управление персонажем</h4>
          <div className="chooseCharacter__body__container__box__modal--info">
            Чтобы разрешить доступ к вашему персонажу другому игроку, необходимо
            отправить ему приглашение на почту, а также сообщить одноразовый код
            по другому каналу связи. Код появится тут после отправки
            приглашения. Данный адрес должен быть уже зарегистрирован в игре.
          </div>
          <div className="chooseCharacter__body__container__box__modal--shareCode">
            <div className="chooseCharacter__body__container__box__modal--shareCode--box">
              <img src={imgShare} alt="" />
              <UiInput
                name="emailShare"
                placeholder="Email"
                type="email"
                value={fields.emailShare}
                onChange={(emailShare) => setFields({ ...fields, emailShare })}
              />
            </div>
            <UiButtonOne
              onClick={() => sendShare()}
              disabled={!fields.money || !fields.ethereum || !fields.emailShare}
            >
              Пригласить
            </UiButtonOne>
          </div>
        </>
      )}
      {openingShare === 2 && (
        <>
          <h4>Управление доступом</h4>
          <div className="chooseCharacter__body__container__box__modal--info">
            В данный момент ограниченный доступ к персонажу предоставлен
            пользователю {heroInfo?.driver?.driver_email} <br />
            Ему было выслано приглашение, но он его еще не принял. Чтобы он смог
            это сделать, ему необходимо сообщить одноразовый код:
          </div>
          <div className="chooseCharacter__body__container__box__modal--send">
            <div className="chooseCharacter__body__container__box__modal--send--box">
              <div className="chooseCharacter__body__container__box__modal--send--box--code">
                {heroInfo?.driver?.password}
              </div>
              <div>
                Код будет действовать еще{' '}
                <TimerInit
                  next_status_time={heroInfo?.driver?.password_expire_time}
                />
              </div>
            </div>
            <UiButtonOne onClick={() => sendShareCancel()}>
              Отозвать доступ
            </UiButtonOne>
          </div>
        </>
      )}
      {openingShare === 3 && (
        <>
          <h4>Управление доступом</h4>
          <div className="chooseCharacter__body__container__box__modal--info">
            В данный момент ограниченный доступ к персонажу предоставлен
            пользователю {heroInfo?.driver?.driver_email} <br />
            Он принял приглашение и теперь может управлять вашим персонажем.
            Последний раз он заходил в него <br />
            {checkTimeFormat(heroInfo?.driver?.last_use)}
          </div>
          <div className="chooseCharacter__body__container__box__modal--send">
            <UiButtonOne onClick={() => sendShareCancel()}>
              Отозвать доступ
            </UiButtonOne>
          </div>
        </>
      )}
    </div>
  );
};

export default ChooseCharacterBodyModalShare;
