import React from 'react';
import { loadersSpin1 } from '../../loaders/loaders';

const BattleInitSearch = ({ visibility }) => {
  return (
    <div
      className="battle__init--search"
      style={{ visibility: visibility ? 'visible' : 'hidden' }}
    >
      <div className="battle__init--search--box">{loadersSpin1}</div>
    </div>
  );
};

export default BattleInitSearch;
