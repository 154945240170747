import React from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBaseModal,
  setBaseModalData,
} from '../../store/reducer/Slices/SliceModal';
import ApplicationForModalAccept from '../application/ApplicationsForModal/ApplicationForModalAccept';
import ApplicationForModalCreate from '../application/ApplicationsForModal/ApplicationForModalCreate';
import ApplicationForModalStart from '../application/ApplicationsForModal/ApplicationForModalStart';
import BattleForModalEnd from '../battle/BattleForModal/BattleForModalEnd';
import UiButtonOne from '../ui/buttons/UiButtonOne';
import { useTranslate } from '../language/action/line';
import ChooseCharacterForModal from '../../page/ChooseCharacter/ChooseCharacterForModal/ChooseCharacterForModal';
import Lang from '../language';
import ModalLevelUp from './ModalLevelUp/ModalLevelUp';

const BaseModal = () => {
  const dispatch = useDispatch();
  const statusOpen = useSelector((state) => state.baseModal.open);
  const modalData = useSelector((state) => state.baseModal.data);
  const [LangTitle] = useTranslate(modalData.title);
  const [LangDesc] = useTranslate(modalData.desc);

  const dataForModal = {
    1: <ApplicationForModalAccept data={modalData.info} />,
    2: <ApplicationForModalCreate data={modalData} />,
    3: <ApplicationForModalStart data={modalData} />,
    4: <BattleForModalEnd data={modalData} />,
    5: <ChooseCharacterForModal data={modalData} />,
    6: <ModalLevelUp data={modalData} />,
  };

  return (
    <div
      className={`modal ${statusOpen ? 'modal--open' : ''} ${modalData.styles}`} // mini и nota в игре max-width: 300px;
      style={{
        zIndex: modalData.zIndex ? modalData.zIndex : 4,
      }}
    >
      {statusOpen && (
        <div
          className="modal__container"
          style={{
            maxWidth: modalData.maxWidth ? modalData.maxWidth : 'none',
          }}
        >
          <div className="modal__container--content">
            {modalData.title && <h1>{LangTitle}</h1>}
            {modalData.desc && (
              <p>
                {/* <Lang>{modalData.desc}</Lang> */}
                {LangDesc}
                {modalData.descPlus && (
                  <div className="modal__container--content--plus">
                    {modalData.descPlus}
                  </div>
                )}
              </p>
            )}
          </div>
          {modalData.numData && dataForModal[modalData.numData]}
          <div className="modal__container--buttons">
            {modalData.buttonGray && (
              <UiButtonOne
                onClick={() => {
                  dispatch(setBaseModal(false));
                  dispatch(setBaseModalData({}));
                  modalData.buttonGray.buttonGray() &&
                    modalData.buttonGray.buttonGray();
                }}
              >
                <Lang>{modalData.buttonGray.text}</Lang>
              </UiButtonOne>
            )}
            {modalData.buttonRed && (
              <UiButtonOne
                onClick={() => {
                  dispatch(setBaseModal(false));
                  dispatch(setBaseModalData({}));
                  modalData.buttonRed.buttonRed() &&
                    modalData.buttonRed.buttonRed();
                }}
              >
                <Lang>{modalData.buttonRed.text}</Lang>
              </UiButtonOne>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BaseModal;
