import HeroNameLink from '../../HeroNameLink/HeroNameLink';
import Lang from '../../../language';
import React from 'react';
import Abil from '../Abil';

// профильный урон

export default function Templ5({
  isUser,
  message,
  msgDamageSize,
  msgWeapon,
  msgAttackZone,
  dmg,
}) {
  return (
    <div className="toChat">
      <HeroNameLink userHero={message?.a_her?.hero_id} isUser={isUser}>
        {message?.a_her?.name}
      </HeroNameLink>{' '}
      <Lang>
        {'CHAT_WENT_ON_THE_ATTACK' +
          (message?.a_her?.isGenderMan ? '' : '_HER')}
      </Lang>
      .{' '}
      <HeroNameLink userHero={message?.d_her?.hero_id} isUser={!isUser}>
        {message?.d_her?.name}
      </HeroNameLink>{' '}
      <Lang>CHAT_HESITATED_AND</Lang>{' '}
      <HeroNameLink userHero={message?.a_her?.hero_id} isUser={isUser}>
        {message?.a_her?.name}
      </HeroNameLink>
      <Lang>{msgDamageSize}</Lang> <Lang>{msgWeapon}</Lang>
      {message?.a_her?.isGenderMan ? (
        <Lang>CHAT_IS</Lang>
      ) : (
        <Lang>CHAT_ER</Lang>
      )}{' '}
      {message?.a_her?.isGenderMan ? (
        <Lang>CHAT_HIS</Lang>
      ) : (
        <Lang>CHAT_HER</Lang>
      )}{' '}
      <Lang>{msgAttackZone}</Lang>{' '}
      <span className="chat__block1--body--message--item--text--hp--power">
        -{dmg} [{message?.data?.d_hp}]
      </span>
      <Abil message={message} />
    </div>
  );
}
