import React, { useEffect, useState } from 'react';
import './styles.scss';
import Lang from '../../language';
const Select = ({ list, disable }) => {
  const [toSelect, setToSelect] = useState(0);

  useEffect(() => {
    setToSelect(list[0]);
  }, []);

  const handleSelect = (e) => {
    if (e === toSelect) {
      setToSelect(0);
    } else setToSelect(e);
    if (disable) {
      if (toSelect === 0) {
        disable(false);
      } else disable(true);
    }
  };

  return (
    <div className="select-ui">
      {list.map((e, i) => (
        <div
          className={`select-ui--nav ${toSelect === e && 'select-ui--nav--active'}`}
          key={i}
          onClick={() => handleSelect(e)}
        >
          <div />
          <div>
            <Lang>{e}</Lang>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Select;
