import React from 'react';
import { useDictionary } from '../../../hooks/useDictionary';
import { Naming } from '../../../helper/naming';
import Images from '../../../helper/Images';
import Lang from '../../language';
import { timeUnixRange } from '../../../helper/helpers';

const InventoryHubEffect = ({ toItem }) => {
  const [dict, getInfoDictionary] = useDictionary();
  const eff = getInfoDictionary('effects', toItem.dict_id);

  const toTurn = (turns) => {
    switch (turns) {
      case 1:
        return `Остался: ${turns} ход`;
      case 2:
      case 3:
      case 4:
        return `Осталось: ${turns} хода`;
      default:
        return turns >= 5 ? `Осталось: ${turns} ходов` : null;
    }
  };

  const time = toItem?.all?.finish_time
    ? timeUnixRange(+toItem.all.finish_time)
    : toTurn(toItem?.all?.finish_turn);

  // console.log('toItem', toItem, eff.effect_type);

  return (
    <div className="inventory__container__hub--body--skills--item">
      <div className="inventory__container__hub--body--skills--item--box">
        <div
          className={`inventory__container__hub--body--skills--item--box--icon--eff`}
        >
          {/* <Images
            name={'abil.1'}
          /> */}
        </div>
        <div className="inventory__container__hub--body--skills--item--box--head">
          <div className="inventory__container__hub--body--skills--item--box--head--title">
            <Lang>EFFECT</Lang>: <Lang>{toItem.dict_id}</Lang>{' '}
            {/* Мок пока-что нет списка имен */}
          </div>
        </div>
      </div>
      <div
        className="inventory__container__hub--body--skills--item--box"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        <div className="inventory__container__hub--body--skills--item--box--info">
          <div
            className="inventory__container__hub--body--skills--item--box--info--other"
            // style={{ maxWidth: '100px' }}
          >
            <Lang>TOYOU</Lang>: <Lang>{toItem.dict_id}</Lang>{' '}
          </div>
          <div className="inventory__container__hub--body--skills--item--box--info--other">
            {time && time}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryHubEffect;
