import { createSlice } from '@reduxjs/toolkit';

const mapOpen = createSlice({
  name: 'mapOpen',
  initialState: { open: false },
  reducers: {
    setOpenMap(state, action) {
      state.open = action.payload;
    },
  },
});

export const { setOpenMap } = mapOpen.actions;

export { mapOpen };
