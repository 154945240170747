import React, { useState } from 'react';
import './styles.scss';

import imgClose from '../../media/img/inventory/close.png';
import imgG from '../../media/img/inventory/gold.png';
import imgE from '../../media/img/inventory/etherium.png';
import imgP from '../../media/img/inventory/+.png';
import imgR from '../../media/img/inventory/→.png';
import InventoryHub from './InventoryHub';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenInventory } from '../../store/reducer/Slices/SlicesInventory';
import Modal from '../modal/Modal';
import InventorySendMoney from './InventorySendMoney';
import DragAndDrop from '../dragAndDrop/DragAndDrop';
import InventoryItemCell from './InventoryItemCell/InventoryItemCell';
import { useRequest } from '../../hooks/useRequest';
import { useDictionary } from '../../hooks/useDictionary';
import InventoryHubThingsItemBuffs from './InventoryHubThings/InventoryHubThingsItemBuffs';
import Tooltip from '../tooltip/Tooltip';
import InventoryHubThingsItemRun from './InventoryHubThings/InventoryHubThingsItemRun';
import Images from '../../helper/Images';
import InventoryHubThingsItemOther from './InventoryHubThings/InventoryHubThingsItemOther';

const miniModal = {
  sendMoney: <InventorySendMoney />,
};

const InventoryMiniSlots = ({ mini = true }) => {
  const dispatch = useDispatch();
  const userBody = useSelector((state) => state.allUserData.data.body);
  const [requestSend] = useRequest();
  const [dict, getInfoDictionary] = useDictionary();
  const [tooltip, setTooltip] = useState(null);

  const toActiveData = (elem) => {
    requestSend.sendData('RequestOperationalSlotChange', {
      slot_source: elem.old.slot,
      slot_dest: elem.slot,
    });
  };

  const toDropData = (elem) => {
    if (elem.old.slot) {
      requestSend.sendData('RequestOperationalSlotChange', {
        slot_source: elem.old.slot,
        slot_dest: elem.slot,
      });
    } else {
      requestSend.sendData('RequestOperationalSlotOn', {
        slot: elem.slot,
        id: elem.old.id,
      });
    }
  };

  return userBody.oper_slots.map((i, index) => {
    if (i.model) {
      const infoSkills = getInfoDictionary('equipments', i.model.dict_id);
      return (
        <Tooltip
          zIndex={18}
          maxWidth="350px"
          key={i.id + 'infoWeaponsBattleM' + index}
          open={true}
          content={
            infoSkills.item_type <= 2 ? (
              <InventoryHubThingsItemOther toItem={i.model} />
            ) : (
              <InventoryHubThingsItemRun toItem={infoSkills} />
            )
          }
        >
          <DragAndDrop
            key={index + 'combMin'}
            dropType="skillOneList"
            dropData={(e) => {
              toActiveData({ old: e, slot: i.slot });
            }}
            className={
              mini
                ? 'game--container__left--block__characteristics--mini-slots--item'
                : ''
            }
            dragData={{
              data: { data: i.model, slot: i.slot },
              dragType: 'skillOneList',
            }}
          >
            {mini ? (
              <div
                onClick={() =>
                  requestSend.sendData('RequestOperationalSlotOff', {
                    slot: i.slot,
                  })
                }
              >
                <Images name={i.model.dict_id} />
              </div>
            ) : (
              <InventoryItemCell type="mini">
                <div
                  onContextMenu={() => {
                    // toDropData(userContext.userSkillsData.combatEquips[index])
                  }}
                  onClick={() =>
                    requestSend.sendData('RequestOperationalSlotOff', {
                      slot: i.slot,
                    })
                  }
                  className={
                    mini
                      ? ''
                      : 'inventory__container__hub--body--skills--equipments--combat--item'
                  }
                >
                  <Images name={i.model.dict_id} />
                </div>
              </InventoryItemCell>
            )}
          </DragAndDrop>
        </Tooltip>
      );
    } else {
      return (
        <DragAndDrop
          dropType="skillOneList"
          dropData={(e) => {
            toDropData({ old: e, slot: i.slot });
          }}
          className={
            mini
              ? `game--container__left--block__characteristics--mini-slots--item ${!i.open ? 'game--container__left--block__characteristics--mini-slots--item__closed' : ''}`
              : ''
          }
          key={index + 'combsc'}
        >
          {mini ? (
            i.slot > userBody.oper_slots_count && <img src={imgClose} alt="" />
          ) : (
            <div className="inventory__item--mini">
              <div
                className={`inventory__container__hub--body--skills--equipments--combat--item ${i.slot <= userBody.oper_slots_count ? '' : 'inventory__container__hub--body--skills--equipments--combat--item__closed'}`}
              >
                {i.slot > userBody.oper_slots_count && (
                  <img src={imgClose} alt="" />
                )}
              </div>
            </div>
          )}
        </DragAndDrop>
      );
    }
  });
};

export default InventoryMiniSlots;
