import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import blue from '../../../media/img/battle/blue.png';
import red from '../../../media/img/battle/red.png';
import ActiveBar from '../../activeBar/ActiveBar';
import imgA1 from '../../../media/img/character/av/cha1.png';
import imgA2 from '../../../media/img/character/av/cha2.png';
import imgA3 from '../../../media/img/character/av/cha3.png';
import imgA4 from '../../../media/img/character/av/cha4.png';
import imgA5 from '../../../media/img/character/av/cha5.png';
import imgA6 from '../../../media/img/character/av/cha6.png';
import imgA7 from '../../../media/img/character/av/cha7.png';
import imgA8 from '../../../media/img/character/av/cha8.png';
import imgCR from '../../../media/img/battle/anim/WindAndHeart-(1).png';
import imgDO from '../../../media/img/battle/anim/WindAndHeart-(2).png';
import imgMob1 from '../../../media/img/npc/av/Mob1.png';
import imgMob2 from '../../../media/img/npc/av/Mob2.png';
import imgMob3 from '../../../media/img/npc/av/Mob3.png';
import imgMob4 from '../../../media/img/npc/av/Mob4.png';
import imgMob5 from '../../../media/img/npc/av/Mob5.png';
import imgMob6 from '../../../media/img/npc/av/Mob6.png';
import imgMob7 from '../../../media/img/npc/av/Mob7.png';
import imgMob8 from '../../../media/img/npc/av/Mob8.png';
import imgMob9 from '../../../media/img/npc/av/Mob9.png';
import Lang from '../../language';
import { useDictionary } from '../../../hooks/useDictionary';

const msgsAttackZones = {
  1: {
    swr: 'удар мечом по шее',
    ham: 'удар молотом в висок',
    axe: 'удар топором в лоб',
    dagger: 'удар кинжалом по шее',
  },
  2: {
    swr: 'удар мечом по груди',
    ham: 'удар молотом по ребрам',
    axe: 'удар топором в грудь',
    dagger: 'удар кинжалом по ребрам',
  },
  3: {
    swr: 'удар мечом по поясу',
    ham: 'удар молотом по животу',
    axe: 'удар топором по животу',
    dagger: 'удар кинжалом по поясу',
  },
  4: {
    swr: 'удар мечом по ноге',
    ham: 'удар молотом по колену',
    axe: 'удар топором по ноге',
    dagger: 'удар кинжалом по колену',
  },
};

const BattleInitBattleProgress = ({ visibility }) => {
  const dataChat = useSelector((state) => state.chatData.data);
  const userData = useSelector((state) => state.allUserData.data);
  const battleStatus = useSelector((state) => state.battle.status);
  const battleData = useSelector((state) => state.battle.myTotal);
  const pairCurrent = useSelector((state) => state.battleCurrent.pairCurrent);
  const characterIDList = new Map([
    ['her.1', imgA1],
    ['her.2', imgA2],
    ['her.3', imgA3],
    ['her.4', imgA4],
    ['her.5', imgA5],
    ['her.6', imgA8],
    ['her.7', imgA6],
    ['her.8', imgA7],
  ]);
  const [dict, getInfoDictionary] = useDictionary();
  const bots = dict?.bot_presets ? dict.bot_presets : [];
  const botIDList = new Map([
    ['avatar1', imgMob1],
    ['avatar2', imgMob2],
    ['avatar3', imgMob3],
    ['avatar4', imgMob4],
    ['avatar5', imgMob5],
    ['avatar6', imgMob6],
    ['avatar7', imgMob7],
    ['avatar8', imgMob8],
    ['avatar9', imgMob9],
  ]);
  const [newLastData, setNewLastData] = useState([]);
  const [infoAll, setInfoAll] = useState({
    total_crit: 0,
    total_damage: 0,
    total_dodge: 0,
  });
  const [avatar, setAvatar] = useState(null);

  useMemo(() => {
    const ff = [...dataChat].reverse();
    const myBattle = ff.filter(
      (data) =>
        data.data?.a_her_id === userData.id ||
        data.data?.d_her_id === userData.id ||
        data.data?.dest_hero_id === userData.id,
    );
    const opponent = myBattle.map((e) => {
      if (e.data.$type !== 35) {
        if (e.data.a_her_id !== userData.id) {
          let tuRet = {
            opponent: e.a_her,
            typeAttack: 'attack',
            hp: e.a_her.hp,
            data: e.data,
            sender: e.sender,
            templNum: e.templNum,
            time: e.time,
            type: e.type,
          };
          return tuRet;
        }
        if (e.data.d_her_id !== userData.id) {
          let tuRet = {
            opponent: e.d_her,
            typeAttack: 'def',
            hp: e.d_her.hp,
            data: e.data,
            sender: e.sender,
            templNum: e.templNum,
            time: e.time,
            type: e.type,
          };

          return tuRet;
        }
      }
    });
    if (myBattle.length > 0) {
      if (battleStatus === 2) {
        if (opponent.length > 2) {
          let result = [];
          if (opponent.length - 2 !== 0) {
            result = opponent.slice(opponent.length - 2);
            setNewLastData([
              {
                one: result[0],
                two: result[1],
              },
            ]);
          } else {
            result = opponent.slice(2);
            setNewLastData([
              {
                one: result[0],
                two: result[1],
              },
            ]);
          }
        } else
          setNewLastData([
            {
              one: opponent[0],
              two: opponent[1],
            },
          ]);
      } else setNewLastData([]);
    } else setNewLastData([]);
  }, [dataChat]);

  useEffect(() => {
    setInfoAll(battleData);
  }, [battleData]);

  useEffect(() => {
    const getAvatar = () => {
      if (newLastData.length > 0) {
        const opponent =
          newLastData[0]?.one?.opponent || newLastData[0]?.two?.opponent;
        if (opponent?.name && opponent?.name.includes('$') && bots.length > 0) {
          let name = opponent?.name.split('$');
          let pic = bots.filter(
            (el) => name[0].includes(el.name) && el.name !== '',
          );
          if (pic[0]?.id.startsWith('mob')) {
            return botIDList.get(pic[0].avatar);
          } else {
            return characterIDList.get(
              pairCurrent?.hero_data?.h_info?.preset_id,
            );
          }
        } else {
          return characterIDList.get(pairCurrent?.hero_data?.h_info?.preset_id);
        }
      }
      return null;
    };

    setAvatar(getAvatar());
  }, [newLastData]);

  return (
    <>
      <div className="battle--result">
        {newLastData.length > 0 &&
          newLastData.map((e) => {
            console.log('e myStat', e);
            // if (!e?.one?.opponent?.length || !e?.two?.opponent?.length) {
            //   return null;
            // }
            let attack = e.one?.typeAttack === 'attack' ? e.one : e.two;
            let def = e.two?.typeAttack === 'def' ? e.two : e.one;

            const msgAttackZone = attack?.opponent?.weapon
              ? msgsAttackZones[attack.data.a_zone][attack?.opponent.weapon]
              : null;

            const checkDodge = attack?.data?.d_dodge || attack?.data?.d_parry;
            // const img = characterIDList.get(attack?.opponent?.preset_id);
            // const img = characterIDList.get(pairCurrent?.hero_data?.h_info?.preset_id)
            const img = avatar;

            return (
              <div key={def?.time}>
                <div className="battle--result--box">
                  <div className="battle--result--box--head">
                    <img src={img} alt={def?.opponent.preset_id} />
                    <div className="battle--result--box--head--hr">
                      <div className="battle--result--box--head--hr--name">
                        {def?.opponent.name.split('$')[0]}
                      </div>
                      <div>
                        <ActiveBar
                          classOne="active-bar--one"
                          classTwo="active-bar--one--active"
                          one={def?.hp}
                          two={def?.opponent.max_hp}
                          check={{
                            plus: 'grin',
                            minus: 'red',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="battle--result--box--body">
                    <div className="chat__block1--body--message--item--attack">
                      <span
                        className={`chat__block1--body--message--item--attack--container ${
                          userData.id === attack?.data?.a_her_id
                            ? 'chat__block1--body--message--item--attack--container__allies'
                            : 'chat__block1--body--message--item--attack--container__opponents'
                        }`}
                      >
                        {Array.from(Array(4).keys()).map((target, index) => (
                          <span
                            key={target}
                            className={`chat__block1--body--message--item--attack--container--target ${
                              attack?.data?.d_zones.find(
                                (i) => i === target + 1,
                              ) ||
                              attack?.data?.d_s_zone === target + 1 ||
                              attack?.data?.d_dodge ||
                              attack?.data?.d_parry
                                ? 'chat__block1--body--message--item--attack--container--target__defence'
                                : ''
                            }`}
                          >
                            {attack?.data?.a_zone === target + 1 && (
                              <span
                                className={`chat__block1--body--message--item--attack--container--target--attack`}
                              />
                            )}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className="battle--result--box--body--info">
                      {attack?.typeAttack === 'def' ? (
                        <img src={red} alt="" />
                      ) : (
                        <img
                          src={blue}
                          style={{
                            transform: 'rotate(180deg)',
                          }}
                          alt=""
                        />
                      )}
                      {attack?.data?.a_damag > 0 && (
                        <div className="battle--result--box--body--damage">
                          -{attack.data.a_damag} HP{' '}
                          {attack.data.a_crit && (
                            <>
                              <div className="battle--result--box--body--damage--crit">
                                КРИТ
                              </div>{' '}
                              Удар пробит!
                            </>
                          )}
                        </div>
                      )}{' '}
                      {msgAttackZone && attack?.typeAttack === 'def' ? (
                        <div className="battle--result--box--body--text">
                          {checkDodge || attack?.data?.a_damag === 0
                            ? 'Защитил'
                            : 'Получил'}{' '}
                          <Lang>{msgAttackZone}</Lang>
                        </div>
                      ) : (
                        <div className="battle--result--box--body--text">
                          Нанес <Lang>{msgAttackZone}</Lang>
                        </div>
                      )}
                    </div>
                    <div className="chat__block1--body--message--item--attack">
                      <span
                        className={`chat__block1--body--message--item--attack--container ${
                          userData.id === def?.data.a_her_id
                            ? 'chat__block1--body--message--item--attack--container__allies'
                            : 'chat__block1--body--message--item--attack--container__opponents'
                        }`}
                      >
                        {Array.from(Array(4).keys()).map((target, index) => (
                          <span
                            key={target}
                            className={`chat__block1--body--message--item--attack--container--target ${
                              def?.data.d_zones.find((i) => i === target + 1) ||
                              def?.data.d_s_zone === target + 1 ||
                              def?.data.d_dodge ||
                              def?.data.d_parry
                                ? 'chat__block1--body--message--item--attack--container--target__defence'
                                : ''
                            }`}
                          >
                            {def?.data.a_zone === target + 1 && (
                              <span
                                className={`chat__block1--body--message--item--attack--container--target--attack`}
                              />
                            )}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className="battle--result--box--body--info">
                      {def?.typeAttack === 'def' ? (
                        <img src={red} alt="" />
                      ) : (
                        <img
                          src={blue}
                          style={{
                            transform: 'rotate(180deg)',
                          }}
                          alt=""
                        />
                      )}
                      {def?.data.a_damag > 0 && (
                        <div className="battle--result--box--body--damage">
                          -{def.data.a_damag} HP{' '}
                          {def.data.a_crit && (
                            <>
                              <div className="battle--result--box--body--damage--crit">
                                КРИТ
                              </div>{' '}
                              Удар пробит!
                            </>
                          )}
                        </div>
                      )}{' '}
                      {msgAttackZone && def?.typeAttack === 'def' ? (
                        <div className="battle--result--box--body--text">
                          {checkDodge || def.data.a_damag === 0
                            ? 'Защитил'
                            : 'Получил'}{' '}
                          <Lang>{msgAttackZone}</Lang>
                        </div>
                      ) : (
                        <div className="battle--result--box--body--text">
                          Нанес <Lang>{msgAttackZone}</Lang>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="battle--result--box__stat">
                    <div className="battle--result--box__stat--one">
                      <Lang>DAMAGE</Lang>: <b>{infoAll?.total_damage}</b>
                    </div>
                    <div className="battle--result--box__stat--two">
                      <div className="battle--result--box__stat--two--left">
                        <img src={imgCR} alt="" />
                        {infoAll?.total_crit}
                      </div>
                      <div className="battle--result--box__stat--two--right">
                        <img src={imgDO} alt="" />
                        {infoAll?.total_dodge}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default BattleInitBattleProgress;
