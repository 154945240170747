import React from 'react';
import Lang from '../../language';
import Tooltip from '../../tooltip/Tooltip';
import InventoryHubSkillsItem from '../../Inventory/InventoryHubSkills/InventoryHubSkillsItem';
import { useDictionary } from '../../../hooks/useDictionary';

const Abil = ({ message }) => {
  const [dict, getInfoDictionary] = useDictionary();

  return (
    <>
      {message.data?.a_abilities_succes?.length > 0 && (
        <>
          <Lang>AND_SUCCESS_USE</Lang>{' '}
          {message.data?.a_abilities_succes.length === 1 ? (
            <>
              <Tooltip
                key={Math.random()}
                zIndex={9990}
                content={
                  <InventoryHubSkillsItem
                    fromItem={getInfoDictionary(
                      'equipments',
                      message.data?.a_abilities_succes[0],
                    )}
                    toItem={getInfoDictionary(
                      'equipments',
                      message.data?.a_abilities_succes[0],
                    )}
                  />
                }
              >
                <p
                  style={{ color: 'green', fontStyle: 'italic', margin: '0px' }}
                >
                  <Lang>{message.data?.a_abilities_succes[0]}</Lang>
                </p>
              </Tooltip>
            </>
          ) : message.data?.a_abilities_succes.length === 2 ? (
            <>
              <Tooltip
                key={Math.random()}
                zIndex={9990}
                content={
                  <InventoryHubSkillsItem
                    fromItem={getInfoDictionary(
                      'equipments',
                      message.data?.a_abilities_succes[0],
                    )}
                    toItem={getInfoDictionary(
                      'equipments',
                      message.data?.a_abilities_succes[0],
                    )}
                  />
                }
              >
                <p
                  style={{ color: 'green', fontStyle: 'italic', margin: '0px' }}
                >
                  <Lang>{message.data?.a_abilities_succes[0]}</Lang>
                </p>
              </Tooltip>{' '}
              <Lang>AND</Lang>{' '}
              <Tooltip
                key={Math.random()}
                zIndex={9990}
                content={
                  <InventoryHubSkillsItem
                    fromItem={getInfoDictionary(
                      'equipments',
                      message.data?.a_abilities_succes[1],
                    )}
                    toItem={getInfoDictionary(
                      'equipments',
                      message.data?.a_abilities_succes[1],
                    )}
                  />
                }
              >
                <p
                  style={{ color: 'green', fontStyle: 'italic', margin: '0px' }}
                >
                  <Lang>{message.data?.a_abilities_succes[1]}</Lang>
                </p>
              </Tooltip>
            </>
          ) : (
            <p style={{ color: 'green', fontStyle: 'italic', margin: '0px' }}>
              <Lang>ABILITY_SERIES</Lang>
            </p>
          )}
        </>
      )}
      {message.data?.a_abilities_failed?.length > 0 && (
        <>
          <Lang>AND_NOT_SUCCESS_USE</Lang>{' '}
          {message.data?.a_abilities_failed?.map((e) => {
            const infoWeapons = getInfoDictionary('equipments', e);
            return (
              <Tooltip
                key={Math.random()}
                zIndex={9990}
                content={
                  <InventoryHubSkillsItem
                    fromItem={infoWeapons}
                    toItem={infoWeapons}
                  />
                }
              >
                <p
                  style={{ color: 'green', fontStyle: 'italic', margin: '0px' }}
                >
                  <Lang>{e}</Lang>
                </p>
              </Tooltip>
            );
          })}
        </>
      )}
    </>
  );
};

export default Abil;
