import React, { useEffect, useState, useRef } from 'react';
import './styles.scss';

const TooltipInput = ({
  open,
  children,
  content,
  theme,
  contentPlus,
  style,
  styleInit,
}) => {
  const [statusOpen, setStatusOpen] = useState(false);
  const toRef = useRef(null);

  useEffect(() => {
    setStatusOpen(open);
  }, [open]);

  return (
    <div
      className={`tooltip--input ${theme && theme}`}
      ref={toRef}
      style={styleInit}
    >
      {children}
      {statusOpen && (
        <div className="tooltip--input--active" style={style}>
          {content}
          {contentPlus && contentPlus}
          <i></i>
        </div>
      )}
    </div>
  );
};

export default TooltipInput;
