import React from 'react';
import { Naming } from '../../helper/naming';
import Images from '../../helper/Images';
import Lang from '../language';

const InventoryDelObject = ({ info, user, img }) => {
  return (
    <div className="inventory__preObject">
      <div className="inventory__preObject--in">
        <div className={`inventory__preObject--img rr-${info.quality}`}>
          <Images name={img} />
        </div>
        <div className="inventory__preObject--info">
          <h4>
            <Lang>{info.id}</Lang>
          </h4>
          <p>
            <Lang>QUALITY</Lang>: <Lang>{Naming.quality[info.quality]}</Lang> (
            <Lang>{Naming.points}</Lang> {user.points}/{info.points_up})
          </p>
        </div>
      </div>
    </div>
  );
};

export default InventoryDelObject;
