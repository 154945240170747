import Templ1 from '../templates/Attack/Templ1';
import Templ2 from '../templates/Attack/Templ2';
import Templ3 from '../templates/Attack/Templ3';
import Templ4 from '../templates/Attack/Templ4';
import Templ5 from '../templates/Attack/Templ5';

const templates = [Templ1, Templ2, Templ3];

export default function ChatMessageAttack(props) {
  let Tmpl = templates[Math.floor(Math.random() * 3)];
  console.log('props.message',props.message)
  if (props.message.data.a_crit) {
    Tmpl = Templ4;
  }

  if (props.message.data.a_power) {
    Tmpl = Templ5;
  }

  if (props.message.data.a_contr_att) {
  }

  return <Tmpl {...props} />;
}
