import React, { useState } from 'react';
import InventoryHubThings from './InventoryHubThings/InventoryHubThings';
import InventoryHubSkills from './InventoryHubSkills/InventoryHubSkills';
import InventoryHubMagazine from './InventoryHubMagazine/InventoryHubMagazine';
import Lang from '../language';

const tabs = [
  {
    id: 1,
    name: 'CHARACTER',
  },
  {
    id: 2,
    name: 'THINGS',
  },
  {
    id: 3,
    name: 'SKILLS',
  },
  {
    id: 4,
    name: 'GUARDIANS',
  },
  {
    id: 5,
    name: 'CLAN',
  },
  {
    id: 6,
    name: 'MAGAZINE',
  },
];

const hub = {
  1: <div className="inventory__container__hub--body"></div>,
  2: <InventoryHubThings />,
  3: <InventoryHubSkills />,
  4: <div className="inventory__container__hub--body"></div>,
  5: <div className="inventory__container__hub--body"></div>,
  6: <InventoryHubMagazine />,
};

const InventoryHub = () => {
  const [tab, setTab] = useState(1);

  return (
    <div className="inventory__container__hub">
      <div className="inventory__container__hub--tabs">
        {tabs.map((i) => (
          <div
            key={i.name + 'hub'}
            className={`inventory__container__hub--tabs--item ${tab === i.id ? 'inventory__container__hub--tabs--item--active' : ''}`}
            onClick={() => setTab(i.id)}
          >
            <Lang>{i.name}</Lang>
          </div>
        ))}
      </div>
      {hub[tab]}
    </div>
  );
};

export default InventoryHub;
