import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react';

const shopOpen = createSlice({
  name: 'ShopOpen',
  initialState: {
    open: false,
    items: {},
    price: [],
    levelUpModal: {},
    respawnModal: {},
    purchase: null,
  },
  reducers: {
    setOpenShop(state, action) {
      state.open = action.payload;
    },
    setShopItems(state, action) {
      state.items = action.payload;
    },
    setShopPrices(state, action) {
      const newPrice = action.payload;
      const existingIndex = state.price.findIndex(
        (price) => price.id === newPrice.id,
      );
      if (existingIndex !== -1) {
        state.price[existingIndex] = newPrice;
      } else {
        state.price = state.price.concat(newPrice);
      }
    },
    setLevelUpModal(state, action) {
      state.levelUpModal = action.payload;
    },
    setRespawnModal(state, action) {
      state.respawnModal = action.payload;
    },
    setPurchase(state, action) {
      state.purchase = action.payload;
    },
  },
});

export const {
  setOpenShop,
  setShopItems,
  setShopPrices,
  setLevelUpModal,
  setRespawnModal,
  setPurchase,
} = shopOpen.actions;

export { shopOpen };
