import React from 'react';
import imgWinTop from '../../../media/img/ui/winLose/ui-win-top.png';
import imgWinLeft from '../../../media/img/ui/winLose/ui-win-left.png';
import imgWinRight from '../../../media/img/ui/winLose/ui-win-right.png';
import imgLoseTop from '../../../media/img/ui/winLose/ui-lose-top.png';
import imgLoseLeft from '../../../media/img/ui/winLose/ui-lose-left.png';
import imgLoseRight from '../../../media/img/ui/winLose/ui-lose-right.png';
import imgAnim from '../../../media/img/ui/winLose/vfx_WinLoseScreen_b&w.gif';

const BattleForModalEndHead = ({ status }) => {
  return (
    <div className="modal-end-head">
      <div className={`modal-end-head--back win${status}`}>
        <img src={imgAnim} />
      </div>
      <img
        src={status ? imgWinTop : imgLoseTop}
        className="modal-end-head--top"
      />
      <img
        src={status ? imgWinLeft : imgLoseLeft}
        className="modal-end-head--left"
      />
      <img
        src={status ? imgWinRight : imgLoseRight}
        className="modal-end-head--right"
      />
    </div>
  );
};

export default BattleForModalEndHead;
