import React, { useEffect, useState } from 'react';
import {
  setBaseModal,
  setBaseModalData,
} from '../../../store/reducer/Slices/SliceModal';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from '../../../hooks/useRequest';
import { checkMinMax, getRangeTime } from '../../../helper/helpers';
import { setApplicationsTime } from '../../../store/reducer/Slices/SlicesApplication';
import { useDictionary } from '../../../hooks/useDictionary';
import UiButtonOne from '../../ui/buttons/UiButtonOne';
import Lang from '../../language';

const ApplicationForModalCreate = ({ data }) => {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();
  const [inputs, setInputs] = useState({});
  const [status, setStatus] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [dict, getInfoDictionary] = useDictionary();
  const constants = dict?.constants ? dict?.constants : null;
  const reqType = [
    {
      type: 3,
      min: 2,
      max: constants ? constants.find((obj) => obj.id === '13').value : 50,
    },
    {
      type: 4,
      min: constants ? constants.find((obj) => obj.id === '23').value : 5,
      max: constants ? constants.find((obj) => obj.id === '17').value : 100,
    },
  ];
  const currentType = reqType.find((item) => item.type === data.type);

  const offModal = () => {
    dispatch(setBaseModal(false));
    dispatch(setBaseModalData({}));
  };

  const create = () => {
    // if (disabledButton && Boolean(inputs.one) && Boolean(inputs.three)) {
    if (disabledButton) {
      offModal();
      requestSend.sendData('RequestBattleRequestCreate', {
        $type: 17,
        request_type: data.type,
        bots_count: inputs.two,
        wait_time: inputs.three * 60,
        memb_count: inputs.one,
      });
    }
  };

  const checkValid = (e, min, max) => {
    const { val, status } = checkMinMax(e, min, max);
    if (!isNaN(val)) {
      setInputs({
        ...inputs,
        [e.target.name]: val,
        [e.target.name + 'Status']: status,
      });
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: '',
        [e.target.name + 'Status']: false,
      });
    }
  };

  useEffect(() => {
    setDisabledButton(
      inputs.oneStatus &&
        inputs.twoStatus &&
        inputs.threeStatus &&
        Boolean(inputs.one) &&
        Boolean(inputs.two) &&
        Boolean(inputs.three) &&
        inputs.one > inputs.two,
    );
  }, [status, inputs]);

  return (
    <div className="application__forModal">
      <div>
        <Lang>FIGHTERS_WITH</Lang>
      </div>
      <div className="application__forModal--box">
        <div className="application__forModal--box--one">
          <div className="application__forModal--box--one--item">
            <div className="application__forModal--box--one--item--text">
              <Lang>FIGHTERS</Lang>
            </div>
            <input
              name="one"
              type="number"
              min={currentType?.min || 2}
              max={currentType?.max || 50}
              // placeholder="2"
              value={inputs.one && inputs.one}
              onChange={(e) =>
                checkValid(e, currentType?.min || 2, currentType?.max || 50)
              }
            />
          </div>
          <div className="application__forModal--box--one--item">
            <div className="application__forModal--box--one--item--text">
              <Lang>OF_THEM_SPIRITS</Lang>
            </div>
            <input
              name="two"
              type="number"
              min="1"
              // placeholder="1"
              value={inputs.two && inputs.two}
              onChange={(e) => checkValid(e, 0, 250)}
            />
          </div>
        </div>
        <div className="application__forModal--box--one">
          <div className="application__forModal--box--one--item--text">
            <Lang>WAITING_TIME</Lang> (<Lang>FROM</Lang>{' '}
            {dict.constants.find((i) => i.id === '12').value / 60}{' '}
            <Lang>TO</Lang>{' '}
            {dict.constants.find((i) => i.id === '11').value / 60}{' '}
            <Lang>MINUTES</Lang>)
          </div>
          <input
            name="three"
            type="number"
            // placeholder="1"
            min={dict.constants.find((i) => i.id === '12').value / 60}
            max={dict.constants.find((i) => i.id === '11').value / 60}
            value={inputs.three && inputs.three}
            onChange={(e) =>
              checkValid(
                e,
                dict.constants.find((i) => i.id === '12').value / 60,
                dict.constants.find((i) => i.id === '11').value / 60,
              )
            }
          />
        </div>
      </div>
      <div className="modal__container--buttons">
        <UiButtonOne
          onClick={() => {
            offModal();
          }}
        >
          <Lang>CANCEL</Lang>
        </UiButtonOne>
        <UiButtonOne
          disabled={!disabledButton}
          onClick={() => {
            create();
          }}
        >
          <Lang>CREATE</Lang>
        </UiButtonOne>
      </div>
    </div>
  );
};

export default ApplicationForModalCreate;
