import { createSlice } from '@reduxjs/toolkit';

const battleCurrent = createSlice({
  name: 'battleCurrent',
  initialState: {
    pairCurrent: {},
    pairList: [],
  },
  reducers: {
    setPairCurrent(state, action) {
      state.pairCurrent = action.payload;
    },
    setPairList(state, action) {
      state.pairList = action.payload;
    },
  },
});

export const { setPairCurrent, setPairList } = battleCurrent.actions;

export { battleCurrent };
