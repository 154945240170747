import { createSlice } from '@reduxjs/toolkit';

const heroData = createSlice({
  name: 'heroData',
  initialState: {
    data: {
      body: {},
      char: {},
      h_effects: {},
      h_info: {
        preset_id: '',
      },
      h_location: {},
      id: '',
      level: {},
      skills: {},
    },
  },
  reducers: {
    setHeroData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setHeroData } = heroData.actions;

export { heroData };
