import React, { useEffect, useState } from 'react';
import imgCircle from '../../media/img/app/circle.png';
import { useSelector } from 'react-redux';
import { useRequest } from '../../hooks/useRequest';
import { RequestBattleTypeInvert } from '../../helper/reqRspName';
import { filterOnlyMy, timer } from '../../helper/helpers';
import TimerInit from '../timerInit/TimerInit';
import Lang from '../language';

const ApplicationList = ({ tab, data, onlyMy }) => {
  const userGlory = useSelector((state) => state.allUserData.data.level.glory);
  const userInfo = useSelector((state) => state.allUserData.user_main);
  const userBattle = useSelector(
    (state) => state.allUserData.data.h_battle_req,
  );
  const [requestSend] = useRequest();

  const toSendData = (id) => {
    if (userBattle.request_id) {
      requestSend
        .sendData('RequestBattleRequestsCancel', {
          $type: 20,
          id: userBattle.request_id,
          request_type: RequestBattleTypeInvert[tab],
        })
        .then((r) => {
          requestSend.sendData('RequestBattleRequestJoin', {
            id,
            request_type: RequestBattleTypeInvert[tab],
          });
        });
    } else {
      requestSend.sendData('RequestBattleRequestJoin', {
        id,
        request_type: RequestBattleTypeInvert[tab],
      });
    }
  };

  return (
    data[tab] &&
    data[tab].map(
      (i, idx) =>
        filterOnlyMy(
          userGlory,
          i,
          onlyMy,
          RequestBattleTypeInvert[tab],
          userInfo,
        ) && (
          <div
            key={i.creator_info.hero_name + idx * 2}
            className="application--body--item"
          >
            <div className="application--body--item--one">
              <img src={imgCircle} alt="" />
              <div className="application--body--item--one--lvl">
                {i.creator_info.glory_level}
              </div>
            </div>
            <div className="application--body--item--two">
              <div className="application--body--item--two--top">
                <div className="application--body--item--two--top--name">
                  {i.creator_info.hero_name}
                </div>
                {(tab === 'bat_req_random' || tab === 'bat_req_himself') && (
                  <div className="application--body--item--two--top--time">
                    <TimerInit next_status_time={i.next_status_time} />
                  </div>
                )}
              </div>
              {(tab === 'bat_req_random' || tab === 'bat_req_himself') && (
                <div className="application--body--item--two--bottom">
                  <div className="application--body--item--two--bottom--count">
                    {i.members_count} / {i.max_members}
                  </div>
                  <Lang>FIGHTERS_CF_GLORY</Lang> {i.glory_average}
                </div>
              )}
              {tab === 'bat_req_pvp' && (
                <div className="application--body--item--two--bottom">
                  {i.creator_info.clan_name}
                </div>
              )}
            </div>
            <div className="application--body--item--three">
              {(userBattle.request_id.length === 0 ||
                tab === 'bat_req_pvp') && (
                <div
                  className="application--button"
                  onClick={() => toSendData(i.id)}
                >
                  {tab === 'bat_req_random' || tab === 'bat_req_himself' ? (
                    <>
                      <Lang>JOIN</Lang>
                    </>
                  ) : (
                    <>
                      <Lang>TAKE_THE_CHALLENGE</Lang>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ),
    )
  );
};

export default ApplicationList;
