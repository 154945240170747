import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './window.scss';
import imgClose from '../../media/img/inventory/close.png';
import { setOpenApplication } from '../../store/reducer/Slices/SlicesApplicationOpen';
import Application from '../application/Application';
import Modal from '../modal/Modal';
import useListenerKey from '../../hooks/useListenerKey';
import { useRequest } from '../../hooks/useRequest';

const hub = {
  1: <Application />,
};

const Window = () => {
  const dispatch = useDispatch();
  const [requestSend] = useRequest();
  const applicationNow = useSelector((state) => state.applicationOpen.now);
  const userRequest = useSelector(
    (state) => state.allUserData.data.h_battle_req,
  );
  const [downKey] = useListenerKey('Escape');

  const closeWindow = () => {
    dispatch(setOpenApplication(0));
    if (userRequest.request_id && userRequest.request_type < 6) {
      requestSend.sendData('RequestBattleRequestsCancel', {
        $type: 20,
        id: userRequest.request_id,
        request_type: userRequest.request_type,
      });
    }
  };

  useEffect(() => {
    closeWindow();
  }, [downKey]);

  if (applicationNow !== 0) {
    return (
      <>
        <div className="preWindow">
          <div className="window">
            <div className="window__container">
              <div
                className="window__container--close"
                onClick={() => closeWindow()}
              >
                <img src={imgClose} alt="закрыть" />
              </div>
              {hub[1]}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Window;
